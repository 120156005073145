/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useContext, useState, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {AssetContext, AssetContextProvider} from '../../context/AssetContext'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import {useHistory} from 'react-router-dom'
import AssetComponent from './AssetComponent'
import AssetForm from '../AddAssetClass/AssetForm'
import AddComponentModal from '../AddAssetClass/AddComponentModal'
import {assetFormValidationSchema} from '../../validators/asset-form'
import {useLocation} from 'react-router-dom'
import {Modal} from '../../../../../_metronic/layout/components/modal/Modal'
import {
  transformAssetClass,
  transformSaveAssetClass,
} from '../../transformer/asset-class-transformer'
import {IAssetClass} from '../../models/asset'
import {updateAssetClass} from '../../api/asset-class-api'

type IEditAssetPage = {}

const EditAssetPage = (props?: IEditAssetPage) => {
  const {
    showToastrNotification,
    isModalVisible,
    setModalVisible,
    previousAssetData,
    setPreviousAssetData,
    isCancelled,
    setIsCancelled,
  } = useContext(AssetContext)

  const history = useHistory()
  const locationState = useLocation().state as {params: any}

  const [isEdit, setIsEdit] = useState(false)
  const [isPromptVisible, setIsPromptVisible] = useState(true)

  const modifiedAssetData = {
    ...locationState.params,
  }

  const [assetData, setAssetData] = useState(transformAssetClass(modifiedAssetData))
  const [isSaving, setIsSaving] = useState(false)

  const {
    register,
    handleSubmit,
    formState: {errors},
    clearErrors,
    reset,
  } = useForm({
    defaultValues: assetData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(assetFormValidationSchema) as any,
  })

  const onSubmit = (values: IAssetClass) => {
    setIsSaving(true)
    if (values.id) {
      const payload = transformSaveAssetClass({
        ...values,
        assetClassComponents: assetData.assetClassComponents,
      })
      updateAssetClass(payload)
        .then((result) => {
          setIsSaving(false)
          history.push('/asset-classes')
          showToastrNotification('Asset class successfully updated!', 'success')
        })
        .catch(() => {
          setIsSaving(false)
          showToastrNotification('Oops we encountered a problem. Please try again!', 'error')
        })
    }
    setIsSaving(false)
  }

  useEffect(() => {
    setPreviousAssetData(modifiedAssetData) // Set previous assetData when component mounts
  }, [])

  const onCancel = () => {
    setIsCancelled(true)
    localStorage.setItem('isCancelled', JSON.stringify(true))
    // Reset assetData to previous value when cancel button is clicked
    setAssetData(previousAssetData)
    clearErrors()
    reset(previousAssetData)
    history.push('/asset-classes')
  }

  useEffect(() => {
    if (assetData.assetClassComponents.length < 1) {
      setIsPromptVisible(true)
    }
  }, [assetData.assetClassComponents])

  useEffect(() => {
    if (isCancelled) localStorage.setItem('isCancelled', JSON.stringify(false))
  }, [isCancelled])

  return (
    <div
      style={{
        backgroundColor: '#fff',
        height: 'auto',
        padding: 26,
        boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} name='asset'>
        <div className='d-flex flex-row align-items-center justify-content-end w-100'>
          <div className='d-flex flex-row align-items-center'>
            {/* <Button buttonType='primary' text={'Save'} /> */}
            <Button
              buttonType='primary'
              text={'Save'}
              //disabled={assetData.assetClassComponents.length === 0}
            />
            <Button
              buttonType='secondary'
              text={'Cancel'}
              onClick={onCancel}
              style={{marginLeft: 10}}
            />
          </div>
        </div>
        <AssetForm
          errors={errors}
          handleSubmit={handleSubmit}
          clearErrors={clearErrors}
          register={register}
          isSaving={isSaving}
        />
        <AssetComponent
          setModalVisible={setModalVisible}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          assetData={assetData}
          setAssetData={setAssetData}
        />
      </form>

      {isModalVisible &&
        createPortal(
          <AddComponentModal
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            assetData={assetData}
            setAssetData={setAssetData}
          />,
          document.body
        )}
    </div>
  )
}

const EditAssetClassWrapper: FC = () => {
  const intl = useIntl()

  return (
    <AssetContextProvider>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.EDITASSETCLASS'})}</PageTitle>
      <EditAssetPage />
    </AssetContextProvider>
  )
}

export {EditAssetClassWrapper}
