import React, {useCallback, useEffect, useContext, useState, useRef} from 'react'
import {UseFormHandleSubmit} from 'react-hook-form'
import {ProjectAssetData} from '../../../../../../../models/project-assets'
import axios from 'axios'
import {Dialog} from '@progress/kendo-react-dialogs'
import Map, {Marker} from 'react-map-gl/maplibre'
import Button from '../../../../../../../../../../_metronic/layout/components/buttons/Button'
import './AssetLocationMap.scss'
import {ProjectContext} from '../../../../../../../context/ProjectContext'
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder'
import 'maplibre-gl/dist/maplibre-gl.css'
import maplibregl from 'maplibre-gl'
import './AssetLocationMapModal.css'

type AssetLocationMapModalProps = {
  handleCancel: () => void
  handleSubmit: UseFormHandleSubmit<ProjectAssetData>
  onSubmit: () => void
}

const MAPTILER_ENDPOINT = process.env.REACT_APP_MAPTILER_ENDPOINT
const MAPTILER_STYLE_KEY = process.env.REACT_APP_MAPTILER_STYLE_KEY

export const AssetLocationMapModal = (props: AssetLocationMapModalProps) => {
  const [loading, setLoading] = useState(true)
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const {handleCancel, onSubmit} = props
  const {address, setAddress, pinnedLocation, setPinnedLocation, setRegion} =
    useContext(ProjectContext)

  const fetchAddress = useCallback(async () => {
    if (pinnedLocation) {
      try {
        setLoading(true)
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${
            pinnedLocation && pinnedLocation[1]
          }&lon=${pinnedLocation && pinnedLocation[0]}`
        )
        if (response.data.error === 'Unable to geocode') {
          setAddress('')
        } else {
          setRegion(response.data.address.municipality)
          setAddress(response.data.display_name)
        }
      } catch (error) {
        console.error('Error fetching address:', error)
        return ''
      }
      setLoading(false)
    }
  }, [pinnedLocation])

  useEffect(() => {
    fetchAddress()
    return () => {}
  }, [pinnedLocation])

  const handleMarkerDrag = (event: any) => {
    const {lng, lat} = event.lngLat

    setAddress('') // Clear previous address
    setPinnedLocation([lng, lat])
    clearTimeout(debounceTimeout)
  }

  const handleMarkerDragEnd = (event: any) => {
    const {lng, lat} = event.lngLat
    console.log(event.lngLat)
    setAddress('') // Clear previous address
    setPinnedLocation([lng, lat])
    // fetchAddress()
    clearTimeout(debounceTimeout)
  }

  /** */
  let debounceTimeout: any
  const debounce = (func: any, delay: any) => {
    clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(func, delay)
  }

  const handleAddressSearch = useCallback(async () => {
    try {
      console.log('address', address)
      setLoading(true)
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json`
      )

      if (response.data && response.data.length > 0) {
        const {lat, lon} = response.data[0]
        setAddress('') // Clear previous address
        setPinnedLocation([parseFloat(lon), parseFloat(lat)])
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error)
    }
    setLoading(false)
  }, [address, setPinnedLocation])

  const debouncedHandleAddressSearch = () => {
    debounce(handleAddressSearch, 300)
  }

  useEffect(() => {
    debouncedHandleAddressSearch()
    return () => {
      clearTimeout(debounceTimeout)
    }
  }, [])

  const handleMapLoad = () => {
    setIsMapLoaded(true)
    console.log('Map has fully loaded')
  }

  /*
  
*/

  const mapRef = useRef<any>(null)

  useEffect(() => {
    const map = mapRef.current
    //console.log('start', map)

    if (map) {
      const Geo = {
        forwardGeocode: async (config: any) => {
          //console.log('forward', config.query)
          const res = await fetch(
            `https://nominatim.openstreetmap.org/search?q=${config.query}&format=geojson&polygon_geojson=1&addressdetails=1`
          )

          var result = await res.json().then((response) => {
            const features = []
            for (const feature of response.features) {
              const center = [
                feature.bbox[0] + (feature.bbox[2] - feature.bbox[0]) / 2,
                feature.bbox[1] + (feature.bbox[3] - feature.bbox[1]) / 2,
              ]
              const point = {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: center,
                },
                place_name: feature.properties.display_name,
                properties: feature.properties,
                text: feature.properties.display_name,
                place_type: ['place'],
                center,
              }
              features.push(point)
            }

            return Promise.resolve<any>({features})
          })

          return result
        },
        reverseGeocode: async (config: any) => {
          const res = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${config.lat}&lon=${config.lon}`
          )

          return await res.json()
        },

        /*
        getSuggestions: async (config: any) => {
          const res = await fetch(
            `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
              config.query
            )}&format=json&addressdetails=1`
          )
          console.log('suggest')

          var result = await res.json().then((response) => {
            const data = response.map((item: any) => ({
              place_name: item.display_name,
              geometry: {
                coordinates: [item.lon, item.lat],
              },
            }))

            console.log(response)

            return Promise.resolve<any>(data)
          })

          //return {
          //  suggestions: [
          //    {text: 'ssss ', placeId: '111'},
          //    {text: 'ssss2 ', placeId: '111'},
          //  ],
          //}
          console.log(result)
          return result

          //return {
          //   suggestions: (await res.json()).features,
          //}
        },*/
      }

      // Pass in or define a geocoding API that matches the above
      const geocoder = new MaplibreGeocoder(Geo, {maplibregl: maplibregl})
      geocoder.setLimit(10)
      map.addControl(geocoder, 'top-left')

      // Cleanup on unmount
      return () => {
        map.removeControl(geocoder)
      }
    }
  }, [isMapLoaded])

  return (
    <Dialog
      height={'auto'}
      minWidth={'50%'}
      style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
      title={'Asset Location'}
      onClose={handleCancel}
    >
      <>
        <p style={{color: 'rgb(66, 66, 66)', fontStyle: 'italic'}}>Pin a location on the map</p>

        {loading ? (
          <div className='d-flex alig-items-center justify-content-center mb-4'>
            <div className='spinner-border' role='status' style={{color: '#a71304'}}>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : !pinnedLocation && !address ? (
          <div style={{height: '50px'}}>
            <p style={{color: 'red', fontStyle: 'italic'}}>No location selected</p>
          </div>
        ) : (
          <div style={{height: '50px'}}>
            {pinnedLocation && address && (
              <p style={{color: 'rgb(66, 66, 66)', fontStyle: 'italic', marginBottom: 0}}>
                {pinnedLocation && pinnedLocation[0]}, {pinnedLocation && pinnedLocation[1]}
              </p>
            )}
            {pinnedLocation && <p style={{color: 'red', fontStyle: 'italic'}}>{address}</p>}
          </div>
        )}
        <Map
          onLoad={handleMapLoad}
          ref={mapRef}
          onClick={handleMarkerDrag}
          onDblClick={handleMarkerDrag}
          initialViewState={{
            longitude: 146.73796758540948,
            latitude: -20.282642493114977,
            zoom: 4,
          }}
          style={{width: '100%', height: '400px'}}
          mapStyle={`${MAPTILER_ENDPOINT}/maps/streets/style.json?key=${MAPTILER_STYLE_KEY}`}
        >
          {pinnedLocation && (
            <Marker
              longitude={pinnedLocation[0]}
              latitude={pinnedLocation[1]}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
            />
          )}
        </Map>

        <div className='d-flex flex-row align-items-center justify-content-end w-100 mt-10'>
          <div className='d-flex flex-row align-items-center'>
            <Button buttonType='secondary' text={'Cancel'} onClick={handleCancel} />
            <Button
              buttonType='primary'
              text={'Save'}
              style={{marginLeft: 10}}
              onClick={onSubmit}
            />
          </div>
        </div>
      </>
    </Dialog>
  )
}
