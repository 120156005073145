import {useState, useEffect} from 'react'
import {
  Controller,
  Control,
  UseFormSetValue,
  FieldErrors,
  UseFormRegister,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormGetValues,
} from 'react-hook-form'
import Select from 'react-select'
import TitleHeader from '../../../../../../../../../../_metronic/layout/components/header/TitleHeader'
import {ProjectAssetData} from '../../../../../../../models/project-assets'
import {getConstructionDifficulty} from '../../../../../../../api/asset-api'

type IConstructionDifficultyProps = {
  control: Control<ProjectAssetData>
  isValid: boolean
  setValue: UseFormSetValue<ProjectAssetData>
  getValues: UseFormGetValues<ProjectAssetData>
  errors: FieldErrors<ProjectAssetData>
  register: UseFormRegister<ProjectAssetData>
  clearErrors: UseFormClearErrors<ProjectAssetData>
  handleSubmit: UseFormHandleSubmit<ProjectAssetData>
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData[]) => void
}

type ConstructionDifficulty = {
  fulcrumId: string
  fulcrumReference: string
  distanceFromRoad: string
  siteFactors: string
  fullText: string
  sizeSqm: number
  defaultUnitRate: number
  unAdjustedReplacementCost: number
  afterAdjustment: number
  allowance: number
  fulcrumReferenceChoices: string[]
}

function ConstructionDifficultySection(props: IConstructionDifficultyProps) {
  const {control, errors, clearErrors, setValue, getValues} = props
  const [constructionDifficulty, setConstructionDifficulty] = useState<ConstructionDifficulty[]>([])

  /**
   * DISTANCE FROM ROAD
   */
  const [distanceFromRoad, setDistanceFromRoad] = useState<{value: string; label: string}[]>([])
  const selectedDistance = getValues('distanceFromRoad')

  /**
   * CONSTRUCTION DIFFICULTY FACTOR
   */
  const [constructionDifficultyFactor, setConstructionDifficultyFactor] = useState<
    {value: string; label: string}[]
  >([])

  const getDifficultyFactor = () => {
    const difficultyFactors: {value: string; label: string}[] = constructionDifficulty
      .filter(
        (difficulty: ConstructionDifficulty) => difficulty?.distanceFromRoad === selectedDistance
      )
      .map((difficulty: ConstructionDifficulty) => ({
        value: difficulty.siteFactors,
        label: difficulty.siteFactors,
      }))

    setConstructionDifficultyFactor(difficultyFactors)
  }

  useEffect(() => {
    getDifficultyFactor()
  }, [selectedDistance])

  const fetchDistanceFromRoad = async () => {
    const response = await getConstructionDifficulty()
    setConstructionDifficulty(response.data)

    const distanceSet = new Set()
    response.data.forEach(
      (distance: {
        fulcrumId: string
        fulcrumReference: string
        distanceFromRoad: string
        siteFactor: number | null
        fullText: string
        sizeSqm: number
        defaultUnitRate: number
        unAdjustedReplacementCost: number
        afterAdjustment: number
        allowance: number
        fulcrumReferenceChoices: string[]
      }) => {
        distanceSet.add(distance.distanceFromRoad)
      }
    )

    const distanceOptions = Array.from(distanceSet).map((distance) => ({
      value: distance,
      label: distance,
    })) as {value: string; label: string}[]

    setDistanceFromRoad(distanceOptions)
  }

  useEffect(() => {
    fetchDistanceFromRoad()
  }, [])

  return (
    <>
      <TitleHeader title='Construction Difficulty' />
      <div id='constructionDifficulty'>
        <div className='col'>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='distance'>
              Distance From Road
            </label>
            <Controller
              control={control}
              name='distanceFromRoad'
              render={({field: {onChange, value}}) => (
                <div>
                  <Select
                    placeholder='Select Distance From Road'
                    id='distance'
                    isMulti={false}
                    options={distanceFromRoad}
                    value={{
                      label: value,
                      value: value,
                    }}
                    onChange={(e) => {
                      onChange(e?.value)
                      clearErrors('distanceFromRoad')
                      setValue('constructionAndSiteFactors', '')
                    }}
                    className={`controllerSelect${errors.distanceFromRoad ? ' border-danger' : ''}`}
                  />
                  {errors.distanceFromRoad && (
                    <small className='text-danger'>{errors.distanceFromRoad.message}</small>
                  )}
                </div>
              )}
            />
          </div>
          <div className='form-outline'>
            <label className='fw-bolder' htmlFor='difficulty'>
              Construction Difficulty Factor
            </label>
            <Controller
              control={control}
              name='constructionAndSiteFactors'
              render={({field: {onChange, value}}) => (
                <div>
                  <Select
                    placeholder='Select Construction Difficulty Factor'
                    id='constructionAndSiteFactors'
                    isMulti={false}
                    options={constructionDifficultyFactor}
                    value={{
                      label: value,
                      value: value,
                    }}
                    onChange={(e) => {
                      onChange(e?.value)
                      clearErrors('constructionAndSiteFactors')
                    }}
                    className={`controllerSelect${
                      errors.constructionAndSiteFactors ? ' border-danger' : ''
                    }`}
                  />
                  {errors.constructionAndSiteFactors && (
                    <small className='text-danger'>
                      {errors.constructionAndSiteFactors.message}
                    </small>
                  )}
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ConstructionDifficultySection
