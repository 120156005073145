import React from 'react'
import './Modal.css'

export const Modal = ({isOpen, closeModal, children, width}: any) => {
  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className='overlay' onClick={closeModal}></div>
      <div className='modal-content' style={{width}}>
        {children}
      </div>
    </div>
  )
}
