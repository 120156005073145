import React, {useContext, useState, useEffect} from 'react'
import toastr from 'toastr'
import {Dialog} from '@progress/kendo-react-dialogs'
import './AdjustmentMatrixInfoModal.css'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {AdjustmentMatrixContext} from '../../context/AdjustmentMatrixContext'
import {CustomAlert, IAlert} from '../../../../shared/components/CustomAlert'
import {adjustmentMatrixFormValidationSchema} from '../../validators/adjustmentmatrix-form'
import {ADJUSTMENT_MATRIX_FORM_DEFAULT} from '../../constant/adjustment-matrix-default'
import {IAdjustmentMatrix} from '../../models/adjustmentmatrix'
import {createAdjustmentMatrix, updateAdjustmentMatrix} from '../../api/adjustment-matrix-api'
import {Loader2} from '../../../../../_metronic/layout/components/loader/Loader2'
import {AdjustmentMatrixFormFields} from './AdjustmentMatrixFormFields'

function AdjustmentMatrixInfoModal(props: any) {
  const {
    initialValue,
    isModalVisible,
    setModalVisible,
    selectedData,
    setSelectedData,
    userGroupOptions,
    fetchData,
  } = useContext(AdjustmentMatrixContext)

  const [formNotification, setformNotification] = useState<IAlert | undefined>()
  const [isDisabled, setDisabled] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    reset,
    clearErrors,
  } = useForm({
    defaultValues: selectedData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(adjustmentMatrixFormValidationSchema),
  })

  useEffect(() => {
    if (selectedData) {
      reset({
        ...selectedData,
      })
    } else {
      reset(ADJUSTMENT_MATRIX_FORM_DEFAULT)
    }
    setformNotification(undefined)
  }, [selectedData, reset])

  const closeModal = () => {
    setModalVisible(false)
  }

  const onSubmit = async (values: IAdjustmentMatrix) => {
    const payload: IAdjustmentMatrix = values
    setIsSaving(true)
    if (payload.id === 0) {
      createAdjustmentMatrix(payload)
        .then(() => {
          setIsSaving(false)
          closeModal()
          fetchData()
          setSelectedData(initialValue)
          reset(ADJUSTMENT_MATRIX_FORM_DEFAULT)
          toastr.success('Record added successfully.', 'Saving Adjustment Matrix List')
        })
        .catch((err) => {
          setIsSaving(false)
          if (err.response) {
            toastr.error(err.response.data.message, 'Saving Adjustment Matrix List')
          } else {
            toastr.error(err.message, 'Saving Adjustment Matrix List')
          }
          setformNotification({
            message: err.response.data ? err.response.data : 'Error saving',
            header: `Error Saving Record`,
            type: 'danger',
          })
        })
    } else {
      updateAdjustmentMatrix(payload)
        .then(() => {
          setIsSaving(false)
          closeModal()
          fetchData()
          setSelectedData(initialValue)
          reset(ADJUSTMENT_MATRIX_FORM_DEFAULT)
          toastr.success('Record updated successfully.', 'Update Adjustment Matrix List')
        })
        .catch((err) => {
          setIsSaving(false)
          if (err.response) {
            toastr.error(err.response.data.message, 'Update Adjustment Matrix List')
          } else {
            toastr.error(err.message, 'Update Adjustment Matrix List')
          }
          setformNotification({
            message: err.response.data ? err.response.data.title : 'Error saving',
            header: `Error Saving Record`,
            type: 'danger',
          })
        })
    }
    setIsSaving(false)
  }

  const handleCancel = () => {
    setSelectedData(initialValue)
    closeModal()
    reset(ADJUSTMENT_MATRIX_FORM_DEFAULT)
    clearErrors()
  }

  return (
    <div style={{display: 'none'}}>
      {isModalVisible && (
        <Dialog
          height={'auto'}
          minWidth={'30%'}
          style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
          title={selectedData.id === 0 ? 'Add Record' : 'Edit Record'}
          onClose={handleCancel}
        >
          <form onSubmit={handleSubmit(onSubmit)} name='user'>
            {formNotification && <CustomAlert {...formNotification} />}
            {isSaving && <Loader2></Loader2>}
            <AdjustmentMatrixFormFields
              adjustmentMatrixData={selectedData}
              register={register}
              control={control}
              errors={errors}
              userGroupOptions={userGroupOptions}
              isDisabled={isDisabled}
              clearErrors={clearErrors}
            ></AdjustmentMatrixFormFields>
            <div className='d-flex flex-row align-items-center justify-content-end w-100 mb-2'>
              <div className='d-flex flex-row align-items-center'>
                <Button buttonType='primary' text={'Save'} />
                <Button
                  buttonType='secondary'
                  text={'Cancel'}
                  onClick={handleCancel}
                  style={{marginLeft: 10}}
                />
              </div>
            </div>
          </form>
        </Dialog>
      )}
    </div>
  )
}

export default AdjustmentMatrixInfoModal
