import { Redirect } from 'react-router-dom';
import { usePageData } from '../../_metronic/layout/core';
import { PAGE_PERMISSION } from '../modules/roles/models/role-model';

const ActivePageBasedRedirect = () => {

  const { currentUser: { activePages } } = usePageData();

  if (!activePages || activePages.length === 0) {
    return <Redirect to='error/404' />;
  }

  const permissionsOrder = [
    { permission: PAGE_PERMISSION.DASHBOARD_VIEW, route: '/dashboard' },
    { permission: PAGE_PERMISSION.PROJECT_VIEW, route: '/projects' },
    { permission: PAGE_PERMISSION.REPORTS_VIEW, route: '/reports' },
    { permission: PAGE_PERMISSION.ASSET_CLASSIFICATION_VIEW, route: '/asset-classes' },
    { permission: PAGE_PERMISSION.USER_MANAGEMENT_VIEW, route: '/user-management' },
    { permission: PAGE_PERMISSION.CLIENTS_VIEW, route: '/clients' },
    { permission: PAGE_PERMISSION.OFFICE_LOCATIONS_VIEW, route: '/office-locations' },
  ];
  for (let { permission, route } of permissionsOrder) {
    if (activePages.some(page => page === permission)) {
      return <Redirect to={route} />;
    }
  }

  return null;

};

export default ActivePageBasedRedirect;
