import React, {FC, useContext, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import Button from '../../../_metronic/layout/components/buttons/Button'
import {
  AdjustmentMatrixContext,
  AdjustmentMatrixContextProvider,
} from './context/AdjustmentMatrixContext'
import AdjustmentMatrixLookupTable from './components/AdjustmentMatrixLookupTable/AdjustmentMatrixLookupTable'
import AdjustmentMatrixInfoModal from './components/modal/AdjustmentMatrixInfoModal'

const AdjustmentMatrixPage = () => {
  const {setSearchFilter, initialValue, setSelectedData, setModalVisible} =
    useContext(AdjustmentMatrixContext)

  const [searchText, setSearchText] = useState<string>('')

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      setSearchFilter(searchText)
      event.preventDefault()
    }
  }

  const handleNew = () => {
    setSelectedData(initialValue)
    setModalVisible(true)
  }

  return (
    <div
      style={{
        backgroundColor: '#fff',
        height: 'auto',
        padding: 26,
        boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px',
      }}
    >
      <div className='d-flex align-items-center justify-content-between'>
        <div style={{position: 'relative'}}>
          <input
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            onKeyDown={handleKeyPress}
            type='text'
            placeholder='Search Rates'
            style={{
              minWidth: 400,
              minHeight: 35,
              borderRadius: 17.5,
              border: '1px solid #E5E4E2',
              paddingLeft: 30,
              outline: 'none',
              fontStyle: 'italic',
            }}
          />
          <i
            className='bi bi-search'
            style={{position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)'}}
          ></i>
        </div>
        <div className='d-flex align-items-center'>
          <Button
            text={'Add Record'}
            buttonType='primary'
            onClick={() => handleNew()}
            icon={<i className='bi bi-plus-lg' style={{color: '#fff'}}></i>}
          />
        </div>
      </div>
      <AdjustmentMatrixLookupTable />
      <AdjustmentMatrixInfoModal />
    </div>
  )
}
const AdjustmentMatrixWrapper: FC = () => {
  const intl = useIntl()

  return (
    <AdjustmentMatrixContextProvider>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'MENU.ADJUSTMENTMATRIXLOOKUP'})}
      </PageTitle>
      <AdjustmentMatrixPage />
    </AdjustmentMatrixContextProvider>
  )
}

export {AdjustmentMatrixWrapper}
