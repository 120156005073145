import React, {useContext, useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import Map from 'react-map-gl/maplibre'
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'
import {ProjectAssetData} from '../../../../../../../models/project-assets'
import {ProjectContext} from '../../../../../../../context/ProjectContext'
import {AssetLocationMapModal} from './AssetLocationMapModal'
import './AssetLocationMap.scss'

const MAPTILER_ENDPOINT = process.env.REACT_APP_MAPTILER_ENDPOINT
const MAPTILER_STYLE_KEY = process.env.REACT_APP_MAPTILER_STYLE_KEY

type AssetLocationMapProps = {
  control: Control<ProjectAssetData>
  isValid: boolean
  setValue: UseFormSetValue<ProjectAssetData>
  errors: FieldErrors<ProjectAssetData>
  register: UseFormRegister<ProjectAssetData>
  clearErrors: UseFormClearErrors<ProjectAssetData>
  handleSubmit: UseFormHandleSubmit<ProjectAssetData>
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData[]) => void
  projectId: string
  selectInputRef: any
}

const AssetLocationMap = (props: AssetLocationMapProps) => {
  const {handleSubmit, selectInputRef, setValue, selectedData} = props
  const {setAddress, setPinnedLocation, setRegionOptions, pinnedLocation} =
    useContext(ProjectContext)
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleMapClick = async (event: any) => {
    setShowModal(true)
  }

  const handleCancel = () => {
    setShowModal(false)
    setAddress('')
    setPinnedLocation(null)
    setRegionOptions([])
    selectInputRef?.current.clearValue()
  }

  const onSubmit = () => {
    setShowModal(false)
  }

  useEffect(() => {
    if (pinnedLocation) {
      const [long, lat] = pinnedLocation
      setValue('latitude', lat)
      setValue('longitude', long)
    }
  }, [pinnedLocation])

  return (
    <>
      <Map
        onClick={handleMapClick}
        initialViewState={{
          longitude: selectedData.longitude,
          latitude: selectedData.latitude,
          zoom: 3,
        }}
        style={{width: '100%', height: 150}}
        mapStyle={`${MAPTILER_ENDPOINT}/maps/streets/style.json?key=${MAPTILER_STYLE_KEY}`}
      />
      {showModal &&
        createPortal(
          <AssetLocationMapModal
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />,
          document.body
        )}
    </>
  )
}

export default AssetLocationMap
