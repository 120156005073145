import {SortDescriptor} from '@progress/kendo-data-query'
import axios from 'axios'

export const OFFICE_URL = `${process.env.REACT_APP_API_URL}/Office`

export const getAllOffices = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  search: string
) => {
  return await axios.post(`${OFFICE_URL}/Paginated`, {
    filter: search,
    skip: skip,
    take: take,
    sort: pageSort ? `${pageSort.field} ${pageSort.dir}` : '',
  })
}

export const addNewOffice = async (data: any) => {
  return await axios.post(`${OFFICE_URL}`, data)
}

export const updateOffice = async (data: any) => {
  return await axios.put(`${OFFICE_URL}`, data)
}

export const deleteOffice = async (id: number) => {
  return await axios.delete(`${OFFICE_URL}`, {
    params: {
      id,
    },
  })
}

export const getOffices = async () => {
  return await axios.get(`${OFFICE_URL}`)
}

export const getUserOffices = async () => {
  return await axios.get(`${OFFICE_URL}/getuseroffices`)
}
