import React, {useContext, useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import toastr from 'toastr'
import {Dialog} from '@progress/kendo-react-dialogs'
import './UserInfoModal.css'
import {UserContext} from '../../../context/UserContext'
import Button from '../../../../../../_metronic/layout/components/buttons/Button'
import {UserFormFields} from './UserFormFields'
import {CustomAlert, IAlert} from '../../../../../shared/components/CustomAlert'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {userFormValidationSchema} from '../../../validators/user-form'
import {IUserData} from '../../../models/user-model'
import {
  transformDataUserGroupNames,
  transformDataUserGroups,
  transformDataUserOffices,
  transformSaveUserGroups,
  transformSaveUserOffices,
} from '../../../transformers/user-transformer'
import {USER_FORM_DEFAULT} from '../../../constant/user-default'
import {createUser, updateUser} from '../../../api'
import { Loader2 } from '../../../../../../_metronic/layout/components/loader/Loader2'
import { AccessControlProvider } from '../../../../../../_metronic/layout/core/AccessControlProvider'
import { PAGE_PERMISSION } from '../../../../roles/models/role-model'
import { useLayout } from '../../../../../../_metronic/layout/core'

function UserInfoModal(props: any) {

  const { selectedOffice } = useLayout()
  const {
    initialValue,
    isModalVisible,
    setModalVisible,
    setSelectedData,
    selectedData,
    userGroupOptions,
    fetchData,
    officeOptions,
  } = useContext(UserContext)

  const [formNotification, setformNotification] = useState<IAlert | undefined>()
  const [isDisabled, setDisabled] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    reset,
    clearErrors,
  } = useForm({
    defaultValues: selectedData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(userFormValidationSchema),
  })

  useEffect(() => {
    if (selectedData) {
      selectedData.selectedUserGroups = transformDataUserGroupNames(selectedData.userGroups)
      selectedData.selectedUserOffices = transformDataUserOffices(selectedData.officeData)

      !selectedData.id ? setDisabled(false) : setDisabled(true)
      reset({
        ...selectedData,
      })
    } else {
      reset(USER_FORM_DEFAULT)
    }
    setformNotification(undefined)
  }, [selectedData, reset])

  const closeModal = () => {
    setModalVisible(false)
  }

  const onSubmit = async (values: IUserData) => {
    const payload: IUserData = values
    payload.userGroups = transformSaveUserGroups(values.selectedUserGroups)
    payload.userOffices = transformSaveUserOffices(values.selectedUserOffices)
    setIsSaving(true)
    if (payload.id === '') {
      createUser(payload)
        .then(() => {
          setIsSaving(false)
          closeModal()
          fetchData('', selectedOffice.id)
          toastr.success('User saved successfully.', 'Saving User List')
        })
        .catch((err) => {
          setIsSaving(false)
          if (err.response) {
            toastr.error(err.response.data.message, 'Saving User List')
          } else {
            toastr.error(err.message, 'Saving User List')
          }          
          setformNotification({
            message: err.response.data ? err.response.data : 'Error saving',
            header: `Error Saving User`,
            type: 'danger',
          })
        })
    } else {
      updateUser(payload)
        .then(() => {
          setIsSaving(false)
          closeModal()
          fetchData('', selectedOffice.id)
          toastr.success('User update successfully.', 'Update User List')
        })
        .catch((err) => {
          setIsSaving(false)
          if (err.response) {
            toastr.error(err.response.data.message, 'Update User List')
          } else {
            toastr.error(err.message, 'Update User List')
          }
          setformNotification({
            message: err.response.data ? err.response.data.title : 'Error saving',
            header: `Error Edit User`,
            type: 'danger',
          })
        })
    }
    setIsSaving(false)
    setSelectedData(initialValue)
  }

  const handleCancel = () => {
    setSelectedData(initialValue)
    closeModal()
    //
    reset(USER_FORM_DEFAULT)
    clearErrors()
  }

  return (
    <div style={{display: 'none'}}>
      {isModalVisible && (
        <Dialog
          height={'auto'}
          minWidth={'60%'}
          style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
          title={selectedData.id === '' ? 'Add New User' : 'Edit User'}
          onClose={handleCancel}
        >
          <form onSubmit={handleSubmit(onSubmit)} name='user'>
            {formNotification && <CustomAlert {...formNotification} />}
            {isSaving && <Loader2></Loader2>}
            <UserFormFields
              userData={selectedData}
              register={register}
              control={control}
              errors={errors}
              userGroupOptions={userGroupOptions}
              isDisabled={isDisabled}
              officeOptions={officeOptions}
              clearErrors={clearErrors}
            ></UserFormFields>
            <div className='d-flex flex-row align-items-center justify-content-end w-100 mb-2'>
              <div className='d-flex flex-row align-items-center'>
                <AccessControlProvider allowedPermission={selectedData.id === 0 ? PAGE_PERMISSION.USER_MANAGEMENT_CREATE : PAGE_PERMISSION.USER_MANAGEMENT_UPDATE}>
                    <Button buttonType='primary' text={'Save'} />
                </AccessControlProvider>
                <Button
                  buttonType='secondary'
                  text={'Cancel'}
                  onClick={handleCancel}
                  style={{marginLeft: 10}}
                />
              </div>
            </div>
          </form>
        </Dialog>
      )}
    </div>
  )
}

export default UserInfoModal
