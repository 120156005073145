import {FC, useContext, useEffect, useState} from 'react'
import {Grid, GridColumn as Column} from '@progress/kendo-react-grid'
import React from 'react'
import {FieldArrayWithId, UseFieldArrayReplace} from 'react-hook-form'
import {IProjectAssetNote, ProjectAssetData} from '../../../models/project-assets'
import {ProjectContext} from '../../../context/ProjectContext'
import {deleteProjectAssetNote} from '../../../api/asset-api'
import {ActionButton} from '../../../../../../_metronic/layout/components/buttons/ActionButton'
import {gearIcon} from '@progress/kendo-svg-icons'
import moment from 'moment'
import {Dialog} from '@progress/kendo-react-dialogs'
import Button from '../../../../../../_metronic/layout/components/buttons/Button'

type IProps = {
  projectAssetNotes: FieldArrayWithId<ProjectAssetData, 'projectAssetNotes', 'keyIdx'>[]
  replaceProjectAssetNotes: UseFieldArrayReplace<ProjectAssetData, 'projectAssetNotes'>
  projectAssetId?: number
  editedIndex: number | null
  setEditedIndex: (value: number | null) => void
  currentUserId?: string
  noteText: string
  setNoteText: (value: string) => void
}

const AssetNoteCustomFields: FC<IProps> = ({
  projectAssetNotes,
  replaceProjectAssetNotes,
  setEditedIndex,
  editedIndex,
  currentUserId,
  projectAssetId,
  noteText,
  setNoteText,
}) => {
  const {showToastrNotification} = useContext(ProjectContext)
  const [data, setData] = useState<IProjectAssetNote[]>(projectAssetNotes)
  const items = ['Edit Note', 'Delete Note']
  const [selectedDeleteIndex, setSelectedDeleteIndex] = useState<number | null>(null)

  useEffect(() => {
    setData(projectAssetNotes)
  }, [projectAssetNotes])

  const handleEdit = (index: number) => {
    setEditedIndex(index)
    setNoteText(data[index].note)
  }

  const handleDelete = (index: number) => {
    setSelectedDeleteIndex(index)
  }

  const renderCellDate = (props: any) => {
    const {field, dataItem} = props
    const isValid = !isNaN(new Date(dataItem[field]).getDate())
    let formattedDate = ''

    if (isValid) {
      const date = new Date(dataItem[field])
      formattedDate = moment(date).format('DD-MMMM-YYYY')
    }

    return (
      <td>
        <span>{formattedDate}</span>
      </td>
    )
  }

  const handleActionDropDownClick = (
    dataIndex: number,
    user: IProjectAssetNote,
    action: string
  ) => {
    if (action === 'Edit Note') {
      handleEdit(dataIndex)
    } else if (action === 'Delete Note') {
      handleDelete(dataIndex)
    }
  }

  const renderActions = (props: any) => {
    const {dataIndex, dataItem} = props
    const isEdited = dataIndex === editedIndex
    const rowUserId = props.dataItem.userId
    if (rowUserId !== currentUserId && dataItem.id !== 0) return <td></td>

    if (isEdited) {
      return <td></td>
    }

    return (
      <td className='align-middle text-center'>
        <ActionButton
          svgIcon={gearIcon}
          text='Actions'
          items={items}
          onItemClick={(event: any) =>
            handleActionDropDownClick(dataIndex, props.dataItem, event.item)
          }
        />
      </td>
    )
  }

  const handleNoteChange = (event: any) => {
    setNoteText(event.target.value)
  }

  const handleCloseDeleteDialog = () => {
    setSelectedDeleteIndex(null)
  }

  const deleteItem = async () => {
    try {
      if (selectedDeleteIndex !== null) {
        const deleteId = data[selectedDeleteIndex].id
        await deleteProjectAssetNote(deleteId).then((response) => {
          if (response) {
            const newData = [...data]
            newData.splice(selectedDeleteIndex, 1)
            setData(newData)
            setSelectedDeleteIndex(null)

            replaceProjectAssetNotes([...newData])
            showToastrNotification('Asset Note Deleted succesfully', 'success')
          } else {
            showToastrNotification('Error Deleting Asset Note. Please try again.', 'error')
          }
        })

        //TOASTR
        toastr.success('User deleted successfully.', 'Delete User List')
      }
    } catch (error) {
      setSelectedDeleteIndex(null)
    }
  }

  return (
    <div>
      {selectedDeleteIndex !== null && (
        <Dialog
          height={'auto'}
          style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
          title={'Delete Note'}
          onClose={handleCloseDeleteDialog}
        >
          <div className='row mb-4'>
            <div className='col'>
              <p>Are you sure you want to delete this item?</p>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col  d-flex justify-content-end'>
              <Button onClick={deleteItem} buttonType='primary' text={'Yes'} />
              <Button
                buttonType='secondary'
                text={'No'}
                onClick={() => setSelectedDeleteIndex(null)}
                style={{marginLeft: 10}}
              />
            </div>
          </div>
        </Dialog>
      )}
      {editedIndex !== null && (
        <div>
          <label className='fw-bolder'>Notes / Remarks</label>
          <textarea
            style={{
              width: '100%',
              fontSize: '1rem',
            }}
            value={noteText}
            onChange={handleNoteChange}
            className={`form-control mt-2 mb-5`}
            rows={2}
            cols={50}
            placeholder='Type something here...'
          />
        </div>
      )}
      {data?.length === 0 ? (
        <p>No Notes </p>
      ) : (
        <Grid data={data}>
          <Column field='lastModified' title='Date' cell={renderCellDate} />
          <Column field='userName' title='Created By' />
          <Column field='note' title='Notes' /*cell={renderCellNote}*/ />
          <Column width={130} title='' cell={renderActions} />
        </Grid>
      )}
    </div>
  )
}

export {AssetNoteCustomFields}
