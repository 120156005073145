import {SortDescriptor} from '@progress/kendo-data-query'
import axios from 'axios'
import {IClient} from '../../clients/models/client'

export const CLIENT_URL = `${process.env.REACT_APP_API_URL}/Client`
export const OFFICE_URL = `${process.env.REACT_APP_API_URL}/Office`

export const getAllClients = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  search: string,
  officeId: number,
) => {

  return await axios.post(`${CLIENT_URL}/pagination`, {
    filter: search,
    skip: skip,
    take: take,
    sort:  pageSort ? `${pageSort.field} ${pageSort.dir}` : '',
    officeId: officeId
  })
}

export const addNewClient = async (data: IClient) => {
  return await axios.post(`${CLIENT_URL}`, data)
}

export const updateClient = async (data: IClient) => {
  return await axios.put(`${CLIENT_URL}`, data)
}

export const deleteClient = async (id: number) => {
  return await axios.delete(`${CLIENT_URL}`, {
    params: {
      id,
    },
  })
}

export const getOffices = async () => {
  return await axios.get(`${OFFICE_URL}`)
}
