import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getUserByToken} from './AuthCRUD'
import {RootState} from '../../../../setup'
import MsalService from '../azure/MsalService'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const rememberMe = useSelector<RootState>(({auth}) => auth.isRememberMe, shallowEqual) as any
  const {setActiveAccount /*, isAuthenticated, accounts*/} = MsalService()

  useEffect(() => {
    const requestUser = async () => {
      try {
        // Ensure if azure is login set the active user
        setActiveAccount()
        // if (accounts.length > 0) {
        //   props.fulfillUser(
        //     {
        //       email: accounts[0].username,
        //     } as any,
        //     rememberMe
        //   )
        // } else {
        //   if (!didRequest.current) {
        const {data: user} = await getUserByToken(accessToken as string)
        dispatch(props.fulfillUser(user, rememberMe))
        // }
        // }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUser()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
