import {SortDescriptor} from '@progress/kendo-data-query'
import axios from 'axios'
import {IProjectData} from '../models/project-model'
import {ASSETCLASS_URL} from '../../asset-classes/api/asset-class-api'
import {IAssetValuationReportSheets} from '../models/report'
//import {IUserGroup} from '../models/user-group'

export const PROJECT_URL = `${process.env.REACT_APP_API_URL}/project`
export const ROLE_URL = `${process.env.REACT_APP_API_URL}/Role`
export const CLIENT_URL = `${process.env.REACT_APP_API_URL}/Role`
export const USER_URL = `${process.env.REACT_APP_API_URL}/User`
export const PROJECT_SETTING_URL = `${process.env.REACT_APP_API_URL}/projectsetting`
export const PROJECT_ASSETS = `${process.env.REACT_APP_API_URL}/ProjectAsset/Pagination`
export const EVALUATION_REGION_URL = `${process.env.REACT_APP_API_URL}/evaluationregion`
export const REGION_INDEX_URL = `${process.env.REACT_APP_API_URL}/regionindex`
export const PROJECT_ASSET_URL = `${process.env.REACT_APP_API_URL}/projectAsset`
export const LEAD_UP_TIMES_URL = `${process.env.REACT_APP_API_URL}/leadupTimes`
export const REBUILD_TIMES_URL = `${process.env.REACT_APP_API_URL}/rebuildTimes`
export const REPORT_URL = `${process.env.REACT_APP_API_URL}/Report`

// PROJECT ASSETS
export const getProjectAssets = async (
  id: string,
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  search: string
) => {
  return await axios.post(PROJECT_ASSETS, {
    projectId: id,
    skip,
    take,
    sort: `${pageSort.field} ${pageSort.dir}`,
    filter: search,
  })
}

// PROJECTS //
export const getProjects = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  search: string,
  selectedOffice: number
) => {
  return await axios.post(`${PROJECT_URL}/pagination`, {
    filter: search,
    pageNumber: skip,
    pageSize: take,
    sort: `${pageSort.field} ${pageSort.dir}`,
    officeId: selectedOffice,
  })
}

export const getAllRoles = async (keyword: any) => {
  return await axios.get(`${ROLE_URL}/getallroles${keyword ? '?keyword=' + keyword : ''}`)
}

export const createProject = async (form: IProjectData) => {
  const payload: IProjectData = form
  await axios.post(`${PROJECT_URL}`, payload)
}

export const updateProject = async (form: IProjectData) => {
  const payload: IProjectData = form
  return await axios.put(`${PROJECT_URL}`, payload)
}

export const deleteProject = async (id: number) => {
  return await axios.delete(`${PROJECT_URL}?id=${id}`)
}

export const getUserGroups = async () => {
  return await axios.get(`${PROJECT_URL}/usergroupsOptions`)
}

export const getProjectUserRoles = async () => {
  return await axios.get(`${PROJECT_URL}/projectUserRoleOptions`)
}

export const getClientOptions = async (keyword: string, officeId: number) => {
  return await axios.get(`${PROJECT_URL}/clientOptions`, {
    params: {
      keyword,
      officeId: officeId,
    },
  })
}

export const getUserOptions = async (keyword: string) => {
  return await axios.get(`${PROJECT_URL}/userOptions?keyword=${keyword}`)
}

export const getProjectById = async (id: number) => {
  return await axios.get(`${PROJECT_URL}/getbyid?id=${id}`)
}

export const getProjectByFulcrumId = async (id: string) => {
  return await axios.get(`${PROJECT_URL}/getbyfulcrumid?id=${id}`)
}

export const updateProjectSettings = async (form: IProjectData) => {
  const payload: IProjectData = form
  return await axios.patch(`${PROJECT_SETTING_URL}/updateprojectsettings`, payload)
}

export const getEvaluationRegions = async () => {
  return await axios.get(`${EVALUATION_REGION_URL}`)
}

export const getProjectAssetById = async (id: number) => {
  return await axios.get(`${PROJECT_ASSET_URL}/getbyid?id=${id}`)
}

export const getAssetClassOptions = async (keyword: string) => {
  return await axios.get(`${PROJECT_ASSET_URL}/assetClassOptions?keyword=${keyword}`)
}

export const getAssetTypeOptions = async (keyword: string) => {
  return await axios.get(`${PROJECT_ASSET_URL}/assetClassComponentOptions?keyword=${keyword}`)
}

export const getAsbestosStatusOptions = async (keyword: string) => {
  return await axios.get(`${PROJECT_ASSET_URL}/asbestosStatusOptions?keyword=${keyword}`)
}

export const getValuerOptions = async (projectId: string) => {
  return await axios.get(`${PROJECT_ASSET_URL}/valuerOptions?projectId=${projectId}`)
}

export const getRegionIndexes = async (stateCode?: string, localGovernmentArea?: string) => {
  return await axios.get(`${REGION_INDEX_URL}/getregionindexes`, {
    params: {
      stateCode: stateCode || '',
      localGovernmentArea: localGovernmentArea || '',
    },
  })
}
export const getProjectCustomFieldOptions = async (projectId: string) => {
  return await axios.get(`${PROJECT_ASSET_URL}/projectCustomFieldOption?projectId=${projectId}`)
}

export const getInspectorOptions = async (projectId: string) => {
  return await axios.get(`${PROJECT_ASSET_URL}/inspectorOptions?projectId=${projectId}`)
}

export const getBuildingElementsOption = async () => {
  return await axios.get(`${ASSETCLASS_URL}/getbuildingelementsoption`)
}

export const getLeadUpTimes = async () => {
  return await axios.get(`${LEAD_UP_TIMES_URL}/getleaduptimes`)
}

export const getRebuildTimes = async () => {
  return await axios.get(`${REBUILD_TIMES_URL}/getrebuildtimes`)
}

export const getProjectAssetHistoricalData = async (id: number) => {
  return await axios.get(`${PROJECT_ASSET_URL}/gethistoricaldata?id=${id}`)
}

export const getAssetValuationReport = async (
  projectId: string,
  assetValuationRequest: IAssetValuationReportSheets
) => {
  return await axios.post(`${REPORT_URL}/assetvaluationreport`, {
    projectId: projectId,
    assetValuationRequest: assetValuationRequest,
  })
}

/*
export const forgotPassword = async (email: string) => {
  return await axios.post(`${PROJECT_URL}/forgot-password`, {email})
}



// USER GROUP //

export const createUserGroup = async (form: IUserGroup) => {
  const payload: IUserGroup = form
  await axios.post(`${USERGROUP_URL}`, payload)
}

export const updateUserGroup = async (form: IUserGroup) => {
  const payload: IUserGroup = form
  await axios.put(`${USERGROUP_URL}`, payload)
}

export const deleteUserGroup = async (id: string) => {
  return await axios.delete(`${USERGROUP_URL}?id=${id}`)
}

export const getUserGroupPagination = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  search: string
) => {
  return await axios.get(`${USERGROUP_URL}/pagination`, {
    params: {
      //...userSearch,
      filter: search,
      skip,
      take,
      sort: pageSort ? `${pageSort.field} ${pageSort.dir}` : '',
    },
  })
}

export const getModulePermission = async () => {
  return await axios.get(`${MODULE_URL}/getmodulepermission`)
}


export const getAvailableUsers = async (search: string) => {
  return await axios.get(`${PROJECT_URL}/getavailableusers`, {
    params: {
      keyword: search,
    },
  })
}
*/
