import {SortDescriptor} from '@progress/kendo-data-query'
import axios from 'axios'
import {IAssetComponentized} from '../models/project-asset/componentized'
import {IAssetInsuranceValuation} from '../models/project-asset/insurance-valuation'

export const ASSET_COMPONENT_VALUATION = `${process.env.REACT_APP_API_URL}/AssetComponentValuation`
export const ASSET_INSURANCE_VALUATION = `${process.env.REACT_APP_API_URL}/AssetInsuranceValuation`

// PROJECTS //
export const getAssetComponentValuationComponentizedPagination = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  projectAssetId: number
) => {
  return await axios.get(`${ASSET_COMPONENT_VALUATION}/pagination`, {
    params: {
      skip,
      take,
      sort: `${pageSort.field} ${pageSort.dir}`,
      projectAssetId,
    },
  })
}

export const updateValuationComponentized = async (payload: IAssetComponentized) => {
  return await axios.put(`${ASSET_COMPONENT_VALUATION}`, payload)
}

// PROJECTS //
export const getAssetComponentInsurancePagination = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  projectAssetId: number
) => {
  return await axios.get(`${ASSET_INSURANCE_VALUATION}/pagination`, {
    params: {
      skip,
      take,
      sort: `${pageSort.field} ${pageSort.dir}`,
      projectAssetId,
    },
  })
}

export const updateAssetInsuranceValuation = async (payload: IAssetInsuranceValuation) => {
  return await axios.put(`${ASSET_INSURANCE_VALUATION}`, payload)
}
