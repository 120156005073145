interface OutlinedBoxProps {
  label: string
}

const OutlinedBox: React.FC<OutlinedBoxProps> = ({label, children}) => {
  return (
    <div style={{position: 'relative', marginBottom: '20px'}}>
      <label
        style={{
          position: 'absolute',
          top: '-10px',
          backgroundColor: 'white',
          padding: '0 10px 0px 10px',
          marginLeft: '10px',
        }}
      >
        {label}
      </label>
      <div style={{border: '1px solid #ddd', padding: '10px', borderRadius: '5px'}}>{children}</div>
    </div>
  )
}

export {OutlinedBox}
