import React, {useState, useEffect} from 'react'
import {getOffices} from '../api'
import {IClient} from '../../clients/models/client'

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

export const ClientListContext = React.createContext<any>({})

export const clientInitialValue: IClient = {
  id: 0,
  name: '',
  shortCode: '',
  pointContact: '',
  address: '',
  phoneNumber: '',
  email: '',
  officeId: 0,
  isActive: true,
}

const initialDataState = {
  skip: 0,
  take: 10,
}

export const ClientListContextProvider: React.FC = ({children}) => {
  /**CLIENTS */
  const [initialValue, setInitialValue] = useState<IClient>(clientInitialValue)
  const [dataUpdated, setDataUpdated] = useState(false)
  const [clients, setClients] = useState<any[]>([])
  const [newClient, setNewClient] = useState(initialValue)
  const [isModalVisible, setModalVisible] = useState(false)
  const [offices, setOffices] = useState<any[]>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(1)

  const fetchAllClients = async () => {
    // const response = await getAllClients(pageNumber, pageSize)
    // setClients([...response?.data?.items])
    // setTotalPages(response?.data?.totalPages)
    // setTotalCount(response?.data?.totalCount)
    // setDataUpdated(false)
  }

  const fetchAllOffices = async () => {
    const response = await getOffices()
    setOffices(response?.data)
  }

  useEffect(() => {
    fetchAllClients()
  }, [pageNumber, pageSize, dataUpdated])

  const handlePageChange = (event: any) => {
    const newPageNumber = event.page?.skip / event.page?.take + 1
    setPageNumber(newPageNumber)
  }

  // Function to trigger data update
  const updateData = () => {
    setDataUpdated(true)
  }

  /**
   * TOASTR
   */
  const showToastrNotification = (message: any, type: any) => {
    toastr.options = {
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: false,
    }
    toastr.clear()
    setTimeout(() => {
      if (type === 'success') {
        toastr.success(message)
      } else {
        toastr.error(message)
      }
    }, 300)
  }

  return (
    <ClientListContext.Provider
      value={{
        initialValue,
        clients,
        setClients,
        newClient,
        setNewClient,
        isModalVisible,
        setModalVisible,
        fetchAllOffices,
        offices,
        setInitialValue,
        totalCount,
        totalPages,
        handlePageChange,
        pageNumber,
        pageSize,
        initialDataState,
        fetchAllClients,
        updateData,
        setTotalPages,
        setTotalCount,
        dataUpdated,
        showToastrNotification,
      }}
    >
      {children}
    </ClientListContext.Provider>
  )
}
