import React from 'react'
import {UseFieldArrayReplace} from 'react-hook-form'
import {IModule} from '../../../models/module'
import {IPermission} from '../../../models/permission'

import './UserGroupInfoModal.scss'
import {capitalizeFirstLetter, hasPermission} from '../../../../../shared/service/utils'
import {IUserGroupPermission} from '../../../models/user-group'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'
import {usePageData} from '../../../../../../_metronic/layout/core'

interface IProps {
  modules: IModule[]
  permissions: IPermission[]
  groupPermissions: any
  replaceGroupPermissions: UseFieldArrayReplace<any, any>
  userGroupId: number
}

const UserGroupPermissionForm: React.FC<IProps> = ({
  modules,
  permissions,
  groupPermissions,
  replaceGroupPermissions,
  userGroupId,
}) => {
  const {
    currentUser: {activePages},
  } = usePageData()
  const onChangeHandler = (event: any, moduleId: number, permissionId: number) => {
    if (event.target.checked) {
      replaceGroupPermissions([
        ...groupPermissions,
        {
          moduleId,
          permissionId,
        },
      ])
    } else {
      let removedPermmission = groupPermissions.find(
        (item: IUserGroupPermission) =>
          item.moduleId === moduleId && item.permissionId === permissionId
      )

      if (removedPermmission) {
        const newPermissions = groupPermissions.filter(
          (item: IUserGroupPermission) => item !== removedPermmission
        )
        replaceGroupPermissions([...newPermissions])
      }
    }
  }
  return (
    <React.Fragment>
      <h3>Permissions</h3>
      <table key={'module'} className='table-module text-center'>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {modules.map((module, i) => (
            <tr key={'m-' + module.id}>
              <td className='module-name' key={'m-' + i + '-' + module.id}>
                {module.name}
              </td>
              {permissions.map((permission, i) => (
                <td key={'p-' + i + permission.id} className='text-center'>
                  <input
                    type='checkbox'
                    onChange={(event) => onChangeHandler(event, module.id, permission.id)}
                    checked={
                      groupPermissions.filter(
                        (item: IUserGroupPermission) =>
                          item.moduleId === module.id && item.permissionId === permission.id
                      ).length > 0
                    }
                    disabled={
                      userGroupId > 0 &&
                      !hasPermission(PAGE_PERMISSION.USER_MANAGEMENT_UPDATE, activePages || [])
                    }
                  />{' '}
                  <span className='ps-2'>{capitalizeFirstLetter(permission.name)}</span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  )
}
export {UserGroupPermissionForm}
