import React, {useContext, useState} from 'react'
import {Grid, GridColumn as Column, GridSortChangeEvent} from '@progress/kendo-react-grid'
import {gearIcon} from '@progress/kendo-svg-icons'

import {Dialog} from '@progress/kendo-react-dialogs'
import {ActionButton} from '../../../../../_metronic/layout/components/buttons/ActionButton'
import {UserContext} from '../../context/UserContext'
import {IUserData} from '../../models/user-model'
import {deleteUserGroup} from '../../api'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import GroupMembers from './GroupMembers'
import toastr from 'toastr'
import {hasPermission} from '../../../../shared/service/utils'
import {PAGE_PERMISSION} from '../../../roles/models/role-model'
import {usePageData} from '../../../../../_metronic/layout/core'

function UserGroupListTable(props: any) {
  const {
    setSelectedUserGroupData,
    setIsUserGroupModalVisible,
    userGroupData,
    userGroupTotalRows,
    handlePageChangeUserGroup,
    fetchUserGroupData,
    gridSetupUserGroup,
    setGridSetupUserGroup,
  } = useContext(UserContext)
  const {
    currentUser: {activePages},
  } = usePageData()

  // EVENTS //
  const handleDropDownClick = (user: IUserData, action: string) => {
    if (action === 'View/Edit' || action === 'View') {
      setSelectedUserGroupData({...user})
      setIsUserGroupModalVisible(true)
    } else if (action === 'Delete') {
      confirmDelete(user.id ?? '')
    }
  }

  // DELETE //
  const [selectedDeleteId, setSelectedDeleteId] = useState<string | null>(null)

  const handleCloseDeleteDialog = () => {
    setSelectedDeleteId(null)
  }

  const confirmDelete = (id: string) => {
    setSelectedDeleteId(id)
  }

  const deleteItem = () => {
    if (selectedDeleteId !== null) {
      deleteUserGroup(selectedDeleteId).then(() => {
        toastr.success('User Group deleted successfully.', 'Delete User Group')
        fetchUserGroupData()
        setSelectedDeleteId(null)
      })
    }
  }

  // CUSTOOM CELL //
  const customHeaderCell = (props: any) => {
    return (
      <>
        <span onClick={props.onClick} className={'k-cell-inner'}>
          <span className={'k-link'}>
            <span
              className={'k-column-title fw-bold'}
              style={{textAlign: 'center', marginBottom: 0}}
            >
              {props.title}
            </span>{' '}
            {props.children}
          </span>
        </span>
      </>
    )
  }

  return (
    <div className='m-auto mt-4'>
      {selectedDeleteId !== null && (
        <Dialog
          height={'auto'}
          style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
          title={'Delete User Group'}
          onClose={handleCloseDeleteDialog}
        >
          <div className='row mb-4'>
            <div className='col'>
              <p>Are you sure you want to delete this item?</p>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col  d-flex justify-content-end'>
              <Button onClick={deleteItem} buttonType='primary' text={'Yes'} />
              <Button
                buttonType='secondary'
                text={'No'}
                onClick={() => setSelectedDeleteId(null)}
                style={{marginLeft: 10}}
              />
            </div>
          </div>
        </Dialog>
      )}

      {!userGroupTotalRows || userGroupTotalRows === 0 ? (
        <div className='align-middle text-center'>There are no user groups to display!</div>
      ) : (
        <Grid
          resizable={true}
          data={userGroupData}
          pageable={true}
          onPageChange={handlePageChangeUserGroup}
          {...gridSetupUserGroup}
          total={userGroupTotalRows}
          sort={gridSetupUserGroup.sort}
          sortable={true}
          onSortChange={(e: GridSortChangeEvent) => {
            setGridSetupUserGroup({...gridSetupUserGroup, sort: e.sort})
          }}
        >
          <Column
            width={80}
            title=''
            cell={(props) => {
              let items = []

              if (hasPermission(PAGE_PERMISSION.USER_MANAGEMENT_UPDATE, activePages || [])) {
                items.push('View/Edit')
              } else {
                items.push('View')
              }

              if (
                !props.dataItem.isDefault &&
                hasPermission(PAGE_PERMISSION.USER_MANAGEMENT_DELETE, activePages || [])
              ) {
                items.push('Delete')
              }
              return (
                <td className='align-middle text-center'>
                  <ActionButton
                    width='50px'
                    svgIcon={gearIcon}
                    text=''
                    items={items}
                    onItemClick={(event: any) => handleDropDownClick(props.dataItem, event.item)}
                  />
                </td>
              )
            }}
          />
          <Column field='name' title='Group Name' headerCell={customHeaderCell} />
          <Column
            title='Members'
            headerCell={customHeaderCell}
            cell={(props) => (
              <td className='align-middle text-center'>
                <GroupMembers members={props.dataItem.members}></GroupMembers>
              </td>
            )}
          />
          <Column field='status' title='Status' headerCell={customHeaderCell} />
        </Grid>
      )}
    </div>
  )
}

export default UserGroupListTable
