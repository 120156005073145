import React, {useContext, useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {Dialog} from '@progress/kendo-react-dialogs'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import './NewOfficeInfoModal.css'
import {OfficeLocationsContext} from '../../context/OfficeLocationsContext'
import {addNewOffice, updateOffice} from '../../api'
import {Loader2} from '../../../../../_metronic/layout/components/loader/Loader2'

function NewOfficeInfoModal(props: any) {
  const {
    initialValue,
    newOffice,
    setNewOffice,
    isModalVisible,
    setModalVisible,
    updateData,
    showToastrNotification,
  } = useContext(OfficeLocationsContext)
  const history = useHistory()
  const [statusCheckbox, setStatusCheckbox] = useState(newOffice?.status)
  const [isSaving, setIsSaving] = useState(false)

  /** CHECK if newOffice is empty object */
  function hasEmptyStrings(obj: any) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'string' && obj[key].trim() === '') {
        return true
      }
    }
    return false
  }

  const [errors, setErrors] = useState({
    name: false,
    code: false,
    address: false,
    suburb: false,
    state: false,
    postCode: false,
  })

  const closeModal = () => {
    setModalVisible(false)
  }

  /**
   * REGEX
   */
  const validNumberOfChar = /^.{0,99}$/
  const validAddressNumberOfChar = /^.{0,200}$/
  const validPostCode = /^.{0,4}$/

  const handleAddNewOffice = async () => {
    setIsSaving(true)
    const newErrors = {
      name: !newOffice?.name || newOffice?.name.match(validNumberOfChar) === null,
      code: !newOffice?.code || newOffice?.code.match(validNumberOfChar) === null,
      address: !newOffice?.address || newOffice?.address.match(validAddressNumberOfChar) === null,
      suburb: !newOffice?.suburb || newOffice?.suburb.match(validNumberOfChar) === null,
      state: !newOffice?.state || newOffice?.state.match(validNumberOfChar) === null,
      postCode: !newOffice?.postCode || newOffice?.postCode.match(validPostCode) === null,
    }

    setErrors(newErrors)

    const office: any = {
      id: newOffice.id,
      name: newOffice.name,
      code: newOffice.code,
      address: newOffice.address,
      suburb: newOffice.suburb,
      state: newOffice.state,
      postCode: newOffice.postCode,
      status: newOffice.status ? 'Active' : 'Inactive',
    }

    if (!Object.values(newErrors).some((error) => error)) {
      //POST
      try {
        if (initialValue.id === 0) {
          await addNewOffice(office)
          setIsSaving(false)
          showToastrNotification('New Office successfully added', 'success')
        } else {
          await updateOffice(office)
          setIsSaving(false)
          showToastrNotification('Office successfully updated', 'success')
        }
      } catch (error: any) {
        if (error.response) {
          setIsSaving(false)
          showToastrNotification(error.response.data.message, 'error')
        } else {
          setIsSaving(false)
          showToastrNotification(error.message, 'error')
        }
      }

      updateData()
      setNewOffice(initialValue)
      props?.setIsEdit(false)
      closeModal()
    }
    setIsSaving(false)
  }

  const handleCancel = () => {
    props?.setIsEdit(false)
    setNewOffice(initialValue)
    history.push('/office-locations')
    closeModal()
  }

  const handleInfoOnChange = (e: any, info: any) => {
    if (info === 'status') {
      // setNewOffice((prevOffice: any) => ({...prevOffice, status: !prevOffice.status}))
      setStatusCheckbox(e.target.checked)
      setNewOffice((prevOffice: any) => ({...prevOffice, status: e.target.checked}))
    } else if (info === 'postCode') {
      setNewOffice({...newOffice, [info]: formatPostCode(e.target.value)})
    } else {
      setNewOffice({...newOffice, [info]: e.target.value})
    }
  }

  // useEffect(() => {
  //   setNewOffice(initialValue)

  //   if (props.isEdit) {
  //     setNewOffice((prevOffice: any) => ({...prevOffice, status: initialValue.status === 'Active'}))
  //   }

  //   setErrors({
  //     name: false,
  //     code: false,
  //     address: false,
  //     suburb: false,
  //     state: false,
  //     postCode: false,
  //   })
  // }, [isModalVisible])

  useEffect(() => {
    // Update the state only if the modal is visible
    if (isModalVisible) {
      setNewOffice(initialValue)

      if (props.isEdit) {
        setNewOffice((prevOffice: any) => ({
          ...prevOffice,
          status: initialValue.status === 'Active',
        }))
        setStatusCheckbox(initialValue.status === 'Active')
      }

      setErrors({
        name: false,
        code: false,
        address: false,
        suburb: false,
        state: false,
        postCode: false,
      })
    }
  }, [isModalVisible])

  const formatPostCode = (input: any) => {
    const postCode = input.replace(/\D/g, '')
    const formattedPostCode = postCode.replace(/(\d{4})/, '$1')
    return formattedPostCode
  }

  return (
    <>
      {isModalVisible && (
        <Dialog
          height={'auto'}
          minWidth={'60%'}
          style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
          title={!props?.isEdit ? 'Add New Office' : 'Edit Office'}
          onClose={handleCancel}
        >
          {isSaving && <Loader2></Loader2>}
          <div className='col'>
            <div className='row mb-4'>
              <div className='col-6'>
                <div className='form-outline'>
                  <label className='fw-bolder' htmlFor='code'>
                    Office Code
                  </label>
                  <input
                    style={{
                      fontSize: '1rem',
                      border: errors?.code ? '1px solid #f00' : '1px solid #E4E6EF',
                    }}
                    type='text'
                    id='code'
                    className='form-control'
                    placeholder='Office Code'
                    name='code'
                    value={newOffice?.code}
                    onChange={(e) => {
                      handleInfoOnChange(e, 'code')
                      setErrors({...errors, code: false})
                    }}
                  />
                  {newOffice?.code.match(validNumberOfChar) === null && errors?.code ? (
                    <small style={{color: '#f00'}}>Maximum character limit of 99</small>
                  ) : (
                    errors?.code && <small style={{color: '#f00'}}>Office code is required!</small>
                  )}
                </div>
              </div>
              <div className='col-6'>
                <div className='form-outline'>
                  <label className='fw-bolder' htmlFor='name'>
                    Office Name
                  </label>
                  <input
                    style={{
                      fontSize: '1rem',
                      border: errors?.name ? '1px solid #f00' : '1px solid #E4E6EF',
                    }}
                    type='text'
                    maxLength={200}
                    id='name'
                    className='form-control'
                    placeholder='Office Name'
                    name='name'
                    value={newOffice?.name}
                    onChange={(e) => {
                      handleInfoOnChange(e, 'name')
                      setErrors({...errors, name: false})
                    }}
                  />
                  {newOffice?.name.match(validNumberOfChar) === null && errors?.name ? (
                    <small style={{color: '#f00'}}>Maximum character limit of 99</small>
                  ) : (
                    errors?.name && <small style={{color: '#f00'}}>Office name is required!</small>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-4'>
              <div className='col-6'>
                <div className='form-outline'>
                  <label className='fw-bolder' htmlFor='address'>
                    Address
                  </label>
                  <input
                    style={{
                      fontSize: '1rem',
                      border: errors?.address ? '1px solid #f00' : '1px solid #E4E6EF',
                    }}
                    type='text'
                    id='address'
                    className='form-control'
                    placeholder='Address'
                    name='address'
                    maxLength={200}
                    value={newOffice?.address}
                    onChange={(e) => {
                      handleInfoOnChange(e, 'address')
                      setErrors({...errors, address: false})
                    }}
                  />
                  {newOffice?.address.match(validAddressNumberOfChar) === null &&
                  errors?.address ? (
                    <small style={{color: '#f00'}}>Maximum character limit of 99</small>
                  ) : (
                    errors?.address && <small style={{color: '#f00'}}>Address is required!</small>
                  )}
                </div>
              </div>
              <div className='col-6'>
                <div className='form-outline'>
                  <label className='fw-bolder' htmlFor='suburb'>
                    Suburb
                  </label>
                  <input
                    style={{
                      fontSize: '1rem',
                      border: errors?.suburb ? '1px solid #f00' : '1px solid #E4E6EF',
                    }}
                    type='suburb'
                    id='suburb'
                    maxLength={50}
                    className='form-control'
                    placeholder='Suburb'
                    name='suburb'
                    value={newOffice?.suburb}
                    onChange={(e) => {
                      handleInfoOnChange(e, 'suburb')
                      setErrors({...errors, suburb: false})
                    }}
                  />
                  {newOffice?.suburb.match(validAddressNumberOfChar) === null && errors?.suburb ? (
                    <small style={{color: '#f00'}}>Maximum character limit of 99</small>
                  ) : (
                    errors?.suburb && <small style={{color: '#f00'}}>Suburb is required!</small>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-4'>
              <div className='col-6'>
                <div className='form-outline'>
                  <label className='fw-bolder' htmlFor='state'>
                    State
                  </label>
                  <input
                    style={{
                      fontSize: '1rem',
                      border: errors?.state ? '1px solid #f00' : '1px solid #E4E6EF',
                    }}
                    type='text'
                    id='state'
                    maxLength={50}
                    className='form-control'
                    placeholder='State'
                    name='state'
                    value={newOffice?.state}
                    onChange={(e) => {
                      handleInfoOnChange(e, 'state')
                      setErrors({...errors, state: false})
                    }}
                  />

                  {newOffice?.state.match(validAddressNumberOfChar) === null && errors?.state ? (
                    <small style={{color: '#f00'}}>Maximum character limit of 99</small>
                  ) : (
                    errors?.state && <small style={{color: '#f00'}}>State is required!</small>
                  )}
                </div>
              </div>
              <div className='col-6'>
                <div className='form-outline'>
                  <label className='fw-bolder' htmlFor='postCode'>
                    Post Code
                  </label>
                  <input
                    style={{
                      fontSize: '1rem',
                      border: errors?.postCode ? '1px solid #f00' : '1px solid #E4E6EF',
                    }}
                    maxLength={4}
                    type='text'
                    id='postCode'
                    className='form-control'
                    placeholder='Post Code'
                    name='postCode'
                    value={newOffice?.postCode}
                    onChange={(e) => {
                      handleInfoOnChange(e, 'postCode')
                      setErrors({...errors, postCode: false})
                    }}
                  />
                  {newOffice?.postCode.match(validPostCode) === null && errors?.postCode ? (
                    <small style={{color: '#f00'}}>Maximum character limit of 4</small>
                  ) : (
                    errors?.postCode && (
                      <small style={{color: '#f00'}}>Post Code is required!</small>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-4'>
              <div className='col-4'>
                <div className='form-outline'>
                  <input
                    style={{
                      fontSize: '1rem',
                    }}
                    type='checkbox'
                    id='status'
                    className='form-check-input'
                    name='status'
                    // checked={newOffice?.status}
                    checked={statusCheckbox}
                    onChange={(e) => {
                      handleInfoOnChange(e, 'status')
                    }}
                  />
                  <label className='fw-bolder' htmlFor='status'>
                    &nbsp;Active
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-row align-items-center justify-content-end w-100 mt-10'>
            <div className='d-flex flex-row align-items-center'>
              <Button
                buttonType='primary'
                text={'Save'}
                onClick={handleAddNewOffice}
                // disabled={!props.isEdit && hasEmptyStrings(newOffice)}
                disabled={
                  Object.values(errors).some((value) => value === true) &&
                  hasEmptyStrings(newOffice)
                }
              />
              <Button
                buttonType='secondary'
                text={'Cancel'}
                onClick={handleCancel}
                style={{marginLeft: 10}}
              />
            </div>
          </div>
        </Dialog>
      )}
    </>
  )
}

export default NewOfficeInfoModal
