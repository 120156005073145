import {IAlert} from '../../../shared/components/CustomAlert'
import {IProjectCustomField} from '../models/project-custom-field'
import {GridSetup, IOption, IProjectData, IProjectSearch} from '../models/project-model'
import {IProjectSetting} from '../models/project-setting'

// export const FIELD_TYPE: any[] = [
//   {value: 'Text', type: 'TextField', numeric: false, format: null},
//   {value: 'Amount', type: 'TextField', numeric: true, format: 'decimal'},
// ]

export const FIELD_TYPE: string[] = ['Alphanumeric', 'Numeric', 'Currency', 'Conditional', 'Date']

export const PROJECT_FORM_DEFAULT: IProjectData = {
  id: 0,
  displayId: '',
  name: '',
  description: '',
  insuranceValuation: false,
  fairValueValuation: false,
  clientId: 0,
  projectUsers: [],
  status: '',
  assetCount: 0,
  completed: 0,
  queued: 0,
  deletedCount: 0,
  userRoleDisplay: '',
  selectedUserGroups: [],
  projectCustomFields: [],
  valuationRegion: '',
}

export const USER_STATUS: IOption[] = [
  {value: '1', label: 'Active'},
  {value: '0', label: 'Inactive'},
]

export const SEARCH_DEFAULT: IProjectSearch = {
  search: '',
  userName: '',
  firstName: '',
  lastName: '',
  status: true,
}

export const Initial_GridSetup: GridSetup = {
  sort: [{field: 'id', dir: 'desc'}],
  skip: 0,
  take: 10,
  search: '',
  filter: {
    logic: 'and',
    filters: [],
  },
}

export const TOASTER_DEFAULT: IAlert = {
  message: ``,
  header: ``,
  type: 'success',
}

export const PROJECT_CUSTOM_FIELD: IProjectCustomField = {
  id: 0,
  field: '',
  type: FIELD_TYPE[0],
  databaseField: '',
}

export const PROJECT_SETTINGS_DEFAULT: IProjectSetting = {
  projectCustomField: [],
  region: '',
}
