import {FC, useContext, useEffect, useRef, useState} from 'react'
import {GalleryViewer} from '../../../models/file-attachment'
import {Dialog} from '@progress/kendo-react-dialogs'
import Button from '../../../../../../_metronic/layout/components/buttons/Button'
import {useFieldArray, useForm} from 'react-hook-form'
import {ProjectAssetData} from '../../../models/project-assets'
import './AssetGalleries.scss'
import ImageGallery from 'react-image-gallery'
import {updateAssetGalleries} from '../../../api/asset-api'
import {ProjectContext} from '../../../context/ProjectContext'

const PROJECTASSET_PATH = process.env.REACT_APP_PROJECTASSET_PATH
type IAssetGalleriesProps = {
  assetData: ProjectAssetData
  setAssetData: (assetData: ProjectAssetData) => void
}

export const AssetGalleries: FC<IAssetGalleriesProps> = ({assetData, setAssetData}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const refSlider = useRef<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {showToastrNotification} = useContext(ProjectContext)

  const {control, handleSubmit, reset} = useForm({
    defaultValues: assetData,
    reValidateMode: 'onSubmit',
  })

  const {
    fields: assetGalleries,
    insert: addAssetGalleries,
    remove: removeAssetGalleries,
    replace: replaceAssetGalleries,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'galleries', // unique name for your Field Array
    keyName: 'idx',
  })

  const onSubmit = (values: ProjectAssetData) => {
    setIsLoading(true)
    updateAssetGalleries(values.galleries, values.id)
      .then((result) => {
        const [response]: any = result
        if (response.data) {
          // const galleryImages: GalleryViewer[] = [];
          setAssetData({
            ...assetData,
            galleries: response.data,
          })
        }
        setIsLoading(false)
        showToastrNotification('Asset Saved succesfully', 'success')
      })
      .catch((err) => {
        showToastrNotification('Error Saving Asset. Please try again.', 'error')
        setIsLoading(false)
      })
  }

  // Use Effect if changes comming from parent
  useEffect(() => {
    if (assetData.id > 0) {
      reset({...assetData})

      if (assetData?.galleries?.length > 0) {
        const galleryImages: GalleryViewer[] = []
        assetData.galleries.forEach((gallery, i) => {
          const fullPath = `${PROJECTASSET_PATH}/${assetData.fulcrumId}/Galleries/`
          const imageFile = `${gallery.fileKey}${gallery.fileExtension}`

          galleryImages.push({
            id: gallery.id,
            file: undefined,
            imageUrl: `${fullPath}${imageFile}`,
          })
        })
        replaceAssetGalleries([...galleryImages])
      }
    }
  }, [assetData])

  const handleImageClick = (index: number) => {
    setActiveIndex(index)
    setShowModal(true)
  }

  const onFileUpload = () => {
    if (fileInputRef.current) fileInputRef.current.click()
  }

  const handleCarouselSlide = (index: number) => {
    setActiveIndex(index)
  }

  const uploadImages = (fileEvent: React.ChangeEvent<HTMLInputElement>) => {
    const files = fileEvent.target.files
    let newImageBlobs: GalleryViewer[] = []
    if (!files) {
      return
    }
    if (files && files !== null) {
      for (let i = 0; i < files.length; i++) {
        const fileItem = files.item(i)
        if (fileItem) {
          const newImageBlob: GalleryViewer = {
            file: fileItem,
            fileName: '',
            imageUrl: '',
          }
          newImageBlobs.push(newImageBlob)
          const reader = new FileReader()
          reader.onload = (event: any) => {
            let index = newImageBlobs.findIndex((f) => f.file === files.item(i))
            if (index > -1) newImageBlobs[index].imageUrl = event.target.result

            addAssetGalleries(0, {...newImageBlobs[index]})
          }
          reader.readAsDataURL(fileItem)
        }
      }
    }
  }

  const AllImagesModal = () => (
    <Dialog
      height={'60%'}
      width={'30%'}
      style={{
        backgroundColor: 'rgba(0,0,0,0.45)',
        zIndex: 100,
      }}
      className='gallery-dialog'
      onClose={() => setShowModal(false)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ImageGallery
          ref={refSlider}
          items={assetGalleries.map((gallery) => ({
            original: gallery.imageUrl,
            thumbnail: gallery.imageUrl,
          }))}
          startIndex={activeIndex}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              text={'Delete'}
              buttonType='secondary'
              isSubmit={false}
              disabled={isLoading}
              onClick={() => {
                if (refSlider) {
                  let currentIndex = refSlider.current.getCurrentIndex()
                  if (currentIndex > 0) {
                    setTimeout(() => {
                      let itemCountWithDeleted = assetGalleries.length - 1
                      if (currentIndex <= 0) currentIndex = 0
                      if (currentIndex > itemCountWithDeleted)
                        currentIndex = itemCountWithDeleted - 1

                      setActiveIndex(currentIndex - 1)
                      refSlider.current.slideToIndex(currentIndex - 1)
                    }, 100)
                  } else {
                    setActiveIndex(0)
                    refSlider.current.slideToIndex(0)
                  }
                  removeAssetGalleries(currentIndex)
                }
              }}
            />
            <input
              type='file'
              accept='image/*,.pdf,.doc,.docx,.xlsx,.xls,.csv'
              multiple
              onChange={uploadImages}
              ref={fileInputRef}
              hidden
            />

            <Button
              text={'Attach File'}
              buttonType='primary'
              isSubmit={false}
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault()
                onFileUpload()
              }}
            />
            <div>
              <Button buttonType='secondary' text={'Save'} isSubmit={true} disabled={isLoading} />
              &nbsp;&nbsp;
              <Button
                buttonType='secondary'
                text={'Cancel'}
                isSubmit={false}
                onClick={() => {
                  setActiveIndex(0)
                  setAssetData({...assetData})
                  setShowModal(false)
                }}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  )

  return (
    <section>
      <div
        id='kt_carousel_1_carousel'
        className='carousel carousel-custom slide'
        data-bs-ride='carousel'
        // data-bs-interval='8000'
      >
        <div className='carousel-controls'>
          <span
            className='carousel-control-prev'
            role='button'
            data-bs-target='#kt_carousel_1_carousel'
            data-bs-slide='prev'
            onClick={() =>
              handleCarouselSlide((activeIndex - 1 + assetGalleries.length) % assetGalleries.length)
            }
            style={{display: activeIndex === 0 ? 'none' : 'block'}}
          >
            <i className='bi bi-caret-left-fill'></i>
            <span className='visually-hidden'>Previous</span>
          </span>
          <span
            className='carousel-control-next'
            role='button'
            data-bs-target='#kt_carousel_1_carousel'
            data-bs-slide='next'
            onClick={() => handleCarouselSlide((activeIndex + 1) % assetGalleries.length)}
            style={{display: activeIndex === assetGalleries.length - 1 ? 'none' : 'block'}}
          >
            <i className='bi bi-caret-right-fill'></i>
            <span className='visually-hidden'>Next</span>
          </span>
        </div>

        <div className='carousel-inner pt-8'>
          {assetGalleries.map((gallery, index) => (
            <div className={`carousel-item ${index === activeIndex ? 'active' : ''}`} key={index}>
              <img
                src={gallery.imageUrl}
                alt='/media/background/login-background.jpg'
                style={{width: '100%', height: '100', objectFit: 'contain', cursor: 'pointer'}}
                onClick={() => handleImageClick(index)}
              />
            </div>
          ))}
        </div>
      </div>
      {/* MODAL */}
      {showModal && <AllImagesModal />}
    </section>
  )
}
