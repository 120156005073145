import React, {useRef} from 'react'
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid'
import {
  Control,
  UseFormSetValue,
  FieldErrors,
  UseFormRegister,
  UseFormClearErrors,
  UseFormHandleSubmit,
  useFieldArray,
} from 'react-hook-form'

import TitleHeader from '../../../../../../../../../../_metronic/layout/components/header/TitleHeader'
import Button from '../../../../../../../../../../_metronic/layout/components/buttons/Button'
import {ProjectAssetData} from '../../../../../../../models/project-assets'
import {FileViewer} from '../../../../../../../models/file-attachment'

type IAssetAttachmentsProps = {
  control: Control<ProjectAssetData>
  isValid: boolean
  setValue: UseFormSetValue<ProjectAssetData>
  errors: FieldErrors<ProjectAssetData>
  register: UseFormRegister<ProjectAssetData>
  clearErrors: UseFormClearErrors<ProjectAssetData>
  handleSubmit: UseFormHandleSubmit<ProjectAssetData>
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData[]) => void
}

function AssetAttachments(props: IAssetAttachmentsProps) {
  const {control} = props
  const fileInputRef = useRef<HTMLInputElement>(null)

  const {
    fields: attachments,
    insert: insertAtachment,
    remove: removeAttachment,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'attachments', // unique name for your Field Array
    keyName: 'idx',
  })

  // const [selectedFile, setSelectedFile] = useState<any>(null)
  // const [files, setFiles] = useState<any[]>([])

  // const onFileChange = (event: any) => {
  //   setSelectedFile(event.target.files[0])
  //   let data = [...attachments, event.target.files[0]];
  //    replaceAtachments([...data])
  // }

  const onFileUpload = () => {
    if (fileInputRef.current) fileInputRef.current.click()
    // const formData = new FormData()
    // if (!selectedFile) {
    //   return
    // } else {
    //   formData.append('myFile', selectedFile, selectedFile.name)
    //   // setFiles([...files, selectedFile])
    //   let data = [...attachments, selectedFile];
    //   replaceAtachments([...data])
    //   console.log(data)
    // }
  }

  const customHeaderCell = (props: any) => {
    return (
      <>
        <span onClick={props.onClick} className={'k-cell-inner'}>
          <span className={'k-link'}>
            <span
              className={'k-column-title fw-bolder'}
              style={{textAlign: 'center', marginBottom: 0}}
            >
              {props.title}
            </span>
            {props.children}
          </span>
        </span>
      </>
    )
  }

  const uploadImages = (fileEvent: React.ChangeEvent<HTMLInputElement>) => {
    const files = fileEvent.target.files
    let newImageBlobs: FileViewer[] = []
    if (!files) {
      return
    }
    if (files && files !== null) {
      for (let i = 0; i < files.length; i++) {
        const fileItem = files.item(i)
        if (fileItem) {
          const newImageBlob: FileViewer = {
            idx: '',
            file: fileItem,
            imageUrl: '',
            uploadDate: new Date().toDateString(),
          }
          newImageBlobs.push(newImageBlob)
          const reader = new FileReader()
          reader.onload = (event: any) => {
            let index = newImageBlobs.findIndex((f) => f.file === files.item(i))
            if (index > -1) newImageBlobs[index].imageUrl = event.target.result

            insertAtachment(0, newImageBlobs[index])
          }
          reader.readAsDataURL(fileItem)
        }
      }
    }
  }

  const deleteAttachment = (id: any) => {
    removeAttachment(id)
  }

  return (
    <>
      <TitleHeader title='Asset Attachments' />
      <div id='assetAttachments'>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <input
            type='file'
            accept='image/*,.pdf,.doc,.docx,.xlsx,.xls,.csv'
            multiple
            onChange={uploadImages}
            ref={fileInputRef}
            hidden
          />

          <Button
            text={'Attach File'}
            buttonType='primary'
            onClick={(e) => {
              e.preventDefault()
              onFileUpload()
            }}
          />
        </div>

        <Grid data={attachments}>
          <GridNoRecords>No files available</GridNoRecords>
          <GridColumn
            title=''
            headerCell={customHeaderCell}
            width={60}
            cell={(props) => (
              <td key={props.dataIndex} className='text-center'>
                <i className='fa fa-times' onClick={() => deleteAttachment(props.dataIndex)}></i>
              </td>
            )}
          />
          <GridColumn
            title='File Name'
            headerCell={customHeaderCell}
            cell={(props) => <td className='align-middle text-left'>{props.dataItem.file.name}</td>}
          />
          <GridColumn
            title='File Size'
            headerCell={customHeaderCell}
            cell={(props) => (
              <td className='align-middle text-left'>{props.dataItem.file.size / 1000} kB</td>
            )}
          />
          <GridColumn
            title='Upload Date'
            headerCell={customHeaderCell}
            cell={(props) => {
              return (
                <td className='align-middle text-left'>
                  {/* {props.dataItem.file.lastModifiedDate.toDateString()} */}
                  {props.dataItem.uploadDate}
                </td>
              )
            }}
          />
        </Grid>
      </div>
    </>
  )
}

export default AssetAttachments
