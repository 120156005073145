import React, {useCallback, useEffect, useState} from 'react'
import {getDistinctObjects} from '../../../shared/service/utils'
import {
  getClientOptions,
  getProjects,
  getUserOptions,
  getEvaluationRegions,
  getRegionIndexes,
  getBuildingElementsOption,
  getLeadUpTimes,
  getRebuildTimes,
  getProjectUserRoles,
} from '../api'
import {Initial_GridSetup, PROJECT_FORM_DEFAULT} from '../constant/project-default'
import {GridSetup, IOption, IProjectData} from '../models/project-model'
import {
  transformBuildingElementOptions,
  transformDataClientOptions,
  transformDataEvaluationRegionOptions,
  transformDataOptions,
  transformDataUserGroups,
  transformDataUserOptions,
  transformStateOptions,
} from '../transformers/project-transformer'
import {DropDownListFilterChangeEvent} from '@progress/kendo-react-dropdowns'

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {AxiosResponse} from 'axios'
import {IEvaluationRegion} from '../models/evaluation-region'
import {
  getAsbestosStatusOptions,
  getAssetClassComponentOptions,
  getAssetClassOptions,
} from '../api/asset-api'
import {IRegionIndex} from '../models/region-index'
import {
  IBuildingElementSectionOption,
  IBuildingElementSectionResponse,
} from '../models/building-element'
import {ISingleLineValutaion} from '../models/single-line-valuation'
import {SINGLELINE_FORM_DEFAULT} from '../constant/single-line-default'
import {ILeadUpTimes} from '../models/leadUpTimes'
import {IRebuildTimes} from '../models/rebuildTimes'
import {useLayout} from '../../../../_metronic/layout/core'

export const ProjectContext = React.createContext<any>({})

export const ProjectContextProvider: React.FC = ({children}) => {
  const {selectedOffice} = useLayout()
  // PROJECTS //
  const [selectedData, setSelectedData] = useState<IProjectData>(PROJECT_FORM_DEFAULT)
  const [isModalVisible, setModalVisible] = useState(false)

  const [gridSetup, setGridSetup] = useState<GridSetup>(Initial_GridSetup)
  const [tableData, setTableData] = useState<IProjectData[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isClientOptionLoading, setIsClientOptionLoading] = useState(false)
  const [isAssetClassOptionLoading, setIsAssetClassOptionLoading] = useState(false)
  const [isAsbestosStatusOptionLoading, setIsAsbestosStatusOptionLoading] = useState(false)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [projectUserRoleOptions, setProjectUserRoleOptions] = useState<IOption[]>([])
  const [clientOptions, setClientOptions] = useState<IOption[]>([])
  const [userOptions, setUserOptions] = useState<IOption[]>([])
  const [searchFilter, setSearchFilter] = useState('')
  const [assetClassOptions, setAssetClassOptions] = useState<IOption[]>([])
  const [asbestosStatusOptions, setAsbestosStatusOptions] = useState<IOption[]>([])
  const [regionIndexes, setRegionIndexes] = useState<IRegionIndex[]>([])
  const [stateOptions, setStateOptions] = useState<IOption[]>([])
  // const [regionOptions, setRegionOptions] = useState<IRegionIndex[]>([])
  const [leadUpTimes, setLeadUpTimes] = useState<ILeadUpTimes[]>([])
  const [rebuildTimes, setRebuildTimes] = useState<IRebuildTimes[]>([])
  const [singleLineData, setSingleLineData] = useState<ISingleLineValutaion>({
    ...SINGLELINE_FORM_DEFAULT,
  })
  const [singleLineReplacementCost, setSingleLineReplacementCost] = useState<number>(0)
  // Trigger data update
  const [dataUpdated, setDataUpdated] = useState<boolean>(false)
  const updateData = () => {
    setDataUpdated(true)
  }

  const [buildingElementOption, setBuildingElementOption] =
    useState<IBuildingElementSectionOption>()

  /**
   * TOASTR
   */
  const showToastrNotification = (message: string, type: 'success' | 'info') => {
    toastr.options = {
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: false,
    }
    toastr.clear()
    setTimeout(() => {
      if (type === 'success') {
        toastr.success(message)
      } else if (type === 'info') {
        toastr.info(message)
      } else {
        toastr.error(message)
      }
    }, 300)
  }

  let fetchProjectQue: any
  const fetchData = useCallback(() => {
    setIsLoading(true)
    if (fetchProjectQue) {
      clearTimeout(fetchProjectQue)
    }

    fetchProjectQue = setTimeout(function () {
      let search = searchFilter

      var {sort, skip, take} = gridSetup

      if (selectedOffice.id >= 0) {
        getProjects(skip, take, sort[0], search, selectedOffice.id)
          .then((response: any) => {
            setTableData(response?.data.items)
            setTotalRows(response?.data.totalCount)
            setIsLoading(false)
          })
          .catch(() => {
            setIsLoading(false)
          })
      }
    }, 500)
  }, [gridSetup, searchFilter, selectedOffice])

  const fetchUserOptions = useCallback((keyword: string) => {
    setIsLoading(true)
    getUserOptions(keyword)
      .then((response: any) => {
        const groups = transformDataUserOptions(response.data)
        setUserOptions(groups)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  const fetchProjectUserRoles = useCallback(() => {
    setIsLoading(true)
    getProjectUserRoles()
      .then((response: any) => {
        const groups = transformDataUserGroups(response.data)
        setProjectUserRoleOptions(groups)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  const fetchClient = useCallback((keyword: string, officeId: number) => {
    setIsClientOptionLoading(true)

    getClientOptions(keyword, officeId)
      .then((response: any) => {
        const clients = transformDataClientOptions(response.data)
        setClientOptions(clients)
        setIsClientOptionLoading(false)
      })
      .catch(() => {
        setIsClientOptionLoading(false)
      })
  }, [])

  const fetchAssetClassOptions = useCallback((keyword: string) => {
    setIsAssetClassOptionLoading(true)
    getAssetClassOptions(keyword)
      .then((response: any) => {
        const assetClass = transformDataOptions(response.data)
        setAssetClassOptions(assetClass)
        setIsAssetClassOptionLoading(false)
      })
      .catch(() => {
        setIsAssetClassOptionLoading(false)
      })
  }, [])

  const fetchAsbestosStatusOptions = useCallback((keyword: string) => {
    setIsAsbestosStatusOptionLoading(true)
    getAsbestosStatusOptions(keyword)
      .then((response: any) => {
        const asbestosStatuses = transformDataOptions(response.data)
        setAsbestosStatusOptions(asbestosStatuses)
        setIsAsbestosStatusOptionLoading(false)
      })
      .catch(() => {
        setIsAsbestosStatusOptionLoading(false)
      })
  }, [])

  const [evaluationRegionOptions, setEvaluationRegionOptions] = useState<IOption[]>([])
  const fetchEvaluationRegions = useCallback(() => {
    setIsClientOptionLoading(true)
    getEvaluationRegions()
      .then((response: AxiosResponse<IEvaluationRegion[]>) => {
        const regions = transformDataEvaluationRegionOptions(response.data)
        setEvaluationRegionOptions(regions)
        setIsClientOptionLoading(false)
      })
      .catch(() => {
        setIsClientOptionLoading(false)
      })
  }, [])

  /**MAP ADDRESS */
  const [address, setAddress] = useState<string>('')
  const [region, setRegion] = useState<string>('')
  const [regionOptions, setRegionOptions] = useState<any>([])
  const [pinnedLocation, setPinnedLocation] = useState<[number, number] | null>(null)

  const [selectedRegion, setSelectedRegion] = useState<any>('')
  const [selectedState, setSelectedState] = useState<any>('')

  //parsed address
  /*let addressParts = address?.split(',')
  let parsedState = !isNaN(+(addressParts && addressParts[addressParts.length - 2]?.trim()))
    ? addressParts && addressParts[addressParts.length - 3]?.trim()
    : addressParts && addressParts[addressParts.length - 2]?.trim()

  //parsed region
  let parsedRegion = !isNaN(+(addressParts && addressParts[addressParts.length - 2]?.trim()))
    ? addressParts && addressParts[addressParts.length - 4]?.trim()
    : addressParts && addressParts[addressParts.length - 3]?.trim()
  */
  // useEffect(() => {
  //   if (address) {
  //     setSelectedRegion({value: parsedRegion, label: parsedRegion})
  //     setSelectedState(
  //       stateOptions.filter((option) => option.label.includes(parsedState) && parsedState !== '')[0]
  //     )
  //   }
  // }, [address])

  // useEffect(() => {
  //   if (region) setRegionOptions([{value: region, label: region}])
  // }, [region, address])
  /** */
  // // Temporary modified code to parse state of selected address
  // const fetchRegionIndexes = useCallback(() => {
  //   setIsClientOptionLoading(true)
  //   getRegionIndexes()
  //     .then((response: AxiosResponse<IRegionIndex[]>) => {
  //       setRegionIndexes(response.data)
  //       let states = transformStateOptions(response.data.map((res) => res.stateCode))
  //       let distinct: IOption[] = getDistinctObjects(states, ['label', 'value'])
  //       let stateOption = distinct.filter((option) => option.label.includes(parsedState) && parsedState !== '')
  //       setStateOptions(stateOption)
  //       setIsClientOptionLoading(false)
  //     })
  //     .catch(() => {
  //       setIsClientOptionLoading(false)
  //     })
  // }, [state])

  const fetchRegionIndexes = useCallback(() => {
    setIsClientOptionLoading(true)
    getRegionIndexes()
      .then((response: AxiosResponse<IRegionIndex[]>) => {
        // regions
        setRegionIndexes(response.data)
        let states = transformStateOptions(response.data.map((res) => res.stateCode))
        let distinct: IOption[] = getDistinctObjects(states, ['label', 'value'])
        setStateOptions(distinct)
        setIsClientOptionLoading(false)
      })
      .catch(() => {
        setIsClientOptionLoading(false)
      })
  }, [])

  const fetchLeadUpTimes = useCallback(() => {
    setIsClientOptionLoading(true)
    getLeadUpTimes()
      .then((response: AxiosResponse<ILeadUpTimes[]>) => {
        setLeadUpTimes(response.data)
        setIsClientOptionLoading(false)
      })
      .catch(() => {
        setIsClientOptionLoading(false)
      })
  }, [])

  const fetchRebuildTimes = useCallback(() => {
    setIsClientOptionLoading(true)
    getRebuildTimes()
      .then((response: AxiosResponse<IRebuildTimes[]>) => {
        setRebuildTimes(response.data)
        setIsClientOptionLoading(false)
      })
      .catch(() => {
        setIsClientOptionLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!address) {
      setRegionOptions([])
      setSelectedRegion('')
      setSelectedState('')
      setRegion('')
      return
    }
    fetchRegionIndexes()
  }, [address, fetchRegionIndexes])

  const fetchBuildingElementsOption = useCallback(() => {
    setIsClientOptionLoading(true)
    getBuildingElementsOption()
      .then((response: AxiosResponse<IBuildingElementSectionResponse>) => {
        setBuildingElementOption({
          alarmSystem: transformBuildingElementOptions(response.data.alarmSystem),
          fireServices: transformBuildingElementOptions(response.data.fireServices),
          hotWaterSystems: transformBuildingElementOptions(response.data.hotWaterSystems),
          hvaCs: transformBuildingElementOptions(response.data.hvaCs),
          lift: transformBuildingElementOptions(response.data.lift),
          liftSecondary: transformBuildingElementOptions(response.data.liftSecondary),
          septicSystem: transformBuildingElementOptions(response.data.septicSystem),
          solars: transformBuildingElementOptions(response.data.solars),
          tankStand: transformBuildingElementOptions(response.data.tankStand),
          waterTank: transformBuildingElementOptions(response.data.waterTank),
        })
      })
      .catch(() => {
        setIsClientOptionLoading(false)
      })
  }, [])

  useEffect(() => {}, [buildingElementOption])

  useEffect(() => {
    fetchData()
    if (projectUserRoleOptions.length === 0) fetchProjectUserRoles()
    // if (clientOptions.length === 0) fetchClient('')
    if (userOptions.length === 0) fetchUserOptions('')
    if (evaluationRegionOptions.length === 0) fetchEvaluationRegions()
    if (assetClassOptions.length === 0) fetchAssetClassOptions('')
    if (asbestosStatusOptions.length === 0) fetchAsbestosStatusOptions('')
    if (regionIndexes.length === 0) fetchRegionIndexes()
    if (!buildingElementOption) fetchBuildingElementsOption()
    if (leadUpTimes.length === 0) fetchLeadUpTimes()
    if (rebuildTimes.length === 0) fetchRebuildTimes()
  }, [gridSetup])

  useEffect(() => {
    fetchData()
    fetchClient('', selectedOffice.id)
  }, [selectedOffice])

  const handlePageChange = (pageProps: any) => {
    setGridSetup({
      ...gridSetup,
      skip: pageProps.page.skip,
      take: pageProps.page.take,
    })
  }

  const handleSearch = (searchText: any) => {
    setGridSetup({
      ...gridSetup,
      search: searchText,
    })

    //console.log('search', searchText)
  }

  useEffect(() => {
    setGridSetup({
      ...gridSetup,
      skip: 0,
    })
  }, [searchFilter])

  const [gridSetupUserGroup, setGridSetupUserGroup] = useState<GridSetup>(Initial_GridSetup)
  const [searchFilterUserGroup, setSearchFilterUserGroup] = useState('')

  const items = ['View/Edit', 'Delete']

  const handlePageChangeUserGroup = (pageProps: any) => {
    setGridSetupUserGroup({
      ...gridSetupUserGroup,
      skip: pageProps.page.skip,
      take: pageProps.page.take,
    })
  }

  let fetchClientOptionQueue: any
  const clientOptionFilterChangeHandler = (event: DropDownListFilterChangeEvent) => {
    if (!event.filter) return
    if (fetchClientOptionQueue) {
      clearTimeout(fetchClientOptionQueue)
    }

    fetchClientOptionQueue = setTimeout(function () {
      fetchClient(event.filter.value, selectedOffice.id)
    }, 1000)
  }

  const [isUserGroupModalVisible, setIsUserGroupModalVisible] = useState(false)

  const [assetClassComponentOptions, setAssetClassComponentOptions] = useState<IOption[]>([])
  const fetchAssetClassComponentOptions = useCallback((assetClassComponent: string) => {
    getAssetClassComponentOptions(assetClassComponent)
      .then((response: any) => {
        const valuers = transformDataOptions(response?.data)
        setAssetClassComponentOptions(valuers)
      })
      .catch(() => {})
  }, [])

  return (
    <ProjectContext.Provider
      value={{
        initialValue: PROJECT_FORM_DEFAULT,
        tableData,
        setTableData,
        isLoading,
        setIsLoading,
        totalRows,
        handlePageChange,
        handleSearch,
        fetchData,
        selectedData,
        setSelectedData,
        isModalVisible,
        setModalVisible,
        userOptions,
        projectUserRoleOptions,
        setSearchFilter,
        searchFilter,
        gridSetup,
        setGridSetup,
        isUserGroupModalVisible,
        setIsUserGroupModalVisible,
        clientOptions,
        isClientOptionLoading,
        clientOptionFilterChangeHandler,
        handlePageChangeUserGroup,
        searchFilterUserGroup,
        setSearchFilterUserGroup,
        gridSetupUserGroup,
        setGridSetupUserGroup,
        showToastrNotification,
        evaluationRegionOptions,
        isAsbestosStatusOptionLoading,
        asbestosStatusOptions,
        isAssetClassOptionLoading,
        assetClassOptions,
        stateOptions,
        regionIndexes,
        dataUpdated,
        setDataUpdated,
        updateData,
        buildingElementOption,
        address,
        setAddress,
        pinnedLocation,
        setPinnedLocation,
        setStateOptions,
        region,
        setRegion,
        regionOptions,
        setRegionOptions,
        selectedRegion,
        setSelectedRegion,
        selectedState,
        setSelectedState,
        fetchAssetClassComponentOptions,
        assetClassComponentOptions,
        singleLineReplacementCost,
        setSingleLineReplacementCost,
        singleLineData,
        setSingleLineData,
        leadUpTimes,
        rebuildTimes,
        fetchClient,
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}
