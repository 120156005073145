import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import {IAssetComponentized} from '../../../../../models/project-asset/componentized'
import {FC} from 'react'
import './UnitRateForm.scss'
import React from 'react'
import {IOption} from '../../../../../models/project-model'
import {UnitRateFields} from './UnitRateFields'

type IAssetInformationTabProps = {
  currentIndex: number
  register: UseFormRegister<IAssetComponentized>
  control: Control<IAssetComponentized>
  getValues: UseFormGetValues<IAssetComponentized>
  errors: FieldErrors<IAssetComponentized>
  watch: UseFormWatch<IAssetComponentized>
  setValue: UseFormSetValue<IAssetComponentized>
  assetClassComponentOptions: IOption[]
  locationOptions: IOption[]
}

const UnitRateForm: FC<IAssetInformationTabProps> = ({
  register,
  currentIndex,
  control,
  getValues,
  errors,
  watch,
  setValue,
  assetClassComponentOptions,
  locationOptions,
}) => {
  return (
    <div key={currentIndex}>
      <UnitRateFields
        currentIndex={currentIndex}
        control={control}
        register={register}
        getValues={getValues}
        watch={watch}
        errors={errors}
        setValue={setValue}
        assetClassComponentOptions={assetClassComponentOptions}
        locationOptions={locationOptions}
      ></UnitRateFields>
    </div>
  )
}

export {UnitRateForm}
