import React from 'react'

type ButtonProps = {
  style?: any
  onClick?: (value?: any) => void
  text: string
  icon?: any
  buttonType: 'primary' | 'secondary' | 'section'
  errors?: any
  data?: any
  disabled?: boolean
  isSubmit?: boolean
}

function Button(props: ButtonProps) {
  const {text, onClick = () => {}, icon, buttonType, style, disabled, isSubmit = true} = props

  return (
    <button
      disabled={disabled}
      type={isSubmit ? 'submit' : 'button'}
      className={
        'btn ' +
        (buttonType === 'section' ? 'btn-xsm' : 'btn-sm') +
        ' btn-block ' +
        (buttonType === 'section' ? 'fs-8' : 'fs-6')
      }
      style={{
        ...style,
        backgroundColor:
          buttonType === 'primary' ? '#a71304' : buttonType === 'section' ? '#fff' : 'transparent',
        color: buttonType === 'primary' ? '#fff' : '#a71304',
        border: buttonType === 'primary' ? 'none' : '1px solid #a71304',
      }}
      onClick={onClick}
    >
      {icon}
      {text}
    </button>
  )
}

export default Button
