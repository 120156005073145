import React, {useState, useEffect, useContext} from 'react'
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
  GridCellProps,
} from '@progress/kendo-react-grid'
import {SortDescriptor, orderBy} from '@progress/kendo-data-query'

import {CircularProgressbar} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import TitleHeader from '../../../../_metronic/layout/components/header/TitleHeader'
import {ProjectListContext} from './../context/ProjectListContext'
import {useHistory} from 'react-router'

const initialSort: Array<SortDescriptor> = [{field: 'id', dir: 'desc'}]

function ProjectListTable(props: any) {
  const history = useHistory()
  const {projects, pageNumber, pageSize, isLoadingProjects} = useContext(ProjectListContext)

  const [loading, setLoading] = useState(isLoadingProjects)

  const [sort, setSort] = useState(initialSort)

  useEffect(() => {
    setLoading(isLoadingProjects)
  }, [isLoadingProjects])

  const percentage = 38

  let sortedAllProjects = projects
  switch (sort[0]?.field) {
    case 'name':
      sortedAllProjects = orderBy(projects, sort)
      break
    case 'status':
      sortedAllProjects = orderBy(projects, sort)
      break
    case 'assetCount':
      sortedAllProjects = orderBy(projects, sort)
      break
    case 'completed':
      sortedAllProjects = orderBy(projects, sort)
      break
    case 'queried':
      sortedAllProjects = orderBy(projects, sort)
      break
    case 'deletedCount':
      sortedAllProjects = orderBy(projects, sort)
      break
    case 'lastModified':
      sortedAllProjects = orderBy(projects, sort)
      break
    case 'projectManagers':
      sortedAllProjects = orderBy(projects, sort)
      break
    default:
      sortedAllProjects = projects
      break
  }

  const customHeaderCell = (props: any) => {
    return (
      <>
        <span onClick={props.onClick} className={'k-cell-inner'}>
          <span className={'k-link'}>
            <span
              className={'k-column-title fw-bolder'}
              style={{textAlign: 'center', marginBottom: 0}}
            >
              {props.title}
            </span>
            {props.children}
          </span>
        </span>
      </>
    )
  }

  const formatDate = (dateString: any) => {
    const date = new Date(dateString)
    let day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date)
    let month = new Intl.DateTimeFormat('en', {month: 'long'}).format(date)
    let year = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date)
    return `${day} ${month} ${year}`
  }

  /**
   * Dynamic Column Width
   */
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight])
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const calculateWidth = (field: any) => {
    let maxWidth = 0
    projects.forEach((item: any) => {
      const size = getTextWidth(item[field], 'normal 14px Poppins') + 12
      if (size > maxWidth) {
        maxWidth = windowSize[0] >= 1920 ? size * 1.5 : size
      }
    })
    return maxWidth
  }

  const getTextWidth = (text: any, font: any): any => {
    const canvas: any =
      (getTextWidth as any).canvas ||
      ((getTextWidth as any).canvas = document.createElement('canvas'))
    const context = canvas.getContext('2d')
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
  }

  const customTwoLineCell = (props: GridCellProps) => {
    return (
      <td>
        <div onClick={() => onClickProject(props.dataItem.id)}>
          <strong className='project-name'>{props.dataItem['name']}</strong>
        </div>
        <div>{props.dataItem['description']}</div>
      </td>
    )
  }

  const onClickProject = (projectId: string) => {
    history.push(`/projects/${projectId}`)
  }

  return (
    <div
      className='m-auto mt-4 px-6 py-6'
      style={{backgroundColor: '#fff', boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px'}}
    >
      {loading ? (
        <div className='d-flex alig-items-center justify-content-center'>
          <div className='spinner-border' role='status' style={{color: '#a71304'}}>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : !projects || projects.length === 0 ? (
        <div className='align-middle text-center'>There are no projects to display!</div>
      ) : (
        <>
          <TitleHeader title='Open Projects' />
          <Grid
            data={orderBy(
              sortedAllProjects.slice(
                (pageNumber - 1) * pageSize,
                pageSize + (pageNumber - 1) * pageSize
              ).length === 0
                ? sortedAllProjects
                : sortedAllProjects.slice(
                    (pageNumber - 1) * pageSize,
                    pageSize + (pageNumber - 1) * pageSize
                  ),
              sort
            )}
            // data={orderBy(projects, sort)}
            pageable={true}
            resizable={true}
            reorderable={false}
            // total={totalCount}
            // total={sortedAllProjects?.length}
            // onPageChange={handlePageChange}
            // take={pageSize}
            // skip={(pageNumber - 1) * pageSize}
            sort={sort}
            sortable={true}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort)
            }}
          >
            <Column
              width={80}
              field=''
              title=''
              cell={(props) => (
                <td className='align-middle' style={{position: 'relative', padding: 40}}>
                  <div
                    style={{
                      width: 40,
                      height: 40,
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <CircularProgressbar
                      value={percentage}
                      text={`${percentage}%`}
                      styles={{
                        root: {},
                        path: {stroke: `rgba(255, 0, 0, 1)`},
                        text: {
                          fill: '#f88',
                          fontSize: '32px',
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </div>
                </td>
              )}
            />
            <Column
              width={calculateWidth('name') as any}
              field='name'
              title='Project Name'
              headerCell={customHeaderCell}
              cell={customTwoLineCell}
            />
            <Column field='status' title='Status' headerCell={customHeaderCell} />
            <Column field='assetCount' title='Asset Count' headerCell={customHeaderCell} />
            <Column field='completed' title='Completed' headerCell={customHeaderCell}></Column>
            <Column field='queried' title='Queried' headerCell={customHeaderCell}></Column>
            <Column field='pending' title='Pending' headerCell={customHeaderCell}></Column>
            <Column field='deletedCount' title='Deleted' headerCell={customHeaderCell}></Column>
            <Column
              sortable={true}
              field='projectManagers'
              title='Project Manager'
              cell={(props) => (
                <td className='align-middle text-left'>
                  {props?.dataItem?.projectManagers?.map((item: any, i: number) => (
                    <p key={i}>
                      <i className='bi bi-person-circle' style={{color: '#009ef6'}}></i>{' '}
                      {item?.name}
                    </p>
                  ))}
                </td>
              )}
              headerCell={customHeaderCell}
            ></Column>
            <Column
              field='lastModified'
              title='Last Update Date'
              headerCell={customHeaderCell}
              cell={(props) => (
                <td className='align-middle text-left'>
                  {formatDate(props.dataItem.lastModified)}
                </td>
              )}
            ></Column>
          </Grid>
        </>
      )}
    </div>
  )
}

export default ProjectListTable
