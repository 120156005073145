import React, {useContext, useState} from 'react'
import {useForm, useFieldArray} from 'react-hook-form'
import {Dialog} from '@progress/kendo-react-dialogs'
import {yupResolver} from '@hookform/resolvers/yup'
import GeneralDetails from './GeneralDetails/GeneralDetails'
import AssetInformation from './AssetInformation/AssetInformation'
import InspectionDetails from './InspectionDetails/InspectionDetails'
import BuildingReplacementRate from './BuildingReplacementRate/BuildingReplacementRate'
import ConstructionDifficulty from './ConstructionDifficulty/ConstructionDifficulty'
import ClientSpecifics from './ClientSpecifics/ClientSpecifics'
import AssetAttachments from './AssetAttachments/AssetAttachments'
import Button from '../../../../../../../../../_metronic/layout/components/buttons/Button'
import './NewAssetForm.css'
import {assetFormValidationSchema} from '../../../../../../validators/asset-form'
import {ProjectAssetData, ProjectData} from '../../../../../../models/project-assets'
import {PROJECTASSET_FORM_DEFAULT} from '../../../../../../constant/project-asset-default'
import {createProjectAsset} from '../../../../../../api/asset-api'
import {ProjectContext} from '../../../../../../context/ProjectContext'
import {IProjectData} from '../../../../../../models/project-model'
import BuildingElements from './BuildingElements/BuildingElements'
import {transformSaveProjectAssetData} from '../../../../../../transformers/project-transformer'
import {Loader2} from '../../../../../../../../../_metronic/layout/components/loader/Loader2'

type INewAssetFormProps = {
  isEdit: boolean
  setIsEdit: (value: boolean) => void
  setAssetModalVisible: (value: boolean) => void
  fulcrumId: string
  onSaveAsset: () => void
  valuationRegion: string
  projectData: IProjectData | ProjectData | undefined
}

function NewAssetForm(props: INewAssetFormProps) {
  const {setAssetModalVisible, isEdit, setIsEdit, valuationRegion, fulcrumId, projectData} = props
  const {
    showToastrNotification,
    evaluationRegionOptions,
    stateOptions,
    regionIndexes,
    setSelectedRegion,
    setAddress,
    setPinnedLocation,
    setSelectedState,
  } = useContext(ProjectContext)
  const [selectedData, setSelectedData] = useState<ProjectAssetData>({
    ...PROJECTASSET_FORM_DEFAULT,
    // ...dataProvider.newAsset as any,
    projectId: fulcrumId,
    valuationRegion: valuationRegion,
  })
  // Client specific field
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    register,
    control,
    handleSubmit,
    formState: {errors, isValid},
    clearErrors,
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm({
    defaultValues: selectedData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(assetFormValidationSchema) as any,
  })

  const {fields: projectAssetCustomFields, replace: replaceProjectAssetCustomFields} =
    useFieldArray({
      control,
      name: 'projectAssetCustomFields',
      keyName: 'keyIdx',
    })

  const closeModal = () => {
    setAssetModalVisible(false)
  }

  const handleCancel = () => {
    setIsEdit(false)
    closeModal()
    setAddress('')
    setSelectedState('')
    setSelectedRegion('')
    setPinnedLocation(null)
  }

  const renderFormComponent = (
    Component: React.FC<any>,
    additionalProps: Record<string, ProjectAssetData> = {}
  ) => (
    <Component
      register={register}
      control={control}
      handleSubmit={handleSubmit}
      errors={errors}
      isValid={isValid}
      clearErrors={clearErrors}
      getValues={getValues}
      setValue={setValue}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
      projectId={props.fulcrumId}
      projectAssetCustomFields={projectAssetCustomFields}
      replaceProjectAssetCustomFields={replaceProjectAssetCustomFields}
      watch={watch}
      valuationRegionOptions={evaluationRegionOptions}
      stateOptions={stateOptions}
      regionIndexes={regionIndexes}
      projectData={projectData}
      trigger={trigger}
      {...additionalProps}
    />
  )

  const onSubmit = (values: ProjectAssetData) => {
    // const modifiedValues = {...values, projectCustomFields}
    // modifiedValues.assetDescription =
    //   modifiedValues.assetName + '-' + modifiedValues.assetDescription

    // console.log(modifiedValues)
    const payload = transformSaveProjectAssetData(values)
    setIsLoading(true)
    createProjectAsset(payload)
      .then((response) => {
        if (response) {
          setIsLoading(false)
          showToastrNotification('Asset Saved succesfully', 'success')
          props.onSaveAsset()
        } else {
          setIsLoading(false)
          showToastrNotification('Error Saving Asset. Please try again.', 'error')
        }
      })
      .catch(() => {
        showToastrNotification('Error Saving Asset. Please try again.', 'error')
        setIsLoading(false)
      })
  }

  // useEffect(() => {
  //   if (address === '') {
  //     setSelectedState('')
  //     setSelectedRegion('')
  //   }
  // }, [address])

  return (
    <Dialog
      height={'80%'}
      width={'85%'}
      style={{backgroundColor: 'rgba(0,0,0,0.45)', zIndex: 100}}
      title={!isEdit ? 'Add Asset' : 'Edit Asset'}
      onClose={handleCancel}
    >
      <div>
        {isLoading && <Loader2></Loader2>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row'>
            <RowWrapper>
              <div className='col'>{renderFormComponent(GeneralDetails)}</div>
            </RowWrapper>
            <RowWrapper>
              <div className='col-9'>{renderFormComponent(AssetInformation)}</div>
              <div className='col-3'>{renderFormComponent(InspectionDetails)}</div>
            </RowWrapper>
            <RowWrapper>
              <div className='col-9'>{renderFormComponent(BuildingReplacementRate)}</div>
              <div className='col-3'>{renderFormComponent(ConstructionDifficulty)}</div>
            </RowWrapper>
            <RowWrapper>
              <div className='col'>{renderFormComponent(BuildingElements)}</div>
            </RowWrapper>
            <RowWrapper>
              <div className='col'>{renderFormComponent(ClientSpecifics)}</div>
            </RowWrapper>
            <RowWrapper>
              <div className='col'>{renderFormComponent(AssetAttachments)}</div>
            </RowWrapper>
            <RowWrapper>
              <div className='d-flex flex-row align-items-center justify-content-end w-100 mt-10'>
                <div className='d-flex flex-row align-items-center'>
                  <Button buttonType='primary' text={'Save'} />
                  <Button
                    buttonType='secondary'
                    text={'Cancel'}
                    onClick={handleCancel}
                    style={{marginLeft: 10}}
                  />
                </div>
              </div>
            </RowWrapper>
          </div>
        </form>
      </div>
    </Dialog>
  )
}

export default NewAssetForm

export const RowWrapper = ({children}: React.PropsWithChildren<{example?: string}>) => {
  return <div className='row mb-8'>{children}</div>
}
