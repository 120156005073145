/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {UserModel} from '../modules/auth/models/UserModel'
import PermissionProvider from '../modules/auth/providers/permission-provider/PermissionProvider'
import {getUserRolePermissions} from '../modules/auth/providers/permission-provider/UserRolePermissions'
import PrivateRoutes from './PrivateRoutes'
import {ErrorsPageLoggedIn} from '../modules/errors/ErrorsPageLoggedIn'

const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const currentUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel

  return (
    <>
      <Switch>
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from='/auth' to='/' />
        )}

        <Route path='/error' component={isAuthorized ? ErrorsPageLoggedIn : ErrorsPage} />
        <Route path='/logout' component={Logout} />

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to='/auth/login' />
        ) : (
          <>
            <PermissionProvider fetchPermission={getUserRolePermissions(currentUser)}>
              <MasterLayout>
                <PrivateRoutes activePages={currentUser.activePages} />
              </MasterLayout>
            </PermissionProvider>
          </>
        )}
      </Switch>
      <MasterInit />
    </>
  )
}

export {Routes}
