import React from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
} from 'react-hook-form'
import {IAdjustmentMatrix} from '../../models/adjustmentmatrix'
import {NumericTextBox} from '@progress/kendo-react-inputs'

interface IProps {
  adjustmentMatrixData?: IAdjustmentMatrix
  register: UseFormRegister<any>
  control: Control<any, any>
  errors: FieldErrors<any>
  userGroupOptions: any
  isDisabled: boolean
  clearErrors: UseFormClearErrors<any>
}

const AdjustmentMatrixFormFields: React.FC<IProps> = ({
  adjustmentMatrixData,
  register,
  errors,
  control,
  isDisabled,
  clearErrors,
}) => {
  return (
    <React.Fragment>
      <div className='col'>
        {adjustmentMatrixData ? (
          <input
            type='hidden'
            className={`form-control${errors.id ? ' border-danger' : ''}`}
            {...register('id')}
            disabled
          />
        ) : null}
        <div className='row mb-4'>
          <div className='col-12'>
            <div className='form-outline'>
              <label className='fw-bolder' htmlFor='adjustmentReason'>
                Adjustment Reason
              </label>
              <input
                type='text'
                className={`form-control${errors.firstName ? ' border-danger' : ''}`}
                placeholder='Enter Adjustment Reason'
                {...register('adjustmentReason', {
                  onChange: (e) => {
                    clearErrors('adjustmentReason')
                  },
                })}
                disabled={isDisabled}
              />
              {errors.adjustmentReason && (
                <small className='text-danger'>{errors.adjustmentReason.message}</small>
              )}
            </div>
          </div>
          <div className='col-12'>
            <div className='form-outline'>
              <label className='fw-bolder' htmlFor='adjustmentFactor'>
                Adjustment Factor (%)
              </label>
              <div>
                <Controller
                  control={control}
                  name='adjustmentFactor'
                  render={({field: {onChange, value}}) => (
                    <NumericTextBox
                      style={{
                        fontSize: '1rem',
                        height: '40px',
                        width: '120px',
                      }}
                      className={`form-control${errors.adjustmentFactor ? ' border-danger' : ''}`}
                      defaultValue={value}
                      value={value}
                      format='n2'
                      step={0.01}
                      min={0.0}
                      max={100.0}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.adjustmentFactor && (
                  <div>
                    <small className='text-danger'>{errors.adjustmentFactor.message}</small>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export {AdjustmentMatrixFormFields}
