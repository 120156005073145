import React, {useContext, useEffect, useState} from 'react'
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
  GridCellProps,
} from '@progress/kendo-react-grid'
import {gearIcon} from '@progress/kendo-svg-icons'

import {Dialog} from '@progress/kendo-react-dialogs'
import {ActionButton} from '../../../../../_metronic/layout/components/buttons/ActionButton'
import {ProjectContext} from '../../context/ProjectContext'
import {IProjectData} from '../../models/project-model'
//import {deleteUser} from '../../api'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import {FullNameFormatCell} from '../kendo/format/FullNameFormatCell'
import {deleteProject} from '../../api'
import './ProjectListTable.css'
import {useHistory} from 'react-router-dom'
import {CircularPercentageCell} from '../kendo/format/CircularPercentageCell'
import {useEffectOnce} from 'react-use'
import {hasPermission} from '../../../../shared/service/utils'
import {PAGE_PERMISSION} from '../../../roles/models/role-model'
import {usePageData} from '../../../../../_metronic/layout/core'

function ProjectsListTable(props: any) {
  const history = useHistory()
  const {
    tableData,
    totalRows,
    gridSetup,
    setGridSetup,
    handlePageChange,
    setSelectedData,
    setModalVisible,
    fetchData,
    showToastrNotification,
    isLoading,
    setIsLoading,
  } = useContext(ProjectContext)

  // let items: string[] = []
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>(null)
  const {currentUser} = usePageData()

  const [items, setItems] = useState<string[]>([])

  useEffectOnce(() => {
    let currentItems = []
    if (hasPermission(PAGE_PERMISSION.PROJECT_UPDATE, currentUser.activePages || []))
      currentItems.push('View/Edit')
    else currentItems.push('View')

    if (hasPermission(PAGE_PERMISSION.PROJECT_DELETE, currentUser.activePages || []))
      currentItems.push('Delete')

    setItems(currentItems)
  })

  const confirmDelete = (id: number) => {
    setSelectedDeleteId(id)
  }

  const deleteItem = async () => {
    try {
      if (selectedDeleteId !== null) {
        await deleteProject(selectedDeleteId).then(() => {
          fetchData()
          setSelectedDeleteId(null)
        })
        showToastrNotification('Project successfully deleted', 'success')
      }
    } catch (error) {
      showToastrNotification('Oops we encountered a problem. Please try again!', 'error')
      setSelectedDeleteId(null)
    }
  }

  const handleDropDownClick = (user: IProjectData, action: string) => {
    if (action === 'View/Edit') {
      setSelectedData(user)
      setModalVisible(true)
    } else if (action === 'View') {
      setSelectedData(user)
      setModalVisible(true)
    } else if (action === 'Delete') {
      confirmDelete(user.id ?? 0)
    }
  }

  const handleCloseDeleteDialog = () => {
    setSelectedDeleteId(null)
  }

  const handleRowClick = (event: any) => {
    const dataItem = event.dataItem
    // if (hasPermission(PAGE_PERMISSION.ASSETS_PAGE_VIEW, currentUser.activePages || [])) {
    history.push(`/projects/${dataItem['id']}`)
    // }
  }

  const customHeaderCell = (props: any) => {
    return (
      <>
        <span onClick={props.onClick} className={'k-cell-inner'}>
          <span className={'k-link'}>
            <span
              className={'k-column-title fw-bolder'}
              style={{textAlign: 'center', marginBottom: 0}}
            >
              {props.title}
            </span>{' '}
            {props.children}
          </span>
        </span>
      </>
    )
  }

  const customTwoLineCell = (props: GridCellProps) => {
    return (
      <td>
        <div onClick={() => onClickProject(props.dataItem.id)}>
          <strong className='project-name'>{props.dataItem['name']}</strong>
        </div>
        <div>{props.dataItem['description']}</div>
      </td>
    )
  }

  const onClickProject = (projectId: string) => {
    if (hasPermission(PAGE_PERMISSION.ASSETS_PAGE_VIEW, currentUser.activePages || [])) {
      history.push(`/projects/${projectId}`)
    }
  }

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }, [tableData])

  return (
    <div className='m-auto mt-4'>
      {selectedDeleteId !== null && (
        <Dialog
          height={'auto'}
          style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
          title={'Delete Project'}
          onClose={handleCloseDeleteDialog}
        >
          <div className='row mb-4'>
            <div className='col'>
              <p>Are you sure you want to delete this item?</p>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col d-flex justify-content-end'>
              <Button onClick={deleteItem} buttonType='primary' text={'Yes'} />
              <Button
                buttonType='secondary'
                text={'No'}
                onClick={() => setSelectedDeleteId(null)}
                style={{marginLeft: 10}}
              />
            </div>
          </div>
        </Dialog>
      )}

      {isLoading ? (
        <div className='d-flex alig-items-center justify-content-center'>
          <div className='spinner-border' role='status' style={{color: '#a71304'}}>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : !totalRows || totalRows === 0 ? (
        <div className='align-middle text-center'>There are no projects to display!</div>
      ) : (
        <Grid
          resizable={true}
          data={tableData}
          // data={props?.allUsers}
          pageable={true}
          onPageChange={handlePageChange}
          {...gridSetup}
          total={totalRows}
          sort={gridSetup.sort}
          sortable={true}
          onSortChange={(e: GridSortChangeEvent) => {
            setGridSetup({...gridSetup, sort: e.sort})
          }}
          onRowClick={handleRowClick}
        >
          <Column
            width={80}
            title=''
            cell={(props) => (
              <td className='align-middle text-center'>
                <ActionButton
                  width='50px'
                  svgIcon={gearIcon}
                  text=''
                  items={items}
                  onItemClick={(event: any) => handleDropDownClick(props.dataItem, event.item)}
                />
              </td>
            )}
          />
          <Column width={80} field='progressPercent' title=' ' cell={CircularPercentageCell} />
          <Column field='displayId' title='ID' headerCell={customHeaderCell} />
          <Column
            field='name'
            title='Project Name'
            headerCell={customHeaderCell}
            cell={customTwoLineCell}
          />
          <Column field='clientName' title='Client Name' headerCell={customHeaderCell} />
          <Column
            field='projectManagersDisplay'
            title='Project Manager'
            cell={FullNameFormatCell}
            headerCell={customHeaderCell}
            sortable={true}
          />
          <Column
            field='valuerDisplay'
            title='Valuer'
            cell={FullNameFormatCell}
            headerCell={customHeaderCell}
            sortable={true}
          />
        </Grid>
      )}
    </div>
  )
}

export default ProjectsListTable
