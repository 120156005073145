/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout, usePageData} from '../../core'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import {getUserOffices} from '../../../../app/modules/office-list/api'
import {AxiosResponse} from 'axios'
import {IOffice} from '../../../../app/modules/office-list/models/office'
import {LS_DEFAULTSELECTEDOFFICE} from '../../../../app/shared/service/utils'

const AsideDefault = () => {
  const {
    config,
    classes,
    setModalVisible,
    selectedOffice,
    setOfficeOption,
    setSelectedOption,
    setSelectedOffice,
  } = useLayout()

  const {currentUser} = usePageData()

  const selectedDefaultOffice = JSON.parse(
    localStorage.getItem(LS_DEFAULTSELECTEDOFFICE) as any
  ) as IOffice

  const {aside} = config

  useEffect(() => {
    if (currentUser && currentUser.email !== '') {
      getUserOffices()
        .then((response: AxiosResponse<IOffice[]>) => {
          if (response.data) {
            if (response.data.length > 0) {
              let defaultSelected = response.data[0]
              if (selectedDefaultOffice) {
                const selectedOfficeLS = response.data.find(
                  (item) => item.id === selectedDefaultOffice.id
                )
                if (selectedOfficeLS) {
                  defaultSelected = selectedOfficeLS
                }
              }
              setSelectedOption(defaultSelected)
              setSelectedOffice(defaultSelected)
            }
            setOfficeOption(response.data)
          }
        })
        .catch(() => {})
    }
  }, [currentUser])

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
      style={{backgroundColor: '#151d23', zIndex: 99}}
    >
      {/* begin::Brand */}
      <div
        className='aside-logo flex-column-auto'
        id='kt_aside_logo'
        style={{backgroundColor: '#151d23'}}
      >
        {/* begin::Logo */}
        {aside.theme === 'dark' && (
          <Link to='/dashboard'>
            <img
              alt='Logo'
              className='h-60px logo'
              src={toAbsoluteUrl('/media/logos/australis-logo.png')}
            />
          </Link>
        )}
        {aside.theme === 'light' && (
          <Link to='/dashboard'>
            <img
              alt='Logo'
              className='h-60px logo'
              src={toAbsoluteUrl('/media/logos/australis-logo.png')}
            />
          </Link>
        )}
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      <div className='flex-column-auto pt-5 pb-7 px-5'>
        <button
          onClick={() => setModalVisible(true)}
          className='btn btn-custom btn-primary w-100'
          style={{backgroundColor: '#a71304', color: '#fff'}}
        >
          <span className='btn-label fw-bold'>
            {selectedOffice.id === 0 ? 'Office Name' : selectedOffice.name}
          </span>
          <span className='svg-icon btn-icon svg-icon-2'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' />
          </span>
        </button>
      </div>

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}
      <p
        style={{
          width: '100%',
          fontSize: 11,
          color: '#fff',
          textAlign: 'center',
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        2024&copy; Australis Navigator
      </p>
    </div>
  )
}

export {AsideDefault}
