import {GridCellProps} from '@progress/kendo-react-grid'
import {CurrencyFormatter} from '../../../../../shared/service/utils'

export const AmountCell = (props: GridCellProps) => {
  const {dataItem} = props
  const field = props.field || ''
  const dataValue = dataItem ? (dataItem[field] ? dataItem[field] : '') : ''

  return <td className='align-right'>{CurrencyFormatter(dataValue)}</td>
}
