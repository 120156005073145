import {Dialog} from '@progress/kendo-react-dialogs'
import {FC, useContext, useState} from 'react'
import {CLIENT_INITIAL} from '../../constant/client-constant'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {clientFormValidationSchema} from '../../validator/client-form'
import {ClientFormFields} from './ClientFormFields'
import {ClientContext} from '../../context/ClientContext'
import {addNewClient} from '../../api/client-api'
import {IClient} from '../../models/client'

interface IProps {
  handleCloseDialog: (refresh: boolean, id?: any) => void
}

const NewClientForm: FC<IProps> = ({handleCloseDialog}) => {
  const {showToastrNotification, fetchAllClients} = useContext(ClientContext)
  const [isSaving, setIsSaving] = useState(false)

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    getValues,
  } = useForm({
    defaultValues: CLIENT_INITIAL,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(clientFormValidationSchema) as any,
  })

  const onSubmitHandler = async (values: IClient) => {
    setIsSaving(true)

    const [data, err] = await addNewClient(values)
    if (err) {
      if (showToastrNotification)
        showToastrNotification('Error encountered while saving client.', 'danger')
      setIsSaving(false)
    } else {
      if (showToastrNotification) {
        showToastrNotification('Client saved successfully.', 'success')
        fetchAllClients()
      }
      handleCloseDialog(true, data)
    }
  }

  return (
    <Dialog
      style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
      title={'New Client'}
      onClose={() => handleCloseDialog(false)}
      width={'50%'}
    >
      <div className='row mb-4'>
        <div className='col'>
          <form
            className='form'
            onSubmit={(e) => {
              e.stopPropagation()
              return handleSubmit(onSubmitHandler)(e)
            }}
          >
            <ClientFormFields
              register={register}
              getValues={getValues}
              errors={errors}
              control={control}
            ></ClientFormFields>
          </form>
        </div>
      </div>
    </Dialog>
  )
}

export {NewClientForm}
