import React, {useEffect} from 'react'
import {createPortal} from 'react-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider, useLayout} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
import {Modal} from './components/modal/Modal'
import Button from './components/buttons/Button'
import TitleHeader from './components/header/TitleHeader'
import {IOffice} from '../../app/modules/office-list/models/office'

const MasterLayout: React.FC = ({children}) => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  // MULTI-OFFICE FEATURE
  const {
    isModalVisible,
    setModalVisible,
    selectedOption,
    setSelectedOption,
    handleSubmit,
    officeOption,
  } = useLayout()

  const onChangeHandler = (index: number) => {
    setSelectedOption(officeOption[index])
  }

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <Toolbar />
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>{children}</Content>
            </div>
          </div>
        </div>
        {/* MULTI-OFFICE FEATURE MODAL*/}
        {isModalVisible &&
          createPortal(
            <Modal isOpen={isModalVisible} closeModal={() => setModalVisible(false)} width={'30%'}>
              <TitleHeader title='Select Office' />
              <div className='form-check my-4 scrollable-container'>
                {officeOption.map((officeOption: IOffice, index: number) => (
                  <div key={index} className='form-check mb-6'>
                    <input
                      type='radio'
                      className='form-check-input'
                      id={`radio${index + 1}`}
                      value={officeOption.id}
                      checked={selectedOption.id === officeOption.id}
                      onChange={() => onChangeHandler(index)}
                    />
                    <label className='form-check-label fw-bold' htmlFor={`radio${index + 1}`}>
                      {officeOption.code} ({officeOption.name})
                    </label>
                  </div>
                ))}
              </div>
              <Button
                buttonType='primary'
                text={'Select Office'}
                onClick={() => {
                  setModalVisible(false)
                  handleSubmit()
                }}
              />
            </Modal>,
            document.body
          )}
      </div>

      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
