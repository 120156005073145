import React from 'react'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import { AccessControlProvider } from '../../../../../_metronic/layout/core/AccessControlProvider'

type ISectionTitleProps = {
  isAdd: boolean
  title: string
  onAdd: () => void
  onCancel: () => void
  addButtonText?: string
  permission?: string
}

function SectionTitleAddHeader(props: ISectionTitleProps) {
  const {title, isAdd, onAdd, addButtonText, onCancel, permission} = props
  return (
    <>
      <div
        className='d-flex justify-content-between align-items-center mb-2 px-2'
        style={{width: '100%', background: '#a71304'}}
      >
        <h4
          style={{
            color: '#fff',
            marginBottom: 0,
            paddingTop: 10,
            paddingBottom: 10,
            fontWeight: 600,
          }}
        >
          {title}
        </h4>
        <AccessControlProvider allowedPermission={permission}>
            {isAdd ? (
              <div>
                <Button buttonType='section' text={'Save'} />
                <Button
                  buttonType='section'
                  isSubmit={false}
                  text={'Cancel'}
                  onClick={onCancel}
                  style={{marginLeft: 5}}
                />
              </div>
            ) : (
              <Button
                buttonType='section'
                isSubmit={false}
                text={addButtonText ? addButtonText : 'Add'}
                onClick={onAdd}
              />
            )}
        </AccessControlProvider>
      </div>
    </>
  )
}

export default SectionTitleAddHeader
