import {
  Grid,
  GridExpandChangeEvent,
  GridColumn as Column,
  GridDetailRowProps,
  GridCellProps,
} from '@progress/kendo-react-grid'
import {FC, useCallback, useContext, useEffect, useState} from 'react'
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form'
import {GridSetup, IOption} from '../../../../../models/project-model'
import {Initial_GridSetup} from '../../../../../constant/project-default'
import {getAssetComponentInsurancePagination} from '../../../../../api/asset-component-valuation-api'
import {MainAreaField} from './CustomFieldProps/MainAreaField'
import {SecondaryAreaField} from './CustomFieldProps/SecondaryAreaField'
import {ProjectContext} from '../../../../../context/ProjectContext'
import {transformDefaultOptions} from '../../../../../transformers/project-transformer'
import {CurrencyFormatter, getDistinctObjects} from '../../../../../../../shared/service/utils'
import {ComponentNameTextField} from './CustomFieldProps/ComponentNameTextField'
import {
  IAssetInsuranceValuation,
  IInsuranceValuation,
} from '../../../../../models/project-asset/insurance-valuation'
import InsuranceValuationTabForm from '../InsuranceValuationTab/InsuranceValuationTabForm'

type IAssetInformationTabProps = {
  register: UseFormRegister<IAssetInsuranceValuation>
  projectAssetId: number
  control: Control<IAssetInsuranceValuation>
  errors: FieldErrors<IAssetInsuranceValuation>
  getValues: UseFormGetValues<IAssetInsuranceValuation>
  watch: UseFormWatch<IAssetInsuranceValuation>
  setValue: UseFormSetValue<IAssetInsuranceValuation>
  reset: UseFormReset<IAssetInsuranceValuation>
  stateCode: string
}

const InsuranceRecord: FC<IAssetInformationTabProps> = ({
  register,
  projectAssetId,
  errors,
  getValues,
  control,
  watch,
  setValue,
  stateCode,
  reset,
}) => {
  const [gridSetup, setGridSetup] = useState<GridSetup>(Initial_GridSetup)
  const [isLoading, setIsLoading] = useState(false)
  const [totalRows, setTotalRows] = useState<number>(0)
  const {assetClassComponentOptions, regionIndexes} = useContext(ProjectContext)
  const [locationOptions, setLocationOptions] = useState<IOption[]>([])

  useEffect(() => {
    let locBase = regionIndexes.filter((item: any) => item.stateCode === stateCode)
    let locBaseOption = transformDefaultOptions(locBase.map((res: any) => res.localGovernmentArea))
    let distinct: IOption[] = getDistinctObjects(locBaseOption, ['label', 'value'])
    setLocationOptions(distinct)
  }, [regionIndexes])

  const {
    fields: componentized,
    update: updateComponentized,
    replace: replaceComponentized,
  } = useFieldArray({
    control,
    name: 'componentized',
    keyName: 'idx',
  })

  const fetchData = useCallback(
    (search: string = '') => {
      setIsLoading(true)
      var {sort, skip, take} = gridSetup
      getAssetComponentInsurancePagination(skip, take, sort[0], projectAssetId)
        .then((response: any) => {
          replaceComponentized([...response?.data.items])
          setTotalRows(response?.data.totalCount)
          setIsLoading(false)
          reset(getValues())
        })
        .catch(() => {
          setIsLoading(false)
        })
    },
    [gridSetup]
  )

  useEffect(() => {
    fetchData()
  }, [gridSetup, fetchData])

  const handlePageChange = (pageProps: any) => {
    setGridSetup({
      ...gridSetup,
      skip: pageProps.page.skip,
      take: pageProps.page.take,
    })
  }

  const expandChange = (event: GridExpandChangeEvent) => {
    componentized.forEach((item: IInsuranceValuation, index) => {
      if (item.id === event.dataItem.id) {
        const expanded = !event.dataItem.expanded
        updateComponentized(index, {
          ...getValues(`componentized.${index}`),
          expanded: expanded,
        })
        reset(getValues())
      }
    })
  }

  const [selected, setSelected] = useState<number>(0)
  const handleSelect = (e: any) => {
    setSelected(e.selected)
  }

  const renderFormComponent = (Component: React.FC<any>, currentIndex: number) => (
    <Component
      currentIndex={currentIndex}
      register={register}
      errors={errors}
      getValues={getValues}
      control={control}
      watch={watch}
      setValue={setValue}
      assetClassComponentOptions={assetClassComponentOptions}
      locationOptions={locationOptions}
    />
  )

  const DetailComponent = (props: GridDetailRowProps) => {
    return (
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab title='Insurance Valuation'>
          {renderFormComponent(InsuranceValuationTabForm, props.dataIndex)}
        </TabStripTab>
      </TabStrip>
    )
  }

  const componentNameTextField = (props: GridCellProps) => {
    return (
      <ComponentNameTextField
        register={register}
        dataIndex={props.dataIndex}
      ></ComponentNameTextField>
    )
  }

  const mainAreaField = (props: GridCellProps) => {
    return <MainAreaField register={register} dataIndex={props.dataIndex}></MainAreaField>
  }
  const secondaryAreaField = (props: GridCellProps) => {
    return <SecondaryAreaField register={register} dataIndex={props.dataIndex}></SecondaryAreaField>
  }

  const currencyCellField = (props: GridCellProps) => {
    return (
      <td key={props.dataIndex}>
        <span>{CurrencyFormatter(props.dataItem.replacementCost)}</span>
      </td>
    )
  }

  return (
    <div>
      {isLoading ? (
        <div className='d-flex alig-items-center justify-content-center'>
          <div className='spinner-border' role='status' style={{color: '#a71304'}}>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        <Grid
          data={componentized}
          expandField='expanded'
          onExpandChange={expandChange}
          detail={DetailComponent}
          pageable={true}
          onPageChange={handlePageChange}
          total={totalRows}
        >
          <Column field='componentNumber' title='Component Number' />
          <Column field='componentType' title='Component Type' />
          <Column field='componentName' title='Component Name' cell={componentNameTextField} />
          <Column field='mainArea' title='Main Area' cell={mainAreaField} />
          <Column field='secondaryArea' title='Secondary Area' cell={secondaryAreaField} />
          <Column field='valuationRegion' title='Valuation Region' />
          <Column
            field='replacementCost'
            title='Fair Value Replacement Cost'
            cell={currencyCellField}
          />
        </Grid>
      )}
    </div>
  )
}

export default InsuranceRecord
