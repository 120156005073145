import axios from 'axios'
import {SortDescriptor} from '@progress/kendo-data-query'

export const REPORT_URL = `${process.env.REACT_APP_API_URL}/Report`

export const getAaagMonthlyReport = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  search: string
) => {
  const pageNumber = skip / take + 1

  return await axios.post(`${REPORT_URL}/aaagmonthlyreport`, {
    filter: search,
    pageNumber: pageNumber,
    pageSize: take * 10,
    sort: pageSort ? `${pageSort.field} ${pageSort.dir}` : '',
  })
}

export const downloadAaagMonthlyReport = async (id: any) => {
  return await axios.get(`${REPORT_URL}/download`, {
    params: {
      id,
    },
    responseType: 'blob',
  })
}
