import React, {useEffect, useState, useCallback, useContext} from 'react'
import Map, {Marker, NavigationControl} from 'react-map-gl/maplibre'
import '../ui/AssetInformation.scss'
import {Dialog} from '@progress/kendo-react-dialogs'
import Button from '../../../../../../_metronic/layout/components/buttons/Button'
import {ProjectAssetData} from '../../../models/project-assets'
import axios from 'axios'
import {createPortal} from 'react-dom'
import {AssetGalleries} from './AssetGalleries'
import {
  getAssetStatusOptions,
  updateProjectAssetLocation,
  updateProjectAssetProfile,
} from '../../../api/asset-api'
import 'maplibre-gl/dist/maplibre-gl.css'
import maplibregl from 'maplibre-gl'
import SectionTitleHeader from '../../common/SectionTitleHeader'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {assetInformationValidationSchema} from '../../../validators/asset-form'
import Select from 'react-select'
import {transformDataOptions} from '../../../transformers/project-transformer'
import {useEffectOnce} from 'react-use'
import {IOption} from '../../../models/project-model'
import {ProjectContext} from '../../../context/ProjectContext'
import SectionEditHeader from '../../common/SectionEditHeader'

type IAssetProfileProps = {
  assetData: ProjectAssetData
  setAssetData: (assetData: ProjectAssetData) => void
  // onHandleFormChange: (isDirty: boolean) => void
}

type AssetLocationMapModalProps = {
  address: string
  setAddress: (value: string) => void
  handleCancel: () => void
  onSubmit: () => void
  pinnedLocation: [number, number] | null
  setPinnedLocation: (value: [number, number] | null) => void
  fetchAddress: () => void
  loading: boolean
  setLoading: (value: boolean) => void
  longitude: number
  latitude: number
}

const MAPTILER_ENDPOINT = process.env.REACT_APP_MAPTILER_ENDPOINT
const MAPTILER_STYLE_KEY = process.env.REACT_APP_MAPTILER_STYLE_KEY

function AssetProfile(props: IAssetProfileProps) {
  const {assetData, setAssetData} = props
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isAssetStatusOptionLoading, setIsAssetStatusOptionLoading] = useState(false)
  const [assetStatusOptions, setAssetStatusOptions] = useState<IOption[]>([])
  const {showToastrNotification} = useContext(ProjectContext)
  /**
   * MAP
   */
  const [loading, setLoading] = useState(false)
  const [showMapModal, setShowMapModal] = useState<boolean>(false)
  const [pinnedLocation, setPinnedLocation] = useState<[number, number] | null>(null)
  const [address, setAddress] = useState<string>('')

  const {
    control,
    handleSubmit,
    formState: {errors, isDirty},
    reset,
    register,
    clearErrors,
    getValues,
    setValue,
  } = useForm({
    defaultValues: assetData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(assetInformationValidationSchema) as any,
  })

  const handleMapClick = async (event: any) => {
    setShowMapModal(true)
  }

  const fetchAssetStatusOptions = useCallback(() => {
    setIsAssetStatusOptionLoading(true)
    getAssetStatusOptions()
      .then((response: any) => {
        const statuses = transformDataOptions(response.data)
        setAssetStatusOptions(statuses)
        setIsAssetStatusOptionLoading(false)
      })
      .catch(() => {
        setIsAssetStatusOptionLoading(false)
      })
  }, [])

  const capitalizeFirstLetter = (str: string) => {
    if (str !== null && str.length > 0) return str.charAt(0).toUpperCase() + str.slice(1)
    else return str
  }

  useEffectOnce(() => {
    fetchAssetStatusOptions()
  })

  useEffect(() => {
    reset({...assetData})
    //props.onHandleFormChange(false)
  }, [assetData])

  const fetchAddress = useCallback(async () => {
    if (pinnedLocation) {
      try {
        setLoading(true)
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${
            pinnedLocation && pinnedLocation[1]
          }&lon=${pinnedLocation && pinnedLocation[0]}`
        )

        if (response.data.error === 'Unable to geocode') {
          setAddress('')
        } else {
          setAddress(response.data.display_name)
        }
      } catch (error) {
        console.error('Error fetching address:', error)
        return ''
      }
      setLoading(false)
    }
  }, [pinnedLocation])

  useEffect(() => {
    if (assetData.id > 0) {
      setPinnedLocation([assetData.longitude, assetData.latitude])
    }
  }, [assetData])

  useEffect(() => {
    fetchAddress()
  }, [pinnedLocation])

  const handleMapModalCancel = () => {
    setShowMapModal(false)
  }

  const onSubmit = () => {
    if (pinnedLocation) {
      const [long, lat] = pinnedLocation
      updateProjectAssetLocationAsync({
        ...assetData,
        latitude: lat,
        longitude: long,
      })
    }
  }

  const updateProjectAssetLocationAsync = useCallback(
    async (payload: ProjectAssetData) => {
      if (pinnedLocation) {
        try {
          setLoading(true)
          await updateProjectAssetLocation(payload)

          setAssetData(payload)
          setShowMapModal(false)
        } catch (error) {
          console.error('Error fetching address:', error)
          setLoading(false)
        }
        setLoading(false)
      }
    },
    [pinnedLocation]
  )

  const handleOnEdit = () => {
    setIsEdit(true)
  }

  const handleOnCancel = () => {
    setIsEdit(false)
    reset({...assetData})
    //props.onHandleFormChange(false)
  }

  useEffect(() => {
    // props.onHandleFormChange(isDirty)
  }, [isDirty])

  const onSubmitProfile = (values: ProjectAssetData) => {
    updateProjectAssetProfile(values).then((response: any) => {
      if (response) {
        setAssetData(values)
        reset({...assetData})
        showToastrNotification('Asset Saved succesfully', 'success')
      } else {
        showToastrNotification('Error Saving Asset. Please try again.', 'error')
      }
      setIsEdit(false)
    })
    //console.log('onSubmitProfile', values)
    setIsEdit(false)
    // props.onHandleFormChange(true)
  }

  return (
    <div className='asset-profile-container scrollable-component-container assetprofile'>
      <form onSubmit={handleSubmit(onSubmitProfile)}>
        <SectionEditHeader
          isEdit={isEdit}
          onEdit={handleOnEdit}
          onCancel={handleOnCancel}
          permission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}
        />
        {/* ASSET Image */}
        <AssetGalleries assetData={assetData} setAssetData={setAssetData}></AssetGalleries>
        {/* ASSET Name */}
        <section>
          <div className='text-center asset-name'>
            <h1>{assetData?.assetName}</h1>
          </div>
        </section>
        {/* ASSET Description */}
        <section>
          <div className='fw-bold'>
            <label>Asset Description</label>
          </div>
          <div className='ms-5'>
            <div>{assetData?.assetDescription}</div>
          </div>
        </section>
        {/* AAAG ID*/}
        <section>
          <div className='fw-bold'>
            <label>AAAG ID</label>
          </div>
          <div className='ms-5'>
            <div>{!assetData?.aaagId ? 'N/A' : assetData?.aaagId}</div>
          </div>
        </section>
        {/* Client Asset ID*/}
        <section>
          <div className='fw-bold'>
            <label>Client Asset ID1</label>
          </div>
          <div className='ms-5'>
            <div>
              {assetData.assetNumber === null || assetData.assetNumber.length === 0
                ? 'N/A'
                : assetData?.assetNumber}
            </div>
          </div>
        </section>
        {/* Assigned Valuer */}
        <section>
          <div className='fw-bold'>
            <label>Assigned Valuer</label>
          </div>
          <div className='ms-5'>
            <div className='d-flex'>
              <i
                className='bi bi-person-circle'
                style={{color: '#009ef6', fontSize: 18, marginRight: 10}}
              ></i>
              <div>{assetData.valuerName}</div>
            </div>
          </div>
        </section>
        {/* Estimated Construction Year */}
        <section>
          <div className='fw-bold'>
            <label>Estimated Construction Year</label>
          </div>
          <div className='ms-5'>
            <div>2024</div>
          </div>
        </section>
        {/* Asset Status */}
        <section>
          <div className='fw-bold'>
            <label>Asset Status</label>
          </div>
          <div className='ms-5'>
            <div>
              {isEdit ? (
                <>
                  <Controller
                    control={control}
                    name='assetStatus'
                    render={({field: {onChange, value}}) => {
                      return (
                        <div>
                          <Select
                            placeholder='Select Asset Status'
                            id='assetStatus'
                            isMulti={false}
                            options={assetStatusOptions}
                            value={
                              assetStatusOptions.length > 0
                                ? assetStatusOptions.find(
                                    (x: any) => x.value === (value ?? '').toString()
                                  )
                                : null
                            }
                            onChange={(e) => {
                              onChange(e?.value)
                              clearErrors('assetStatus')
                            }}
                            className={`controllerSelect${
                              errors.assetStatus ? ' border-danger' : ''
                            }`}
                          />
                          {errors.assetStatus && (
                            <small className='text-danger'>{errors.assetStatus.message}</small>
                          )}
                        </div>
                      )
                    }}
                  />
                </>
              ) : (
                capitalizeFirstLetter(assetData.assetStatus)
              )}
            </div>
          </div>
        </section>
        {/* Asset Location */}
        <section>
          <div className='fw-bold'>
            <label>Asset Location</label>
          </div>

          {!showMapModal && pinnedLocation && (
            <div>
              {/* <GeoScapeMap  
                longitude={pinnedLocation[0]}
                latitude={pinnedLocation[1]}/> */}
              <Map
                onClick={handleMapClick}
                mapLib={maplibregl}
                initialViewState={{
                  longitude: pinnedLocation && pinnedLocation.length > 0 ? pinnedLocation[0] : 0,
                  latitude: pinnedLocation && pinnedLocation.length > 0 ? pinnedLocation[1] : 0,
                  zoom: 5,
                }}
                style={{width: '100%', height: 150}}
                mapStyle={`${MAPTILER_ENDPOINT}/maps/streets/style.json?key=${MAPTILER_STYLE_KEY}`}
              >
                <Marker
                  longitude={pinnedLocation && pinnedLocation.length > 0 ? pinnedLocation[0] : 0}
                  latitude={pinnedLocation && pinnedLocation.length > 0 ? pinnedLocation[1] : 0}
                />
              </Map>
            </div>
          )}
        </section>
        {/* MAP MODAL */}
        {showMapModal &&
          createPortal(
            <AssetLocationMapModal
              address={address}
              setAddress={setAddress}
              fetchAddress={fetchAddress}
              handleCancel={handleMapModalCancel}
              onSubmit={onSubmit}
              pinnedLocation={pinnedLocation}
              setPinnedLocation={setPinnedLocation}
              loading={loading}
              setLoading={setLoading}
              longitude={assetData.longitude}
              latitude={assetData.latitude}
            />,
            document.body
          )}
      </form>
    </div>
  )
}

export default AssetProfile

export const AssetLocationMapModal = (props: AssetLocationMapModalProps) => {
  const {
    handleCancel,
    pinnedLocation,
    setPinnedLocation,
    address,
    //fetchAddress,
    setAddress,
    loading,
    longitude,
    latitude,
    onSubmit,
  } = props

  const handleMarkerDrag = (event: any) => {
    const {lng, lat} = event.lngLat
    setAddress('')
    setPinnedLocation([lng, lat])
  }

  const handleMarkerDragEnd = (event: any) => {
    const {lng, lat} = event.lngLat
    setAddress('')
    setPinnedLocation([lng, lat])
    // fetchAddress()
  }

  return (
    <Dialog
      height={'auto'}
      minWidth={'50%'}
      style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
      title={'Asset Location'}
      onClose={handleCancel}
    >
      <>
        <p style={{color: 'rgb(66, 66, 66)', fontStyle: 'italic'}}>Pin a location on the map</p>
        {loading ? (
          <div className='d-flex alig-items-center justify-content-center mb-4'>
            <div className='spinner-border' role='status' style={{color: '#a71304'}}>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : !pinnedLocation && !address ? (
          <div style={{height: '50px'}}>
            <p style={{color: 'red', fontStyle: 'italic'}}>No location selected</p>
          </div>
        ) : (
          <div style={{height: '50px'}}>
            {pinnedLocation && address && (
              <p style={{color: 'rgb(66, 66, 66)', fontStyle: 'italic', marginBottom: 0}}>
                {pinnedLocation && pinnedLocation[0]}, {pinnedLocation && pinnedLocation[1]}
              </p>
            )}
            {pinnedLocation && <p style={{color: 'red', fontStyle: 'italic'}}>{address}</p>}
          </div>
        )}
        <Map
          onClick={handleMarkerDrag}
          onDblClick={handleMarkerDrag}
          initialViewState={{
            longitude: longitude,
            latitude: latitude,
            zoom: 14,
          }}
          mapLib={maplibregl}
          style={{width: '100%', height: '400px'}}
          mapStyle={`${MAPTILER_ENDPOINT}/maps/streets/style.json?key=${MAPTILER_STYLE_KEY}`}
        >
          <NavigationControl position='top-left' />
          {pinnedLocation && (
            <Marker
              longitude={pinnedLocation[0]}
              latitude={pinnedLocation[1]}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
            />
          )}
        </Map>
        <div className='d-flex flex-row align-items-center justify-content-end w-100 mt-10'>
          <div className='d-flex flex-row align-items-center'>
            <Button buttonType='secondary' text={'Cancel'} onClick={handleCancel} />
            <Button
              buttonType='primary'
              text={'Save'}
              style={{marginLeft: 10}}
              onClick={onSubmit}
            />
          </div>
        </div>
      </>
    </Dialog>
  )
}
