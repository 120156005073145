import { Dialog } from "@progress/kendo-react-dialogs"
import { FC } from "react"
import Button from "../../../../../../_metronic/layout/components/buttons/Button"


type IAttachmentRemoveModalProps = {
    fileName: string,
    handleCancel: () => void
    handleProceed: () => void
  }

const AttachmentRemoveModal: FC<IAttachmentRemoveModalProps> = ({fileName, handleCancel, handleProceed}) => {

    return (
        <Dialog
            style={{backgroundColor: 'rgba(0,0,0,0.45)', zIndex: 100}}
            title={'Delete Attachment'}
            onClose={handleCancel}
        >
          <div className='row mb-4'>
            <div className='col'>
                <p>Are you sure you want to delete this file?</p>
                <span>{fileName}</span>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col d-flex justify-content-end'>
              <Button
                onClick={() => handleProceed()}
                buttonType='primary'
                text={'Yes'}
              />
              <Button
                buttonType='secondary'
                text={'No'}
                onClick={() => handleCancel()}
                style={{marginLeft: 10}}
              />
            </div>
          </div>
        </Dialog>
    )
}

export default AttachmentRemoveModal

