import {FC} from 'react'
import {UseFormRegister} from 'react-hook-form'
import {IAssetInsuranceValuation} from '../../../../../../models/project-asset/insurance-valuation'

type ComponentNameTextFieldrops = {
  register: UseFormRegister<IAssetInsuranceValuation>
  dataIndex: number
}

const ComponentNameTextField: FC<ComponentNameTextFieldrops> = ({register, dataIndex}) => {
  return (
    <td key={dataIndex}>
      <input className='form-control' {...register(`componentized.${dataIndex}.componentName`)} />
    </td>
  )
}

export {ComponentNameTextField}
