import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {PAGE_PERMISSION} from '../modules/roles/models/role-model'

type Props = {
  component: React.ComponentType<any>
  path: string
  exact?: boolean
  permission: PAGE_PERMISSION
  activePages: string[]
}

const ActivatedPageRoute: React.FC<Props> = ({
  component: Component,
  permission,
  activePages,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        var allowed = activePages.some((page) => page === permission)
        return allowed ? <Component {...props} /> : <Redirect to='/error/404' />
      }}
    />
  )
}

export default ActivatedPageRoute
