import {IClient} from '../models/client'

export const CLIENT_INITIAL: IClient = {
  id: 0,
  name: '',
  shortCode: '',
  pointContact: '',
  address: '',
  phoneNumber: '',
  email: '',
  officeId: 0,
  isActive: true,
}

export const initialSort: Array<any> = [{field: 'id', dir: 'desc'}]
