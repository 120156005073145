import axios from "axios"
import { IMarketEvidenceLookup } from "../models/market-evidence-lookup"
import { SortDescriptor } from "@progress/kendo-data-query"

export const MARKETEVIDENCE_URL = `${process.env.REACT_APP_API_URL}/marketevidence`


export const getMarketEvidenceLookupsPagination = async (
  projectassetId: number,
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  filter?: string
) => {
  return await axios.get(`${MARKETEVIDENCE_URL}/getmarketevidencelookupspagination`, {
    params: {
      projectassetId,
      skip,
      take,
      sort:  pageSort ? `${pageSort.field} ${pageSort.dir}` : '',
      filter
    }
  })
}

export const createMarketEvidenceLookups = async (
  projectassetId: number,
  marketEvidenceLookups: IMarketEvidenceLookup[]
) => {
  return await axios.post(`${MARKETEVIDENCE_URL}/createmarketevidenceLookups`, {
    projectassetId,
    marketEvidenceLookups
  })
}

