import {SortDescriptor} from '@progress/kendo-data-query'
import axios from 'axios'
import {IUserData} from '../models/user-model'
import {IUserGroup} from '../models/user-group'

export const USER_URL = `${process.env.REACT_APP_API_URL}/user`
export const ROLE_URL = `${process.env.REACT_APP_API_URL}/Role`
export const USERGROUP_URL = `${process.env.REACT_APP_API_URL}/UserGroup`
export const MODULE_URL = `${process.env.REACT_APP_API_URL}/module`

// USERS //

export const getUsers = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  search: string,
  officeId: number
  //userSearch?: IUserSearch
) => {
  return await axios.post(`${USER_URL}/getuserswithpagination`, {
    //...userSearch,
    filter: search,
    skip,
    take,
    sort: `${pageSort.field} ${pageSort.dir}`,
    officeId: officeId,
  })
}

export const getAllRoles = async (keyword: any) => {
  return await axios.get(`${ROLE_URL}/getallroles${keyword ? '?keyword=' + keyword : ''}`)
}

export const createUser = async (form: IUserData) => {
  const payload: IUserData = form
  await axios.post(`${USER_URL}`, payload)
}

export const updateUser = async (form: IUserData) => {
  const payload: IUserData = form
  return await axios.put(`${USER_URL}`, payload)
}

export const deleteUser = async (id: string) => {
  return await axios.delete(`${USER_URL}?id=${id}`)
}

export const forgotPassword = async (email: string) => {
  return await axios.post(`${USER_URL}/forgot-password`, {email})
}

export const getUserGroups = async () => {
  return await axios.get(`${USER_URL}/usergroups`)
}

// USER GROUP //

export const createUserGroup = async (form: IUserGroup) => {
  const payload: IUserGroup = form
  await axios.post(`${USERGROUP_URL}`, payload)
}

export const updateUserGroup = async (form: IUserGroup) => {
  const payload: IUserGroup = form
  await axios.put(`${USERGROUP_URL}`, payload)
}

export const deleteUserGroup = async (id: string) => {
  return await axios.delete(`${USERGROUP_URL}?id=${id}`)
}

export const getUserGroupPagination = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  search: string
) => {
  return await axios.get(`${USERGROUP_URL}/pagination`, {
    params: {
      //...userSearch,
      filter: search,
      skip,
      take,
      sort: pageSort ? `${pageSort.field} ${pageSort.dir}` : '',
    },
  })
}

export const getModulePermission = async () => {
  return await axios.get(`${MODULE_URL}/getmodulepermission`)
}

export const getAvailableUsers = async (search: string) => {
  return await axios.get(`${USER_URL}/getavailableusers`, {
    params: {
      keyword: search,
    },
  })
}
