import React, {useContext, useEffect, useState} from 'react'
import {ProjectContext} from '../../../context/ProjectContext'
import {PROJECTASSET_FORM_DEFAULT} from '../../../constant/project-asset-default'
import {ProjectAssetData} from '../../../models/project-assets'
import ClientSpecifics from '../../common/ClientSpecifics/ClientSpecifics'
import AssetInformation from '../../common/AssetInformation/AssetInformation'
import InspectionDetails from '../../common/InspectionDetails/InspectionDetails'
import BuildingElements from '../../common/BuildingElements/BuildingElements'
import BuildingReplacementRates from '../../common/BuildingReplacementRates/BuildingReplacementRates'
import AssetAttachments from '../../common/Attachments/AssetAttachments'
import AssetNotes from '../../common/AssetNotes/AssetNotes'
import {Loader2} from '../../../../../../_metronic/layout/components/loader/Loader2'
import ConstructionDifficulties from '../../common/ConstructionDifficulty/ConstructionDifficulties'
import HistoryData from '../../common/HistoryData/HistoryData'

type IAssetInformationTabProps = {
  assetData: ProjectAssetData
  setAssetData: (value: ProjectAssetData) => void
  onHandleFormChange: (isDirty: boolean) => void
}

function AssetInformationTab(props: IAssetInformationTabProps) {
  const {assetData, setAssetData, onHandleFormChange} = props
  const {evaluationRegionOptions, stateOptions, regionIndexes} = useContext(ProjectContext)
  const [selectedData, setSelectedData] = useState<ProjectAssetData>({
    ...PROJECTASSET_FORM_DEFAULT,
    projectId: assetData.projectId,
    //valuationRegion: valuationRegion,
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (assetData.id > 0) {
      setIsLoading(false)
      setSelectedData(assetData)
    }
  }, [assetData])

  const renderFormComponent = (
    Component: React.FC<any>,
    additionalProps: Record<string, ProjectAssetData> = {}
  ) => (
    <Component
      selectedData={selectedData}
      setSelectedData={setSelectedData}
      projectId={assetData.projectId}
      valuationRegionOptions={evaluationRegionOptions}
      stateOptions={stateOptions}
      regionIndexes={regionIndexes}
      {...additionalProps}
      assetData={assetData}
      setAssetData={setAssetData}
      onHandleFormChange={onHandleFormChange}
    />
  )

  return (
    <>
      {isLoading && <Loader2></Loader2>}
      <div className='col scrollable-component-container assetinfotab'>
        <RowWrapper>
          <div className='col-9'>
            <div className='col'>
              <RowWrapper>
                <div className='col-8'>{renderFormComponent(AssetInformation)}</div>
                <div className='col-4'>{renderFormComponent(InspectionDetails)}</div>
              </RowWrapper>
            </div>
            <div>
              <RowWrapper>
                <div className='col-8'>{renderFormComponent(BuildingReplacementRates)}</div>
                <div className='col-4'>{renderFormComponent(ConstructionDifficulties)}</div>
              </RowWrapper>
            </div>
            <div>
              <RowWrapper>
                <div className='col'>{renderFormComponent(BuildingElements)}</div>
              </RowWrapper>
            </div>
            <div>
              <RowWrapper>
                <div className='col'>{renderFormComponent(ClientSpecifics)}</div>
              </RowWrapper>
            </div>
            <div>
              <RowWrapper>
                <div className='col'>{renderFormComponent(AssetNotes)}</div>
                <div className='col'>{renderFormComponent(AssetAttachments)}</div>
              </RowWrapper>
            </div>
          </div>
          <div className='col-3'>
            <div className='col'>{renderFormComponent(HistoryData)}</div>
          </div>
        </RowWrapper>
      </div>
    </>
  )
}

export default AssetInformationTab

export const RowWrapper = ({children}: React.PropsWithChildren<{example?: string}>) => {
  return <div className='row mb-8'>{children}</div>
}
