import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import {IAssetComponentized} from '../../../../../models/project-asset/componentized'
import {FC, useEffect, useState} from 'react'
import './UnitRateForm.scss'
import Select from 'react-select'
import {NumericTextBox} from '@progress/kendo-react-inputs'
import Button from '../../../../../../../../_metronic/layout/components/buttons/Button'
import {DatePicker} from '@progress/kendo-react-dateinputs'
import SectionTitleHeader from '../../../../common/SectionTitleHeader'
import {CurrencyFormatter} from '../../../../../../../shared/service/utils'
import {useEffectOnce} from 'react-use'
import {IOption} from '../../../../../models/project-model'

type IAssetInformationTabProps = {
  currentIndex: number
  control: Control<IAssetComponentized>
  register: UseFormRegister<IAssetComponentized>
  getValues: UseFormGetValues<IAssetComponentized>
  errors: FieldErrors<IAssetComponentized>
  watch: UseFormWatch<IAssetComponentized>
  setValue: UseFormSetValue<IAssetComponentized>
  assetClassComponentOptions: IOption[]
  locationOptions: IOption[]
}

const customStyles = {
  menuPortal: (provided: any) => ({...provided, zIndex: 9999}),
  menu: (provided: any) => ({...provided, zIndex: 9999}),
}
const UnitRateFields: FC<IAssetInformationTabProps> = ({
  register,
  watch,
  control,
  errors,
  setValue,
  getValues,
  currentIndex,
  assetClassComponentOptions,
  locationOptions,
}) => {
  const [mainBaseRateDisabled, setMainBaseRateDisabled] = useState<boolean>(true)
  const onOverride = () => {
    setMainBaseRateDisabled(!mainBaseRateDisabled)
  }

  useEffectOnce(() => {
    const {
      mainAreaBaseRate,
      locationIndexRate,
      timeIndex,
      locationPremium,
      additionalValuerAdjustmentFactor,
      timeIndexRate,
      constructionDifficultyFactor,
      constructionDifficultyRate,
      additionalValuerRate,
      secondaryAdjustmentFactorRate,
      secondaryAdjustmentFactor,
      additionalCostsAdjustmentFactor,
    } = getValues(`componentized.${currentIndex}`)

    setMainAreaBaseRate(mainAreaBaseRate)
    setLocationIndexRate(locationIndexRate)
    setTimeIndex(timeIndex)
    setLocationPremium(locationPremium)
    setAdditionalValuerAdjustmentFactor(additionalValuerAdjustmentFactor)
    setTimeIndexRate(timeIndexRate)
    setConstructionDifficultyFactor(constructionDifficultyFactor)
    setConstructionDifficultyRate(constructionDifficultyRate)
    setAdditionalValuerRate(additionalValuerRate)
    setSecondaryAdjustmentFactorRate(secondaryAdjustmentFactorRate)
    setSecondaryAdjustmentFactor(secondaryAdjustmentFactor)
    setAdditionalCostsAdjustmentFactor(additionalCostsAdjustmentFactor)
  })

  const [rcUnitRate, setRcUnitRate] = useState<number>(0)
  const [rcSecondaryRate, setRcSecondaryRate] = useState<number>(0)
  const [replacementCost, setReplacementCost] = useState<number>(0)
  // Note: when using hook watch it reload the dom that cause the loose of the input focus
  // fixed using state on blur not on change
  const [mainAreaBaseRate, setMainAreaBaseRate] = useState<number>(0)
  const [locationIndexRate, setLocationIndexRate] = useState<number>(0)
  const [timeIndex, setTimeIndex] = useState<number>(0)
  const [locationPremium, setLocationPremium] = useState<number>(0)
  const [additionalValuerAdjustmentFactor, setAdditionalValuerAdjustmentFactor] =
    useState<number>(0)
  const [timeIndexRate, setTimeIndexRate] = useState<number>(0)
  const [constructionDifficultyFactor, setConstructionDifficultyFactor] = useState<number>(0)
  const [constructionDifficultyRate, setConstructionDifficultyRate] = useState<number>(0)
  const [additionalValuerRate, setAdditionalValuerRate] = useState<number>(0)
  const [secondaryAdjustmentFactorRate, setSecondaryAdjustmentFactorRate] = useState<number>(0)
  const [secondaryAdjustmentFactor, setSecondaryAdjustmentFactor] = useState<number>(0)
  const [additionalCostsAdjustmentFactor, setAdditionalCostsAdjustmentFactor] = useState<number>(0)

  const watchedSecondaryArea = watch(`componentized.${currentIndex}.secondaryArea`)
  // Computations

  useEffect(() => {
    computeSecondaryAdjustmentFactorRate()
  }, [mainAreaBaseRate, secondaryAdjustmentFactor])

  useEffect(() => {
    computeRCSecondaryRate()
  }, [secondaryAdjustmentFactorRate])

  // useEffect(() => {
  //   computeReplacementCost()
  // }, [additionalValuerRate])

  useEffect(() => {
    computeReplacementCost()
  }, [additionalCostsAdjustmentFactor, rcUnitRate, rcSecondaryRate])

  useEffect(() => {
    computeAdditionalValuerRate()
  }, [constructionDifficultyRate, additionalValuerAdjustmentFactor])

  useEffect(() => {
    computeConstructionDifficultyRate()
  }, [locationIndexRate, constructionDifficultyFactor])

  useEffect(() => {
    computeLocationIndexRate()
  }, [timeIndexRate, locationPremium])

  useEffect(() => {
    computeTimeIndexRate()
  }, [timeIndex, mainAreaBaseRate])

  useEffect(() => {
    computeRCUnitRate()
  }, [
    mainAreaBaseRate,
    locationIndexRate,
    timeIndex,
    locationPremium,
    additionalValuerAdjustmentFactor,
  ])

  useEffect(() => {
    computeRCSecondaryRate()
    computeReplacementCost()
  }, [rcUnitRate])

  useEffect(() => {
    computeReplacementCost()
  }, [watchedSecondaryArea])

  // useEffect(() => {
  //   if (replacementCost > 0) {
  //     setValue(`componentized.${currentIndex}.replacementCostTotal`, replacementCost)
  //   }
  // }, [replacementCost])

  // Formulas
  const computeRCUnitRate = () => {
    const mainAreaBaseRate = parseFloat(
      getValues(`componentized.${currentIndex}.mainAreaBaseRate`).toFixed(2)
    )
    const locationIndexRate = parseFloat(
      getValues(`componentized.${currentIndex}.locationIndexRate`).toFixed(2)
    )
    const timeIndexRate = parseFloat(
      getValues(`componentized.${currentIndex}.timeIndexRate`).toFixed(2)
    )
    const additionalValuerAdjustmentFactor = parseFloat(
      getValues(`componentized.${currentIndex}.additionalValuerAdjustmentFactor`).toFixed(2)
    )
    const totalRate =
      mainAreaBaseRate * locationIndexRate * timeIndexRate * additionalValuerAdjustmentFactor

    const roundedTotalRate = parseFloat(totalRate.toFixed(2))
    setRcUnitRate(roundedTotalRate)

    return roundedTotalRate
  }
  const computeRCSecondaryRate = () => {
    const secondaryAdjustmentFactorRate = parseFloat(
      getValues(`componentized.${currentIndex}.secondaryAdjustmentFactorRate`).toFixed(2)
    )

    const rcSecondaryRate = parseFloat(rcUnitRate.toFixed(2)) * secondaryAdjustmentFactorRate

    setRcSecondaryRate(rcSecondaryRate)
  }

  const computeReplacementCost = () => {
    const a =
      rcUnitRate * parseFloat(getValues(`componentized.${currentIndex}.mainArea`).toFixed(2))
    const b = parseFloat(rcSecondaryRate.toFixed(2)) * parseFloat(watchedSecondaryArea.toFixed(2))

    const additionalValuerRate = parseFloat(
      getValues(`componentized.${currentIndex}.additionalCostsRate`).toFixed(2)
    )
    const total = parseFloat((a + b + additionalValuerRate).toFixed(2))
    setValue(`componentized.${currentIndex}.replacementCost`, total)
    setReplacementCost(total)
  }

  const computeSecondaryAdjustmentFactorRate = () => {
    const mainAreaBaseRate = parseFloat(
      getValues(`componentized.${currentIndex}.mainAreaBaseRate`).toFixed(2)
    )
    const secondaryAdjustmentFactor = parseFloat(
      getValues(`componentized.${currentIndex}.secondaryAdjustmentFactor`).toFixed(2)
    )
    const total = mainAreaBaseRate * secondaryAdjustmentFactor
    setValue(`componentized.${currentIndex}.secondaryAdjustmentFactorRate`, total)
    setSecondaryAdjustmentFactorRate(total)
  }

  const computeTimeIndexRate = () => {
    const mainAreaBaseRate = parseFloat(
      getValues(`componentized.${currentIndex}.mainAreaBaseRate`).toFixed(2)
    )
    const timeIndex = parseFloat(getValues(`componentized.${currentIndex}.timeIndex`).toFixed(2))
    setValue(`componentized.${currentIndex}.timeIndexRate`, mainAreaBaseRate * timeIndex)
    setTimeIndexRate(mainAreaBaseRate * timeIndex)
  }

  const computeLocationIndexRate = () => {
    const timeIndexRate = parseFloat(
      getValues(`componentized.${currentIndex}.timeIndexRate`).toFixed(2)
    )
    const locationPremium = parseFloat(
      getValues(`componentized.${currentIndex}.locationPremium`).toFixed(2)
    )
    const rate = timeIndexRate * locationPremium
    setValue(`componentized.${currentIndex}.locationIndexRate`, rate)
    setLocationIndexRate(rate)
  }

  const computeConstructionDifficultyRate = () => {
    const locationIndexRate = parseFloat(
      getValues(`componentized.${currentIndex}.locationIndexRate`).toFixed(2)
    )
    const constructionDifficultyFactor = parseFloat(
      getValues(`componentized.${currentIndex}.constructionDifficultyFactor`).toFixed(2)
    )
    setValue(
      `componentized.${currentIndex}.constructionDifficultyRate`,
      locationIndexRate * constructionDifficultyFactor
    )
    setConstructionDifficultyRate(locationIndexRate * constructionDifficultyFactor)
  }

  const computeAdditionalValuerRate = () => {
    const constructionDifficultyRate = parseFloat(
      getValues(`componentized.${currentIndex}.constructionDifficultyRate`).toFixed(2)
    )
    const additionalValuerAdjustmentFactor = parseFloat(
      getValues(`componentized.${currentIndex}.additionalValuerAdjustmentFactor`).toFixed(2)
    )
    setValue(
      `componentized.${currentIndex}.additionalValuerRate`,
      constructionDifficultyRate * additionalValuerAdjustmentFactor
    )
    setAdditionalValuerRate(constructionDifficultyRate * additionalValuerAdjustmentFactor)
  }

  return (
    <div id='unitRateForm' key={currentIndex}>
      <div className='d-flex justify-content-between'>
        <SectionTitleHeader title='Unit Rate' showButtons={false} />
      </div>
      <div id='unitRateSection' style={{display: 'flex', flexWrap: 'wrap', gap: '20px'}}>
        <div className={`card bg-dark`} style={{width: '32%'}}>
          <div className='card-body'>
            <div className={`text-inverse-dark fw-bold fs-5 mb-2`}>RC Unit Rate</div>
            <div
              className={`fw-bolder text-inverse-dark fs-1 text-right`}
              style={{textAlign: 'right'}}
            >
              {CurrencyFormatter(rcUnitRate)}
            </div>
          </div>
        </div>
        <div className={`card bg-dark`} style={{width: '32%'}}>
          <div className='card-body'>
            <div className={`text-inverse-dark fw-bold fs-5 mb-2`}>RC Secondary Rate</div>
            <div
              className={`fw-bolder text-inverse-dark fs-1 text-right`}
              style={{textAlign: 'right'}}
            >
              {CurrencyFormatter(rcSecondaryRate)}
            </div>
          </div>
        </div>
        <div className={`card bg-dark`} style={{width: '32%'}}>
          <div className='card-body'>
            <div className={`text-inverse-dark fw-bold fs-5 mb-2`}>Replacement Cost</div>
            <div
              className={`fw-bolder text-inverse-dark fs-1 text-right`}
              style={{textAlign: 'right'}}
            >
              {CurrencyFormatter(replacementCost)}
            </div>
          </div>
        </div>
      </div>

      <div className='row h-auto mt-5'>
        <div className='col-3'>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='assetClass'>
              Adopted Base Rate
            </label>
            <Controller
              control={control}
              name={`componentized.${currentIndex}.adoptedBaseRate`}
              render={({field: {onChange, value, ref}}) => {
                return (
                  <div>
                    <Select
                      placeholder='Select Adopted BaseRate'
                      styles={customStyles}
                      id='adoptedBaseRate'
                      isMulti={false}
                      options={assetClassComponentOptions}
                      ref={ref}
                      value={{
                        label: value,
                        value: value,
                      }}
                      onChange={(e) => {
                        onChange(e?.value)
                        //   clearErrors('adoptedBaseRate')
                      }}
                      className={`controllerSelect${
                        errors.componentized && errors.componentized[currentIndex]?.adoptedBaseRate
                          ? ' border-danger'
                          : ''
                      }`}
                    />
                    {/* {errors.adoptedBaseRate && (
                        <small className='text-danger'>{errors.adoptedBaseRate.message}</small>
                      )} */}
                  </div>
                )
              }}
            />
          </div>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='mainAreaBaseRate'>
              Main Area Base Rate
            </label>

            <div>
              {mainBaseRateDisabled ? (
                <input
                  style={{
                    fontSize: '1rem',
                    width: '200px',
                    marginRight: '15px',
                    display: 'inline-flex',
                  }}
                  readOnly={true}
                  id='mainAreaBaseRate'
                  className={`form-control${
                    errors.componentized && errors.componentized[currentIndex]?.mainAreaBaseRate
                      ? ' border-danger'
                      : ''
                  }`}
                  placeholder='0.00'
                  value={
                    '$ ' + getValues(`componentized.${currentIndex}.mainAreaBaseRate`)?.toFixed(2)
                  }
                />
              ) : (
                <Controller
                  control={control}
                  name={`componentized.${currentIndex}.mainAreaBaseRate`}
                  render={({field: {onChange, value, ref}}) => (
                    <NumericTextBox
                      style={{
                        fontSize: '1rem',
                        width: '200px',
                        marginRight: '15px',
                        height: '40px',
                      }}
                      ref={ref}
                      className={`form-control${
                        errors.componentized && errors.componentized[currentIndex]?.mainAreaBaseRate
                          ? ' border-danger'
                          : ''
                      }`}
                      defaultValue={value}
                      value={value}
                      format='c2'
                      min={1}
                      max={10}
                      onChange={onChange}
                      onBlur={(event) => {
                        if (event.target.value) {
                          setMainAreaBaseRate(event.target.value)
                        }
                      }}
                    />
                  )}
                />
              )}
              <Button
                text={'Override'}
                isSubmit={false}
                buttonType='section'
                onClick={onOverride}
              />
            </div>
          </div>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='secondaryAdjustmentFactor'>
              Secondary Adjustment Factor
            </label>
            <Controller
              control={control}
              name={`componentized.${currentIndex}.secondaryAdjustmentFactor`}
              render={({field: {onChange, value, ref}}) => (
                <NumericTextBox
                  style={{
                    fontSize: '1rem',
                    height: '40px',
                    width: '120px',
                  }}
                  className={`form-control${
                    errors.componentized &&
                    errors.componentized[currentIndex]?.secondaryAdjustmentFactor
                      ? ' border-danger'
                      : ''
                  }`}
                  defaultValue={value}
                  value={value}
                  format='n2'
                  min={1}
                  max={10}
                  step={0.01}
                  onChange={onChange}
                  ref={ref}
                  onBlur={(event) => {
                    if (event.target.value) {
                      setSecondaryAdjustmentFactor(+event.target.value)
                    }
                  }}
                />
              )}
            />
          </div>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='secondaryAdjustmentFactorRate'>
              Secondary Adjustment Rate
            </label>
            <input
              style={{
                fontSize: '1rem',
              }}
              readOnly={true}
              id='secondaryAdjustmentFactorRate'
              className={`form-control${
                errors.componentized &&
                errors.componentized[currentIndex]?.secondaryAdjustmentFactorRate
                  ? ' border-danger'
                  : ''
              }`}
              placeholder='0.00'
              value={
                '$ ' +
                getValues(`componentized.${currentIndex}.secondaryAdjustmentFactorRate`)?.toFixed(2)
              }
              onBlur={(event) => {
                if (event.target.value) {
                  setSecondaryAdjustmentFactorRate(+event.target.value)
                }
              }}
            />
          </div>
          <div className='form-outline mb-4' style={{height: '62px'}}></div>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='componentBreakdown'>
              Component Breakdown
            </label>
            <div>
              <Controller
                control={control}
                name={`componentized.${currentIndex}.componentBreakdown`}
                render={({field: {onChange, value, ref}}) => (
                  <NumericTextBox
                    style={{
                      fontSize: '1rem',
                      width: '120px',
                      height: '40px',
                    }}
                    className={`form-control${
                      errors.componentized && errors.componentized[currentIndex]?.componentBreakdown
                        ? ' border-danger'
                        : ''
                    }`}
                    defaultValue={value}
                    value={value}
                    format='p'
                    min={1}
                    max={4}
                    step={0.01}
                    ref={ref}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className='col-3'>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='locationBase'>
              Location Base
            </label>
            <Controller
              control={control}
              name={`componentized.${currentIndex}.locationBase`}
              render={({field: {onChange, value, ref}}) => (
                <div>
                  <Select
                    placeholder='Select Location Base'
                    id='locationBase'
                    isMulti={false}
                    options={locationOptions}
                    value={{
                      label: value,
                      value: value,
                    }}
                    ref={ref}
                    onChange={(e) => {
                      onChange(e?.value)
                    }}
                    className={`controllerSelect${
                      errors.componentized && errors.componentized[currentIndex]?.locationBase
                        ? ' border-danger'
                        : ''
                    }`}
                  />
                  {errors.componentized && errors.componentized[currentIndex]?.locationBase && (
                    <small className='text-danger'>
                      {errors.componentized &&
                        errors.componentized[currentIndex]?.locationBase?.message}
                    </small>
                  )}
                </div>
              )}
            />
          </div>
          <div className='form-outline'>
            <label className='fw-bolder' htmlFor='timeAdjustToPeriod'>
              Time Adjust to Period
            </label>
            <>
              <Controller
                control={control}
                name={`componentized.${currentIndex}.timeAdjustToPeriod`}
                render={({field: {onChange, value, onBlur, ref}}) => {
                  return (
                    <DatePicker
                      value={value ? new Date(value) : null}
                      onBlur={onBlur}
                      format={'dd-MMM-yyyy'}
                      formatPlaceholder='formatPattern'
                      ref={ref}
                      className={`controllerSelect${
                        errors.componentized &&
                        errors.componentized[currentIndex]?.timeAdjustToPeriod
                          ? ' border-danger'
                          : ''
                      }`}
                      onChange={(e) => {
                        onBlur()
                        onChange(e.target.value)
                      }}
                    />
                  )
                }}
              />
              {errors.componentized && errors.componentized[currentIndex]?.timeAdjustToPeriod && (
                <small className='text-danger'>
                  {errors.componentized &&
                    errors.componentized[currentIndex]?.timeAdjustToPeriod?.message}
                </small>
              )}
            </>
          </div>
        </div>
        <div className='col-3'>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='locationPremium'>
              Location Premium
            </label>
            <div>
              <Controller
                control={control}
                name={`componentized.${currentIndex}.locationPremium`}
                render={({field: {onChange, value, ref}}) => (
                  <NumericTextBox
                    style={{
                      fontSize: '1rem',
                      height: '40px',
                      width: '120px',
                    }}
                    className={`form-control${
                      errors.componentized && errors.componentized[currentIndex]?.locationPremium
                        ? ' border-danger'
                        : ''
                    }`}
                    defaultValue={value}
                    value={value}
                    format='n2'
                    min={1}
                    max={10}
                    step={0.01}
                    ref={ref}
                    onChange={onChange}
                    onBlur={(event) => {
                      if (event.target.value) {
                        setLocationPremium(+event.target.value)
                      }
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='timeIndex'>
              Time Index
            </label>
            <div>
              <Controller
                control={control}
                name={`componentized.${currentIndex}.timeIndex`}
                render={({field: {onChange, value, ref}}) => (
                  <NumericTextBox
                    style={{
                      fontSize: '1rem',
                      height: '40px',
                      width: '120px',
                    }}
                    className={`form-control${
                      errors.componentized && errors.componentized[currentIndex]?.timeIndex
                        ? ' border-danger'
                        : ''
                    }`}
                    defaultValue={value}
                    value={value}
                    format='n2'
                    min={1}
                    max={10}
                    step={0.01}
                    ref={ref}
                    onChange={onChange}
                    onBlur={(event) => {
                      if (event.target.value) {
                        setTimeIndex(+event.target.value)
                      }
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='constructionDifficultyFactor'>
              Construction Difficulty Factor
            </label>
            <div>
              <Controller
                control={control}
                name={`componentized.${currentIndex}.constructionDifficultyFactor`}
                render={({field: {onChange, value, ref}}) => (
                  <NumericTextBox
                    style={{
                      fontSize: '1rem',
                      height: '40px',
                      width: '120px',
                    }}
                    className={`form-control${
                      errors.componentized &&
                      errors.componentized[currentIndex]?.constructionDifficultyFactor
                        ? ' border-danger'
                        : ''
                    }`}
                    defaultValue={value}
                    value={value}
                    format='n2'
                    min={1}
                    max={10}
                    step={0.01}
                    onChange={onChange}
                    ref={ref}
                    onBlur={(event) => {
                      if (event.target.value) {
                        setConstructionDifficultyFactor(+event.target.value)
                      }
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='additionalValuerAdjustmentFactor'>
              Additional Valuer Adjustment Factor
            </label>
            <div>
              <Controller
                control={control}
                name={`componentized.${currentIndex}.additionalValuerAdjustmentFactor`}
                render={({field: {onChange, value, ref}}) => (
                  <NumericTextBox
                    style={{
                      fontSize: '1rem',
                      height: '40px',
                      width: '120px',
                    }}
                    className={`form-control${
                      errors.componentized &&
                      errors.componentized[currentIndex]?.additionalValuerAdjustmentFactor
                        ? ' border-danger'
                        : ''
                    }`}
                    defaultValue={value}
                    value={value}
                    format='n2'
                    min={1}
                    max={10}
                    step={0.01}
                    ref={ref}
                    onChange={onChange}
                    onBlur={(event) => {
                      if (event.target.value) {
                        setAdditionalValuerAdjustmentFactor(+event.target.value)
                      }
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='additionalCostsAdjustmentFactor'>
              Additional Costs Adjustment Factor
            </label>
            <div>
              <Controller
                control={control}
                name={`componentized.${currentIndex}.additionalCostsAdjustmentFactor`}
                render={({field: {onChange, value, ref}}) => (
                  <NumericTextBox
                    style={{
                      fontSize: '1rem',
                      height: '40px',
                      width: '120px',
                    }}
                    className={`form-control${
                      errors.componentized &&
                      errors.componentized[currentIndex]?.additionalCostsAdjustmentFactor
                        ? ' border-danger'
                        : ''
                    }`}
                    defaultValue={value}
                    value={value}
                    format='n2'
                    min={1}
                    max={10}
                    step={0.01}
                    ref={ref}
                    onChange={onChange}
                    onBlur={(event) => {
                      if (event.target.value) {
                        setAdditionalCostsAdjustmentFactor(+event.target.value)
                      }
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='professionalFee'>
              Professional Fee
            </label>
            <div>
              <Controller
                control={control}
                name={`componentized.${currentIndex}.professionalFee`}
                render={({field: {onChange, value, ref}}) => (
                  <NumericTextBox
                    style={{
                      fontSize: '1rem',
                      height: '40px',
                      width: '120px',
                    }}
                    className={`form-control${
                      errors.componentized && errors.componentized[currentIndex]?.professionalFee
                        ? ' border-danger'
                        : ''
                    }`}
                    defaultValue={value}
                    value={value}
                    format='n2'
                    min={1}
                    max={10}
                    step={0.01}
                    ref={ref}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className='col-3'>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='locationIndexRate'>
              Location Index Rate
            </label>
            <input
              style={{
                fontSize: '1rem',
              }}
              readOnly={true}
              id='locationIndexRate'
              className={`form-control${
                errors.componentized && errors.componentized[currentIndex]?.locationIndexRate
                  ? ' border-danger'
                  : ''
              }`}
              placeholder='0.00'
              value={
                '$ ' + getValues(`componentized.${currentIndex}.locationIndexRate`)?.toFixed(2)
              }
              onChange={(event) => {
                if (event.target.value) {
                  setLocationIndexRate(+event.target.value)
                }
              }}
            />
          </div>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='timeIndexRate'>
              Time Index Rate
            </label>
            <input
              style={{
                fontSize: '1rem',
              }}
              readOnly={true}
              id='timeIndexRate'
              className={`form-control${
                errors.componentized && errors.componentized[currentIndex]?.timeIndexRate
                  ? ' border-danger'
                  : ''
              }`}
              placeholder='0.00'
              value={'$ ' + getValues(`componentized.${currentIndex}.timeIndexRate`)?.toFixed(2)}
              onChange={(event) => {
                if (event.target.value) {
                  setTimeIndexRate(+event.target.value)
                }
              }}
            />
          </div>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='constructionDifficultyRate'>
              Construction Difficulty Rate
            </label>
            <input
              style={{
                fontSize: '1rem',
              }}
              readOnly={true}
              id='constructionDifficultyRate'
              className={`form-control${
                errors.componentized &&
                errors.componentized[currentIndex]?.constructionDifficultyRate
                  ? ' border-danger'
                  : ''
              }`}
              placeholder='0.00'
              value={
                '$ ' +
                getValues(`componentized.${currentIndex}.constructionDifficultyRate`)?.toFixed(2)
              }
              onChange={(event) => {
                if (event.target.value) {
                  setConstructionDifficultyRate(+event.target.value)
                }
              }}
            />
          </div>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='additionalValuerRate'>
              Additional Valuer Rate
            </label>
            <input
              style={{
                fontSize: '1rem',
              }}
              readOnly={true}
              id='additionalValuerRate'
              className={`form-control${
                errors.componentized && errors.componentized[currentIndex]?.additionalValuerRate
                  ? ' border-danger'
                  : ''
              }`}
              placeholder='0.00'
              value={
                '$ ' + getValues(`componentized.${currentIndex}.additionalValuerRate`)?.toFixed(2)
              }
              onChange={(event) => {
                if (event.target.value) {
                  setAdditionalValuerRate(+event.target.value)
                }
              }}
            />
          </div>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='additionalCostsRate'>
              Additional Costs Rate
            </label>
            <input
              style={{
                fontSize: '1rem',
              }}
              readOnly={true}
              id='additionalCostsRate'
              className={`form-control${
                errors.componentized && errors.componentized[currentIndex]?.additionalCostsRate
                  ? ' border-danger'
                  : ''
              }`}
              placeholder='0.00'
              value={
                '$ ' + getValues(`componentized.${currentIndex}.additionalCostsRate`)?.toFixed(2)
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export {UnitRateFields}
