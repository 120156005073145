import React from 'react'
import {Dialog} from '@progress/kendo-react-dialogs'
import {FC} from 'react'
import Button from '../../../../../../_metronic/layout/components/buttons/Button'

type props = {
  selectedDuplicateId: string
  handleCloseDuplicateDialog: () => void
  setSelectedDuplicateId: (id: string | null) => void
  duplicateItem: (id: string) => void
}
const DuplicateAssetModal: FC<props> = ({
  handleCloseDuplicateDialog,
  setSelectedDuplicateId,
  duplicateItem,
  selectedDuplicateId,
}) => {
  return (
    <Dialog
      style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
      title={'Duplicate Asset'}
      onClose={handleCloseDuplicateDialog}
    >
      <div className='row mb-4'>
        <div className='col'>
          <p>Are you sure you want to duplicate this item?</p>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col  d-flex justify-content-end'>
          <Button
            onClick={() => duplicateItem(selectedDuplicateId)}
            buttonType='primary'
            text={'Yes'}
          />
          <Button
            buttonType='secondary'
            text={'No'}
            onClick={() => setSelectedDuplicateId(null)}
            style={{marginLeft: 10}}
          />
        </div>
      </div>
    </Dialog>
  )
}

export {DuplicateAssetModal}
