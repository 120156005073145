/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword} from '../redux/AuthCRUD'

import {PageDataProvider} from '../../../../_metronic/layout/core'
import {ScrollTop} from '../../../../_metronic/layout/components/ScrollTop'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import * as auth from '../redux/AuthRedux'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .max(8, 'Maximum 8 characters')
    .required('Password is required')
    .test('isValidPass', '1 Uppercase, 1 Lowercase, 1 Special Case', (value: any, context) => {
      const hasUpperCase = /[A-Z]/.test(value)
      const hasLowerCase = /[a-z]/.test(value)
      const hasSymbol = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value)
      let validConditions = 0
      const numberOfMustBeValidConditions = 3
      const conditions = [hasLowerCase, hasUpperCase, hasSymbol]
      conditions.forEach((condition) => (condition ? validConditions++ : null))
      if (validConditions >= numberOfMustBeValidConditions) {
        return true
      }
      return false
    }),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
})
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

interface IProps {}

//export function Login() {
const ResetPassword: React.FC<IProps> = () => {
  const [loading, setLoading] = useState(false)
  const query = new URLSearchParams(window.location.search)
  const email = query.get('email') as string
  const resetPasswordToken = query.get('token') || ''

  // ReCaptcha execute
  const {executeRecaptcha} = useGoogleReCaptcha()

  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      const tokenRecaptcha = await executeRecaptcha('resetpassword')
      resetPassword(email, values.password, resetPasswordToken, tokenRecaptcha)
        .then(({data: {token, refreshToken}}) => {
          setLoading(false)
          dispatch(auth.actions.login(token, refreshToken, false))
        })
        .catch(() => {
          setLoading(false)
          setSubmitting(false)
          setStatus('The login detail is incorrect')
        })
    },
  })

  return (
    <PageDataProvider>
      <div className='d-flex flex-row flex-column-fluid'>
        <div style={{backgroundColor: '#151d23', flex: 0.3, position: 'relative'}}>
          <div className='container'>
            <div
              className='mb-20 d-flex aligns-items-center justify-content-center'
              style={{marginTop: 100}}
            >
              <Link to={'/'}>
                <img
                  src={toAbsoluteUrl('/media/logos/australis-logo.png')}
                  alt='Australis logo'
                  style={{width: '247px', height: '65px', objectFit: 'cover'}}
                />
              </Link>
            </div>

            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_login_signin_form'
            >
              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}
              <h3 className='text-white mb-8 w-75 mx-auto'>Reset Password</h3>
              <div className='text-white mb-8 w-75 mx-auto'>
                <ul>
                  <li>1 Uppercase Letter</li>
                  <li>1 Lowercase Letter</li>
                  <li>1 Special Character</li>
                  <li>Maximum 8 Character</li>
                </ul>
              </div>
              <div className='mb-8 w-75 mx-auto'>
                <label className='form-label fs-8 fw-bolder text-white'>New Password </label>
                <input
                  placeholder='New Password'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-sm form-control-solid',
                    {'is-invalid': formik.touched.password && formik.errors.password},
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                  type='password'
                  name='password'
                  autoComplete='off'
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert' className='text-white fs-8'>
                      {formik.errors.password}
                    </span>
                  </div>
                )}
              </div>
              <div className='mb-8 w-75 mx-auto'>
                <label className='form-label fs-8 fw-bolder text-white'>Confirm Password </label>
                <input
                  type='password'
                  autoComplete='off'
                  {...formik.getFieldProps('confirmPassword')}
                  className={clsx(
                    'form-control form-control-sm form-control-solid',
                    {
                      'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                    },
                    {
                      'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                    }
                  )}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='text-white fs-8'>
                        {formik.errors.confirmPassword}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div className='mb-16 d-flex aligns-items-center justify-content-center w-75 mx-auto'>
                <button
                  type='submit'
                  className='btn btn-sm btn-block fs-8'
                  style={{backgroundColor: '#a71304', color: 'white', width: '100%'}}
                >
                  Save Password
                </button>
              </div>
            </form>

            <p
              style={{
                fontSize: 11,
                color: '#fff',
                textAlign: 'center',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              2023&copy; Australis Navigator
            </p>
            <div style={{position: 'fixed', bottom: 0, right: 0, margin: '10px'}}>
              <div id='recaptcha' className='recaptcha'></div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: `url(${toAbsoluteUrl('/media/background/login-background.jpg')})`,
            backgroundSize: 'cover',
            flex: 0.7,
            height: '100%',
          }}
        >
          <p
            style={{
              color: '#fff',
              fontSize: 48,
              textAlign: 'center',
              marginBottom: 0,
              width: '60%',
            }}
          >
            We always deliver the complete picture.
          </p>
        </div>
      </div>
      <ScrollTop />
    </PageDataProvider>
  )
}

export {ResetPassword}
