import {useCallback, useContext, useEffect, useState} from 'react'
import {
  Control,
  UseFormSetValue,
  FieldErrors,
  UseFormRegister,
  UseFormClearErrors,
  UseFormHandleSubmit,
} from 'react-hook-form'

import {ProjectAssetData} from '../../../models/project-assets'
import {IOption} from '../../../models/project-model'
import {getProjectAssetHistoricalData} from '../../../api'
import {formatNumberWithCommas} from '../../../transformers/project-transformer'
import SectionTitleHeader from '../SectionTitleHeader'
import {ProjectContext} from '../../../context/ProjectContext'
import {HISTORICAL_DATA_DEFAULT} from '../../../constant/historical-data-default'
import {IProjectAssetHistoricalData} from '../../../models/project-asset-historical-data'

type HistoryDataProps = {
  control: Control<ProjectAssetData>
  isValid: boolean
  setValue: UseFormSetValue<ProjectAssetData>
  errors: FieldErrors<ProjectAssetData>
  register: UseFormRegister<ProjectAssetData>
  clearErrors: UseFormClearErrors<ProjectAssetData>
  handleSubmit: UseFormHandleSubmit<ProjectAssetData>
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData) => void
  projectId: string
  assetData: ProjectAssetData
}

function HistoryData(props: HistoryDataProps) {
  const {selectedData, setSelectedData} = props
  const [historicalData, setHistoricalData] = useState<IProjectAssetHistoricalData>({
    ...HISTORICAL_DATA_DEFAULT,
  })

  const fetchHistoricalData = useCallback((assetId: number) => {
    if (assetId > 0) {
      getProjectAssetHistoricalData(assetId)
        .then((result: any) => {
          setHistoricalData(result.data)
        })
        .catch(() => {})
    }
  }, [])

  useEffect(() => {
    fetchHistoricalData(selectedData.id)
  }, [selectedData])

  return (
    <form>
      <SectionTitleHeader title='Historical Data' showButtons={false} />
      <div id='inspectionDetails'>
        <div className='col'>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='assetNumberFromPriorWF'>
              Asset Number from Prior FV
            </label>
            <p>{!historicalData ? '' : historicalData?.assetNumberFromPriorFV}</p>
          </div>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='priorFieldNotes'>
              Prior Field Notes
            </label>
            <p>{!historicalData ? '' : historicalData?.priorFieldNotes}</p>
          </div>
          <div className='form-outline'>
            <label className='fw-bolder' htmlFor='priorClientCopycomments'>
              Prior Client Copy Comments
            </label>
            <p>{!historicalData ? '' : historicalData?.priorClientCopyComments}</p>
          </div>
          <div className='form-outline'>
            <label className='fw-bolder' htmlFor='priorPrimaryUnitRate'>
              Prior Primary Unit Rate
            </label>
            <p>
              {!historicalData ? '' : formatNumberWithCommas(historicalData?.priorPrimaryUnitRate)}
            </p>
          </div>
          <div className='form-outline'>
            <label className='fw-bolder' htmlFor='priorLocationPremium'>
              Prior Location Premium
            </label>
            <p>{!historicalData ? '' : historicalData?.priorLocationPremium}</p>
          </div>
          <div className='form-outline'>
            <label className='fw-bolder' htmlFor='priorMainRateAdjFactor'>
              Prior Main Rate Adj Factor
            </label>
            <p>{!historicalData ? '' : historicalData?.priorMainRateAdjFactor.toFixed(2)}</p>
          </div>
          <div className='form-outline'>
            <label className='fw-bolder' htmlFor='priorSecondRateAdjFactor'>
              Prior Second Rate Adj Factor
            </label>
            <p>{!historicalData ? '' : historicalData?.priorSecondRateAdjFactor.toFixed(2)}</p>
          </div>
          <div className='form-outline'>
            <label className='fw-bolder' htmlFor='priorProfessionalFees'>
              Prior Professional Fees
            </label>
            <p>
              {!historicalData ? '' : formatNumberWithCommas(historicalData?.priorProfessionalFees)}
            </p>
          </div>
          <div className='form-outline'>
            <label className='fw-bolder' htmlFor='priorValuationYear'>
              Prior Valuation Year
            </label>
            <p>{!historicalData ? '' : historicalData?.priorValuationYear}</p>
          </div>
          <div className='form-outline'>
            <label className='fw-bolder' htmlFor='priorReplacementCost'>
              Prior Replacement Cost
            </label>
            <p>
              {!historicalData ? '' : formatNumberWithCommas(historicalData?.priorReplacementCost)}
            </p>
          </div>
          <div className='form-outline'>
            <label className='fw-bolder' htmlFor='priorFairValue'>
              Prior Fair Value
            </label>
            <p>{!historicalData ? '' : formatNumberWithCommas(historicalData?.priorFairValue)}</p>
          </div>
          <div className='form-outline'>
            <label className='fw-bolder' htmlFor='equivalentRSPScore'>
              Equivalent RSP Score
            </label>
            <p>{!historicalData ? '' : historicalData?.equivalentRSPScore.toFixed(2)}</p>
          </div>
        </div>
      </div>
    </form>
  )
}

export default HistoryData
