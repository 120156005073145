import React, {useContext, useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'

import Button from '../../../../../_metronic/layout/components/buttons/Button'
import {ClientListContext} from '../../context/ClientListContext'

function NewClientInfo(props: any) {
  const {initialValue, clients, setClients, newClient, setNewClient} = useContext(ClientListContext)
  const history = useHistory()

  const [errors, setErrors] = useState({
    name: false,
    shortCode: false,
    pointOfContact: false,
    address: false,
    phoneNumber: false,
    emailAddress: false,
    office: false,
  })

  const handleAddNewClient = () => {
    const newErrors = {
      name: !newClient.name,
      shortCode: !newClient.shortCode,
      pointOfContact: !newClient.pointOfContact,
      address: !newClient.address,
      phoneNumber: !newClient.phoneNumber,
      emailAddress: !newClient.emailAddress,
      office: !newClient.office || !newClient.office.name,
      isActive: !newClient.isActive,
    }

    setErrors(newErrors)

    if (!Object.values(newErrors).some((error) => error)) {
      setClients([...clients, newClient])
      setNewClient(initialValue)
      history.push('/clients')
    }
  }

  const handleCancel = () => {
    setNewClient(initialValue)
    history.push('/clients')
  }

  const handleInfoOnChange = (e: any, info: any) => {
    setNewClient({...newClient, [info]: e.target.value})
  }

  const handleDropdownOnChange = (event: any, info: any) => {
    const selectedValue = event.target.value
    setNewClient({...newClient, [info]: {id: Math.random(), name: selectedValue}})
  }

  useEffect(() => {
    setNewClient(initialValue)
  }, [])

  return (
    <>
      <div className='d-flex flex-row align-items-center justify-content-end w-100 mb-2'>
        <div className='d-flex flex-row align-items-center'>
          <Button buttonType='primary' text={'Save'} onClick={handleAddNewClient} />
          <Button
            buttonType='secondary'
            text={'Cancel'}
            onClick={handleCancel}
            style={{marginLeft: 10}}
          />
        </div>
      </div>
      <div className='col'>
        <div className='row mb-4'>
          <div className='col-4'>
            <div className='form-outline'>
              <label className='fw-bolder text-dark' htmlFor='name'>
                Client Name
              </label>
              <input
                style={{
                  fontSize: '1rem',
                  border: errors?.name ? '1px solid #f00' : '1px solid #E4E6EF',
                }}
                type='text'
                id='name'
                className='form-control'
                placeholder='Client Name'
                name='name'
                value={newClient?.name}
                onChange={(e) => {
                  handleInfoOnChange(e, 'name')
                  setErrors({...errors, name: false})
                }}
              />
              {errors?.name && <small style={{color: '#f00'}}>Client name is required!</small>}
            </div>
          </div>
          <div className='col-4'>
            <div className='form-outline'>
              <label className='fw-bolder text-dark' htmlFor='shortCode'>
                Client Short Code
              </label>
              <input
                style={{
                  fontSize: '1rem',
                  border: errors?.shortCode ? '1px solid #f00' : '1px solid #E4E6EF',
                }}
                type='text'
                id='shortCode'
                className='form-control'
                placeholder='Client Short Code'
                name='shortCode'
                value={newClient?.shortCode}
                onChange={(e) => {
                  handleInfoOnChange(e, 'shortCode')
                  setErrors({...errors, shortCode: false})
                }}
              />
              {errors?.shortCode && (
                <small style={{color: '#f00'}}>Client short code is required!</small>
              )}
            </div>
          </div>
          <div className='col-4'>
            <div className='form-outline'>
              <label className='fw-bolder text-dark' htmlFor='pointOfContact'>
                Point of Contact
              </label>
              <input
                style={{
                  fontSize: '1rem',
                  border: errors?.pointOfContact ? '1px solid #f00' : '1px solid #E4E6EF',
                }}
                type='text'
                id='pointOfContact'
                className='form-control'
                placeholder='Point of Contact'
                name='pointOfContact'
                value={newClient?.pointOfContact}
                onChange={(e) => {
                  handleInfoOnChange(e, 'pointOfContact')
                  setErrors({...errors, pointOfContact: false})
                }}
              />
              {errors?.pointOfContact && (
                <small style={{color: '#f00'}}>Point of contact is required!</small>
              )}
            </div>
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col'>
            <div className='form-outline'>
              <label className='fw-bolder text-dark' htmlFor='address'>
                Address
              </label>
              <input
                style={{
                  fontSize: '1rem',
                  border: errors?.address ? '1px solid #f00' : '1px solid #E4E6EF',
                }}
                type='text'
                id='address'
                className='form-control text-capitalize'
                placeholder='Address'
                name='address'
                value={newClient?.address}
                onChange={(e) => {
                  handleInfoOnChange(e, 'address')
                  setErrors({...errors, address: false})
                }}
              />
              {errors?.address && <small style={{color: '#f00'}}>Address is required!</small>}
            </div>
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-4'>
            <div className='form-outline'>
              <label className='fw-bolder text-dark' htmlFor='phoneNumber'>
                Phone Number
              </label>
              <input
                style={{
                  fontSize: '1rem',
                  border: errors?.phoneNumber ? '1px solid #f00' : '1px solid #E4E6EF',
                }}
                type='text'
                id='phoneNumber'
                className='form-control'
                placeholder='Phone Number'
                name='phoneNumber'
                value={newClient?.phoneNumber}
                onChange={(e) => {
                  handleInfoOnChange(e, 'phoneNumber')
                  setErrors({...errors, phoneNumber: false})
                }}
              />
              {errors?.phoneNumber && (
                <small style={{color: '#f00'}}>Phone number is required!</small>
              )}
            </div>
          </div>
          <div className='col-4'>
            <div className='form-outline'>
              <label className='fw-bolder text-dark' htmlFor='emailAddress'>
                Email Address
              </label>
              <input
                style={{
                  fontSize: '1rem',
                  border: errors?.emailAddress ? '1px solid #f00' : '1px solid #E4E6EF',
                }}
                type='email'
                id='emailAddress'
                className='form-control'
                placeholder='Email Address'
                name='emailAddress'
                value={newClient?.emailAddress}
                onChange={(e) => {
                  handleInfoOnChange(e, 'emailAddress')
                  setErrors({...errors, emailAddress: false})
                }}
              />
              {errors?.emailAddress && (
                <small style={{color: '#f00'}}>Email address is required!</small>
              )}
            </div>
          </div>
          <div className='col-4'>
            <div className='form-outline'>
              <label className='fw-bolder text-dark' htmlFor='office'>
                Office Assignment
              </label>

              <select
                style={{
                  fontSize: '1rem',
                  border: errors?.office ? '1px solid #f00' : '1px solid #E4E6EF',
                }}
                id='office'
                name='office'
                className='form-select'
                value={newClient?.office?.name}
                onChange={(e) => {
                  handleDropdownOnChange(e, 'office')
                  setErrors({...errors, office: false})
                }}
              >
                <option value={'Not set'}>Not set</option>
                <option value={'Office 1'}>Office 1</option>
                <option value={'Office 2'}>Office 2</option>
                <option value={'Office 3'}>Office 3</option>
              </select>
              {errors?.office && (
                <small style={{color: '#f00'}}>Office assignment is required!</small>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewClientInfo
