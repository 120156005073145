import React, {useContext, useState, useEffect} from 'react'
import {Dialog} from '@progress/kendo-react-dialogs'
import './UserGroupInfoModal.scss'
import {UserContext} from '../../../context/UserContext'
import Button from '../../../../../../_metronic/layout/components/buttons/Button'
import {UserGroupFormFields} from './UserGroupFormFields'
import {CustomAlert, IAlert} from '../../../../../shared/components/CustomAlert'
import {useFieldArray, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {transformDataUserGroups} from '../../../transformers/user-transformer'
import {USER_FORM_DEFAULT} from '../../../constant/user-default'
import {createUserGroup, updateUserGroup} from '../../../api'
import {UserGroupPermissionForm} from './UserGroupPermissionForm'
import {userGroupFormValidationSchema} from '../../../validators/user-group-form'
import {IUserGroup} from '../../../models/user-group'
import toastr from 'toastr'
import {AccessControlProvider} from '../../../../../../_metronic/layout/core/AccessControlProvider'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'

function UserGroupInfoModal(props: any) {
  const {
    initialValue,
    isUserGroupModalVisible,
    setIsUserGroupModalVisible,
    setSelectedData,
    selectedUserGroupData,
    userGroupOptions,
    fetchUserGroupData,
    modules,
    permissions,
  } = useContext(UserContext)

  const [formNotification, setformNotification] = useState<IAlert | undefined>()
  const [isDisabled, setDisabled] = useState(false)
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    reset,
    clearErrors,
  } = useForm({
    defaultValues: selectedUserGroupData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(userGroupFormValidationSchema) as any,
  })

  const {fields: groupMembers, replace: replaceMember} = useFieldArray({
    control,
    name: 'members',
    keyName: 'keyIdx',
  })

  const {fields: groupPermissions, replace: replaceGroupPermissions} = useFieldArray({
    control,
    name: 'userGroupModulePermissions',
    keyName: 'keyIdx',
  })

  useEffect(() => {
    if (selectedUserGroupData) {
      selectedUserGroupData.selectedUserGroups = transformDataUserGroups(
        selectedUserGroupData.members
      )
      !selectedUserGroupData.id ? setDisabled(false) : setDisabled(true)
      reset({
        ...selectedUserGroupData,
      })
    } else {
      reset(USER_FORM_DEFAULT)
    }
    setformNotification(undefined)
  }, [selectedUserGroupData, reset])

  const closeModal = () => {
    setIsUserGroupModalVisible(false)
  }

  const onSubmit = async (values: IUserGroup) => {
    const payload: IUserGroup = values
    payload.members = groupMembers as any
    payload.userGroupModulePermissions = groupPermissions as any

    if (payload.id === 0) {
      createUserGroup(payload)
        .then(() => {
          closeModal()
          fetchUserGroupData()
          toastr.success('User Group saved successfully.', 'Saving User Group')
        })
        .catch((err) => {
          if (err.response)
            if (err.response) {
              toastr.error(err.response.data.message, 'Saving User Group')
            } else {
              toastr.error(err.message, 'Saving User Group')
            }
        })
    } else {
      updateUserGroup(payload)
        .then(() => {
          closeModal()
          fetchUserGroupData()
          toastr.success('User Group update successfully.', 'Update User Group')
        })
        .catch((err) => {
          if (err.response) {
            toastr.error(err.response.data.message, 'Update User Group')
          } else {
            toastr.error(err.message, 'Update User Group')
          }
        })
    }
    setSelectedData(initialValue)
  }

  const handleCancel = () => {
    setSelectedData(initialValue)
    closeModal()
    //
    reset(USER_FORM_DEFAULT)
    clearErrors()
  }

  return (
    <div style={{display: 'none'}}>
      {isUserGroupModalVisible && (
        <Dialog
          height={'auto'}
          width={'70%'}
          style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
          title={selectedUserGroupData.id === 0 ? 'New Group' : 'Edit User Group'}
          onClose={handleCancel}
        >
          <form onSubmit={handleSubmit(onSubmit)} name='user'>
            {formNotification && <CustomAlert {...formNotification} />}
            <div className='row mb-5'>
              <div className='col-lg-4 col-md-4 col-12'>
                <UserGroupFormFields
                  userData={selectedUserGroupData}
                  register={register}
                  control={control}
                  errors={errors}
                  userGroupOptions={userGroupOptions}
                  isDisabled={isDisabled}
                  clearErrors={clearErrors}
                  groupMembers={groupMembers}
                  replaceMember={replaceMember}
                  selectedUserGroupData={selectedUserGroupData}
                ></UserGroupFormFields>
              </div>
              <div className='col-lg-8 col-md-8 col-12'>
                <UserGroupPermissionForm
                  modules={modules}
                  permissions={permissions}
                  groupPermissions={groupPermissions}
                  replaceGroupPermissions={replaceGroupPermissions}
                  userGroupId={selectedUserGroupData.id}
                ></UserGroupPermissionForm>
              </div>
            </div>
            <div className='d-flex flex-row align-items-center justify-content-end w-100 mb-2'>
              <div className='d-flex flex-row align-items-center'>
                <AccessControlProvider
                  allowedPermission={
                    selectedUserGroupData.id === 0
                      ? PAGE_PERMISSION.USER_MANAGEMENT_CREATE
                      : PAGE_PERMISSION.USER_MANAGEMENT_UPDATE
                  }
                >
                  <Button buttonType='primary' text={'Save'} />
                </AccessControlProvider>
                <Button
                  buttonType='secondary'
                  text={'Cancel'}
                  onClick={handleCancel}
                  style={{marginLeft: 10}}
                />
              </div>
            </div>
          </form>
        </Dialog>
      )}
    </div>
  )
}

export default UserGroupInfoModal
