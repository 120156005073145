import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
// import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {Signout} from '../modules/auth/azure/Signout'

import {ReportsWrapper} from '../modules/reports/ReportsWrapper'
import {DashboardWrapper} from '../modules/dashboard/DashboardWrapper'

import {UserManagementWrapper} from '../modules/user-management/UserManagementWrapper'
import {AddNewClientWrapper} from '../modules/clients-list/components/AddNewClient/AddNewClientWrapper'
import {OfficeLocationsWrapper} from '../modules/office-list/OfficeLocationsWrapper'
import {AssetWrapper} from '../modules/asset-classes/AssetWrapper'
import {AddAssetClassWrapper} from '../modules/asset-classes/components/AddAssetClass/AddAssetClassWrapper'
import {EditAssetClassWrapper} from '../modules/asset-classes/components/EditAssetClass/EditAssetClassWrapper'
import ProjectManagementWrapper from '../modules/projects/ProjectsWrapper'
import {AdjustmentMatrixWrapper} from '../modules/adjustment-matrix/AdjustmentMatrixWrapper'
import ActivatedPageRoute from './ActivatedPageRoute'
import {PAGE_PERMISSION} from '../modules/roles/models/role-model'
import ActivePageBasedRedirect from './ActivePageBasedRedirect'

const ClientPageWrapper = lazy<React.FC>(() => import('../modules/clients/ClientPageWrapper'))
const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))

type Props = {
  activePages: string[]
}

const PrivateRoutes: React.FC<Props> = ({activePages = []}) => {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <ActivatedPageRoute
          path='/dashboard'
          component={DashboardWrapper}
          permission={PAGE_PERMISSION.DASHBOARD_VIEW}
          activePages={activePages}
        />
        {/* <Route exact path='/dashboard' component={DashboardWrapper} /> */}
        {/* <Route path='/projects' component={ProjectManagementWrapper} /> */}
        <ActivatedPageRoute
          path='/reports'
          component={ReportsWrapper}
          permission={PAGE_PERMISSION.REPORTS_VIEW}
          activePages={activePages}
        />
        {/* <Route exact path='/reports' component={ReportsWrapper} /> */}
        <ActivatedPageRoute
          path='/client'
          component={ClientPageWrapper}
          permission={PAGE_PERMISSION.CLIENTS_VIEW}
          activePages={activePages}
        />

        <ActivatedPageRoute
          path='/projects'
          component={ProjectManagementWrapper}
          permission={PAGE_PERMISSION.PROJECT_VIEW}
          activePages={activePages}
        />
        <ActivatedPageRoute
          path='/clients'
          component={ClientPageWrapper}
          permission={PAGE_PERMISSION.CLIENTS_VIEW}
          activePages={activePages}
        />

        <Route path='/crafted/widgets' component={WidgetsPage} />
        {/* <ActivatedPageRoute
          path='/management'
          component={UserManagementWrapper}
          permission={PAGE_PERMISSION.USER_MANAGEMENT_VIEW}
          activePages={activePages} /> */}
        <Route exact path='/user-management' component={UserManagementWrapper} />
        {/* <Route exact path='/rates-table' component={RatesTableWrapper} /> */}
        <ActivatedPageRoute
          path='/asset-classes'
          component={AssetWrapper}
          permission={PAGE_PERMISSION.ASSET_CLASSIFICATION_VIEW}
          activePages={activePages}
        />
        <ActivatedPageRoute
          path='/asset-class/new'
          component={AddAssetClassWrapper}
          permission={PAGE_PERMISSION.ASSET_CLASSIFICATION_CREATE}
          activePages={activePages}
        />
        <ActivatedPageRoute
          path='/asset-class/edit/:id'
          component={EditAssetClassWrapper}
          permission={PAGE_PERMISSION.ASSET_CLASSIFICATION_UPDATE}
          activePages={activePages}
        />

        <ActivatedPageRoute
          path='/office-locations'
          component={OfficeLocationsWrapper}
          permission={PAGE_PERMISSION.OFFICE_LOCATIONS_VIEW}
          activePages={activePages}
        />
        <ActivatedPageRoute
          path='/clients/add-new-client'
          component={AddNewClientWrapper}
          permission={PAGE_PERMISSION.CLIENTS_CREATE}
          activePages={activePages}
        />
        {/* <Route exact path='/asset-classes' component={AssetWrapper} />
        <Route exact path='/asset-class/new' component={AddAssetClassWrapper} />
        <Route exact path='/asset-class/edit/:id' component={EditAssetClassWrapper} />
        <Route exact path='/office-locations' component={OfficeLocationsWrapper} />
        <Route exact path='/clients/add-new-client' component={AddNewClientWrapper} /> */}
        <Route exact path='/adjustment-matrix' component={AdjustmentMatrixWrapper} />
        <Route exact path='/auth/signin-out' component={Signout} />

        <Route path='/auth' component={ActivePageBasedRedirect} />
        <Route exact path='/' component={ActivePageBasedRedirect} />

        {/* <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' /> */}
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}

export default PrivateRoutes
