import React, {FC, useContext, useEffect, useState} from 'react'
import './MemberInput.scss' // Import your CSS file for styling
import Select from 'react-select'
import {transformDataUserMember} from '../../../transformers/user-group-transformer'
import {IOption} from '../../../models/user-model'
import {UserContext} from '../../../context/UserContext'
import {Grid, GridColumn as Column, GridCellProps} from '@progress/kendo-react-grid'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers/AssetHelpers'
import {DEFAULT_GROUPS} from '../../../../users/constant/user-default'
interface IProps {
  groupName: string
  groupMembers: any[]
  onAddDeleteMember: (emails: string[]) => void
}

let fetchQue: any
const UserGroupMemberInput: FC<IProps> = ({groupName, groupMembers, onAddDeleteMember}) => {
  const {fetchAvailableUsers, members, isMemberLoading, setIsMemberLoading} =
    useContext(UserContext)

  const [selectedOption, setSelectedOption] = useState(null)
  const [memberList, setMemberList] = useState<any[]>([])
  const [error, setError] = useState<string>()
  const [memberOptions, setMemberOptions] = useState<IOption[]>([])

  // DEFAULT_GROUPS

  useEffect(() => {
    const filteredMember = members.filter(
      (mem: any) => !memberList.some((list: any) => list.id === mem.id)
    )
    let userMember = transformDataUserMember(filteredMember)
    setMemberOptions([...userMember])
  }, [members])

  useEffect(() => {
    if (groupMembers) {
      const filteredMember = members.filter(
        (mem: any) => !groupMembers.some((list: any) => list.id === mem.id)
      )
      let userMember = transformDataUserMember(filteredMember)
      setMemberOptions([...userMember])

      setMemberList([...groupMembers])
    }
  }, [groupMembers])

  const handleRemoveEmail = (memberToRemove: any) => {
    const updatedEmailList = memberList.filter((member) => member.id !== memberToRemove)
    onAddDeleteMember(updatedEmailList)
  }

  const handleInputChange = (inputValue: any) => {
    setIsMemberLoading(true)
    if (fetchQue) {
      setIsMemberLoading(false)
      clearTimeout(fetchQue)
    }
    fetchQue = setTimeout(() => {
      fetchAvailableUsers(inputValue)
    }, 1000)
  }

  const handleChange = (selectedOption: any) => {
    let selectedMember = memberList.find((member) => member.id === selectedOption.value)
    if (!selectedMember) {
      let newMember = [
        ...memberList,
        {
          id: selectedOption.value,
          name: selectedOption.label,
        },
      ]
      onAddDeleteMember([...newMember])
    }
    // Remove selection
    setSelectedOption(null)
  }

  // MEMBERS GRID

  const customActionCell = (props: GridCellProps) => {
    return (
      <td className='align-middle text-center'>
        <div className='btn btn-danger' onClick={() => handleRemoveEmail(props.dataItem.id)}>
          <i className='fa fa-times'></i>
        </div>
      </td>
    )
  }

  const customMemberCell = (props: GridCellProps) => {
    return (
      <td className='k-table-td'>
        <div className='d-flex flex-row'>
          <div className='pe-1'>
            <div className='profile-circle'>
              <div
                className='profile-circle-inner'
                style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
              ></div>
            </div>
          </div>
          <div style={{margin: 'auto', marginLeft: '0'}}>
            <span>{props.dataItem.name}</span>
          </div>
        </div>
      </td>
    )
  }

  return (
    <React.Fragment>
      <div className='member-input-container'>
        <div className='member-list'>
          {groupName !== DEFAULT_GROUPS.clients && (
            <Select
              value={selectedOption}
              onChange={handleChange}
              onInputChange={handleInputChange}
              options={memberOptions}
              isSearchable
              className='w-100'
              placeholder='Search and select...'
              isLoading={isMemberLoading}
            />
          )}
          <Grid
            data={memberList}
            className='mt-5'
            style={{width: 'auto', height: '300px', overflow: 'scroll'}}
          >
            {groupName !== DEFAULT_GROUPS.clients && (
              <Column title=' ' cell={customActionCell} width={100}></Column>
            )}
            <Column title='Name' field='name' cell={customMemberCell}></Column>
          </Grid>
        </div>
      </div>
      {error && <span className='text-danger'>{error}</span>}
    </React.Fragment>
  )
}

export default UserGroupMemberInput
