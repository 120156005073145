import React, {useState} from 'react'
import {Checkbox, CheckboxChangeEvent, Input, InputChangeEvent} from '@progress/kendo-react-inputs'
import './ColumnsList.css'
import {ProjectAssetData} from '../../../../../../models/project-assets'

type IColumnsListProps = {
  columns: ColumnType[]
  setColumns: (value: ColumnType[]) => void
  tableData: ProjectAssetData[]
}

type ColumnType = {
  field: string
  title: string
  show: boolean
}

function ColumnsList(props: IColumnsListProps) {
  const {columns, setColumns} = props
  const [searchTerm, setSearchTerm] = useState<string | number | string[] | undefined>('')

  const onChange = (event: CheckboxChangeEvent) => {
    const field = event.target.name
    const newColumns = columns.map((column: ColumnType) => {
      if (column.field === field) {
        return {
          ...column,
          show: !column.show,
        }
      }
      return column
    })
    setColumns(newColumns)
  }

  const onInputChange = (e: InputChangeEvent) => {
    const target = e.target
    setSearchTerm(target.value)
  }

  const filteredColumns = columns.filter((column: any) => {
    return column?.title.toLowerCase().includes(searchTerm?.toString().toLowerCase())
  })

  return (
    <div
      id='custom_columns_list'
      style={{
        overflow: 'scroll',
      }}
    >
      <>
        <Input
          className='custom_columns_list_input'
          placeholder='Search'
          value={searchTerm}
          onChange={onInputChange}
        />
        <div className='custom_columns_list_input_icon_wrapper'>
          <i className='bi bi-search'></i>
        </div>
      </>
      <div className='custom_columns_list_container'>
        {filteredColumns.map((column: ColumnType, index: number) => (
          <div key={index} className='p-2'>
            <Checkbox
              type='checkbox'
              id={column.field}
              name={column.field}
              checked={column.show}
              onChange={onChange}
              label={column.title}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ColumnsList
