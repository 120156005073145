export const transformDataUserGroups = (userGroups: string[]) => {
    return (userGroups || []).map((userGroup) => ({
      value: userGroup,
      label: userGroup,
    }))
  }
  
  export const transformDataUserMember = (users: []) => {
    return (users || []).map((user: any) => ({
      value: user.id,
      label: user.firstName + ' ' + user.lastName
    }))
  }
  
