import {UserModel} from '../../models/UserModel'
import {Permission} from './PermissionTypes'

interface RolesPermissions {
  [role: string]: string[]
}

const rolesPermissions: RolesPermissions = {
  Administrator: ['user.list', 'user.create'],
  User: ['user.test'],
}

const getPermissionsByRole = (role: string): string[] => {
  return rolesPermissions[role] || []
}

export const getUserRolePermissions =
  (user: UserModel) =>
  async (permission: Permission): Promise<boolean> => {
    let userPermissions: string[] = []

    user.userRoles?.forEach((role) => {
      userPermissions = userPermissions.concat(getPermissionsByRole(role))
    })

    //await new Promise((resolve) => setTimeout(resolve, 1000))
    return userPermissions.includes(permission)
  }
