import {NumericTextBox} from '@progress/kendo-react-inputs'
import {useEffect, useState} from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import {ISingleLineValutaion} from '../../../models/single-line-valuation'
import SectionTitleHeader from '../SectionTitleHeader'
import {formatNumberWithCommas} from '../../../transformers/project-transformer'

type IPreviousReplacementCostSectionProps = {
  control: Control<ISingleLineValutaion>
  isValid: boolean
  watch: UseFormWatch<ISingleLineValutaion>
  setValue: UseFormSetValue<ISingleLineValutaion>
  getValues: UseFormGetValues<ISingleLineValutaion>
  errors: FieldErrors<ISingleLineValutaion>
  register: UseFormRegister<ISingleLineValutaion>
  clearErrors: UseFormClearErrors<ISingleLineValutaion>
  handleSubmit: UseFormHandleSubmit<ISingleLineValutaion>
  selectedData: ISingleLineValutaion
  setSelectedData: (value: ISingleLineValutaion) => void
}

function PreviousReplacementCostSection(props: IPreviousReplacementCostSectionProps) {
  const {watch, control, errors, getValues} = props
  const [replacementCostFormat, setReplacmentCostFormat] = useState<string>('$0.00')
  const watchedReplacementCost = watch('replacementCost')

  useEffect(() => {
    var replacementCost =
      getValues('replacementCost') === undefined ? 0 : getValues('replacementCost')
    setReplacmentCostFormat(formatNumberWithCommas(replacementCost))
  }, [watchedReplacementCost])

  return (
    <>
      <div className='d-flex justify-content-between'>
        <SectionTitleHeader title='Previous Replacement Cost' showButtons={false} />
      </div>
      <div id='previousReplacementCostSection'>
        <div id='previousReplacementCostForm'>
          <div className='row h-auto mt-5'>
            <div className='col-3'>
              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='replacementCost'>
                  Replacement Cost
                </label>
                <div>
                  <input
                    style={{
                      fontSize: '1rem',
                      width: '120px',
                    }}
                    readOnly={true}
                    id='secondaryAdjustmentFactorRate'
                    className={`form-control${
                      errors.secondaryAdjustmentFactorRate ? ' border-danger' : ''
                    }`}
                    placeholder='0.00'
                    value={replacementCostFormat}
                  />
                </div>
              </div>

              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='registerReplacementCost'>
                  Register Replacement Cost
                </label>
                <div>
                  <Controller
                    control={control}
                    name='registerReplacementCost'
                    render={({field: {onChange, value}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${
                          errors.registerReplacementCost ? ' border-danger' : ''
                        }`}
                        defaultValue={value}
                        value={value}
                        format='c2'
                        min={0}
                        max={10}
                        step={0.01}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.registerReplacementCost && (
                    <div>
                      <small className='text-danger'>
                        {errors.registerReplacementCost.message}
                      </small>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-3'>
              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='replacementCostVariance'>
                  Replacement Cost Variance
                </label>
                <div>
                  <Controller
                    control={control}
                    name='replacementCostVariance'
                    render={({field: {onChange, value}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${
                          errors.replacementCostVariance ? ' border-danger' : ''
                        }`}
                        defaultValue={value}
                        value={value}
                        format='n2'
                        min={0}
                        max={10}
                        step={0.01}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.replacementCostVariance && (
                    <div>
                      <small className='text-danger'>
                        {errors.replacementCostVariance.message}
                      </small>
                    </div>
                  )}
                </div>
              </div>

              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='priorRevalReplacementCost'>
                  Prior Reval Replacement Cost
                </label>
                <div>
                  <Controller
                    control={control}
                    name='priorRevalReplacementCost'
                    render={({field: {onChange, value}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${
                          errors.priorRevalReplacementCost ? ' border-danger' : ''
                        }`}
                        defaultValue={value}
                        value={value}
                        format='n2'
                        min={0}
                        max={10}
                        step={0.01}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.priorRevalReplacementCost && (
                    <div>
                      <small className='text-danger'>
                        {errors.priorRevalReplacementCost.message}
                      </small>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-3'>
              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='varianceOnGfa'>
                  Variance on GFA
                </label>
                <div>
                  <Controller
                    control={control}
                    name='varianceOnGfa'
                    render={({field: {onChange, value}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${errors.varianceOnGfa ? ' border-danger' : ''}`}
                        defaultValue={value}
                        value={value}
                        format='n2'
                        min={0}
                        max={10}
                        step={0.01}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.varianceOnGfa && (
                    <div>
                      <small className='text-danger'>{errors.varianceOnGfa.message}</small>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PreviousReplacementCostSection
