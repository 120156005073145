import axios from "axios"
import { SortDescriptor } from "@progress/kendo-data-query"
import { IAdjustmentMatrix } from "../models/adjustmentmatrix"

export const ADJUSTMENT_MATRIX_URL = `${process.env.REACT_APP_API_URL}/adjustmentmatrix`

export const getAdjustmentMatrixPagination = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  filter?: string
) => {
    return await axios.get(`${ADJUSTMENT_MATRIX_URL}/Paginated`, {
     params: {
      skip,
      take,
      sort:  pageSort ? `${pageSort.field} ${pageSort.dir}` : '',
      filter
    }
  })
}

export const createAdjustmentMatrix = async (payload: IAdjustmentMatrix) => {
    return await axios.post(`${ADJUSTMENT_MATRIX_URL}`, payload)
}

export const updateAdjustmentMatrix = async (payload: IAdjustmentMatrix) => {
  return await axios.put(`${ADJUSTMENT_MATRIX_URL}`, payload)
}

export const deleteAdjustmentMatrix = async (id: number) => {
  return await axios.delete(`${ADJUSTMENT_MATRIX_URL}?id=${id}`)
}