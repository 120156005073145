import { FC } from "react"
import { IAssetComponentized } from "../../../../../../models/project-asset/componentized"
import { UseFormRegister } from "react-hook-form"

type MainAreaFieldProps = {
  register: UseFormRegister<IAssetComponentized>
  dataIndex: number
}

const MainAreaField: FC<MainAreaFieldProps> =  ({register, dataIndex}) => {
  return (
    <td key={dataIndex}>
      <input 
          type="number"
          className="form-control"
          {...register(`componentized.${dataIndex}.mainArea`)}
      />
    </td>
  )
}

export { MainAreaField }