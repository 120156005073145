import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest, select } from 'redux-saga/effects'
import { UserModel } from '../models/UserModel'
import { getUserByToken } from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  isRememberMe: false,
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  refreshToken?: string
  isRememberMe?: boolean
}

export const reducer = persistReducer(
  { storage, key: 'aaag-auth', whitelist: ['user', 'accessToken', 'isRememberMe'] },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        const refreshToken = action.payload?.refreshToken
        const isRememberMe = action.payload?.isRememberMe

        sessionStorage.setItem('refresh-token', refreshToken ?? '')
        if (!isRememberMe) {
          sessionStorage.setItem('aaag-auth', accessToken ?? '')
          localStorage.removeItem('remember-me')
        }
        return { accessToken, user: undefined, isRememberMe: isRememberMe }
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return { accessToken, user: undefined, isRememberMe: false }
      }

      case actionTypes.Logout: {
        sessionStorage.removeItem('aaag-auth')
        localStorage.removeItem('refresh-token')
        return initialAuthState
      }

      case actionTypes.UserLoaded:
      case actionTypes.SetUser: {
        const { user, isRememberMe, accessToken } = action.payload ?? {};
        
        return {
          ...state,
          accessToken: accessToken ?? state.accessToken,
          user: user ?? state.user,
          isRememberMe: isRememberMe ?? state.isRememberMe,
        };
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string, refreshToken: string, isRememberMe: boolean) => ({
    type: actionTypes.Login,
    payload: { accessToken, refreshToken, isRememberMe },
  }),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: { accessToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel, isRememberMe: boolean) => ({
    type: actionTypes.UserLoaded,
    payload: { user, isRememberMe },
  }),
  setUser: (user: UserModel, accessToken: string, refreshToken: string, isRememberMe: boolean) => ({
    type: actionTypes.SetUser,
    payload: { user, accessToken, refreshToken, isRememberMe },
  }),
  store: () => ({ type: 'def' }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    // @ts-ignore
    const getToken = (state) => state.auth.accessToken
    // @ts-ignore
    let token = yield select(getToken)
    // @ts-ignore
    const getIsRememberMe = (state) => state.auth.isRememberMe
    // @ts-ignore
    let rememberMe = yield select(getIsRememberMe)

    const { data: user } = yield getUserByToken(token)
    yield put(actions.fulfillUser(user, rememberMe))
  })
}
