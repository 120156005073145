import React from "react";
import './GroupMembers.scss'
import { userInitial } from "../../../../shared/service/utils";

function GroupMembers(props: any) { 

  return (
     <div className='symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2'>
        {
          props.members?.map((member: any) => (
            <div key={member.id} data-toggle="tooltip" title={member.firstName + ' ' + member.lastName}>
               <div className='symbol symbol-circle symbol-25px'>
                  <div><p className="circle-inner">{userInitial(member.firstName, member.lastName)}</p></div>
              </div>
            </div>
          ))
        }
      </div>

  )
}


export default GroupMembers
