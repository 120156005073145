import { FC } from "react"
import { IAssetComponentized } from "../../../../../../models/project-asset/componentized"
import { UseFormRegister } from "react-hook-form"

type SecondaryAreaFieldProps = {
  register: UseFormRegister<IAssetComponentized>
  dataIndex: number
}

const SecondaryAreaField: FC<SecondaryAreaFieldProps> =  ({register, dataIndex}) => {
  return (
    <td key={dataIndex}>
      <input 
          type="number"
          className="form-control"
          {...register(`componentized.${dataIndex}.secondaryArea`)}
      />
    </td>
  )
}

export { SecondaryAreaField }