import * as yup from 'yup'
export const adjustmentMatrixFormValidationSchema = yup.object().shape({
  id: yup.number(),
  adjustmentReason: yup
    .string()
    .min(1, 'Must be at least 1')
    .max(99, 'Must be at most 99')
    .matches(/^[a-z0-9 ]+$/i, { message: 'Must be alphanumeric' })
    .required('Adjustment Reason is required.'),
  adjustmentFactor: yup
    .number().typeError('Must be a number')
    .min(0.00, 'Must be at least 0.00')
    .max(100.00, 'Must be at most 100.00')
    .required('Adjustment Factor is required.')
})