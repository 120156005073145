import {NumericTextBox, RadioGroup, RadioGroupChangeEvent} from '@progress/kendo-react-inputs'
import {useEffect, useState} from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import Select from 'react-select'
import {ISingleLineInsuranceValuation} from '../../../models/single-line-insurance-valuation'
import SectionTitleHeader from '../SectionTitleHeader'
import {formatNumberWithCommas} from '../../../transformers/project-transformer'

type ISingleLineInsuranceValuationSectionProps = {
  control: Control<ISingleLineInsuranceValuation>
  isValid: boolean
  watch: UseFormWatch<ISingleLineInsuranceValuation>
  setValue: UseFormSetValue<ISingleLineInsuranceValuation>
  getValues: UseFormGetValues<ISingleLineInsuranceValuation>
  errors: FieldErrors<ISingleLineInsuranceValuation>
  register: UseFormRegister<ISingleLineInsuranceValuation>
  clearErrors: UseFormClearErrors<ISingleLineInsuranceValuation>
  handleSubmit: UseFormHandleSubmit<ISingleLineInsuranceValuation>
  selectedData: ISingleLineInsuranceValuation
  setSelectedData: (value: ISingleLineInsuranceValuation) => void
  singleLineReplacementCost: number
}

const includeDemolitionOptions = [
  {label: 'Yes', value: 'yes'},
  {label: 'No', value: 'no'},
]

const demolitionTypeOptions = [
  {value: 'Demolition - Standard', label: 'Demolition - Standard'},
  {value: 'Demolition - Risky', label: 'Demolition - Risky'},
]

const selectCustomStyles = {
  control: (provided: any) => ({
    ...provided,
    zIndex: 2,
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 2,
  }),
}

const formContainerStyle = {
  display: 'grid',
  gridGap: '10px',
  gridTemplateColumns: '[label] 200px [input] 1fr',
  alignItems: 'center',
}

const formInputStyle = {
  gridColumn: 'input',
  alignSelf: 'center',
}

const formLabelStyle = {
  gridColumn: 'label',
  alignSelf: 'center',
}

function SingleLineInsuranceValuationSection(props: ISingleLineInsuranceValuationSectionProps) {
  const {
    register,
    watch,
    control,
    errors,
    clearErrors,
    setValue,
    getValues,
    singleLineReplacementCost,
  } = props
  const [insuranceLimitOfLiabilityFormat, setInsuranceLimitOfLiabilityFormat] =
    useState<string>('0.00')
  const [insuranceDemolitionFormat, setInsuranceDemolitionFormat] = useState<string>('0.00')
  const [insuranceCostEscalationFormat, setInsuranceCostEscalationFormat] = useState<string>('0.00')

  const watchedDemolitionAllowance = watch('demolitionAllowance')
  const watchedPolicyPeriod = watch('policyPeriod')
  const watchedLeadUpPeriod = watch('leadUpPeriod')
  const watchedRebuildPeriod = watch('rebuildPeriod')
  const watchedInsuranceDemolition = watch('insuranceDemolition')
  const watchedInsuranceCostEscalation = watch('insuranceCostEscalation')

  const PolicyPeriodEscalationRate = 0.06
  const LeadUpTimeEscalationRate = 0.07
  const RebuildTimePeriodEscalationRate = 0.05
  const RebuildFactor = 0.9

  const computeInsuranceDemolition = () => {
    const reinstatementValue = singleLineReplacementCost
      ? parseFloat(singleLineReplacementCost.toFixed(2))
      : 0

    //Escalate Policy Period
    let policyPeriod = watchedPolicyPeriod ? parseFloat(watchedPolicyPeriod.toFixed(2)) : 0
    let presentValue =
      reinstatementValue * Math.pow(1 + PolicyPeriodEscalationRate / 12, policyPeriod)

    //Escalate LeadUp Period
    let leadUpPeriod = watchedLeadUpPeriod ? parseFloat(watchedLeadUpPeriod.toFixed(2)) : 0
    presentValue =
      parseFloat(presentValue.toFixed(2)) *
      Math.pow(1 + LeadUpTimeEscalationRate / 12, leadUpPeriod)

    //Demolition Cost
    const demolitionCost = roundUpToNearest1000(
      parseFloat(presentValue.toFixed(2)) * watchedDemolitionAllowance
    )

    setValue('insuranceDemolition', demolitionCost)
    setInsuranceDemolitionFormat(formatNumberWithCommas(demolitionCost))
  }

  const computeInsuranceCostEscalation = () => {
    const reinstatementValue = parseFloat(singleLineReplacementCost.toFixed(2))

    //Escalate Policy Period
    let policyPeriod = watchedPolicyPeriod ? parseFloat(watchedPolicyPeriod.toFixed(2)) : 0
    let presentValue =
      reinstatementValue * Math.pow(1 + PolicyPeriodEscalationRate / 12, policyPeriod)

    //Escalate LeadUp Period
    let leadUpPeriod = watchedLeadUpPeriod ? parseFloat(watchedLeadUpPeriod.toFixed(2)) : 0
    presentValue =
      parseFloat(presentValue.toFixed(2)) *
      Math.pow(1 + LeadUpTimeEscalationRate / 12, leadUpPeriod)

    //Escalate Rebuild Period
    let rebuildPeriod = watchedRebuildPeriod ? parseFloat(watchedRebuildPeriod.toFixed(2)) : 0
    presentValue =
      parseFloat(presentValue.toFixed(2)) *
      Math.pow(1 + RebuildTimePeriodEscalationRate / 12, rebuildPeriod * RebuildFactor)

    //round nearest 1000th
    presentValue = roundUpToNearest1000(presentValue)

    const costEscalation = parseFloat(presentValue.toFixed(2)) - reinstatementValue
    setValue('insuranceCostEscalation', costEscalation)
    setInsuranceCostEscalationFormat(formatNumberWithCommas(costEscalation))
  }

  useEffect(() => {
    computeInsuranceDemolition()
  }, [
    watchedPolicyPeriod,
    watchedLeadUpPeriod,
    watchedDemolitionAllowance,
    singleLineReplacementCost,
  ])

  useEffect(() => {
    computeInsuranceCostEscalation()
  }, [watchedPolicyPeriod, watchedRebuildPeriod, watchedLeadUpPeriod, singleLineReplacementCost])

  const computeInsuranceLimitOfLiability = () => {
    const reinstatementValue = parseFloat(singleLineReplacementCost.toFixed(2))
    const insuranceDemolition = parseFloat(getValues('insuranceDemolition').toFixed(2))
    const insuranceCostEscalation = parseFloat(getValues('insuranceCostEscalation').toFixed(2))

    const computedInsuranceLimitOfLiability =
      reinstatementValue + insuranceDemolition + insuranceCostEscalation
    setValue('insuranceLimitOfLiability', computedInsuranceLimitOfLiability)
    setInsuranceLimitOfLiabilityFormat(formatNumberWithCommas(computedInsuranceLimitOfLiability))
  }

  const roundUpToNearest1000 = (number: number) => {
    return Math.ceil(number / 1000) * 1000
  }

  useEffect(() => {
    computeInsuranceLimitOfLiability()
  }, [watchedInsuranceDemolition, watchedInsuranceCostEscalation, singleLineReplacementCost])

  return (
    <>
      <div className='scrollable-component-container singlelineinsurancetab'>
        <div className='d-flex justify-content-between'>
          <SectionTitleHeader title='Insurance Valuation' showButtons={false} />
        </div>
        <div id='singleLineInsuranceValuationSection'>
          <div id='singleLineInsuranceValuationSectionForm'>
            <div className='row h-auto mt-5'>
              <div className='col-4'>
                <div className='form-outline mb-4' style={formContainerStyle}>
                  <label className='fw-bolder' htmlFor='policyPeriod' style={formLabelStyle}>
                    Policy Period
                  </label>
                  <div style={formInputStyle}>
                    <Controller
                      control={control}
                      name='policyPeriod'
                      render={({field: {onChange, value}}) => (
                        <NumericTextBox
                          style={{
                            fontSize: '1rem',
                            height: '40px',
                            width: '120px',
                          }}
                          className={`form-control${errors.policyPeriod ? ' border-danger' : ''}`}
                          defaultValue={value}
                          value={value}
                          format='n'
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.policyPeriod && (
                      <div>
                        <small className='text-danger'>{errors.policyPeriod.message}</small>
                      </div>
                    )}
                  </div>
                </div>
                <div className='form-outline mb-4' style={formContainerStyle}>
                  <label className='fw-bolder' htmlFor='includeDemolition' style={formLabelStyle}>
                    Include Demolition?
                  </label>
                  <div style={formInputStyle}>
                    <Controller
                      control={control}
                      name='includeDemolition'
                      render={({field: {onChange, value, onBlur}}) => {
                        return (
                          <RadioGroup
                            layout='horizontal'
                            value={value ? 'yes' : 'no'}
                            data={includeDemolitionOptions}
                            onChange={(e: RadioGroupChangeEvent) => {
                              clearErrors('includeDemolition')
                              onChange(e.value === 'yes')
                            }}
                          />
                        )
                      }}
                    />
                    {errors.includeDemolition && (
                      <div>
                        <small className='text-danger'>{errors.includeDemolition.message}</small>
                      </div>
                    )}
                  </div>
                </div>
                {getValues('includeDemolition') && (
                  <div className='form-outline mb-4' style={formContainerStyle}>
                    <label className='fw-bolder' htmlFor='demolitionType' style={formLabelStyle}>
                      Demolition Type
                    </label>
                    <div style={formInputStyle}>
                      <Controller
                        control={control}
                        name='demolitionType'
                        render={({field: {onChange, value}}) => (
                          <div>
                            <Select
                              isSearchable={false}
                              styles={selectCustomStyles}
                              placeholder='Select Asset Type'
                              isMulti={false}
                              options={demolitionTypeOptions}
                              value={{
                                label: value,
                                value: value,
                              }}
                              onChange={(e) => {
                                onChange(e?.value)
                                clearErrors('demolitionType')
                              }}
                              className={`controllerSelect${
                                errors.demolitionType ? ' border-danger' : ''
                              }`}
                            />
                            {errors?.demolitionType ? (
                              <small className='text-danger'>
                                {errors?.demolitionType?.message}
                              </small>
                            ) : null}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                )}

                <div className='form-outline mb-4' style={formContainerStyle}>
                  <label className='fw-bolder' htmlFor='demolitionAllowance' style={formLabelStyle}>
                    Demolition Allowance
                  </label>
                  <div style={formInputStyle}>
                    <Controller
                      control={control}
                      name='demolitionAllowance'
                      render={({field: {onChange, value}}) => (
                        <NumericTextBox
                          style={{
                            fontSize: '1rem',
                            height: '40px',
                            width: '120px',
                          }}
                          className={`form-control${
                            errors.demolitionAllowance ? ' border-danger' : ''
                          }`}
                          defaultValue={value}
                          value={value}
                          format='p1'
                          step={0.001}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.demolitionAllowance && (
                      <div>
                        <small className='text-danger'>{errors.demolitionAllowance.message}</small>
                      </div>
                    )}
                  </div>
                </div>
                <div className='form-outline mb-4' style={formContainerStyle}>
                  <label className='fw-bolder' htmlFor='insuranceDemolition' style={formLabelStyle}>
                    Insurance Demolition
                  </label>
                  <div style={formInputStyle}>
                    <input
                      style={{
                        fontSize: '1rem',
                        width: 170,
                      }}
                      readOnly={true}
                      id='insuranceDemolition'
                      className={`form-control${
                        errors.insuranceDemolition ? ' border-danger' : ''
                      }`}
                      placeholder='0.00'
                      value={insuranceDemolitionFormat}
                    />

                    {errors.insuranceDemolition && (
                      <div>
                        <small className='text-danger'>{errors.insuranceDemolition.message}</small>
                      </div>
                    )}
                  </div>
                </div>
                <div className='form-outline mb-4' style={formContainerStyle}>
                  <label className='fw-bolder' htmlFor='leadUpPeriod' style={formLabelStyle}>
                    Lead Up Period
                  </label>
                  <div style={formInputStyle}>
                    <Controller
                      control={control}
                      name='leadUpPeriod'
                      render={({field: {onChange, value}}) => (
                        <NumericTextBox
                          style={{
                            fontSize: '1rem',
                            height: '40px',
                            width: '120px',
                          }}
                          className={`form-control${errors.leadUpPeriod ? ' border-danger' : ''}`}
                          defaultValue={value}
                          value={value}
                          format='n'
                          step={0.01}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.leadUpPeriod && (
                      <div>
                        <small className='text-danger'>{errors.leadUpPeriod.message}</small>
                      </div>
                    )}
                  </div>
                </div>
                <div className='form-outline mb-4' style={formContainerStyle}>
                  <label className='fw-bolder' htmlFor='rebuildPeriod' style={formLabelStyle}>
                    Rebuild Period
                  </label>
                  <div style={formInputStyle}>
                    <Controller
                      control={control}
                      name='rebuildPeriod'
                      render={({field: {onChange, value}}) => (
                        <NumericTextBox
                          style={{
                            fontSize: '1rem',
                            height: '40px',
                            width: '120px',
                          }}
                          className={`form-control${errors.rebuildPeriod ? ' border-danger' : ''}`}
                          defaultValue={value}
                          value={value}
                          format='n'
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.rebuildPeriod && (
                      <div>
                        <small className='text-danger'>{errors.rebuildPeriod.message}</small>
                      </div>
                    )}
                  </div>
                </div>
                <div className='form-outline mb-4' style={formContainerStyle}>
                  <label
                    className='fw-bolder'
                    htmlFor='insuranceCostEscalation'
                    style={formLabelStyle}
                  >
                    Insurance : Cost Escalation
                  </label>
                  <div style={formInputStyle}>
                    <input
                      style={{
                        fontSize: '1rem',
                        width: 170,
                      }}
                      readOnly={true}
                      id='insuranceCostEscalation'
                      className={`form-control${
                        errors.insuranceLimitOfLiability ? ' border-danger' : ''
                      }`}
                      placeholder='0.00'
                      value={insuranceCostEscalationFormat}
                    />
                    {errors.insuranceCostEscalation && (
                      <div>
                        <small className='text-danger'>
                          {errors.insuranceCostEscalation.message}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div className='form-outline mb-4' style={formContainerStyle}>
                  <label
                    className='fw-bolder'
                    htmlFor='insuranceLimitOfLiability'
                    style={formLabelStyle}
                  >
                    Insurance : Limit of Liability
                  </label>

                  <div style={formInputStyle}>
                    <input
                      style={{
                        fontSize: '1rem',
                        width: 170,
                      }}
                      readOnly={true}
                      id='insuranceLimitOfLiability'
                      className={`form-control${
                        errors.insuranceLimitOfLiability ? ' border-danger' : ''
                      }`}
                      placeholder='0.00'
                      value={insuranceLimitOfLiabilityFormat}
                    />
                  </div>
                </div>
              </div>
              <div className='col-1'></div>
              <div className='col-5'>
                <div className='form-outline mb-4'>
                  <label className='fw-bolder' htmlFor='usableLife'>
                    Insurance Comment
                  </label>
                  <div>
                    <textarea
                      rows={5}
                      className={`form-control${errors.insuranceComments ? ' border-danger' : ''}`}
                      placeholder='Enter Description'
                      {...register('insuranceComments', {
                        onChange: (e) => {
                          clearErrors('insuranceComments')
                        },
                      })}
                    />
                    {errors.insuranceComments && (
                      <div>
                        <small className='text-danger'>{errors.insuranceComments.message}</small>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default SingleLineInsuranceValuationSection
