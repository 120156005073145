import {FC, useContext, useState} from 'react'
import ReportsTable from './ReportsTable'
import {ReportContext} from '../context/ReportContext'

const ReportsPage: FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const {setSearchFilter} = useContext(ReportContext)

  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      setSearchFilter(searchTerm)
    }
  }

  return (
    <div
      style={{
        backgroundColor: '#fff',
        height: 'auto',
        padding: 10,
        boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px',
      }}
    >
      <div className='d-flex align-items-center justify-content-between'>
        <div style={{position: 'relative'}}>
          <input
            onChange={(e) => {
              setSearchTerm(e.target.value)
            }}
            onKeyDown={handleEnterKey}
            type='text'
            placeholder='Search Report'
            style={{
              minWidth: 400,
              minHeight: 35,
              borderRadius: 17.5,
              border: '1px solid #E5E4E2',
              paddingLeft: 30,
              outline: 'none',
              fontStyle: 'italic',
            }}
          />
          <i
            className='bi bi-search'
            style={{position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)'}}
          ></i>
        </div>
        <div className='d-flex align-items-center'></div>
      </div>
      <ReportsTable />
    </div>
  )
}

export {ReportsPage}
