import './Loader2.scss'
const Loader2 = () => {
    return (
      <div className="custom-loader-overlay">
        <div className="k-loading-mask">
          <span className="k-loading-text">Logging In</span>
          <div className="k-loading-image" style={{ color: '#a71304' }}></div>
          <div className="k-loading-color"></div>
        </div>
      </div>
    )
  }
  export {Loader2}
  