import React, {useState, useContext} from 'react'
import {Grid, GridColumn as Column} from '@progress/kendo-react-grid'
import {gearIcon} from '@progress/kendo-svg-icons'
import {Dialog} from '@progress/kendo-react-dialogs'
import {ActionButton} from '../../../../../_metronic/layout/components/buttons/ActionButton'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import {AdjustmentMatrixContext} from '../../context/AdjustmentMatrixContext'
import {transformAdjustmentFactor} from '../../transformers/adjustment-matrix-transformer'
import {IAdjustmentMatrix} from '../../models/adjustmentmatrix'
import {deleteAdjustmentMatrix} from '../../api/adjustment-matrix-api'

const initialSort: {field: string; dir: string}[] = [{field: 'id', dir: 'desc'}]

function AdjustmentMatrixLookupTable() {
  const {
    adjustmentMatrix,
    totalRows,
    handlePageChange,
    handleSortChange,
    gridSetup,
    setSelectedData,
    setModalVisible,
    fetchData,
    showToastrNotification,
  } = useContext(AdjustmentMatrixContext)

  const items = ['Update Record', 'Delete Record']
  const [sort, setSort] = useState<any>(initialSort)
  const [isSaving, setIsSaving] = useState(false)
  const [deletedItem, setDeletedItem] = useState<IAdjustmentMatrix | null>(null)

  const handleDropDownClick = (dataItem: IAdjustmentMatrix, action: string) => {
    if (action === 'Update Record') {
      setSelectedData(dataItem)
      setModalVisible(true)
    } else if (action === 'Delete Record') {
      if (dataItem.id !== null) {
        setDeletedItem(dataItem)
      }
    }
  }

  const handleCloseDeleteDialog = () => {
    setDeletedItem(null)
  }

  const deleteItem = async () => {
    setIsSaving(true)
    try {
      if (deleteItem !== null) {
        await deleteAdjustmentMatrix(deletedItem?.id ?? 0)
          .then(() => {
            fetchData()
            setDeletedItem(null)
            setIsSaving(false)
            showToastrNotification('Adjustment Matrix deleted successfully', 'success')
          })
          .catch(() => {
            setIsSaving(false)
            setDeletedItem(null)
            showToastrNotification('Oops we encountered a problem. Please try again!', 'error')
          })
      }
    } catch (error) {
      setDeletedItem(null)
      setIsSaving(false)
      showToastrNotification('Oops we encountered a problem. Please try again!', 'error')
    }
  }

  const customHeaderCell = (props: any) => {
    return (
      <>
        <span onClick={props.onClick} className={'k-cell-inner'}>
          <span className={'k-link'}>
            <span
              className={'k-column-title fw-bolder'}
              style={{textAlign: 'center', marginBottom: 0}}
            >
              {props.title}
            </span>{' '}
            {props.children}
          </span>
        </span>
      </>
    )
  }

  return (
    <div className='m-auto mt-4'>
      <Grid
        id='adjustment-matrix-lookup-table'
        data={adjustmentMatrix}
        pageable={true}
        resizable={true}
        total={totalRows}
        onPageChange={handlePageChange}
        sort={sort}
        sortable={true}
        onSortChange={handleSortChange}
        {...gridSetup}
      >
        <Column
          field='adjustmentReason'
          title='Adjustment Reason'
          headerCell={customHeaderCell}
          cell={(props) => <td className='align-middle'>{props.dataItem[props.field || '']}</td>}
        />
        <Column
          field='adjustmentFactor'
          title='Adjustment Factor'
          headerCell={customHeaderCell}
          cell={(props) => (
            <td className='align-middle'>
              {transformAdjustmentFactor(props.dataItem[props.field || ''])}
            </td>
          )}
        />
        <Column
          title=''
          cell={(props) => (
            <td className='align-middle text-center'>
              <ActionButton
                svgIcon={gearIcon}
                text='Actions'
                items={items}
                onItemClick={(event: any) => handleDropDownClick(props.dataItem, event.item)}
              />
            </td>
          )}
        />
      </Grid>
      {deletedItem !== null && (
        <Dialog
          height={'auto'}
          style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
          title={'Delete Adjment Matrix'}
          onClose={handleCloseDeleteDialog}
        >
          <div className='row mb-4'>
            <div className='col'>
              <p>This will delete the record. Continue? </p>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col  d-flex justify-content-end'>
              <Button buttonType='secondary' text={'Cancel'} onClick={() => setDeletedItem(null)} />
              <Button
                onClick={deleteItem}
                buttonType='primary'
                text={'Delete'}
                style={{marginLeft: 10}}
              />
            </div>
          </div>
        </Dialog>
      )}
    </div>
  )
}

export default AdjustmentMatrixLookupTable
