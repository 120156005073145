import * as yup from 'yup'

export const buildingElementFormValidationSchema = yup.object().shape({
  hotWaterSystem: yup.boolean(),
  hotWaterSystemType: yup.number().when('hotWaterSystem', {
    is: true,
    then: yup.number().positive('Hot water system type is required.'),
    otherwise: yup.number().nullable().oneOf([null, 0]),
  }),
  hvac: yup.boolean(),
  hvacType: yup.number().when('hvac', {
    is: true,
    then: yup.number().positive('Hvac Type is required.'),
    otherwise: yup.number().nullable().oneOf([null, 0]),
  }),
  solar: yup.boolean(),
  solarSystemSize: yup.number().when('solar', {
    is: true,
    then: yup.number().positive('Solar System Size is required.'),
    otherwise: yup.number().nullable().oneOf([null, 0]),
  }),
  waterTank: yup.boolean(),
  waterTankTotalSize: yup.number().when('waterTank', {
    is: true,
    then: yup.number().positive('Water Tank Total Size is required.'),
    otherwise: yup.number().nullable().oneOf([null, 0]),
  }),
  tankStand: yup.boolean(),
  tankStandSize: yup.number().when('tankStand', {
    is: true,
    then: yup.number().positive('Tank Stand Size is required.'),
    otherwise: yup.number().nullable().oneOf([null, 0]),
  }),
  septicSystem: yup.boolean(),
  septicSysteType: yup.number().when('septicSystem', {
    is: true,
    then: yup.number().positive('Septic System is required.'),
    otherwise: yup.number().nullable().oneOf([null, 0]),
  }),
  fireServices: yup.boolean(),
  fireServicesPresent: yup.number().when('fireServices', {
    is: true,
    then: yup.number().positive('Fire Services is required.'),
    otherwise: yup.number().nullable().oneOf([null, 0]),
  }),
  lift: yup.boolean(),
  liftType: yup.number().when('lift', {
    is: true,
    then: yup.number().positive('Lift Type is required.'),
    otherwise: yup.number().nullable().oneOf([null, 0]),
  }),
  liftSecondary: yup.boolean(),
  liftSecondaryType: yup.number().when('liftSecondary', {
    is: true,
    then: yup.number().positive('Lift Secondary Type is required.'),
    otherwise: yup.number().nullable().oneOf([null, 0]),
  }),
  alarmSystem: yup.boolean(),
  alarmSystemSubtype: yup.number().when('alarmSystem', {
    is: true,
    then: yup.number().positive('Alarm System Subtype Type is required.'),
    otherwise: yup.number().nullable().oneOf([null, 0]),
  }),
})
