import React, {useCallback, useContext, useState} from 'react'
import './BulkUpdateForm.css'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {Dialog} from '@progress/kendo-react-dialogs'
import Select from 'react-select'
import {Controller} from 'react-hook-form'
import {bulkFormValidationSchema} from '../../../../../../validators/bulk-form'
import Button from '../../../../../../../../../_metronic/layout/components/buttons/Button'
import {fields} from './fields'
import {FieldValue, ProjectAssetData} from '../../../../../../models/project-assets'
import {bulkUpdateProjectAsset} from '../../../../../../api/asset-api'
import {ProjectContext} from '../../../../../../context/ProjectContext'
import {DatePicker} from '@progress/kendo-react-dateinputs'

type IBulkUpdateFormProps = {
  setBulkModalVisible: (value: boolean) => void
  selectedAssets: ProjectAssetData[]
  setSelectedAssets: (value: ProjectAssetData[]) => void
  successBulkUpdate: () => void
}

function BulkUpdateForm(props: IBulkUpdateFormProps) {
  const {setBulkModalVisible, selectedAssets, setSelectedAssets} = props
  const [bulkUpdateErrorMessage, setBulkUpdateErrorMessage] = useState<string>('')
  const [fieldType, setFieldType] = useState<string>('')

  const [selectedData, setSelectedData] = useState<any>({field: '', value: ''})
  const {showToastrNotification} = useContext(ProjectContext)

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: selectedData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(bulkFormValidationSchema),
  })

  const handleFieldChange = (selectedOption: any) => {
    setFieldType(selectedOption.type ?? '')

    setValue('value', null)

    setSelectedData((prevData: any) => ({...prevData, field: selectedOption.value}))
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target
    const isNumber = fieldType && fieldType === 'Number'
    setSelectedData((prevData: any) => ({...prevData, value: isNumber ? value.toString() : value}))
  }

  const onSubmit = () => {
    // PUT API
    const ids = selectedAssets.map((item) => item.id)
    bulkUpdateRecord(selectedAssets[0].projectId, ids, {
      field: selectedData.field,
      value: selectedData.value,
      fieldType: fieldType,
    }).then((res) => {
      if (res) {
        const updatedAssets = selectedAssets.map((asset) => ({
          ...asset,
          [selectedData.field]: selectedData.value,
        }))
        setSelectedAssets(updatedAssets)
        props.successBulkUpdate()
        setBulkModalVisible(false)
      }
    })
  }

  const bulkUpdateRecord = useCallback(
    async (projectId: string, ids: number[], fieldValue: FieldValue) => {
      try {
        const [response, err] = await bulkUpdateProjectAsset(projectId, ids, fieldValue)
        if (response) {
          showToastrNotification('Record update successfully.', 'success')
          return true
        } else {
          setBulkUpdateErrorMessage(err.response.data.message)
          return false
        }
      } catch {
        showToastrNotification('error encounter while saving', 'info')
        return false
      }
    },
    [showToastrNotification]
  )

  return (
    <Dialog
      height={'auto'}
      title={'Bulk Update'}
      onClose={() => {
        setBulkModalVisible(false)
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='row mb-4'>
          <div className='col'>
            <p>Select the field to be updated on the selected assets.</p>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='fields'>
                Field
              </label>
              <Controller
                control={control}
                name='fields'
                render={({field: {onChange, value}}) => {
                  return (
                    <div>
                      <Select
                        menuPosition='fixed'
                        placeholder='Select Field'
                        id='fields'
                        isMulti={false}
                        options={fields}
                        value={value}
                        onChange={(e) => {
                          onChange(e)
                          clearErrors('fields')
                          handleFieldChange(e)
                        }}
                        className={`controllerSelect${errors.fields ? ' border-danger' : ''}`}
                      />
                      {errors.fields && (
                        <small className='text-danger'>{errors.fields.message}</small>
                      )}
                    </div>
                  )
                }}
              />
            </div>
            <div className='form-outline'>
              <label className='fw-bolder' htmlFor='value'>
                Value
              </label>
              {fieldType === 'Date' ? (
                <Controller
                  control={control}
                  name='value'
                  render={({field: {onChange, value, onBlur}}) => {
                    return (
                      <DatePicker
                        value={value ? new Date(value) : null}
                        onBlur={onBlur}
                        format={'dd-MMM-yyyy'}
                        formatPlaceholder='formatPattern'
                        className={`controllerDatePicker${
                          errors?.inspectionDate ? ' border-danger' : ''
                        }`}
                        onChange={(e) => {
                          onBlur()
                          clearErrors('value')
                          onChange(e.target.value)
                        }}
                      />
                    )
                  }}
                />
              ) : (
                <input
                  minLength={0}
                  style={{
                    fontSize: '1rem',
                  }}
                  type={fieldType && fieldType === 'Number' ? 'number' : 'text'}
                  id='value'
                  className={`form-control${errors.value ? ' border-danger' : ''}`}
                  placeholder='Value'
                  {...register('value', {
                    onChange: (e: any) => {
                      clearErrors('value')
                      handleValueChange(e)
                    },
                  })}
                />
              )}

              {errors?.value && <small className='text-danger'>{errors?.value.message}</small>}
              {bulkUpdateErrorMessage && (
                <small className='text-danger'>{bulkUpdateErrorMessage}</small>
              )}
            </div>
          </div>
        </div>
        <div className='my-10' />
        <div className='row'>
          <div className='col d-flex align-items-center justify-content-center'>
            <Button buttonType='primary' text={'Update Records'} />
          </div>
        </div>
      </form>
    </Dialog>
  )
}

export default BulkUpdateForm
