import React, {useEffect, useRef, useState} from 'react'
import {useEffectOnce} from 'react-use'
import {getCurrentStatus, syncFulcrumApp} from './api/sync-api'

export interface IButtonSync {}

const ButtonSync: React.FC<IButtonSync> = () => {
  const [isRunning, setIsRunning] = useState(false)
  const [isProcessingSync, setIsProcessingSync] = useState(false)
  const [isErrorInSync, setIsErrorInSync] = useState(false)
  const [isNotSyncFulcrum, setIsNotSyncFulcrum] = useState(false)

  const cacheLoadStatus = localStorage.getItem('fulcrumSync')

  const intervalRef = useRef<any>(null)

  const fetchApiData = async () => {
    // if (isRunning) {
    //     return;
    // }
    // setIsRunning(true);
    try {
      const response = await getCurrentStatus()

      if (response.data) {
        setIsProcessingSync(!response.data.isComplete)
        setIsErrorInSync(response.data.isErrorInSync)
        setIsNotSyncFulcrum(response.data.isNotSyncFulcrum)

        localStorage.setItem('fulcrumSync', JSON.stringify(response.data))
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      // setIsRunning(false);
    }
  }

  useEffectOnce(() => {
    // Set interval to call fetchApiData every minute (60000 milliseconds)
    intervalRef.current = setInterval(fetchApiData, 10000)

    // Optionally, call the function immediately if you want the first call to be instant
    fetchApiData()

    if (cacheLoadStatus) {
      const cacheLoadStatusObject = JSON.parse(cacheLoadStatus)
      setIsProcessingSync(!cacheLoadStatusObject.isComplete)
      setIsErrorInSync(cacheLoadStatusObject.isErrorInSync)
      setIsNotSyncFulcrum(cacheLoadStatusObject.isNotSyncFulcrum)
    }

    // Clear interval on component unmount
    return () => clearInterval(intervalRef.current)
  })

  const projectSyncHandler = async () => {
    setIsProcessingSync(true)
    await syncFulcrumApp()
  }

  return (
    <React.Fragment>
      <span className='me-2'>
        {isProcessingSync ? (
          <div className='spinner-border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        ) : isNotSyncFulcrum ? (
          <span className='alert alert-danger' role='alert'>
            The records are not in sync with Fulcrum App
          </span>
        ) : (
          <span
            data-toggle='tooltip'
            data-placement='bottom'
            title='The records are syncs with Fulcrum app'
          >
            <i
              className='fa fa-check-circle'
              style={{
                fontSize: '24px',
                boxShadow: 'none',
                color: 'green',
              }}
            ></i>
          </span>
        )}
      </span>
      <button
        className='btn btn-primary'
        disabled={isProcessingSync || isRunning || !isNotSyncFulcrum}
        onClick={projectSyncHandler}
      >
        Sync Data
      </button>
    </React.Fragment>
  )
}

export {ButtonSync}
