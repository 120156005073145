/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../redux/AuthCRUD'

import {PageDataProvider} from '../../../../_metronic/layout/core'
import {ScrollTop} from '../../../../_metronic/layout/components/ScrollTop'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {SignInButton} from '../azure/SignInButton'
import {RememberMe} from './RememberMe'
import {ForgotPassword} from './ForgotPassword'
import {Loader1} from '../../../../_metronic/layout/components/loader/Loader1'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

interface IProps {
  handleShow2fa: (email: string) => void
}

//export function Login() {
const Login: React.FC<IProps> = ({handleShow2fa}) => {
  const [loading, setLoading] = useState(false)
  const [showRememberMe, setShowRememberMe] = useState(false)
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  // ReCaptcha execute
  const {executeRecaptcha} = useGoogleReCaptcha()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available')
        return
      }
      const tokenRecaptcha = await executeRecaptcha('login')

      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password, tokenRecaptcha)
          .then(({data: {token, twoFactorEnabled, name, longTermToken, refreshToken}}) => {
            setLoading(false)

            localStorage.setItem('refresh-token', refreshToken)
            localStorage.setItem(
              'remember-me',
              JSON.stringify({
                token: token,
                name: name,
                longTermToken: longTermToken,
              })
            )
            setShowRememberMe(true)
          })
          .catch((err) => {
            setLoading(false)
            setSubmitting(false)
            if (err.response.data.message == 'User is Inactive.') {
              setStatus('The user is inactive')
            }
            else {
              setStatus('The login detail is incorrect')
            }
          })
      }, 100)
    },
  })

  return (
    <PageDataProvider>
      <div className='d-flex flex-row flex-column-fluid'>
        <div style={{backgroundColor: '#151d23', flex: 0.3, position: 'relative'}}>
          <div className='container'>
            <div
              className='mb-20 d-flex aligns-items-center justify-content-center'
              style={{marginTop: 100}}
            >
              <Link to={'/'}>
                <img
                  src={toAbsoluteUrl('/media/logos/australis-logo.png')}
                  alt='Australis logo'
                  style={{width: '247px', height: '65px', objectFit: 'cover'}}
                />
              </Link>
            </div>
            {!showForgotPassword && !showRememberMe && (
              <h1 className='text-white mb-8 text-center'>Welcome to Navigator</h1>
            )}
            {showForgotPassword && (
              <ForgotPassword
                cancelHandler={() => {
                  setShowForgotPassword(false)
                }}
              ></ForgotPassword>
            )}
            {showRememberMe && <RememberMe></RememberMe>}

            {loading && <Loader1></Loader1>}

            {!showRememberMe && !showForgotPassword && !loading && (
              <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                {formik.status && (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                )}
                <div className='mb-8 w-75 mx-auto'>
                  <label className='form-label fw-bolder text-white' style={{fontSize: '1.15rem'}}>
                    Email
                  </label>
                  <input
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control form-control-sm form-control-solid',
                      {'is-invalid': formik.touched.email && formik.errors.email},
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className='text-white fs-6'>
                        {formik.errors.email}
                      </span>
                    </div>
                  )}
                </div>
                <div className='mb-8 w-75 mx-auto'>
                  <label
                    style={{fontSize: '1.15rem'}}
                    className='form-label fw-bolder text-white'
                    onClick={() => {
                      setShowForgotPassword(true)
                    }}
                  >
                    Password{' '}
                    <span
                      className='text-primary'
                      style={{
                        cursor: 'pointer',
                        fontSize: '1.15rem',
                      }}
                    >
                      Forgot Password ?
                    </span>
                  </label>
                  <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-sm form-control-solid',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-white fs-6'>
                          {formik.errors.password}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='mb-16 d-flex aligns-items-center justify-content-center w-75 mx-auto'>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary fw-bolder'
                    style={{backgroundColor: '#a71304', color: 'white', width: '100%'}}
                  >
                    <span className='indicator-label' style={{fontSize: '1.15rem'}}>
                      Login
                    </span>
                  </button>
                </div>

                <hr style={{border: '1.5px solid #fff'}} />
                <div className='mt-16 mb-8 d-flex aligns-items-center justify-content-center'>
                  <p className='text-white text-center fs-1'>Australis Staff Login</p>
                </div>

                <div className='d-flex aligns-items-center justify-content-center'>
                  <SignInButton />
                </div>
              </form>
            )}

            {/* {showRememberMe ? (
              <RememberMe></RememberMe>
            ) : (
            
            )} */}

            <div className='mt-5 pt-5'>
              <p
                style={{
                  fontSize: 11,
                  color: '#fff',
                  textAlign: 'center',
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                2024&copy; Australis Navigator 0.12.1
              </p>
            </div>
            <div style={{position: 'fixed', bottom: 0, right: 0, margin: '10px'}}>
              <div id='recaptcha' className='recaptcha'></div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: `url(${toAbsoluteUrl('/media/background/login-background.jpg')})`,
            backgroundSize: 'cover',
            flex: 0.7,
            height: '100%',
          }}
        >
          <p
            style={{
              color: '#fff',
              fontSize: 48,
              textAlign: 'center',
              marginBottom: 0,
              width: '60%',
            }}
          >
            We always deliver the complete picture.
          </p>
        </div>
      </div>
      <ScrollTop />
    </PageDataProvider>
  )
}

export {Login}
