import {
  Control,
  UseFormSetValue,
  FieldErrors,
  UseFormRegister,
  UseFormClearErrors,
  UseFormHandleSubmit,
  FieldArrayWithId,
  UseFieldArrayReplace,
  UseFormGetValues,
} from 'react-hook-form'
import TitleHeader from '../../../../../../../../../../_metronic/layout/components/header/TitleHeader'
import {ProjectAssetData, ProjectData} from '../../../../../../../models/project-assets'
import {ClientSpecificCustomFields} from './ClientSpecificCustomFields'
import {useContext} from 'react'
import {IProjectData} from '../../../../../../../models/project-model'
import {ProjectContext} from '../../../../../../../context/ProjectContext'

type IClientSpecificsProps = {
  control: Control<ProjectAssetData>
  isValid: boolean
  setValue: UseFormSetValue<any>
  errors: FieldErrors<any>
  register: UseFormRegister<any>
  clearErrors: UseFormClearErrors<any>
  handleSubmit: UseFormHandleSubmit<any>
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData[]) => void
  projectAssetCustomFields: FieldArrayWithId<
    ProjectAssetData,
    'projectAssetCustomFields',
    'keyIdx'
  >[]
  replaceProjectAssetCustomFields: UseFieldArrayReplace<
    ProjectAssetData,
    'projectAssetCustomFields'
  >
  projectId: string
  projectData: IProjectData | ProjectData
  getValues: UseFormGetValues<ProjectAssetData>
}

function ClientSpecifics(props: IClientSpecificsProps) {
  const {projectData, control, register, setValue, getValues} = props
  const {tableData} = useContext(ProjectContext)

  const data = tableData.find((data: any) => data?.id === projectData?.id)

  return (
    <>
      <TitleHeader title='Client Specifics' />
      <div id='clientSpecifics'>
        <div className='col'>
          <div className='d-flex align-items-center'>
            <div className='row mt-5'>
              <div className='col'>
                <h4>Custom Fields</h4>

                {data?.projectCustomFields?.length === 0 ? (
                  <p>No Custom Fields defined</p>
                ) : (
                  <ClientSpecificCustomFields
                    projectId={props.projectId}
                    control={control}
                    setValue={setValue}
                    register={register}
                    getValues={getValues}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientSpecifics
