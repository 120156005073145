import React, {useCallback, useEffect, useState} from 'react'
import {
  getAvailableUsers,
  getModulePermission,
  getUserGroupPagination,
  getUserGroups,
  getUsers,
} from '../api'
import {Initial_GridSetup, USER_FORM_DEFAULT} from '../constant/user-default'
import {GridSetup, IOption, IUserData} from '../models/user-model'
import {transformDataOffices, transformDataUserGroups} from '../transformers/user-transformer'
import {USER_GROUP_FORM_DEFAULT} from '../constant/user-group-default'
import {IUserGroup} from '../models/user-group'
import {useEffectOnce} from 'react-use'
import {IPermission} from '../models/permission'
import {getOffices} from '../../office-list/api'
import {AxiosResponse} from 'axios'
import {IOffice} from '../../office-list/models/office'
import {useLayout} from '../../../../_metronic/layout/core'

export const UserContext = React.createContext<any>({})

export const UserContextProvider: React.FC = ({children}) => {
  const {selectedOffice} = useLayout()
  // USERS //
  const [selectedData, setSelectedData] = useState<IUserData>(USER_FORM_DEFAULT)
  const [isModalVisible, setModalVisible] = useState(false)

  const [gridSetup, setGridSetup] = useState<GridSetup>(Initial_GridSetup)
  const [tableData, setTableData] = useState<IUserData[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [userGroupOptions, setUserGroupOptions] = useState<IOption[]>([])
  const [officeOptions, setOfficeOptions] = useState<IOption[]>([])
  const [searchFilter, setSearchFilter] = useState('')

  useEffectOnce(() => {
    getOffices().then((result: AxiosResponse<IOffice[]>) => {
      if (result) {
        const options = transformDataOffices(result.data)
        setOfficeOptions(options)
      }
    })
  })

  const fetchData = useCallback(
    (search: string = '', officeId) => {
      setIsLoading(true)
      var {sort, skip, take} = gridSetup
      //var data = toObjectExpression(gridSetup.filter)

      getUsers(skip, take, sort[0], search, officeId)
        .then((response: any) => {
          setTableData(response.data.items)
          setTotalRows(response.data.totalCount)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    },
    [gridSetup]
  )

  const fetchUserGroups = useCallback(() => {
    setIsLoading(true)
    getUserGroups()
      .then((response: any) => {
        const groups = transformDataUserGroups(response.data)
        setUserGroupOptions(groups)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [gridSetup])

  useEffect(() => {
    fetchData('', selectedOffice.id)
    fetchUserGroups()
  }, [gridSetup, fetchData, selectedOffice.id])

  const handlePageChange = (pageProps: any) => {
    setGridSetup({
      ...gridSetup,
      skip: pageProps.page.skip,
      take: pageProps.page.take,
    })
  }

  useEffect(() => {
    fetchData(searchFilter, selectedOffice.id)
  }, [searchFilter])

  // USER GROUPS //

  const [gridSetupUserGroup, setGridSetupUserGroup] = useState<GridSetup>(Initial_GridSetup)
  const [userGroupData, setUserGroupData] = useState<IUserData[]>([])
  const [userGroupTotalRows, setUserGroupTotalRows] = useState<number>(0)
  const [searchFilterUserGroup, setSearchFilterUserGroup] = useState('')
  //const items = ['View/Edit', 'Delete']

  const fetchUserGroupData = useCallback(
    (search: string = '') => {
      setIsLoading(true)
      var {sort, skip, take} = gridSetupUserGroup

      getUserGroupPagination(skip, take, sort[0], search)
        .then((response: any) => {
          setUserGroupData(response.data.items)
          setUserGroupTotalRows(response.data.totalCount)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    },
    [gridSetupUserGroup]
  )

  const handlePageChangeUserGroup = (pageProps: any) => {
    setGridSetupUserGroup({
      ...gridSetupUserGroup,
      skip: pageProps.page.skip,
      take: pageProps.page.take,
    })
  }

  useEffect(() => {
    fetchUserGroupData()
  }, [gridSetupUserGroup, fetchUserGroupData])

  useEffect(() => {
    fetchUserGroupData(searchFilterUserGroup)
  }, [searchFilterUserGroup])

  useEffectOnce(() => {
    fetchModulePermission()
    fetchAvailableUsers('')
  })

  const [isUserGroupModalVisible, setIsUserGroupModalVisible] = useState(false)
  const [selectedUserGroupData, setSelectedUserGroupData] =
    useState<IUserGroup>(USER_GROUP_FORM_DEFAULT)
  const [modules, setModule] = useState<IPermission[]>([])
  const [permissions, setPermissions] = useState<IPermission[]>([])

  const fetchModulePermission = useCallback(() => {
    getModulePermission()
      .then((response: any) => {
        // Modules //
        setModule(response.data.modules)
        // Permissions //
        setPermissions(response.data.permissions)
      })
      .catch(() => {
        setModule([])
        setPermissions([])
      })
  }, [])

  // MEMBERS //
  const [members, setMembers] = useState<any[]>([])
  const [isMemberLoading, setIsMemberLoading] = useState<boolean>(false)
  const fetchAvailableUsers = useCallback((inputValue) => {
    getAvailableUsers(inputValue)
      .then((response: any) => {
        setMembers(response.data)
        setIsMemberLoading(false)
      })
      .catch(() => {
        setIsMemberLoading(false)
      })
  }, [])

  return (
    <UserContext.Provider
      value={{
        initialValue: USER_FORM_DEFAULT,
        tableData,
        setTableData,
        isLoading,
        setIsLoading,
        totalRows,
        handlePageChange,
        fetchData,
        selectedData,
        setSelectedData,
        isModalVisible,
        setModalVisible,
        userGroupOptions,
        setSearchFilter,
        searchFilter,
        gridSetup,
        setGridSetup,
        isUserGroupModalVisible,
        setIsUserGroupModalVisible,
        selectedUserGroupData,
        setSelectedUserGroupData,
        modules,
        permissions,
        fetchAvailableUsers,
        members,
        setMembers,
        isMemberLoading,
        setIsMemberLoading,
        userGroupData,
        userGroupTotalRows,
        handlePageChangeUserGroup,
        searchFilterUserGroup,
        setSearchFilterUserGroup,
        fetchUserGroupData,
        gridSetupUserGroup,
        setGridSetupUserGroup,
        officeOptions,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
