import {IAssetClass, IAssetComponentData} from '../models/asset'

export const ASSET_CLASSES_INITIAL: IAssetClass = {
  id: 0,
  name: '',
  fairValue: false,
  insuranceValuation: false,
  assetClassComponents: [],
}


export const ASSET_COMPONENT_INITIAL: IAssetComponentData = {
  id: 0,
  number: '',
  name: '',
  additionalValuerAdjustmentRate: 0,
  secondaryAdjustmentFactor: 0,
  usefulLife: 0,
  assetClassComponentTypes: [],
  selectedComponentTypes: [],
  componentTypes: []
}


