import React, {FC} from 'react'
import {IProjectData} from '../../../models/project-model'
import './../../ui/Project.scss'
import {formatDate} from '../../../../../shared/service/utils'

interface IProps {
  projectData: IProjectData
}

const ProjectProfile: FC<IProps> = ({projectData}) => {
  return (
    <div className='profile-container'>
      <section key='section-project-name'>
        <div className='text-center project-name'>
          <h1>{projectData.name}</h1>
        </div>
        <div className='text-center'>
          <h5>{projectData.description}</h5>
        </div>
      </section>
      <section>
        {/* Project Id */}
        <div className='fw-bold'>
          <label>Project ID</label>
        </div>
        <div className='ms-5'>
          <div>{projectData.displayId}</div>
        </div>
        {/* Client */}
        <div className='fw-bold'>
          <label>Client</label>
        </div>
        <div className='ms-5'>
          <div>{projectData.clientName}</div>
        </div>

        {/* Project Manager */}
        <div className='fw-bold'>
          <label>Project Managers</label>
        </div>
        {(projectData.projectUsers || []).length > 0 ? (
          projectData.projectUsers.map((item, index) => (
            <div className='ms-5' key={index}>
              {item.fullname}
            </div>
          ))
        ) : (
          <div className='ms-5'>N/A</div>
        )}
      </section>
      <section>
        {/* Date Created */}
        <div className='fw-bold'>
          <label>Date Created</label>
        </div>
        <div className='ms-5'>
          <div>{projectData.created ? formatDate(projectData.created) : 'N/A'}</div>
        </div>

        {/* Last Updated */}
        <div className='fw-bold'>
          <label>Last Updated </label>
        </div>
        <div className='ms-5'>
          <div>{projectData.lastModified ? formatDate(projectData.lastModified) : 'N/A'}</div>
        </div>
      </section>
    </div>
  )
}

export {ProjectProfile}
