import * as yup from 'yup'
export const assetFormValidationSchema = yup.object().shape({
  // General Details
  id: yup.string(),
  assetName: yup.string().max(99, 'Character limit exceeded!').required('Asset name is required!'),
  assetNumber: yup.string().max(99, 'Character limit exceeded!'),
  valuer: yup.string().required('Valuer is required!'),
  assetStatus: yup.string().required('Asset status is required!'),
  assetDescription: yup.string(),
  // valuer: yup.string().required('Valuer is required!').nullable(),
  estimatedYear: yup
    .number()
    .typeError('Estimated construction year must be a number')
    .required('Estimated construction year is required!')
    .min(1900, 'Year must be greater than or equal to 1900')
    .max(2999, 'Year must be less than or equal to 2999'),
  location: yup.string().required('Location is required!').max(99, 'Character limit exceeded!'),
  stateCode: yup.string().required('State is required!').nullable(),
  region: yup.string().required('Region is required!').nullable(),
  //Asset Information
  asbestosStatus: yup.string().required('Asbestos status is required!').nullable(),
  assetClass: yup.string().required('Asset class is required!').nullable(),
  //: yup.string().required('Asset type is required!').nullable(),
  assetType: yup.string().when('valuationType', {
    is: 'Componentized',
    then: yup.string().required('Asset type is required!'),
    otherwise: yup.string().notRequired(),
  }),
  australisClassification: yup.string().max(99, 'Character limit exceeded!').nullable(),
  valuationDate: yup.date().required('Valuation date is required!').nullable(),
  valuationType: yup.string().required('Valuation type is required!').nullable(),
  // Inspection Details
  status: yup.string().required('Inspection status is required!').nullable(),
  inspector: yup.string().required('Inspection name is required!').nullable(),
  inspectionDate: yup.date().required('Inspection date is required!').nullable(),

  // Construction Difficulty
  distanceFromRoad: yup.string().required('Distance from road is required!').nullable(),
  constructionAndSiteFactors: yup
    .string()
    .required('Construction dificulty factor is required!')
    .nullable(),
  // Building Replacement Rate
  valuationRegion: yup.string().required('Valuation region is required!'),
  locationBase: yup.string().max(99, 'Character limit exceeded!'),
  mainAreaNum: yup.string().required('Main area is required!'),
  mainAreaType: yup.string().required('Measurement value is required!'),
  // locationPremium: yup.string().required('Location premium is required!'),
  timeIndex: yup.string().required('Time index is required!'),
})

export const assetInformationValidationSchema = yup.object().shape({
  // General Details
  id: yup.string(),
  //Asset Information
  asbestosStatus: yup.string().required('Asbestos status is required!').nullable(),
  assetClass: yup.string().required('Asset class is required!').nullable(),
  assetType: yup.string().when('valuationType', {
    is: 'Componentized',
    then: yup.string().required('Asset type is required!'),
    otherwise: yup.string().notRequired(),
  }),
  australisClassification: yup.string().max(99, 'Character limit exceeded!').nullable(),
  valuationDate: yup.date().required('Valuation date is required!').nullable(),
  valuationType: yup.string().required('Valuation type is required!').nullable(),
})

export const singleLineValidationSchema = yup.object().shape({
  id: yup.number(),
  //UnitRate
  adoptedBaseRate: yup.string().required('Adopted Base Rate is required!').nullable(),
  locationBase: yup.string().required('Location Base is required!').nullable(),
  timeAdjustToPeriod: yup
    .date()
    .typeError('Time Adjust to Period  is required!')
    .required('Time Adjust to Period  is required!')
    .nullable(),
  mainAreaBaseRate: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(10, 'Must be at most 10')
    .required('Required'),
  secondaryAdjustmentFactor: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(10, 'Must be at most 10')
    .required('Required'),
  componentBreakdown: yup
    .number()
    .typeError('Must be a number')
    .min(0, 'Must be at least 0')
    .max(1, 'Must be at most 100')
    .required('Required')
    .test('maxDigits', 'Must have at most 4 digits', (value: any) => {
      return value.toString().replace('.', '').length <= 5
    }),
  locationPremium: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(10, 'Must be at most 10')
    .required('Required'),
  timeIndex: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(10, 'Must be at most 10')
    .required('Required'),
  constructionDifficultyFactor: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(10, 'Must be at most 10')
    .required('Required'),
  additionalValuerAdjustmentFactor: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(10, 'Must be at most 10')
    .required('Required'),
  additionalCostsAdjustmentFactor: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(10, 'Must be at most 10')
    .required('Required'),
  professionalFee: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(10, 'Must be at most 10')
    .required('Required'),
  usableLife: yup
    .number()
    .typeError('Must be a number')
    .min(0.001, 'Must be greater than 0')
    .required('Required'),
  physicalConditionScore: yup
    .number()
    .typeError('Must be a number')
    .min(0.001, 'Must be greater than 0')
    .required('Required'),
  remainingServicePotentialScore: yup
    .number()
    .typeError('Must be a number')
    .min(0.001, 'Must be greater than 0'),
  registerReplacementCost: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(10, 'Must be at most 10')
    .required('Required'),
  replacementCostVariance: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(10, 'Must be at most 10')
    .required('Required'),
  priorRevalReplacementCost: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(10, 'Must be at most 10')
    .required('Required'),
  varianceOnGfa: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(10, 'Must be at most 10')
    .required('Required'),
})

export const singleLineInsuranceValuationValidationSchema = yup.object().shape({
  id: yup.number(),
  policyPeriod: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(100, 'Must be at most 100')
    .required('Required'),
  demolitionAllowance: yup
    .number()
    .typeError('Must be a number')
    .min(0.01, 'Must be at least 1')
    .max(1, 'Must be at most 5')
    .required('Required'),
  leadUpPeriod: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(5, 'Must be at most 5')
    .required('Required'),
  rebuildPeriod: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(100, 'Must be at most 100')
    .required('Required'),
  insuranceLimitOfLiability: yup.number().typeError('Must be a number').required('Required'),
  includeDemolition: yup.boolean().required('Include Demolition is required!'),
  demolitionType: yup
    .string()
    .nullable()
    .when('includeDemolition', {
      is: true,
      then: yup.string().required('Demolition Type is required!'),
    }),
})

export const marketApproachValidationSchema = yup.object().shape({
  id: yup.number(),
  salesRangeStart: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(99, 'Must be at most 99')
    .required('Required'),
  salesRangeEnd: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(99, 'Must be at most 99')
    .required('Required')
    .when('salesRangeStart', (salesRangeStart: number, schema: any) => {
      return schema.test({
        message: 'sales range end must be greater than sales range start',
        test: function (salesRangeEnd: number) {
          return salesRangeEnd > salesRangeStart
        },
      })
    }),
  zoningAdjustmentReason: yup.string().typeError('Must have value').required('Required'),

  baseRate: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must be at least 1')
    .max(99, 'Must be at most 99')
    .required('Required'),
  adjustmentFactor: yup
    .number()
    .typeError('Must be a number')
    .min(0.0, 'Must be at least 0.00')
    .max(100.0, 'Must be at most 100.00')
    .required('Required'),
  marketEvidences: yup.array().of(
    yup.object().shape({
      salesId: yup.number().required('Required').positive('Must be positive'),
      landSize: yup.number().required('Required').positive('Must be positive'),
      saleArea: yup.number().required('Required').positive('Must be positive'),
      salePrice: yup.number().required('Required').positive('Must be positive'),
    })
  ),
})
