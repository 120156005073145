import React from 'react'
import {ProjectAssetData} from '../../../models/project-assets'
import InsuranceForm from './insurance-valuation/InsuranceForm/InsuranceForm'

type IAssetInformationTabProps = {
  assetData: ProjectAssetData
  onHandleFormChange: (isDirty: boolean) => void
}

function InsuranceValuationTab(props: IAssetInformationTabProps) {
  return (
    <React.Fragment>
      <InsuranceForm
        assetData={props.assetData}
        onHandleFormChange={props.onHandleFormChange}
      ></InsuranceForm>
    </React.Fragment>
  )
}

export default InsuranceValuationTab
