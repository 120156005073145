import {NumericTextBox} from '@progress/kendo-react-inputs'
import {useEffect, useState} from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import SectionTitleHeader from '../SectionTitleHeader'
import Select from 'react-select'
import {IMarketApproachValuation} from '../../../models/market-approach-valuation'
import {formatNumberWithCommas} from '../../../transformers/project-transformer'
import {IAdjustmentMatrix} from '../../../models/adjustmentMatrix'
import {IOption} from '../../../models/project-model'

type IMarketApproachFairValueSectionProps = {
  control: Control<IMarketApproachValuation>
  isValid: boolean
  watch: UseFormWatch<IMarketApproachValuation>
  setValue: UseFormSetValue<IMarketApproachValuation>
  getValues: UseFormGetValues<IMarketApproachValuation>
  errors: FieldErrors<IMarketApproachValuation>
  register: UseFormRegister<IMarketApproachValuation>
  clearErrors: UseFormClearErrors<IMarketApproachValuation>
  handleSubmit: UseFormHandleSubmit<IMarketApproachValuation>
  selectedData: IMarketApproachValuation
  setSelectedData: (value: IMarketApproachValuation) => void
  adjustmentMatrixOptions: IAdjustmentMatrix[]
}

const selectCustomStyles = {
  control: (provided: any) => ({
    ...provided,
    zIndex: 2,
    width: '100%',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 2,
  }),
}

function MarketApproachFairValueSection(props: IMarketApproachFairValueSectionProps) {
  const {
    register,
    watch,
    control,
    errors,
    clearErrors,
    setValue,
    getValues,
    adjustmentMatrixOptions,
  } = props
  const [marketValuationFairValueFormat, setMarketValuationFairValueFormat] = useState<string>('')
  const [baseValueFormat, setBaseValueFormat] = useState<string>('0.00')
  const [zoningAdjustmentReasonOptions, setZoningAdjustmentReasonOptions] = useState<IOption[]>([])

  const watchedBaseRate = watch('baseRate')
  const watchedBaseValue = watch('baseValue')
  const watchedAdjustmentFactor = watch('adjustmentFactor')

  useEffect(() => {
    computeBaseValue()
  }, [watchedBaseRate])

  useEffect(() => {
    const mappedList: IOption[] = adjustmentMatrixOptions.map((item: IAdjustmentMatrix) => ({
      label: item.adjustmentReason,
      value: item.adjustmentReason,
    }))
    setZoningAdjustmentReasonOptions(mappedList)
  }, [adjustmentMatrixOptions])

  const computeBaseValue = () => {
    const baseRate = getValues('baseRate') ? parseFloat(getValues('baseRate').toFixed(2)) : 0
    const mainAreaAsset = getValues('mainArea') ? parseFloat(getValues('mainArea').toFixed(2)) : 0

    const computedBaseRate = mainAreaAsset === 0 ? 0 : baseRate / mainAreaAsset
    setValue('baseValue', computedBaseRate)
    setBaseValueFormat(formatNumberWithCommas(computedBaseRate))
  }

  useEffect(() => {
    const baseValue = parseFloat(getValues('baseValue').toFixed(2))
    const adjustmentFactor = parseFloat(getValues('adjustmentFactor').toFixed(2))
    const fairValue = parseFloat((baseValue * adjustmentFactor).toFixed(2))

    setValue('fairValue', fairValue)
    setMarketValuationFairValueFormat(formatNumberWithCommas(fairValue))
  }, [watchedBaseValue, watchedAdjustmentFactor])

  return (
    <>
      <div className='d-flex justify-content-between'>
        <SectionTitleHeader title='Fair Value' showButtons={false} />
      </div>
      <div id='marketApproachFairValueSection'>
        <div id='marketApproachFairValueForm'>
          <div className='row h-auto mt-5'>
            <div className='col-5'>
              <div className='row h-auto'>
                <div className='col-5'>
                  <div className='form-outline mb-4'>
                    <label className='fw-bolder' htmlFor='salesRangeStart'>
                      Sales Range
                    </label>
                    <div>
                      <Controller
                        control={control}
                        name='salesRangeStart'
                        render={({field: {onChange, value}}) => (
                          <NumericTextBox
                            style={{
                              fontSize: '1rem',
                              height: '40px',
                              width: '90%',
                            }}
                            className={`form-control${
                              errors.salesRangeStart ? ' border-danger' : ''
                            }`}
                            defaultValue={value}
                            value={value}
                            format='c2'
                            min={1}
                            max={99}
                            onChange={onChange}
                          />
                        )}
                      />
                      {errors.salesRangeStart && (
                        <div>
                          <small className='text-danger'>{errors.salesRangeStart.message}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-1'>
                  <div
                    style={{
                      marginTop: '30px',
                    }}
                  >
                    -
                  </div>
                </div>
                <div className='col-5'>
                  <div className='form-outline mb-4'>
                    <label className='fw-bolder' htmlFor='salesRangeEnd'></label>
                    <div>
                      <Controller
                        control={control}
                        name='salesRangeEnd'
                        render={({field: {onChange, value}}) => (
                          <NumericTextBox
                            style={{
                              fontSize: '1rem',
                              height: '40px',
                              width: '90%',
                            }}
                            className={`form-control${
                              errors.salesRangeEnd ? ' border-danger' : ''
                            }`}
                            defaultValue={value}
                            value={value}
                            format='c2'
                            step={0.01}
                            min={1}
                            max={99}
                            onChange={onChange}
                          />
                        )}
                      />
                      {errors.salesRangeEnd && (
                        <div>
                          <small className='text-danger'>{errors.salesRangeEnd.message}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row h-auto'>
                <div className='form-outline mb-4'>
                  <label className='fw-bolder' htmlFor='baseRate'>
                    Outer Sales Range Comment
                  </label>
                  <div>
                    <textarea
                      rows={3}
                      maxLength={4000}
                      className={`form-control${
                        errors.outerSalesRangeComment ? ' border-danger' : ''
                      }`}
                      placeholder='Enter here..'
                      {...register(`outerSalesRangeComment`, {
                        onChange: (e) => {
                          clearErrors(`outerSalesRangeComment`)
                        },
                      })}
                    />
                    {errors.outerSalesRangeComment && (
                      <div>
                        <small className='text-danger'>
                          {errors.outerSalesRangeComment.message}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='row h-auto'>
                <div className='col-5'>
                  <div className='form-outline mb-4'>
                    <label className='fw-bolder' htmlFor='baseRate'>
                      Base Rate / SQM
                    </label>
                    <div>
                      <Controller
                        control={control}
                        name='baseRate'
                        render={({field: {onChange, value}}) => (
                          <NumericTextBox
                            style={{
                              fontSize: '1rem',
                              height: '40px',
                              width: '90%',
                            }}
                            className={`form-control${errors.baseRate ? ' border-danger' : ''}`}
                            defaultValue={value}
                            value={value}
                            format='c2'
                            onChange={onChange}
                            min={1}
                            max={99}
                          />
                        )}
                      />
                      {errors.baseRate && (
                        <div>
                          <small className='text-danger'>{errors.baseRate.message}</small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='form-outline mb-4'>
                    <label className='fw-bolder' htmlFor='zoningAdjustmentReason'>
                      Zoning Adjustment Reason
                    </label>
                    <div>
                      <Controller
                        control={control}
                        name='zoningAdjustmentReason'
                        render={({field: {onChange, value}}) => (
                          <div>
                            <Select
                              isSearchable={false}
                              styles={selectCustomStyles}
                              placeholder='Select Reason'
                              isMulti={false}
                              options={zoningAdjustmentReasonOptions}
                              value={{
                                label: value,
                                value: value,
                              }}
                              onChange={(e) => {
                                onChange(e?.value)
                                const obj = adjustmentMatrixOptions.find(
                                  (item: IAdjustmentMatrix) => item.adjustmentReason === e?.value
                                )
                                const adjustmentFactor = parseFloat(
                                  obj ? obj.adjustmentFactor : '0'
                                )
                                setValue('adjustmentFactor', adjustmentFactor)
                                clearErrors('zoningAdjustmentReason')
                              }}
                              className={`controllerSelect${
                                errors.zoningAdjustmentReason ? ' border-danger' : ''
                              }`}
                            />
                            {errors?.zoningAdjustmentReason ? (
                              <small className='text-danger'>
                                {errors?.zoningAdjustmentReason?.message}
                              </small>
                            ) : null}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-1'></div>
                <div className='col-5'>
                  {' '}
                  <div className='form-outline mb-4'>
                    <label className='fw-bolder' htmlFor='baseValue'>
                      Base Value
                    </label>
                    <div>
                      <input
                        style={{
                          fontSize: '1rem',
                          width: 190,
                        }}
                        readOnly={true}
                        id='baseValue'
                        className={`form-control${errors.baseValue ? ' border-danger' : ''}`}
                        placeholder='0.00'
                        value={baseValueFormat}
                      />
                    </div>
                  </div>
                  <div className='form-outline mb-4'>
                    <label className='fw-bolder' htmlFor='adjustmentFactor'>
                      Adjustment Factor
                    </label>
                    <div>
                      <Controller
                        control={control}
                        name='adjustmentFactor'
                        render={({field: {onChange, value}}) => (
                          <NumericTextBox
                            style={{
                              fontSize: '1rem',
                              height: '40px',
                              width: '120px',
                            }}
                            className={`form-control${
                              errors.adjustmentFactor ? ' border-danger' : ''
                            }`}
                            defaultValue={value}
                            value={value}
                            format='n2'
                            step={0.01}
                            onChange={onChange}
                            min={0.0}
                            max={100.0}
                            readOnly={true}
                          />
                        )}
                      />
                    </div>
                    {errors.adjustmentFactor && (
                      <div>
                        <small className='text-danger'>{errors.adjustmentFactor.message}</small>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-1'></div>
            <div className='col-4'>
              <h4>Fair Value Total</h4>
              <div className={`card bg-dark`}>
                <div className='card-body'>
                  <div
                    className={`fw-bolder text-inverse-dark fs-1 text-right`}
                    style={{textAlign: 'center'}}
                  >
                    {marketValuationFairValueFormat}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default MarketApproachFairValueSection
