export const fields = [
  {value: 'assetNumber', label: 'Asset ID'},
  {value: 'assetDescription', label: 'Asset Name'},
  {value: 'status', label: 'Status'},
  {value: 'location', label: 'Location Premium'},
  {value: 'address', label: 'Address'},
  {value: 'suburb', label: 'Suburb'},
  {value: 'stateCode', label: 'State'},
  // {value: 'id', label: 'ID'},
  // {value: 'fulcrumId', label: 'Fulcrum ID'},
  {value: 'projectId', label: 'Project ID'},
  {value: 'locationCodeDesc', label: 'Location Code Desc'},
  {value: 'assetClass', label: 'Asset Class'},
  {value: 'assetType', label: 'Asset Type'},
  {value: 'assetSubType', label: 'Asset SubType'},
  {value: 'australisClassification', label: 'Australis Classification'},
  {value: 'asbestosStatus', label: 'Asbestos Status'},
  {value: 'valuationDate', label: 'Valuation Date', type: 'Date'},
  {value: 'valuationType', label: 'Valuation Type'},
  {value: 'insuranceValuation', label: 'Insurance Valuation'},
  {value: 'marketApproach', label: 'Market Approach'},
  {value: 'inspectionDate', label: 'Inspection Date', type: 'Date'},
  {value: 'inspector', label: 'Inspector'},
  {value: 'mainQuantity', label: 'Main Quantity', type: 'Number'},
  {value: 'secondaryQuantity', label: 'Secondary Quantity', type: 'Number'},
  {value: 'localGovernmentArea', label: 'Local Government Area'},
  {value: 'locationPremiumAdjustment', label: 'Location Premium Adjustment', type: 'Number'},
  {value: 'timeIndex', label: 'Time Index', type: 'Number'},
  {value: 'distanceFromRoad', label: 'Distance From Road'},
  {value: 'constructionAndSiteFactors', label: 'Site Factors'},
  {value: 'hotWaterSystem', label: 'Hot Water System'},
  {value: 'hotWaterSystemType', label: 'Hot Water System Type'},
  {value: 'hvac', label: 'HVAC'},
  {value: 'hvacType', label: 'HVAC Type'},
  {value: 'ductedAcCoverage', label: 'Ducted AC Coverage'},
  {value: 'countOfAcUnits', label: 'Count Of AC Units'},
  {value: 'solar', label: 'Solar'},
  {value: 'solarSystemSize', label: 'Solar System Total Size', type: 'Number'},
  {value: 'waterTank', label: 'Water Tank'},
  {value: 'waterTankTotalSize', label: 'Water Tank Total Size', type: 'Number'},
  {value: 'tankStand', label: 'Tank Stand'},
  {value: 'tankStandSize', label: 'Tank Stand Size', type: 'Number'},
  {value: 'septicSystem', label: 'Septic System'},
  {value: 'septicSysteType', label: 'Septic System Type', type: 'Number'},
  {value: 'fireServices', label: 'Fire Services'},
  {value: 'fireServicesPresent', label: 'Fire Services Present'},
  {value: 'lift', label: 'Lift'},
  {value: 'liftType', label: 'Lift Type'},
  {value: 'liftSecondary', label: 'Lift Secondary'},
  {value: 'liftSecondaryType', label: 'Lift Secondary Type'},
  {value: 'alarmSystem', label: 'Alarm System'},
  {value: 'alarmSystemSubtype', label: 'Alarm System Subtype'},
  {value: 'assetNumberFromPriorWF', label: 'Asset Number From Prior WF'},
  {value: 'priorFieldNotes', label: 'Prior Field Notes'},
  {value: 'priorCopyComments', label: 'Prior Copy Comments'},
  {value: 'priorRawlosRate', label: 'Prior Rawlos Rate'},
  {value: 'priorAlternateRate', label: 'Prior Alternate Rate'},
  {value: 'priorLocationPremium', label: 'Prior Location Premium'},
  {value: 'priorMainRateAdjFactor', label: 'Prior Main Rate Adj Factor'},
  {value: 'priorSecondRateAdjFactor', label: 'Prior Second Rate Adj Factor'},
  {value: 'priorFVProfessionalFees', label: 'Prior FV Professional Fees'},
  {value: 'aaagId', label: 'AAAG ID'},
  {value: 'suburbLgaText', label: 'Suburb Lga Text'},
  {value: 'region', label: 'Valuation Region'},
]
