import axios from 'axios'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/Authenticate/verify-token`
export const LOGIN_URL = `${API_URL}/Authenticate/login`
export const REFRESH_TOKEN = `${API_URL}/Authenticate/refresh-token`
export const LOGIN_2FA_URL = `${API_URL}/Authenticate/login-2FA`
export const REGISTER_URL = `${API_URL}/Authenticate/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/User/forgot-password`
export const GET_USER_BY_AZURE = `${API_URL}/Authenticate/verify-token-azure`
export const RESET_PASSWORD_URL = `${API_URL}/authenticate/reset-password`
export const LOGINVIAENTRA_URL = `${API_URL}/Authenticate/login-via-entra`


// Server should return AuthModel
export function login(email: string, password: string, tokenRecaptcha: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
    tokenRecaptcha,
  })
}

export function loginEntraUser(email: string, tokenRecaptcha: string) {
  return axios.post(LOGINVIAENTRA_URL, {
    email,
    tokenRecaptcha,
  })
}

export function refreshToken(email: string, refreshToken: string) {
  return axios.post(REFRESH_TOKEN, {
    email,
    refreshToken,
  })
}

export function login2Fa(email: string, code: string) {
  return axios.post(LOGIN_2FA_URL, {
    email,
    code,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string, tokenRecaptcha: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
    tokenRecaptcha,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    apiToken: token,
  })
}
export function geAzureUser(user: string) {
  return axios.post<UserModel>(GET_USER_BY_AZURE, {
    apiToken: user,
  })
}

export const resetPassword = async (
  email: string,
  password: string,
  token: string,
  tokenRecaptcha: string
) => {
  return await axios.post(`${RESET_PASSWORD_URL}`, {
    email: email,
    resetPasswordToken: token,
    password: password,
    tokenRecaptcha,
  })
}
