/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {MasterLayout} from '../../../_metronic/layout/MasterLayout'
import {ErrorsPage} from './ErrorsPage'

const ErrorsPageLoggedIn: React.FC = () => {
  return (
    <MasterLayout>
      <ErrorsPage></ErrorsPage>
    </MasterLayout>
  )
}

export {ErrorsPageLoggedIn}
