import React from 'react'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import {AccessControlProvider} from '../../../../../_metronic/layout/core/AccessControlProvider'

type ISectionTitleProps = {
  isEdit?: boolean
  title: string
  onEdit?: () => void
  onCancel?: () => void
  showButtons?: boolean
  permission?: string
}

function SectionTitleHeader(props: ISectionTitleProps) {
  const {title, isEdit, onEdit, onCancel, showButtons = true, permission} = props
  return (
    <>
      <div
        className='d-flex justify-content-between align-items-center mb-2 px-2'
        style={{width: '100%', background: '#a71304'}}
      >
        <h4
          style={{
            color: '#fff',
            marginBottom: 0,
            paddingTop: 10,
            paddingBottom: 10,
            fontWeight: 600,
          }}
        >
          {title}
        </h4>
        <AccessControlProvider allowedPermission={permission}>
          {showButtons ? (
            isEdit ? (
              <div>
                <Button buttonType='section' text={'Save'} />
                <Button
                  buttonType='section'
                  isSubmit={false}
                  text={'Cancel'}
                  onClick={onCancel}
                  style={{marginLeft: 5}}
                />
              </div>
            ) : (
              <Button buttonType='section' isSubmit={false} text={'Edit'} onClick={onEdit} />
            )
          ) : null}
        </AccessControlProvider>
      </div>
    </>
  )
}

export default SectionTitleHeader
