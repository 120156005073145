import React, {useContext, useState} from 'react'
import Select from 'react-select'
import './AssetValuationReportForm.css'
import {useForm, useFieldArray, Controller, useWatch} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {Dialog} from '@progress/kendo-react-dialogs'
import {reportSheetValidationSchema} from '../../validators/report-sheets'
import {IProjectData} from '../../models/project-model'
import {DatePicker} from '@progress/kendo-react-dateinputs'
import {OutlinedBox} from '../../../../../_metronic/layout/components/box/OutlinedBox'
import {getAssetValuationReport} from '../../api'
import {ProjectData} from '../../models/project-assets'
import {IAssetValuationReportSheets} from '../../models/report'
import {REPORT_SHEETS_DEFAULT} from '../../constant/report-default'
import {downloadFileFromBase64} from '../../../../shared/service/utils'
import {transformUpdateAssetValuationReportSheetForm} from '../../transformers/project-transformer'
import {ProjectContext} from '../../context/ProjectContext'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import moment from 'moment'

export const REPORT_SHEET_DEFAULT = {
  title: 'Sheet1',
  assetStatus: 'completed',
  referenceDateType: '',
  selectedDataFields: [
    {value: 'assetId', label: 'Asset ID'},
    {value: 'assetName', label: 'Asset Name'},
  ],
  dataFields: [],
}

const assetStatusOptions = [
  {value: '', label: 'All'},
  {value: 'pending', label: 'Pending'},
  {value: 'queried', label: 'Queried'},
  {value: 'completed', label: 'Completed'},
  {value: 'deleted', label: 'Deleted'},
]

const referenceDateOptions = [
  {value: '', label: '\u00A0'},
  {value: 'Date of Valuation', label: 'Date of Valuation'},
  {value: 'Date of Inspection', label: 'Date of Inspection'},
]

const dataFieldOptions = [
  {value: 'assetId', label: 'Asset ID'},
  {value: 'assetName', label: 'Asset Name'},
  {value: 'assetClass', label: 'Asset Class'},
  {value: 'assetType', label: 'Asset Type'},
  {value: 'australisClassification', label: 'Australis Classification'},
  {value: 'usableLife', label: 'Usable Life'},
  {value: 'replacementCost', label: 'Replacement Cost'},
  {value: 'fairValue', label: 'Fair Value'},
]

interface IProps {
  projectData?: IProjectData | ProjectData | undefined
}

const AssetValuationReportForm: React.FC<IProps> = ({projectData}) => {
  const [selectedData, setSelectedData] =
    useState<IAssetValuationReportSheets>(REPORT_SHEETS_DEFAULT)
  const {showToastrNotification} = useContext(ProjectContext)
  //const [sheetNumber, setSheetNumber] = useState<number>(2)
  const [selectedDeleteIndex, setSelectedDeleteIndex] = useState<number | null>(null)

  const {register, control, handleSubmit, getValues, setValue, formState, setError} = useForm({
    defaultValues: selectedData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(reportSheetValidationSchema) as any,
  })

  const {fields, append, remove, replace} = useFieldArray({name: 'sheets', control})

  const watchSheets = useWatch({
    control,
    name: `sheets`,
  })

  const onSubmit = async (values: IAssetValuationReportSheets) => {
    const payload: IAssetValuationReportSheets =
      transformUpdateAssetValuationReportSheetForm(values)

    if (projectData) {
      getAssetValuationReport(projectData?.fulcrumId ?? '', payload)
        .then((result) => {
          downloadFileFromBase64(
            result.data,
            moment().format('YYYYMMDD') + '_Asset Valuation Report.xlsx',
            'application/ms-excel'
          )
        })
        .catch(() => {
          showToastrNotification('Failed to download file. Please try again later.', 'error')
        })
    }
  }

  const addSheet = () => {
    const sheetLast = watchSheets[watchSheets.length - 1]
    const newTitle = incrementNumericAtEnd(sheetLast.title)

    append({
      title: `${newTitle}`,
      assetStatus: 'completed',
      referenceDateType: '',
      selectedDataFields: [
        {value: 'assetId', label: 'Asset ID'},
        {value: 'assetName', label: 'Asset Name'},
      ],
      dataFields: [],
    })
  }

  const resetSheet = (index: number) => {
    const updatedSheet = {...REPORT_SHEET_DEFAULT}
    updatedSheet.title = `Sheet${index + 1}`
    setValue(`sheets.${index}`, updatedSheet)
    replace(getValues('sheets'))
    setError(`sheets.${index}`, {
      type: 'manual',
      message: undefined,
    })
  }

  const getPositionName = (index: number): string => {
    const positions = [
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth',
      'Sixth',
      'Seventh',
      'Eighth',
      'Ninth',
      'Tenth',
    ]
    return positions[index] || `${index + 1}th`
  }

  const handleCloseDeleteDialog = () => {
    setSelectedDeleteIndex(null)
  }

  const deleteItem = async () => {
    if (selectedDeleteIndex !== null) remove(selectedDeleteIndex)
    setSelectedDeleteIndex(null)
  }

  const incrementNumericAtEnd = (str: string) => {
    // Use regular expression to match numeric characters at the end of the string
    const match = str.match(/(\d+)$/)

    if (match) {
      const numericPart = match[0]
      const incrementedNumericPart = (parseInt(numericPart) + 1).toString()

      return str.replace(/(\d+)$/, incrementedNumericPart)
    } else {
      return str + '2'
    }
  }

  return (
    <React.Fragment>
      {selectedDeleteIndex !== null && (
        <Dialog
          height={'auto'}
          style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
          title={'Delete Sheet'}
          onClose={handleCloseDeleteDialog}
        >
          <div className='row mb-4'>
            <div className='col'>
              <p>Are you sure you want to delete this sheet?</p>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col'></div>
            <div className='col-auto'>
              <Button onClick={deleteItem} buttonType='primary' text={'Yes'} />
              <Button
                buttonType='secondary'
                text={'No'}
                onClick={() => setSelectedDeleteIndex(null)}
                style={{marginLeft: 10}}
              />
            </div>
          </div>
        </Dialog>
      )}

      <form onSubmit={handleSubmit(onSubmit)} name='reportSheets'>
        {fields.map((item, index) => (
          <div className='p-5' key={item.id}>
            <OutlinedBox label={`${getPositionName(index)} Sheet`}>
              <div className='col'>
                <div className='form-group row mb-2'>
                  <label htmlFor='sheetTitle' className='col-2 col-form-label'>
                    Sheet Title
                  </label>
                  <div className='col-3'>
                    <input
                      {...register(`sheets.${index}.title` as const)}
                      className={`form-control${
                        formState.errors.sheets &&
                        formState.errors.sheets[index] &&
                        formState.errors.sheets[index]?.title
                          ? ' border-danger'
                          : ''
                      }`}
                      id='sheetTitle'
                      placeholder='Sheet Title'
                    />
                    {formState.errors &&
                      formState.errors.sheets &&
                      formState.errors.sheets[index] &&
                      formState.errors.sheets[index]?.title && (
                        <div>
                          <small className='text-danger'>
                            {formState.errors.sheets[index]?.title?.message}
                          </small>
                        </div>
                      )}
                  </div>
                </div>
                <div className='form-group row mb-2'>
                  <label htmlFor='assetStatus' className='col-2 col-form-label'>
                    Asset Status
                  </label>
                  <div className='col-3'>
                    <Controller
                      control={control}
                      name={`sheets.${index}.assetStatus`}
                      render={({field: {onChange, value}}) => (
                        <div>
                          <Select
                            defaultValue={
                              assetStatusOptions.length > 0
                                ? assetStatusOptions.find((x: any) => x.value === value.toString())
                                : null
                            }
                            value={
                              assetStatusOptions.length > 0
                                ? assetStatusOptions.find((x: any) => x.value === value.toString())
                                : null
                            }
                            options={assetStatusOptions}
                            onChange={(e: any) => {
                              onChange(e.value)
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className='form-group row mb-2'>
                  <label htmlFor='assetStatus' className='col-2 col-form-label'>
                    Reference Date
                  </label>
                  <div className='col-3 d-flex align-items-center'>
                    <div className='reference-date-main'>
                      <Controller
                        control={control}
                        name={`sheets.${index}.referenceDateType`}
                        render={({field: {onChange, value}}) => (
                          <div>
                            <Select
                              defaultValue={
                                referenceDateOptions.length > 0
                                  ? referenceDateOptions.find(
                                      (x: any) => x.value === value.toString()
                                    )
                                  : null
                              }
                              value={
                                referenceDateOptions.length > 0
                                  ? referenceDateOptions.find(
                                      (x: any) => x.value === value.toString()
                                    )
                                  : null
                              }
                              options={referenceDateOptions}
                              onChange={(e: any) => {
                                onChange(e.value)
                              }}
                            />
                          </div>
                        )}
                      />
                    </div>
                    <div></div>
                  </div>
                  {watchSheets[index] && watchSheets[index].referenceDateType !== '' && (
                    <div className='col-6 d-flex align-items-center '>
                      <div className='reference-date'>
                        <Controller
                          control={control}
                          name={`sheets.${index}.fromDate`}
                          render={({field: {onChange, value, onBlur}}) => {
                            return (
                              <DatePicker
                                value={value ? new Date(value) : null}
                                onBlur={onBlur}
                                format={'dd-MMM-yyyy'}
                                formatPlaceholder='formatPattern'
                                className={`controllerDatePicker${
                                  formState.errors.sheets &&
                                  formState.errors.sheets[index] &&
                                  formState.errors.sheets[index]?.fromDate
                                    ? ' border-danger'
                                    : ''
                                }`}
                                onChange={(e) => {
                                  onBlur()
                                  //clearErrors('inspectionDate')
                                  onChange(e.target.value)
                                }}
                              />
                            )
                          }}
                        />
                        {formState.errors &&
                          formState.errors.sheets &&
                          formState.errors.sheets[index] &&
                          formState.errors.sheets[index]?.fromDate && (
                            <div>
                              <small className='text-danger'>
                                {formState.errors.sheets[index]?.fromDate?.message}
                              </small>
                            </div>
                          )}
                      </div>
                      <div className='mx-3'>to</div>
                      <div className='reference-date'>
                        <Controller
                          control={control}
                          name={`sheets.${index}.toDate`}
                          render={({field: {onChange, value, onBlur}}) => {
                            return (
                              <DatePicker
                                value={value ? new Date(value) : null}
                                onBlur={onBlur}
                                format={'dd-MMM-yyyy'}
                                formatPlaceholder='formatPattern'
                                className={`controllerDatePicker${
                                  formState.errors.sheets &&
                                  formState.errors.sheets[index] &&
                                  formState.errors.sheets[index]?.toDate
                                    ? ' border-danger'
                                    : ''
                                }`}
                                onChange={(e) => {
                                  onBlur()
                                  //clearErrors('inspectionDate')
                                  onChange(e.target.value)
                                }}
                              />
                            )
                          }}
                        />
                        {formState.errors &&
                          formState.errors.sheets &&
                          formState.errors.sheets[index] &&
                          formState.errors.sheets[index]?.toDate && (
                            <div>
                              <small className='text-danger'>
                                {formState.errors.sheets[index]?.toDate?.message}
                              </small>
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                </div>
                <div className='form-group row mb-2'>
                  <label htmlFor='dataFields' className='col-2 col-form-label'>
                    Data Fields
                  </label>
                  <div className='col-3'>
                    <Controller
                      control={control}
                      name={`sheets.${index}.selectedDataFields`}
                      render={({field: {onChange, value}}) => (
                        <div>
                          <Select
                            //maxMenuHeight={150}
                            value={value}
                            menuPortalTarget={document.body}
                            menuPosition='fixed'
                            isMulti={true}
                            options={dataFieldOptions}
                            className={`controllerSelect${
                              formState.errors.sheets &&
                              formState.errors.sheets[index] &&
                              formState.errors.sheets[index]?.selectedDataFields
                                ? ' border-danger'
                                : ''
                            }`}
                            onChange={(e) => {
                              const filterAssetId = e.filter((option) => option.value === 'assetId')
                              const filterAssetName = e.filter(
                                (option) => option.value === 'assetName'
                              )

                              if (filterAssetId.length === 0 && filterAssetName.length === 0) {
                                //dont allow change
                              } else onChange(e)
                            }}
                          />
                        </div>
                      )}
                    />
                    {formState.errors &&
                      formState.errors.sheets &&
                      formState.errors.sheets[index] &&
                      formState.errors.sheets[index]?.selectedDataFields && (
                        <div>
                          <small className='text-danger'>
                            {formState.errors.sheets[index]?.selectedDataFields?.message}
                          </small>
                        </div>
                      )}
                  </div>
                </div>
                <div className='form-group row mb-2'>
                  <div className='col'></div>
                  <div className='col-auto'>
                    {index > 0 ? (
                      <button
                        type='button'
                        className='btn btn-sm btn-primary mx-4'
                        onClick={() => setSelectedDeleteIndex(index)}
                      >
                        Delete Sheet
                      </button>
                    ) : null}
                    <button
                      className='btn btn-sm btn-primary '
                      type='button'
                      onClick={() => {
                        resetSheet(index)}
                      }
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </OutlinedBox>
          </div>
        ))}
        {watchSheets.length < 5 && (
          <button className='btn btn-sm btn-primary mx-5' type='button' onClick={() => addSheet()}>
            Add Sheet
          </button>
        )}
        <hr></hr>
        <div className='d-flex justify-content-between'>
          <div></div>
          <button type='submit' className='btn btn-primary'>
            Generate Report
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

export {AssetValuationReportForm}
