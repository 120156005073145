import React, {useEffect, useState, useContext, useRef, useCallback} from 'react'
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
  GridNoRecords,
  getSelectedState,
  GridPageChangeEvent,
} from '@progress/kendo-react-grid'

import {getter} from '@progress/kendo-react-common'
import {gearIcon} from '@progress/kendo-svg-icons'
import {Dialog} from '@progress/kendo-react-dialogs'
import {ActionButton} from '../../../../../../../../../_metronic/layout/components/buttons/ActionButton'
import Button from '../../../../../../../../../_metronic/layout/components/buttons/Button'
import {BadgeProjectAssetStatus} from '../BadgeProjectAssetStatus/BadgeProjectAssetStatus'
import {ProjectContext} from '../../../../../../context/ProjectContext'
import ColumnsList from '../ColumnsList/ColumnsList'
import {GridSetup} from '../../../../../../models/project-model'
import {deleteProjectAsset, duplicateProjectAsset} from '../../../../../../api/asset-api'
import {DuplicateAssetModal} from '../../../../../Modal/ProjectAssetModal/DuplicateAssetModal'
import './ProjectAssetListTable.css'
import {ProjectAssetData} from '../../../../../../models/project-assets'
import {useHistory} from 'react-router-dom'
import {dateFormatddMMMyyyy, hasPermission} from '../../../../../../../../shared/service/utils'
import {useEffectOnce} from 'react-use'
import {PAGE_PERMISSION} from '../../../../../../../roles/models/role-model'
import {usePageData} from '../../../../../../../../../_metronic/layout/core'

type IProjectAssetListTableProps = {
  tableData: ProjectAssetData[]
  isLoading: boolean
  setIsLoading: (value: boolean) => void
  totalRows: number
  fetchProjectAssets: (search?: string) => void
  gridSetup: GridSetup
  setGridSetup: (value: GridSetup) => void
  columns: ColumnType[]
  setColumns: (value: ColumnType[]) => void
  handlePageChange: (event: GridPageChangeEvent) => void
  selectedAssets: ProjectAssetData[]
  setSelectedAssets: (value: any) => void
}

type ColumnType = {
  field: string
  title: string
  show: boolean
}

function ProjectAssetListTable(props: IProjectAssetListTableProps) {
  const {
    tableData,
    isLoading,
    setIsLoading,
    totalRows,
    fetchProjectAssets,
    gridSetup,
    setGridSetup,
    columns,
    setColumns,
    handlePageChange,
    selectedAssets,
    setSelectedAssets,
  } = props

  const {showToastrNotification, dataUpdated, fetchData} = useContext(ProjectContext)

  const [items, setItems] = useState<string[]>([])
  const [selectedDeleteId, setSelectedDeleteId] = useState<string | null>(null)
  const [selectedDuplicateId, setSelectedDuplicateId] = useState<string | null>(null)
  const [showList, setShowList] = useState<boolean>(false)
  const columnsListRef = useRef<HTMLDivElement | null>(null)
  const {currentUser} = usePageData()
  const history = useHistory()

  useEffectOnce(() => {
    let currentItems = []
    if (hasPermission(PAGE_PERMISSION.ASSETS_PAGE_VIEW, currentUser.activePages || []))
      currentItems.push('View Asset')

    if (hasPermission(PAGE_PERMISSION.ASSETS_PAGE_CREATE, currentUser.activePages || []))
      currentItems.push('Duplicate Asset')

    if (hasPermission(PAGE_PERMISSION.ASSETS_PAGE_DELETE, currentUser.activePages || []))
      currentItems.push('Delete Asset')

    setItems(currentItems)
  })

  const handleDropDownClick = (dataItem: any, action: string) => {
    if (action === 'View Asset') {
      onClickAsset(dataItem)
    } else if (action === 'Duplicate Asset') {
      if (dataItem.id !== null) setSelectedDuplicateId(dataItem.id)
    } else if (action === 'Delete Asset') {
      if (dataItem.id !== null) setSelectedDeleteId(dataItem.id)
    }
  }

  const onClickAsset = (dataItem: any) => {
    history.push(`/projects/${dataItem.projectId}/asset/${dataItem.id}`)
  }

  const handleCloseDeleteDialog = () => {
    setSelectedDeleteId(null)
  }
  const deleteItem = async (selectedDeleteId: string | number) => {
    try {
      deleteProjectAsset(+selectedDeleteId)
        .then((response) => {
          showToastrNotification('Asset successfully deleted', 'success')
          fetchProjectAssets()
          setSelectedDeleteId(null)
        })
        .catch((error) => {
          showToastrNotification('Oops we encountered a problem. Please try again!', 'error')
          setSelectedDeleteId(null)
        })
    } catch (error) {
      showToastrNotification('Oops we encountered a problem. Please try again!', 'error')
      setSelectedDeleteId(null)
    }
  }

  const duplicateItem = async (selectedDeleteId: string | number) => {
    try {
      duplicateProjectAsset(+selectedDeleteId)
        .then((response) => {
          showToastrNotification('Asset successfully duplicated', 'success')
          fetchProjectAssets()
          setSelectedDeleteId(null)
        })
        .catch((error) => {
          showToastrNotification('Oops we encountered a problem. Please try again!', 'error')
          setSelectedDeleteId(null)
        })
    } catch (error) {
      showToastrNotification('Oops we encountered a problem. Please try again!', 'error')
      setSelectedDeleteId(null)
    }
  }

  const handleCloseDuplicateDialog = () => {
    setSelectedDuplicateId(null)
  }

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }, [tableData])

  /**CHECKBOX SELECTION*/
  const DATA_ITEM_KEY = 'fulcrumId'
  const SELECTED_FIELD = 'selected'
  const idGetter = getter(DATA_ITEM_KEY)

  const [selectedState, setSelectedState] = useState<any>({})

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      })
      setSelectedState({...newSelectedState, data: event.dataItem})
      //

      if (event.syntheticEvent?.target.checked) {
        setSelectedAssets((prevSelectedAssets: any) => [...prevSelectedAssets, event.dataItem])
      } else {
        setSelectedAssets((prevSelectedAssets: any) =>
          prevSelectedAssets.filter((item: any) => idGetter(item) !== idGetter(event.dataItem))
        )
      }
    },
    [selectedState]
  )
  const onHeaderSelectionChange = useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target
    const checked = checkboxElement.checked
    const newSelectedState: ProjectAssetData | any = {}
    event.dataItems.forEach((item: any) => {
      newSelectedState[idGetter(item)] = checked
    })
    setSelectedState(newSelectedState)
    setSelectedAssets([...selectedAssets, {...newSelectedState, data: event.dataItem}])
  }, [])

  /**
   * Dynamic Column Width
   */
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight])
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const calculateWidth = (field: string): number => {
    let maxWidth = 0
    tableData.forEach((item: any) => {
      const size = getTextWidth(item[field], 'normal 14px Poppins')
      if (size > maxWidth) {
        maxWidth = windowSize[0] >= 1920 ? size * 0.85 : size
      }
    })

    if (maxWidth < 200) return 200

    return maxWidth
  }

  const getTextWidth = (text: any, font: any): any => {
    const canvas: any =
      (getTextWidth as any).canvas ||
      ((getTextWidth as any).canvas = document.createElement('canvas'))
    const context = canvas.getContext('2d')
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
  }

  const customHeaderCell = (props: any) => {
    return (
      <span onClick={props.onClick} className={'k-cell-inner'}>
        <span className={'k-link'}>
          <span className={'k-column-title fw-bolder custom_header_cell_title'}>{props.title}</span>
          {props.children}
        </span>
      </span>
    )
  }

  const assetNameCellFormat = (props?: any) => {
    const assetName = props.dataItem?.assetName
      ? props.dataItem?.assetName
      : props.dataItem?.assetDescription
    const assetDescription = props.dataItem?.assetDescription
    return (
      <td className='align-middle text-left'>
        {
          <>
            <p
              onClick={() => onClickAsset(props.dataItem)}
              className='custom_asset_name_first_part'
            >
              {assetName}
            </p>
            <p className='custom_asset_name_other_parts'>{assetDescription}</p>
          </>
        }
      </td>
    )
  }

  const insuranceValuationCellFormat = (props?: any) => {
    return (
      <td className='align-middle text-left'>
        {props.dataItem?.insuranceValuation ? 'Yes' : 'No'}
      </td>
    )
  }

  const dateCellFormat = (props?: any) => {
    return (
      <td className='align-middle text-left'>{dateFormatddMMMyyyy(props.dataItem[props.field])}</td>
    )
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetNode = event.target as Node
      if (columnsListRef.current && !columnsListRef.current.contains(targetNode)) {
        setShowList(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (dataUpdated) fetchData()
  }, [dataUpdated])

  return (
    <div style={{position: 'relative'}}>
      {tableData.length !== 0 && (
        <div className='mt-4 d-flex flex-column'>
          <button
            className='d-flex align-self-end columns_list_button'
            onClick={() => {
              setShowList(!showList)
            }}
          >
            +
          </button>
          {showList && (
            <div ref={columnsListRef}>
              <ColumnsList columns={columns} setColumns={setColumns} tableData={tableData} />
            </div>
          )}
        </div>
      )}
      <div className='m-auto mt-4'>
        {selectedDuplicateId !== null && (
          <DuplicateAssetModal
            duplicateItem={duplicateItem}
            handleCloseDuplicateDialog={handleCloseDuplicateDialog}
            selectedDuplicateId={selectedDuplicateId}
            setSelectedDuplicateId={setSelectedDuplicateId}
          ></DuplicateAssetModal>
        )}
        {selectedDeleteId !== null && (
          <Dialog
            className='delete_asset_dialog'
            title={'Delete Asset'}
            onClose={handleCloseDeleteDialog}
          >
            <div className='row mb-4'>
              <div className='col'>
                <p>Are you sure you want to delete this item?</p>
              </div>
            </div>
            <div className='row mb-4'>
              <div className='col d-flex justify-content-end'>
                <Button
                  onClick={() => deleteItem(selectedDeleteId)}
                  buttonType='primary'
                  text={'Yes'}
                />
                <Button
                  buttonType='secondary'
                  text={'No'}
                  onClick={() => setSelectedDeleteId(null)}
                  style={{marginLeft: 10}}
                />
              </div>
            </div>
          </Dialog>
        )}
        {isLoading ? (
          <div className='d-flex alig-items-center justify-content-center'>
            <div className='spinner-border' role='status' style={{color: '#a71304'}}>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : !totalRows || totalRows === 0 ? (
          <div className='align-middle text-center'>There are no assets to display!</div>
        ) : (
          <Grid
            style={{overflow: 'auto'}}
            id='project-asset-list-table'
            {...gridSetup}
            data={tableData.map((item: ProjectAssetData) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))}
            pageable={true}
            resizable={true}
            total={totalRows}
            onPageChange={handlePageChange}
            sort={gridSetup.sort}
            sortable={{
              allowUnsort: false,
            }}
            onSortChange={(e: GridSortChangeEvent) => {
              console.log('Sort changed', e.sort)
              setGridSetup({...gridSetup, sort: e.sort})
            }}
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: 'multiple',
            }}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
          >
            <GridNoRecords>No assets available</GridNoRecords>
            <Column
              field={SELECTED_FIELD}
              width='50px'
              headerSelectionValue={
                tableData.findIndex((item: ProjectAssetData) => !selectedState[idGetter(item)]) ===
                -1
              }
              headerCell={(props: any) => (
                <span
                  style={{
                    display: 'none',
                  }}
                ></span>
              )}
            />
            <Column
              width={80}
              title=''
              cell={(props) => (
                <td className='align-middle text-center'>
                  <ActionButton
                    width='50px'
                    svgIcon={gearIcon}
                    text=''
                    items={items}
                    onItemClick={(event: any) => handleDropDownClick(props.dataItem, event.item)}
                  />
                </td>
              )}
              headerCell={customHeaderCell}
            />
            {columns.map(
              (column: any, i: number) =>
                column.show && (
                  <Column
                    key={i}
                    field={column.field}
                    title={column.title}
                    headerCell={customHeaderCell}
                    width={column.title === 'Asset Name' ? calculateWidth('assetDescription') : 200}
                    cell={
                      column.title === 'Asset Name'
                        ? assetNameCellFormat
                        : column.title === 'Asset Status'
                        ? BadgeProjectAssetStatus
                        : column.title === 'Inspection Status'
                        ? BadgeProjectAssetStatus
                        : column.title === 'Insurance Valuation'
                        ? insuranceValuationCellFormat
                        : column.title.includes('Date')
                        ? dateCellFormat
                        : undefined
                    }
                  />
                )
            )}
          </Grid>
        )}
      </div>
    </div>
  )
}

export default ProjectAssetListTable
