import {NumericTextBox} from '@progress/kendo-react-inputs'
import {useEffect, useState} from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import {ISingleLineValutaion} from '../../../models/single-line-valuation'
import SectionTitleHeader from '../SectionTitleHeader'
import {formatNumberWithCommas} from '../../../transformers/project-transformer'

type IFairValueSectionProps = {
  control: Control<ISingleLineValutaion>
  isValid: boolean
  watch: UseFormWatch<ISingleLineValutaion>
  setValue: UseFormSetValue<ISingleLineValutaion>
  getValues: UseFormGetValues<ISingleLineValutaion>
  errors: FieldErrors<ISingleLineValutaion>
  register: UseFormRegister<ISingleLineValutaion>
  clearErrors: UseFormClearErrors<ISingleLineValutaion>
  handleSubmit: UseFormHandleSubmit<ISingleLineValutaion>
  selectedData: ISingleLineValutaion
  setSelectedData: (value: ISingleLineValutaion) => void
}

function FairValueSection(props: IFairValueSectionProps) {
  const {selectedData, watch, control, errors, setValue, getValues} = props

  const [remainingServicePotentialFormat, setRemainingServicePotentialFormat] =
    useState<string>('0.00')
  const [fairValueFormat, setFairValueFormat] = useState<string>('0.00')
  const watchedUsableLife = watch('usableLife')
  const watchedRemainingServicePotentialScore = watch('remainingServicePotentialScore')
  const watchedRemainingServicePotential = watch('remainingServicePotential')
  const watchedReplacementCost = watch('replacementCost')
  useEffect(() => {
    computeRemainingServicePotential()
  }, [watchedUsableLife, watchedRemainingServicePotentialScore])

  useEffect(() => {
    computeFairValue()
  }, [watchedUsableLife, watchedRemainingServicePotential, watchedReplacementCost])

  const computeRemainingServicePotential = () => {
    const RSPScore = getValues('remainingServicePotentialScore')
      ? parseFloat(getValues('remainingServicePotentialScore').toFixed(2))
      : 0
    const usableLife = getValues('usableLife') ? parseFloat(getValues('usableLife').toFixed(2)) : 0

    const remainingServicePotential = ((10 - RSPScore) / 10) * usableLife
    setValue('remainingServicePotential', remainingServicePotential)
    setRemainingServicePotentialFormat(remainingServicePotential.toFixed(2))
  }

  const computeFairValue = () => {
    let replacementCost =
      getValues('replacementCost') === undefined
        ? 0
        : parseFloat(getValues('replacementCost').toFixed(2))
    let usableLife = getValues('usableLife') ? parseFloat(getValues('usableLife').toFixed(2)) : 0
    let remainingServicePotential = getValues('remainingServicePotential')
      ? parseFloat(getValues('remainingServicePotential').toFixed(2))
      : 0

    const fairValue = (replacementCost / usableLife) * remainingServicePotential
    setFairValueFormat(formatNumberWithCommas(fairValue))
    setValue('fairValue', fairValue)
  }

  useEffect(() => {
    computeRemainingServicePotential()
  }, [selectedData])

  return (
    <>
      <div className='d-flex justify-content-between'>
        <SectionTitleHeader title='Fair Value' showButtons={false} />
      </div>
      <div id='fairValueSection'>
        <div id='fairValueForm'>
          <div className='row h-auto mt-5'>
            <div className='col-3'>
              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='usableLife'>
                  Usable Life
                </label>
                <div>
                  <Controller
                    control={control}
                    name='usableLife'
                    render={({field: {onChange, value}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${errors.usableLife ? ' border-danger' : ''}`}
                        defaultValue={value}
                        value={value}
                        format='n2'
                        min={0}
                        max={10}
                        step={0.01}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.usableLife && (
                    <div>
                      <small className='text-danger'>{errors.usableLife.message}</small>
                    </div>
                  )}
                </div>
              </div>

              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='physicalConditionScore'>
                  Physical Condition Score
                </label>
                <div>
                  <Controller
                    control={control}
                    name='physicalConditionScore'
                    render={({field: {onChange, value}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${
                          errors.physicalConditionScore ? ' border-danger' : ''
                        }`}
                        defaultValue={value}
                        value={value}
                        format='n2'
                        min={0}
                        max={10}
                        step={0.01}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.physicalConditionScore && (
                    <div>
                      <small className='text-danger'>{errors.physicalConditionScore.message}</small>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-3'>
              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='remainingServicePotentialScore'>
                  Remaining Service Potential Score
                </label>
                <div>
                  <Controller
                    control={control}
                    name='remainingServicePotentialScore'
                    render={({field: {onChange, value}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${
                          errors.remainingServicePotentialScore ? ' border-danger' : ''
                        }`}
                        defaultValue={value}
                        value={value}
                        format='n2'
                        min={0}
                        max={10}
                        step={0.01}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.remainingServicePotentialScore && (
                    <div>
                      <small className='text-danger'>
                        {errors.remainingServicePotentialScore.message}
                      </small>
                    </div>
                  )}
                </div>
              </div>

              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='remainingServicePotential'>
                  Remaining Service Potential
                </label>
                <div>
                  <input
                    style={{
                      fontSize: '1rem',
                      width: 130,
                    }}
                    readOnly={true}
                    id='remainingServicePotential'
                    className={`form-control${
                      errors.remainingServicePotential ? ' border-danger' : ''
                    }`}
                    placeholder='0.00'
                    value={remainingServicePotentialFormat}
                  />
                </div>
              </div>
            </div>
            <div className='col-4'>
              <h4>Fair Value</h4>
              <div className={`card bg-dark`}>
                <div className='card-body'>
                  <div
                    className={`fw-bolder text-inverse-dark fs-1 text-right`}
                    style={{textAlign: 'center'}}
                  >
                    {fairValueFormat}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default FairValueSection
