import {GridCellProps} from '@progress/kendo-react-grid'
import {CircularProgressbar} from 'react-circular-progressbar'

export const CircularPercentageCell = (props: GridCellProps) => {
  const {dataItem} = props
  const field = props.field || ''
  const dataValue = dataItem ? (dataItem[field] ? dataItem[field] : '') : ''

  return (
    <td className='align-middle' style={{position: 'relative', padding: 40}}>
      <div
        style={{
          width: 40,
          height: 40,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <CircularProgressbar
          value={dataValue}
          text={`${dataValue}%`}
          styles={{
            root: {},
            path: {stroke: `rgba(255, 0, 0, 1)`},
            text: {
              fill: '#f88',
              fontSize: '32px',
              fontWeight: 'bold',
            },
          }}
        />
      </div>
    </td>
  )
}
