import React, {useState, useEffect, useCallback} from 'react'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import {ASSET_CLASSES_INITIAL, ASSET_COMPONENT_INITIAL} from '../constant/asset-constant'
import {
  IAssetClass,
  IAssetComponent,
  IAssetComponentData,
  IComponentTypeOptions,
} from '../models/asset'
import {Initial_GridSetup} from '../../projects/constant/project-default'
import {GridSetup} from '../../users/models/user-model'
import {getAssetClassesPagination} from '../api/asset-class-api'
import {GridPageChangeEvent, GridSortChangeEvent} from '@progress/kendo-react-grid'

export const AssetContext = React.createContext<any>({})

export const AssetContextProvider: React.FC = ({children}) => {
  const [componentInitialValue, setComponentInitialValue] =
    useState<IAssetComponent>(ASSET_COMPONENT_INITIAL)
  const [selectedData, setSelectedData] = useState<IAssetClass>(ASSET_CLASSES_INITIAL)

  // const [assets, setAssets] = useState<any[]>(
  //   JSON.parse(localStorage.getItem('assets') as any) || []
  // )
  const [previousAssetData, setPreviousAssetData] = useState(
    JSON.parse(localStorage.getItem('previousAssetData') as any)
  )

  const [isCancelled, setIsCancelled] = useState(
    JSON.parse(localStorage.getItem('isCancelled') as any)
  )

  const [assetClassComponents, setAssetClassComponents] = useState<IAssetComponentData[]>([])
  const [newComponent, setNewComponent] = useState(componentInitialValue)
  const [componentTypeOptions, setComponentTypeOptions] = useState<IComponentTypeOptions[]>([
    {value: 'Substructure', label: 'Substructure'},
    {value: 'Superstructure', label: 'Superstructure'},
    {value: 'Roof Cladding', label: 'Roof Cladding'},
    {value: 'Floor Coverings', label: 'Floor Coverings'},
    {value: 'Internal Walls and Ceilings', label: 'Internal Walls and Ceilings'},
    {value: 'Fittings', label: 'Fittings'},
    {value: 'Hot Water System', label: 'Hot Water System'},
    {value: 'HVAC', label: 'HVAC'},
    {value: 'Electrical Services', label: 'Electrical Services'},
    {value: 'Hydraulics Services', label: 'Hydraulics Services'},
    {value: 'Fire Services', label: 'Fire Services'},
    {value: 'Transport Services', label: 'Transport Services'},
    {value: 'External Services', label: 'External Services'},
  ])
  const [dataUpdated, setDataUpdated] = useState<boolean>(false)
  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(1)
  const [gridSetup, setGridSetup] = useState<GridSetup>(Initial_GridSetup)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [assets, setAssets] = useState<IAssetClass[]>([])
  const [totalRows, setTotalRows] = useState<number>(0)
  const [searchFilter, setSearchFilter] = useState<string>('')

  // Function to trigger data update
  const updateData = () => {
    setDataUpdated(true)
  }

  const handlePageChange = (pageProps: GridPageChangeEvent) => {
    setGridSetup({
      ...gridSetup,
      skip: pageProps.page.skip,
      take: pageProps.page.take,
    })
  }

  const handleSortChange = (pageProps: GridSortChangeEvent) => {
    setGridSetup({...gridSetup, sort: pageProps.sort})
  }

  const fetchData = useCallback(
    (filter?: string) => {
      setIsLoading(true)

      var {sort, skip, take} = gridSetup

      getAssetClassesPagination(skip, take, sort[0], filter)
        .then((response: any) => {
          setAssets(response.data.items)
          setTotalRows(response.data.totalCount)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    },
    [gridSetup]
  )

  useEffect(() => {
    fetchData(searchFilter)
  }, [gridSetup, fetchData, searchFilter])

  /**
   * TOASTR
   */
  const showToastrNotification = (message: any, type: any) => {
    toastr.options = {
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: false,
    }
    toastr.clear()
    setTimeout(() => {
      if (type === 'success') {
        toastr.success(message)
      } else if (type === 'info') {
        toastr.info(message)
      } else {
        toastr.error(message)
      }
    }, 300)
  }

  /** TEMPORARY, TO BE REMOVED when API is ready */
  useEffect(() => {
    localStorage.setItem('assets', JSON.stringify(assets))
  }, [assets])

  useEffect(() => {
    localStorage.setItem('previousAssetData', JSON.stringify(previousAssetData))
  }, [previousAssetData])

  useEffect(() => {
    localStorage.setItem('isCancelled', JSON.stringify(false))
  }, [])

  return (
    <AssetContext.Provider
      value={{
        assets,
        setAssets,
        previousAssetData,
        setPreviousAssetData,
        isCancelled,
        setIsCancelled,
        newComponent,
        setNewComponent,
        assetClassComponents,
        setAssetClassComponents,
        componentTypeOptions,
        setComponentTypeOptions,
        componentInitialValue,
        setComponentInitialValue,
        updateData,
        dataUpdated,
        showToastrNotification,
        isModalVisible,
        setModalVisible,
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
        totalPages,
        setTotalPages,
        totalCount,
        setTotalCount,
        selectedData,
        setSelectedData,
        handlePageChange,
        handleSortChange,
        isLoading,
        totalRows,
        gridSetup,
        setSearchFilter,
      }}
    >
      {children}
    </AssetContext.Provider>
  )
}
