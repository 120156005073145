import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { useDispatch } from 'react-redux';
import * as auth from '../../../app/modules/auth/redux/AuthRedux';
import MsalService from '../../modules/auth/azure/MsalService';
import { refreshToken } from '../../modules/auth/redux/AuthCRUD';
import store from '../../../setup/redux/Store';

interface IProps {
  show: boolean;
  onRequestClose: () => void;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '500px',
    width: '100%',
    padding: '50px',
    fontSize: '20px',
  },
};

const TimeoutWarningModal: React.FC<IProps> = ({ show, onRequestClose }) => {
  const { logoutAzureAccount } = MsalService();
  const { accounts } = useMsal();

  const dispatch = useDispatch();
  const onLogOffCall = () => {
    if (accounts.length > 0) {
      logoutAzureAccount();
      onRequestClose();
    } else {
      dispatch(auth.actions.logout());
      onRequestClose();
    }
  };

  const onStayLoggedIn = () => {
    const {
      auth: { user, isRememberMe },
    } = store.getState()

    if (user) {
      const rtoken = localStorage.getItem('refresh-token') || '';
      refreshToken(user.email, rtoken)
        .then(({ data: { token, name, longTermToken, refreshToken } }) => {
          localStorage.setItem('refresh-token', refreshToken)
          if (isRememberMe) {
            dispatch(auth.actions.setUser(user, longTermToken, refreshToken, isRememberMe));
            localStorage.setItem(
              'remember-me',
              JSON.stringify({
                token: token,
                userName: user.email,
                password: user.password,
                name: name,
                longTermToken: longTermToken,
              })
            )
          }
          else {
            dispatch(auth.actions.setUser(user, token, refreshToken, false));
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }


    onRequestClose();
    setStayLoggedInCounter(30);
  };

  const [counter, setCounter] = useState(30);
  const [stayLoggedInCounter, setStayLoggedInCounter] = useState(30);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [counter]);


  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (stayLoggedInCounter > 0) {
      intervalId = setInterval(() => setStayLoggedInCounter((prevCounter) => prevCounter - 1), 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [stayLoggedInCounter]);

  useEffect(() => {
    if (counter === 0) {
      onRequestClose();
      dispatch(auth.actions.logout());
    }
  }, [counter]);

  return (
    <>
      <Modal
        show={show}
        style={customStyles}
        onHide={onLogOffCall}
        dialogClassName='modal-dialog-centered mw-400px h-auto'
        aria-hidden='true'
      >
        <Modal.Header>
          <Modal.Title>Session Timeout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            You're being timed out due to inactivity. Please choose to stay
            signed in or to logoff. Otherwise, you will be logged off automatically
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-sm btn-primary' onClick={onLogOffCall}>
            Log off
          </button>
          <button className='btn btn-sm btn-primary' onClick={onStayLoggedIn}>
            Stay Logged In ({stayLoggedInCounter})
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { TimeoutWarningModal };

