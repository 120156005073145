import {Configuration, InteractionType} from '@azure/msal-browser'

const CLIENTID = process.env.REACT_APP_AZURE_CLIENTID || ''
const TENANTID = process.env.REACT_APP_AZURE_TENANTID || ''
const REDIRECT = process.env.REACT_APP_AZURE_REDIRECT || ''
const GRAPHENDPOINT = process.env.REACT_APP_AZURE_GRAPHENDPOINT || ''
const SCOPE = process.env.REACT_APP_AZURE_SCOPE || ''
const INSTANCE = process.env.REACT_APP_AZURE_INSTANCE || ''

// MSAL Configuration: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
const msalConfig: Configuration = {
  auth: {
    clientId: CLIENTID,
    authority: `${INSTANCE}/${TENANTID}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: `${REDIRECT}`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

const graphConfig = {
  scopes: ['User.Read'],
  urls: {
    getMe: `${GRAPHENDPOINT}/me`,
  },
}

const apiConfig = {
  scopes: [SCOPE],
}

const sharepointConfig = {
  scopes: ['https://81ys3y.sharepoint.com/Sites.Search.All'],
  urls: {
    search: 'https://81ys3y.sharepoint.com/_api/search/query?querytext=%27sharepoint%27&rowLimit=3',
  },
}

const loginMode = InteractionType.Popup // Redirect or Popup

const authConfig = {
  msal: msalConfig,
  graph: graphConfig,
  sharepoint: sharepointConfig,
  api: apiConfig,
  loginMode: loginMode,
}
export default authConfig
