import {ProjectForm} from './ProjectForm'
import {FC, useEffect, useState} from 'react'
import {IProjectData} from '../../models/project-model'
import React from 'react'
import {getProjectById} from '../../api'
import {AxiosResponse} from 'axios'
import {useParams} from 'react-router'

const ProjectInformationPage: FC = () => {
  const [projectData, setProjectData] = useState<IProjectData>()
  const {projectId} = useParams<{projectId: string}>()

  useEffect(() => {
    if (projectId && +projectId > 0) {
      getProjectById(+projectId).then((result: AxiosResponse<IProjectData>) => {
        setProjectData(result.data)
      })
    }
  }, [projectId])

  return (
    <React.Fragment>
      {projectData && <ProjectForm projectData={projectData}></ProjectForm>}
    </React.Fragment>
  )
}

export {ProjectInformationPage}
