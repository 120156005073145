import {IMarketApproachValuation, IMarketEvidence} from '../models/market-approach-valuation'

export const MARKET_APPROACH_FORM_DEFAULT: IMarketApproachValuation = {
  id: 0,
  projectAssetId: 0,
  salesRangeStart: 0,
  salesRangeEnd: 0,
  baseRate: 0,
  baseValue: 0,
  zoningAdjustmentReason: '',
  outerSalesRangeComment: '',
  adjustmentFactor: 0,
  mainArea: 0,
  fairValue: 0,
  selected: false,
  fairValueTotal: 0,
  marketEvidences: [
    {
      id: 0,
      projectAssetId: 0,
      salesId: 0,
      streetAddress: '111',
      saleArea: 0,
      landSize: 0,
      saleDate: null,
      salePrice: 0,
      timeAdjSalePrice: 0,
      comparison: '',
      comment: '',
    },
    /*{
      id: 0,
      projectAssetId: 0,
      saleId: 0,
      address: '222',
      saleArea: 0,
      saleLandSize: 0,
      saleDate: null,
      salePrice: 0,
      timeAdjustmentLandValue: 0,
      comparison: '',
      comment: '',
    },
    {
      id: 0,
      projectAssetId: 0,
      saleId: 0,
      address: '333',
      saleArea: 0,
      saleLandSize: 0,
      saleDate: null,
      salePrice: 0,
      timeAdjustmentLandValue: 0,
      comparison: '',
      comment: '',
    },*/
  ],
}

export const MARKET_EVIDENCE_FORM_DEFAULT: IMarketEvidence = {
  id: 0,
  projectAssetId: 0,
  salesId: 0,
  streetAddress: '',
  saleArea: 0,
  landSize: 0,
  saleDate: null,
  salePrice: 0,
  timeAdjSalePrice: 0,
  comparison: '',
  comment: '',
}
