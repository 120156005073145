import React, {useState, useEffect, useCallback} from 'react'
import {getAllOffices} from '../api'

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {IOffice} from '../models/office'
import {GridSetup} from '../../projects/models/config-default'
import {Initial_GridSetup} from '../../projects/constant/project-default'
import {GridSortChangeEvent} from '@progress/kendo-react-grid'

export const OfficeLocationsContext = React.createContext<any>({})

export const officeInitialValue: IOffice = {
  id: 0,
  name: '',
  code: '',
  address: '',
  suburb: '',
  state: '',
  postCode: '',
  status: 'Active',
}

const initialDataState = {
  skip: 0,
  take: 10,
}

export const OfficeLocationsContextProvider: React.FC = ({children}) => {
  /**OFFICES */
  const [initialValue, setInitialValue] = useState<IOffice>(officeInitialValue)
  const [dataUpdated, setDataUpdated] = useState(false)
  const [newOffice, setNewOffice] = useState(initialValue)
  const [isModalVisible, setModalVisible] = useState(false)
  const [offices, setOffices] = useState<any[]>([])
  // const [pageNumber, setPageNumber] = useState(1)
  // const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [gridSetup, setGridSetup] = useState<GridSetup>(Initial_GridSetup)
  const [searchFilter, setSearchFilter] = useState<string>('')

  const fetchAllOffices = useCallback(
    (filter: string = '') => {
      setIsLoading(true)

      var {skip, take, sort} = gridSetup

      getAllOffices(skip, take, sort[0], filter || '')
        .then((response: any) => {
          setOffices(response?.data?.items)
          setTotalPages(response?.data?.totalPages)
          setTotalCount(response?.data?.totalCount)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    },
    [gridSetup, dataUpdated]
  )

  useEffect(() => {
    fetchAllOffices(searchFilter)
  }, [searchFilter, gridSetup, dataUpdated])

  const handleSortChange = (pageProps: GridSortChangeEvent) => {
    setGridSetup({...gridSetup, sort: pageProps.sort})
  }

  const handlePageChange = (pageProps: any) => {
    setGridSetup({
      ...gridSetup,
      skip: pageProps.page.skip,
      take: pageProps.page.take,
    })
  }

  // Function to trigger data update
  const updateData = () => {
    setDataUpdated(true)
  }

  /**
   * TOASTR
   */
  const showToastrNotification = (message: any, type: any) => {
    toastr.options = {
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: false,
    }
    toastr.clear()
    setTimeout(() => {
      if (type === 'success') {
        toastr.success(message)
      } else {
        toastr.error(message)
      }
    }, 300)
  }

  return (
    <OfficeLocationsContext.Provider
      value={{
        initialValue,
        offices,
        setOffices,
        newOffice,
        setNewOffice,
        isModalVisible,
        setModalVisible,
        fetchAllOffices,
        setInitialValue,
        searchFilter,
        setSearchFilter,
        totalCount,
        totalPages,
        handlePageChange,
        handleSortChange,
        gridSetup,
        initialDataState,
        updateData,
        setTotalPages,
        setTotalCount,
        dataUpdated,
        showToastrNotification,
        isLoading,
      }}
    >
      {children}
    </OfficeLocationsContext.Provider>
  )
}
