/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Login2Fa} from './Login2Fa'
import {Login} from './Login'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
const RECAPTCHA_CLIENTKEY = process.env.REACT_APP_RECAPTCHA

export function LoginPage() {
  const [show2Fa, setShow2Fa] = useState(false)
  const [email, setEmail] = useState('')

  const show2faHandler = (email: string) => {
    setEmail(email)
    setShow2Fa(true)
  }

  const cancelHandler = () => {
    setShow2Fa(false)
  }

  return !show2Fa ? (
    <GoogleReCaptchaProvider
      reCaptchaKey={RECAPTCHA_CLIENTKEY || ''}
      container={{
        // optional to render inside custom element
        element: 'recaptcha',
        parameters: {
          // badge: 'inline', // optional, default undefined
          badge: 'bottomright',
          theme: 'light', // optional, default undefined
        },
      }}
    >
      <Login handleShow2fa={show2faHandler} />
    </GoogleReCaptchaProvider>
  ) : (
    <Login2Fa email={email} cancelHandler={cancelHandler} />
  )
}
