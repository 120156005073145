import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query"

export interface IRole {
    id?: string
    name: string,
    displayId: number
}

export interface IRoleSearch {
    search?: string
    name?: string
}

export interface GridSetup 
{
    sort: Array<SortDescriptor>
    filter: CompositeFilterDescriptor
    skip: number
    take: number
}


export enum PAGE_PERMISSION {
    ADMIN_TOOLS_CREATE = 'admintools.create',
    ADMIN_TOOLS_DELETE = 'admintools.delete',
    ADMIN_TOOLS_UPDATE = 'admintools.update',
    ADMIN_TOOLS_VIEW = 'admintools.view',
    ADJUSTMENT_MATRIX_CREATE = 'adjustmentmatrix.create',
    ADJUSTMENT_MATRIX_DELETE = 'adjustmentmatrix.delete',
    ADJUSTMENT_MATRIX_UPDATE = 'adjustmentmatrix.update',
    ADJUSTMENT_MATRIX_VIEW = 'adjustmentmatrix.view',
    ASSETS_DETAILS_CREATE = 'assetsdetails.create',
    ASSETS_DETAILS_DELETE = 'assetsdetails.delete',
    ASSETS_DETAILS_UPDATE = 'assetsdetails.update',
    ASSETS_DETAILS_VIEW = 'assetsdetails.view',
    ASSETS_PAGE_CREATE = 'assetspage.create',
    ASSETS_PAGE_DELETE = 'assetspage.delete',
    ASSETS_PAGE_UPDATE = 'assetspage.update',
    ASSETS_PAGE_VIEW = 'assetspage.view',
    ASSET_CLASSIFICATION_CREATE = 'assetclassification.create',
    ASSET_CLASSIFICATION_DELETE = 'assetclassification.delete',
    ASSET_CLASSIFICATION_UPDATE = 'assetclassification.update',
    ASSET_CLASSIFICATION_VIEW = 'assetclassification.view',
    CLIENTS_CREATE = 'clients.create',
    CLIENTS_DELETE = 'clients.delete',
    CLIENTS_UPDATE = 'clients.update',
    CLIENTS_VIEW = 'clients.view',
    DASHBOARD_CREATE = 'dashboard.create',
    DASHBOARD_DELETE = 'dashboard.delete',
    DASHBOARD_UPDATE = 'dashboard.update',
    DASHBOARD_VIEW = 'dashboard.view',
    OFFICE_LOCATIONS_CREATE = 'officelocations.create',
    OFFICE_LOCATIONS_DELETE = 'officelocations.delete',
    OFFICE_LOCATIONS_UPDATE = 'officelocations.update',
    OFFICE_LOCATIONS_VIEW = 'officelocations.view',
    PROJECT_CREATE = 'project.create',
    PROJECT_DELETE = 'project.delete',
    PROJECT_UPDATE = 'project.update',
    PROJECT_VIEW = 'project.view',
    PROJECT_DETAILS_CREATE = 'projectdetails.create',
    PROJECT_DETAILS_DELETE = 'projectdetails.delete',
    PROJECT_DETAILS_UPDATE = 'projectdetails.update',
    PROJECT_DETAILS_VIEW = 'projectdetails.view',
    RATES_TABLE_CREATE = 'ratestable.create',
    RATES_TABLE_DELETE = 'ratestable.delete',
    RATES_TABLE_UPDATE = 'ratestable.update',
    RATES_TABLE_VIEW = 'ratestable.view',
    REPORTS_CREATE = 'reports.create',
    REPORTS_DELETE = 'reports.delete',
    REPORTS_UPDATE = 'reports.update',
    REPORTS_VIEW = 'reports.view',
    USER_MANAGEMENT_CREATE = 'usermanagement.create',
    USER_MANAGEMENT_DELETE = 'usermanagement.delete',
    USER_MANAGEMENT_UPDATE = 'usermanagement.update',
    USER_MANAGEMENT_VIEW = 'usermanagement.view'
}



export type Page = {
    name: string,
    enabled: boolean,
    id: number
}