import {ResetPassword} from './ResetPassword'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import React from 'react'
const RECAPTCHA_CLIENTKEY = process.env.REACT_APP_RECAPTCHA

export function ResetPasswordPage() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={RECAPTCHA_CLIENTKEY || ''}
      container={{
        // optional to render inside custom element
        element: 'recaptcha',
        parameters: {
          // badge: 'inline', // optional, default undefined
          badge: 'bottomright',
          theme: 'light', // optional, default undefined
        },
      }}
    >
      <ResetPassword />
    </GoogleReCaptchaProvider>
  )
}
