import {NumericTextBox} from '@progress/kendo-react-inputs'
import {FC, useEffect, useState} from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import {IAssetComponentized} from '../../../../../models/project-asset/componentized'
import SectionTitleHeader from '../../../../common/SectionTitleHeader'
import {CurrencyFormatter} from '../../../../../../../shared/service/utils'
import {useEffectOnce} from 'react-use'

type IFairValueProps = {
  control: Control<IAssetComponentized>
  isValid: boolean
  watch: UseFormWatch<IAssetComponentized>
  setValue: UseFormSetValue<IAssetComponentized>
  getValues: UseFormGetValues<IAssetComponentized>
  errors: FieldErrors<IAssetComponentized>
  register: UseFormRegister<IAssetComponentized>
  currentIndex: number
}
const FairValueForm: FC<IFairValueProps> = ({
  register,
  watch,
  control,
  errors,
  setValue,
  getValues,
  currentIndex,
}) => {
  const [usableLife, setUsableLife] = useState<number>(0)
  const [remainingServicePotentialScore, setRemainingServicePotentialScore] = useState<number>(0)
  const [remainingServicePotential, setReplacementCost] = useState<number>(0)
  const [replacementCost, setRemainingServicePotential] = useState<number>(0)

  useEffectOnce(() => {
    const {remainingServicePotentialScore, remainingServicePotential, replacementCost, usableLife} =
      getValues(`componentized.${currentIndex}`)
    setUsableLife(usableLife)
    setRemainingServicePotentialScore(remainingServicePotentialScore)
    setRemainingServicePotential(remainingServicePotential)
    setReplacementCost(replacementCost)
  })

  useEffect(() => {
    computeRemainingServicePotential()
    computeFairValue()
  }, [usableLife, remainingServicePotentialScore])

  useEffect(() => {
    computeFairValue()
  }, [usableLife, replacementCost, remainingServicePotential])

  const computeRemainingServicePotential = () => {
    const RSPScore = parseFloat(
      getValues(`componentized.${currentIndex}.remainingServicePotentialScore`).toFixed(2)
    )
    const usableLife = parseFloat(getValues(`componentized.${currentIndex}.usableLife`).toFixed(2))

    const remainingServicePotential = ((10 - RSPScore) / 10) * usableLife
    setValue(`componentized.${currentIndex}.remainingServicePotential`, remainingServicePotential)
  }

  const computeFairValue = () => {
    let replacementCost =
      getValues(`componentized.${currentIndex}.replacementCost`) === undefined
        ? 0
        : parseFloat(getValues(`componentized.${currentIndex}.replacementCost`).toFixed(2))
    let usableLife = parseFloat(getValues(`componentized.${currentIndex}.usableLife`).toFixed(2))
    let remainingServicePotential = parseFloat(
      getValues(`componentized.${currentIndex}.remainingServicePotential`).toFixed(2)
    )

    const fairValue = (replacementCost / usableLife) * remainingServicePotential
    setValue(`componentized.${currentIndex}.fairValue`, fairValue)
  }

  return (
    <>
      <div className='d-flex justify-content-between'>
        <SectionTitleHeader title='Fair Value' showButtons={false} />
      </div>
      <div id='fairValueSection'>
        <div id='fairValueForm'>
          <div className='row h-auto mt-5'>
            <div className='col-3'>
              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='usableLife'>
                  Usable Life
                </label>
                <div>
                  <Controller
                    control={control}
                    name={`componentized.${currentIndex}.usableLife`}
                    render={({field: {onChange, value}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${
                          errors.componentized && errors.componentized[currentIndex]?.usableLife
                            ? ' border-danger'
                            : ''
                        }`}
                        defaultValue={value}
                        value={value}
                        format='n2'
                        min={0}
                        max={10}
                        step={0.01}
                        onChange={onChange}
                        onBlur={(event) => {
                          if (event.target.value) {
                            setUsableLife(event.target.value)
                          }
                        }}
                      />
                    )}
                  />
                  {errors.componentized && errors.componentized[currentIndex]?.usableLife && (
                    <div>
                      <small className='text-danger'>
                        {errors.componentized[currentIndex]?.usableLife?.message}
                      </small>
                    </div>
                  )}
                </div>
              </div>

              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='physicalConditionScore'>
                  Physical Condition Score
                </label>
                <div>
                  <Controller
                    control={control}
                    name={`componentized.${currentIndex}.physicalConditionScore`}
                    render={({field: {onChange, value}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${
                          errors.componentized &&
                          errors.componentized[currentIndex]?.physicalConditionScore
                            ? ' border-danger'
                            : ''
                        }`}
                        defaultValue={value}
                        value={value}
                        format='n2'
                        min={0}
                        max={10}
                        step={0.01}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.componentized &&
                    errors.componentized[currentIndex]?.physicalConditionScore && (
                      <div>
                        <small className='text-danger'>
                          {errors.componentized[currentIndex]?.physicalConditionScore?.message}
                        </small>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className='col-3'>
              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='remainingServicePotentialScore'>
                  Remaining Service Potential Score
                </label>
                <div>
                  <Controller
                    control={control}
                    name={`componentized.${currentIndex}.remainingServicePotentialScore`}
                    render={({field: {onChange, value}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${
                          errors.componentized &&
                          errors.componentized[currentIndex]?.remainingServicePotentialScore
                            ? ' border-danger'
                            : ''
                        }`}
                        defaultValue={value}
                        value={value}
                        format='n2'
                        min={0}
                        max={10}
                        step={0.01}
                        onChange={onChange}
                        onBlur={(event) => {
                          if (event.target.value) {
                            setRemainingServicePotentialScore(event.target.value)
                          }
                        }}
                      />
                    )}
                  />
                  {errors.componentized &&
                    errors.componentized[currentIndex]?.remainingServicePotentialScore && (
                      <div>
                        <small className='text-danger'>
                          {
                            errors.componentized[currentIndex]?.remainingServicePotentialScore
                              ?.message
                          }
                        </small>
                      </div>
                    )}
                </div>
              </div>

              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='remainingServicePotential'>
                  Remaining Service Potential
                </label>
                <div>
                  <Controller
                    control={control}
                    name={`componentized.${currentIndex}.remainingServicePotential`}
                    render={({field: {onChange, value}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${
                          errors.componentized &&
                          errors.componentized[currentIndex]?.remainingServicePotentialScore
                            ? ' border-danger'
                            : ''
                        }`}
                        defaultValue={value}
                        value={value}
                        format='n2'
                        min={0}
                        max={10}
                        step={0.01}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className='col-4'>
              <h4>Fair Value</h4>
              <div className={`card bg-dark`}>
                <div className='card-body'>
                  <div
                    className={`fw-bolder text-inverse-dark fs-1 text-right`}
                    style={{textAlign: 'center'}}
                  >
                    {CurrencyFormatter(
                      +getValues(`componentized.${currentIndex}.fairValue`).toFixed(2)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default FairValueForm
