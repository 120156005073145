import {useContext, useEffect, useRef, useState} from 'react'
import {ProjectAssetData} from '../../../models/project-assets'
import {Grid, GridColumn as Column, GridSortChangeEvent} from '@progress/kendo-react-grid'
import {IMarketEvidenceLookup} from '../../../models/market-evidence-lookup'
import {
  createMarketEvidenceLookups,
  getMarketEvidenceLookupsPagination,
} from '../../../api/market-evidence'
import {GridSetup} from '../../../models/config-default'
import {Initial_GridSetup} from '../../../constant/project-default'
import {AxiosResponse} from 'axios'
import Button from '../../../../../../_metronic/layout/components/buttons/Button'
import {AmountCell} from '../../kendo/format/AmountCell'
import {ProjectContext} from '../../../context/ProjectContext'
import {AccessControlProvider} from '../../../../../../_metronic/layout/core/AccessControlProvider'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'

const initialSort: {field: string; dir: string}[] = [{field: 'id', dir: 'desc'}]

type IMarketEvidenceTabTabProps = {
  assetData: ProjectAssetData
}

function MarketEvidenceTab(props: IMarketEvidenceTabTabProps) {
  const {assetData} = props
  const {showToastrNotification} = useContext(ProjectContext)
  const [gridSetup, setGridSetup] = useState<GridSetup>(Initial_GridSetup)
  const [marketEvidenceLookups, setMarketEvidenceLookup] = useState<IMarketEvidenceLookup[]>([])
  const [totalRows, setTotalRows] = useState<number>(0)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const [searchText, setSearchText] = useState<string>('')
  const [searchFilter, setSearchFilter] = useState<string>('')

  const handlePageChange = (pageProps: any) => {
    setGridSetup({
      ...gridSetup,
      skip: pageProps.page.skip,
      take: pageProps.page.take,
    })
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      setSearchFilter(searchText)
      event.preventDefault()
    }
  }

  const handleSortChange = (pageProps: GridSortChangeEvent) => {
    setGridSetup({...gridSetup, sort: pageProps.sort})
  }

  useEffect(() => {
    fetchMarketEvidenceLookups(searchFilter)
  }, [gridSetup, searchFilter])

  const uploadMarketEvidence = (values: IMarketEvidenceLookup[]) => {
    createMarketEvidenceLookups(assetData.id, values)
      .then((result) => {
        fetchMarketEvidenceLookups()
        showToastrNotification('Market Evidence Uploaded succesfully', 'success')
      })
      .catch((error) => {
        showToastrNotification(
          'Invalid CSV File for Market Evidence, Please import another file.',
          'error'
        )
      })
  }

  const fetchMarketEvidenceLookups = (filter?: string) => {
    var {sort, skip, take} = gridSetup

    getMarketEvidenceLookupsPagination(assetData.id, skip, take, sort[0], filter).then(
      (response: AxiosResponse<any>) => {
        if (response) {
          setMarketEvidenceLookup(response.data.items)
          setTotalRows(response.data.totalCount)
        }
      }
    )
  }

  const uploadImages = (fileEvent: React.ChangeEvent<HTMLInputElement>) => {
    const files = fileEvent.target.files
    if (files && files.length > 0) {
      const file = files[0]
      if (file.type === 'text/csv') {
        const reader = new FileReader()
        reader.onload = (event) => {
          if (event.target && event.target.result) {
            const content = event.target.result as string
            const lines = content.split('\n')
            //const header = lines[0].split(',')
            const data = lines.slice(1).map((line) => line.split(','))

            let newMarketEvidenceLookup: IMarketEvidenceLookup[] = []
            let rowCounter = 0
            let csvErrorRows = ''

            data.forEach((item) => {
              rowCounter++
              if (item.length >= 7) {
                const csvSaleId = item[0] !== undefined ? +item[0] : 0
                const csvStreetAddress = item[1] !== undefined ? item[1].toString() : ''
                const csvSuburb = item[2] !== undefined ? item[2].toString() : ''
                const csvLandSize = item[3] !== undefined ? +item[3] : 0 // Corrected syntax and variable name
                const csvSalePrice = item[4] !== undefined ? +item[4] : 0
                const saleDate = new Date(item[5])
                const csvSaleDate = item[5] !== undefined ? saleDate : new Date()
                const csvTimeAdjSalePrice = item[6] !== undefined ? +item[6] : 0

                if (
                  csvSaleId === 0 ||
                  csvStreetAddress === '' ||
                  csvSuburb === '' ||
                  csvLandSize === 0 ||
                  csvSalePrice === 0 ||
                  csvTimeAdjSalePrice === 0 ||
                  saleDate.toString() === 'Invalid Date'
                ) {
                  csvErrorRows += rowCounter + ' '
                } else {
                  newMarketEvidenceLookup.push({
                    salesId: csvSaleId,
                    streetAddress: csvStreetAddress,
                    suburb: csvSuburb,
                    landSize: csvLandSize,
                    salePrice: csvSalePrice,
                    saleDate: csvSaleDate,
                    timeAdjSalePrice: csvTimeAdjSalePrice,
                  })
                }
              }
            })

            if (csvErrorRows !== '') {
              showToastrNotification(
                'Invalid CSV File for Market Evidence. Please import another file.\n Check row(s) ' +
                  csvErrorRows.trim(),
                'error'
              )
            } else if (newMarketEvidenceLookup.length > 0) {
              uploadMarketEvidence(newMarketEvidenceLookup)
            }
          }
        }
        reader.readAsText(file)
      } else {
        console.error('Uploaded file is not a CSV.')
      }
    }
  }

  const onFileUpload = () => {
    if (fileInputRef.current) fileInputRef.current.click()
  }

  return (
    <>
      <div
        style={{
          backgroundColor: '#fff',
          height: 'auto',
          padding: 26,
          boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px',
        }}
      >
        <div className='d-flex align-items-center justify-content-between mb-5'>
          <div style={{position: 'relative'}}>
            <input
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
              onKeyDown={handleKeyPress}
              type='text'
              placeholder='Search Sales'
              style={{
                minWidth: 400,
                minHeight: 35,
                borderRadius: 17.5,
                border: '1px solid #E5E4E2',
                paddingLeft: 30,
                outline: 'none',
                fontStyle: 'italic',
              }}
            />
            <i
              className='bi bi-search'
              style={{position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)'}}
            ></i>
          </div>
          <div className='d-flex align-items-center'>
            <input
              type='file'
              accept='.csv'
              onChange={uploadImages}
              ref={fileInputRef}
              onClick={(event: any) => {
                event.target.value = null
              }}
              hidden
            />
            <AccessControlProvider allowedPermission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}>
              <Button
                text={'Attach File'}
                buttonType='primary'
                onClick={(e) => {
                  e.preventDefault()
                  onFileUpload()
                }}
              />
            </AccessControlProvider>
          </div>
        </div>
        <Grid
          id='market-evidence-table'
          data={marketEvidenceLookups}
          pageable={true}
          resizable={true}
          total={totalRows}
          onPageChange={handlePageChange}
          sortable={true}
          onSortChange={handleSortChange}
          {...gridSetup}
        >
          <Column field='salesId' title='Sales ID' />
          <Column field='streetAddress' title='Street Address' />
          <Column field='suburb' title='Suburb' />
          <Column field='landSize' title='Land Size' />
          <Column field='salePrice' title='Sale Price' cell={AmountCell} />
          <Column
            field='saleDate'
            title='Sale Date'
            cell={(props) => {
              return (
                <td className='align-middle text-left'>
                  {new Date(props.dataItem.saleDate).toDateString()}
                </td>
              )
            }}
          />
          <Column field='timeAdjSalePrice' title='Time Adjustment Sale Price' cell={AmountCell} />
        </Grid>
      </div>
    </>
  )
}

export default MarketEvidenceTab
