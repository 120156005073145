import React from 'react'
import {DropDownButton} from '@progress/kendo-react-buttons'
import './ActionButton.css'

interface ActionButtonProps {
  className?: string
  svgIcon?: any
  text?: string
  items?: any[]
  onItemClick: any
  width?: string
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  className = 'buttons-container-button',
  svgIcon,
  text = 'Actions',
  items,
  onItemClick,
  width,
}) => {
  const buttonStyle = {
    width: width, // Set your desired width here
  }

  return width ? (
    <DropDownButton
      style={buttonStyle}
      className={className}
      svgIcon={svgIcon}
      text={text}
      items={items}
      onItemClick={onItemClick}
    />
  ) : (
    <DropDownButton
      className={className}
      svgIcon={svgIcon}
      text={text}
      items={items}
      onItemClick={onItemClick}
    />
  )
}
