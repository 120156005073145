import axios from "axios"
import { IAssetClass } from "../models/asset"
import { SortDescriptor } from "@progress/kendo-data-query"

export const ASSETCLASS_URL = `${process.env.REACT_APP_API_URL}/assetclass`

export const getAssetClassesPagination = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  filter?: string
) => {
  return await axios.get(`${ASSETCLASS_URL}/Paginated`, {
     params: {
      skip,
      take,
      sort:  pageSort ? `${pageSort.field} ${pageSort.dir}` : '',
      filter
    }
  })
}

export const createAssetClass = async (payload: IAssetClass) => {
  return await axios.post(`${ASSETCLASS_URL}`, payload)
}

export const updateAssetClass = async (payload: IAssetClass) => {
  return await axios.put(`${ASSETCLASS_URL}`, payload)
}

export const deleteAssetClass = async (id: number) => {
  return await axios.delete(`${ASSETCLASS_URL}?id=${id}`)
}