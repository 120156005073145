import {FC, useContext} from 'react'
import './../../../ui/Project.scss'
import {CustomFields} from './CustomFields'
import {
  FieldArrayWithId,
  UseFieldArrayReplace,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form'
import {IOption, IProjectData} from '../../../../models/project-model'
import {ProjectContext} from '../../../../context/ProjectContext'
import {AccessControlProvider} from '../../../../../../../_metronic/layout/core/AccessControlProvider'
import {PAGE_PERMISSION} from '../../../../../roles/models/role-model'
import {hasPermission} from '../../../../../../shared/service/utils'
import {usePageData} from '../../../../../../../_metronic/layout/core'

type IProps = {
  projectCustomFields: FieldArrayWithId<IProjectData, 'projectCustomFields', 'keyIdx'>[]
  replaceProjectCustomFields: UseFieldArrayReplace<IProjectData, 'projectCustomFields'>
  register: UseFormRegister<any>
  handleSubmit: UseFormHandleSubmit<any>
  onSubmitHandler: any
}

const ProjectSettings: FC<IProps> = ({
  projectCustomFields,
  replaceProjectCustomFields,
  register,
  handleSubmit,
  onSubmitHandler,
}) => {
  const {evaluationRegionOptions} = useContext(ProjectContext)
  const {
    currentUser: {activePages},
  } = usePageData()

  return (
    <form className='form' onSubmit={handleSubmit(onSubmitHandler)}>
      <div className='d-flex justify-content-between'>
        <h1>Project Settings</h1>
        <AccessControlProvider allowedPermission={PAGE_PERMISSION.PROJECT_DETAILS_UPDATE}>
          <button type='submit' className='btn btn-primary'>
            Save Changes
          </button>
        </AccessControlProvider>
      </div>
      <div className='row mt-3'>
        <div className='col-md-3'>
          <label className='form-label'>Valuation Region</label>

          <div className='text-muted'>which region as the base rates to use</div>
        </div>
        <div className='col-md-4'>
          <select
            style={{
              fontSize: '1rem',
            }}
            id='valuationRegion'
            className='form-select'
            disabled={!hasPermission(PAGE_PERMISSION.PROJECT_DETAILS_UPDATE, activePages)}
            {...register('valuationRegion')}
          >
            <option value={0}>Select Region</option>
            {evaluationRegionOptions.map((region: IOption, i: number) => (
              <option key={i} value={region.value}>
                {region.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-md-3'>
          <label className='form-label'>Professional Fee</label>

          <div className='text-muted'>
            This will indicate if the Professional Fee will be applied on the Valuation
          </div>
        </div>
        <div className='col-md-4'>
          <div className='d-flex flex-column bd-highlight mb-3'>
            <div className='p-2'>
              <input
                className='form-check-input'
                type='checkbox'
                {...register('fairValueValuation')}
                disabled={!hasPermission(PAGE_PERMISSION.PROJECT_DETAILS_UPDATE, activePages)}
              />{' '}
              Fair Value Valuation
            </div>
            <div className='p-2'>
              <input
                className='form-check-input'
                type='checkbox'
                {...register('insuranceValuation')}
                disabled={!hasPermission(PAGE_PERMISSION.PROJECT_DETAILS_UPDATE, activePages)}
              />{' '}
              Insurance Valuation
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col'>
          <h4>Custom Fields</h4>
          <CustomFields
            projectCustomFields={projectCustomFields}
            replaceProjectCustomFields={replaceProjectCustomFields}
          ></CustomFields>
        </div>
      </div>
    </form>
  )
}

export {ProjectSettings}
