import React, {useState, useEffect} from 'react'
import {IClient} from '../models/client'

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {getAllClients, getOffices} from '../../clients-list/api'
import {CLIENT_INITIAL} from '../constant/client-constant'
import {GridSetup} from '../../user-management/models/user-model'
import {Initial_GridSetup} from '../../user-management/constant/user-default'
import {useLayout} from '../../../../_metronic/layout/core'

export const ClientContext = React.createContext<any>({})

export const ClientContextProvider: React.FC = ({children}) => {
  const {selectedOffice} = useLayout()

  /**CLIENTS */
  const [initialValue, setInitialValue] = useState<IClient>(CLIENT_INITIAL)
  const [dataUpdated, setDataUpdated] = useState(false)
  const [clients, setClients] = useState<any[]>([])
  const [gridSetup, setGridSetup] = useState<GridSetup>(Initial_GridSetup)
  const [searchFilter, setSearchFilter] = useState<string>('')

  const [newClient, setNewClient] = useState(initialValue)
  const [isModalVisible, setModalVisible] = useState(false)
  const [offices, setOffices] = useState<any[]>()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(1)

  const fetchAllClients = async (search: string = '', officeId: number) => {
    fetchAllClientsApi(search, selectedOffice.id)
  }

  const fetchAllClientsApi = async (search: string = '', officeId: number) => {
    var {sort, skip, take} = gridSetup
    setIsLoading(true)

    const response = await getAllClients(skip, take, sort[0], search || '', officeId)
    setClients([...response?.data?.items])
    setTotalPages(response?.data?.totalPages)
    setTotalCount(response?.data?.totalCount)
    setDataUpdated(false)
    setIsLoading(false)
  }

  const fetchAllOffices = async () => {
    const response = await getOffices()
    setOffices(response?.data)
  }

  useEffect(() => {
    fetchAllClients('', selectedOffice.id)
  }, [gridSetup, selectedOffice.id])

  const handlePageChange = (pageProps: any) => {
    setGridSetup({
      ...gridSetup,
      skip: pageProps.page.skip,
      take: pageProps.page.take,
    })
  }

  useEffect(() => {
    fetchAllClients(searchFilter, selectedOffice.id)
  }, [searchFilter])

  // Function to trigger data update
  const updateData = () => {
    setDataUpdated(true)
  }

  /**
   * TOASTR
   */
  const showToastrNotification = (message: any, type: any) => {
    toastr.options = {
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: false,
    }
    toastr.clear()
    setTimeout(() => {
      if (type === 'success') {
        toastr.success(message)
      } else {
        toastr.error(message)
      }
    }, 300)
  }

  return (
    <ClientContext.Provider
      value={{
        initialValue,
        clients,
        setClients,
        newClient,
        setNewClient,
        isModalVisible,
        setModalVisible,
        fetchAllOffices,
        offices,
        setInitialValue,
        totalCount,
        totalPages,
        handlePageChange,
        fetchAllClients,
        updateData,
        setTotalPages,
        setTotalCount,
        dataUpdated,
        showToastrNotification,
        setSearchFilter,
        gridSetup,
        setGridSetup,
        isLoading,
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}
