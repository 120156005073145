import React, {FC, useEffect, useState} from 'react'
import {usePageData} from './PageData'

const AccessControlProvider: FC<{allowedPermission?: string}> = ({children, allowedPermission}) => {
  const {currentUser} = usePageData()
  const [isEnabled, setIsEnabled] = useState<boolean>(false)

  useEffect(() => {
    // If null allowed permission force allow to access
    if (!allowedPermission) {
      setIsEnabled(true)
    }

    if (currentUser.activePages.some((page) => page === allowedPermission)) {
      setIsEnabled(true)
    }
  }, [allowedPermission])

  return isEnabled ? <>{children}</> : null
}

export {AccessControlProvider}
