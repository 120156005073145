/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login2Fa} from '../redux/AuthCRUD'

const loginSchema = Yup.object().shape({
  email: Yup.string(),
  code: Yup.string(),
})

const initialValues = {
  email: '',
  code: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

interface IProps {
  email: string
  cancelHandler: () => void
}

const Login2Fa: React.FC<IProps> = ({email, cancelHandler}) => {
  //export function Login2Fa() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  initialValues.email = email

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      console.log(values.email, values.code)
      setTimeout(() => {
        login2Fa(values.email, values.code)
          .then(({data: {token, twoFactorEnabled, refreshToken}}) => {
            setLoading(false)
            dispatch(auth.actions.login(token, refreshToken, false))
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
            setSubmitting(false)
            setStatus('The verification code is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Authenticate your account</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          Please confirm your account by entering your authorization code sent to email "{email}"
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Code</label>
        <input
          placeholder='Code'
          {...formik.getFieldProps('code')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.code && formik.errors.code},
            {
              'is-valid': formik.touched.code && !formik.errors.code,
            }
          )}
          type='text'
          name='code'
          autoComplete='off'
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.code}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <button type='button' className='btn btn-outline-primary w-100 ' onClick={cancelHandler}>
          Cancel
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}

export {Login2Fa}
