import React, {useContext} from 'react'
import InfoCard from './InfoCard'
import {ProjectListContext} from '../context/ProjectListContext'

function InfoCards(props: any) {
  const {infoCards, isLoadingInfoCard} = useContext(ProjectListContext)


  return (
    <div
      className='d-flex flex-row align-items-center px-6 py-6'
      style={{background: '#fff', boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px'}}
    >
      <InfoCard label='All Projects' content={infoCards?.allProjects} isLoadingInfoCard={isLoadingInfoCard}/>
      <div className='me-7' />
      <InfoCard label='Open Projects' content={infoCards?.openProjects} isLoadingInfoCard={isLoadingInfoCard}/>
      <div className='me-7' />
      <InfoCard label='Closed Projects' content={infoCards?.closedProjects} isLoadingInfoCard={isLoadingInfoCard}/>
      <div className='me-7' />
      <InfoCard label='Project Total Assets' content={infoCards?.projectTotalAsset} isLoadingInfoCard={isLoadingInfoCard}/>
    </div>
  )
}

export default InfoCards
