const Loader1 = () => {
  return (
    <div className='d-flex alig-items-center justify-content-center'>
      <div className='spinner-border' role='status' style={{color: '#a71304'}}>
        <span className='sr-only'>Logging In...</span>
      </div>
    </div>
  )
}
export {Loader1}
