import {IProjectData} from '../../models/project-model'
import {FC, useContext, useEffect} from 'react'
import {ProjectProfile} from './profile/ProjectProfile'
import React from 'react'
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'

import {ProjectSettings} from './tabs/settings/ProjectSettings'
import {useFieldArray, useForm} from 'react-hook-form'
import {updateProjectSettings} from '../../api'
import {ProjectContext} from '../../context/ProjectContext'
import {ProjectAssets} from './tabs/asset/component/ProjectAssets/ProjectAssets'
import {ReportPage} from './tabs/reports/ReportPage'
import {PAGE_PERMISSION} from '../../../roles/models/role-model'
import {usePageData} from '../../../../../_metronic/layout/core'
import {useSelector, shallowEqual} from 'react-redux'
import {UserModel} from '../../../auth/models/UserModel'
import {RootState} from '../../../../../setup'
import {DEFAULT_GROUPS} from '../../../users/constant/user-default'

interface IProps {
  projectData?: IProjectData
}

const ProjectForm: FC<IProps> = ({projectData}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {showToastrNotification, updateData} = useContext(ProjectContext)
  const {
    currentUser: {activePages},
  } = usePageData()
  const [selected, setSelected] = React.useState<number>(0)
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false)
  const [isProjectManager, setIsProjectManager] = React.useState<boolean>(false)
  const handleSelect = (e: any) => {
    setSelected(e.selected)
  }
  const {register, control, handleSubmit, reset} = useForm({
    defaultValues: projectData,
    reValidateMode: 'onSubmit',
  })

  const {fields: projectCustomFields, replace: replaceProjectCustomFields} = useFieldArray({
    control,
    name: 'projectCustomFields',
    keyName: 'keyIdx',
  })

  useEffect(() => {
    setIsAdmin(
      user?.userGroups.includes(DEFAULT_GROUPS.superAdministrators) ||
        user?.userGroups.includes(DEFAULT_GROUPS.officeAdministrators)
    )

    const userObject = projectData?.projectUsers.find((obj) => obj.userId === user.id)
    if (
      user?.userGroups.includes(DEFAULT_GROUPS.projectManagers) &&
      userObject &&
      userObject.projectUserRole === 'Project Manager'
    )
      setIsProjectManager(true)

    reset({...projectData})
  }, [projectData])

  const onSubmitHandler = (formValue: IProjectData) => {
    updateProjectSettings(formValue)
      .then((result) => {
        showToastrNotification('Saved successfully', 'success')
        replaceProjectCustomFields(result.data)
        updateData()
      })
      .catch((error) => {
        showToastrNotification('Error while saving', 'danger')
      })
  }

  const hasPermissions = (pagePermission: PAGE_PERMISSION[]) => {
    const allowed = activePages.some((page) => pagePermission.includes(page as PAGE_PERMISSION))

    return allowed
  }

  return (
    <>
      <div className='row gy-5 g-xl-8' style={{height: 'auto', minHeight: '100vh'}}>
        <div className='col-xxl-2'>
          <div className='card card-xl-stretch p-6'>
            {projectData && <ProjectProfile projectData={projectData}></ProjectProfile>}
          </div>
        </div>
        <div className='col-xxl-10'>
          <TabStrip selected={selected} onSelect={handleSelect}>
            {hasPermissions([PAGE_PERMISSION.ASSETS_PAGE_VIEW]) && (
              <TabStripTab title='Asset List'>
                <ProjectAssets projectData={projectData} />
              </TabStripTab>
            )}
            {hasPermissions([PAGE_PERMISSION.REPORTS_VIEW]) &&
              (isAdmin || (!isAdmin && isProjectManager)) && (
                <TabStripTab title='Reports'>
                  <ReportPage projectData={projectData}></ReportPage>
                </TabStripTab>
              )}
            {hasPermissions([PAGE_PERMISSION.PROJECT_DETAILS_VIEW]) && (
              <TabStripTab title='Project Settings'>
                <ProjectSettings
                  projectCustomFields={projectCustomFields}
                  replaceProjectCustomFields={replaceProjectCustomFields}
                  register={register}
                  handleSubmit={handleSubmit}
                  onSubmitHandler={onSubmitHandler}
                />
              </TabStripTab>
            )}
          </TabStrip>
        </div>
      </div>
    </>
  )
}

export {ProjectForm}
