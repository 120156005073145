import {FC} from 'react'
import {UseFormRegister} from 'react-hook-form'
import {IAssetInsuranceValuation} from '../../../../../../models/project-asset/insurance-valuation'

type SecondaryAreaFieldProps = {
  register: UseFormRegister<IAssetInsuranceValuation>
  dataIndex: number
}

const SecondaryAreaField: FC<SecondaryAreaFieldProps> = ({register, dataIndex}) => {
  return (
    <td key={dataIndex}>
      <input
        type='number'
        className='form-control'
        {...register(`componentized.${dataIndex}.secondaryArea`)}
      />
    </td>
  )
}

export {SecondaryAreaField}
