import {ISingleLineValutaion} from '../models/single-line-valuation'

export const SINGLELINE_FORM_DEFAULT: ISingleLineValutaion = {
  id: 0,
  projectAssetId: 0,
  rcUnitRate: 0,
  rcSecondaryRate: 0,
  replacementCost: 0,
  adoptedBaseRate: '',
  locationBase: '',
  timeAdjustToPeriod: '',
  mainAreaBaseRate: 1,
  secondaryAdjustmentFactor: 1,
  secondaryAdjustmentFactorRate: 1,
  locationPremium: 1,
  locationIndexRate: 1,
  timeIndex: 1,
  timeIndexRate: 1,
  constructionDifficultyFactor: 1,
  constructionDifficultyRate: 1,
  additionalValuerAdjustmentFactor: 1,
  additionalValuerRate: 1,
  additionalCostsAdjustmentFactor: 1,
  additionalCostsRate: 1,
  professionalFee: 1,
  componentBreakdown: 1,
  selected: false,
  mainArea: 0,
  secondaryArea: 0,
  usableLife: 0,
  physicalConditionScore: 0,
  remainingServicePotentialScore: 0,
  remainingServicePotential: 0,
  fairValue: 0,
  registerReplacementCost: 0,
  replacementCostVariance: 0,
  priorRevalReplacementCost: 0,
  varianceOnGfa: 0,
  fairValueTotal: 0,
}
