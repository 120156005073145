import React, {useContext, useEffect, useState} from 'react'
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'
import AssetProfile from './profile/AssetProfile'
import {ProjectAssetData} from '../../models/project-assets'
import {useParams} from 'react-router'
import {PROJECTASSET_FORM_DEFAULT} from '../../constant/project-asset-default'
import {getProjectAssetById} from '../../api'
import AssetInformationTab from './tabs/AssetInformationTab'
import ComponentValuationTab from './tabs/ComponentValuationTab'
import SingleLineValuationTab from './tabs/SingleLineValuationTab'
import InsuranceValuationTab from './tabs/InsuranceValuationTab'
import SingleLineInsuranceValuationTab from './tabs/SingleLineInsuranceValuationTab'
import {getSingleLineValuationByProjectAssetId} from '../../api/asset-api'
import {ProjectContext} from '../../context/ProjectContext'
import MarketApproachValuationTab from './tabs/MarketApproachValuationTab'
import MarketEvidenceTab from './tabs/MarketEvidenceTab'

type IAssetInformationPageProps = {}

function AssetInformationPage(props: IAssetInformationPageProps) {
  const {assetId} = useParams<{assetId: string}>()
  const {singleLineData, setSingleLineData, setSingleLineReplacementCost} =
    useContext(ProjectContext)
  const [assetData, setAssetData] = useState<ProjectAssetData>({
    ...PROJECTASSET_FORM_DEFAULT,
  })
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    if (assetId && +assetId > 0) {
      getProjectAssetById(+assetId).then((result: any) => {
        setAssetData(result.data)
        if (result.data.valuationType === 'Single Line') {
          getSingleLineValuationByProjectAssetId(result.data.id).then((result: any) => {
            setSingleLineData(result.data)
            setSingleLineReplacementCost(result.data.replacementCost)
          })
        }
      })
    }
  }, [assetId])

  const [selected, setSelected] = React.useState<number>(0)
  const handleSelect = (e: any) => {
    if (isDirty) {
      if (!window.confirm('You have unsaved changes. Are you sure you want to switch tabs?')) {
        return
      }
      setIsDirty(false)
    }

    setSelected(e.selected)
  }

  const handleFormChange = (isDirty: boolean) => {
    setIsDirty(isDirty)
  }

  return (
    <>
      <div className='row gy-5 g-xl-8' style={{height: 'auto'}}>
        <div className='col-xxl-2'>
          <div className='card card-xl-stretch p-6'>
            {assetData && (
              <AssetProfile
                assetData={assetData}
                setAssetData={setAssetData}
                //  onHandleFormChange={handleFormChange}
              ></AssetProfile>
            )}
          </div>
        </div>
        <div className='col-xxl-10'>
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title='Asset Info'>
              <AssetInformationTab
                assetData={assetData}
                setAssetData={setAssetData}
                onHandleFormChange={handleFormChange}
              />
            </TabStripTab>
            {assetData.valuationType === 'Single Line' ? (
              <TabStripTab title='Single Line Valuation'>
                <SingleLineValuationTab
                  assetData={assetData}
                  onHandleFormChange={handleFormChange}
                />
              </TabStripTab>
            ) : (
              <TabStripTab title='Component Valuation'>
                <ComponentValuationTab
                  assetData={assetData}
                  onHandleFormChange={handleFormChange}
                ></ComponentValuationTab>
              </TabStripTab>
            )}
            {assetData.valuationType === 'Componentized' && assetData.insuranceValuation && (
              <TabStripTab title='Insurance Valuation'>
                <InsuranceValuationTab
                  assetData={assetData}
                  onHandleFormChange={handleFormChange}
                ></InsuranceValuationTab>
              </TabStripTab>
            )}
            {assetData.valuationType === 'Single Line' && assetData.insuranceValuation && (
              <TabStripTab title='Insurance Valuation'>
                <SingleLineInsuranceValuationTab
                  assetData={assetData}
                  onHandleFormChange={handleFormChange}
                ></SingleLineInsuranceValuationTab>
              </TabStripTab>
            )}
            {assetData.marketApproach && (
              <TabStripTab title='Market Approach Valuation'>
                <MarketApproachValuationTab
                  assetData={assetData}
                  onHandleFormChange={handleFormChange}
                />
              </TabStripTab>
            )}
            {assetData.marketApproach && (
              <TabStripTab title='Market Evidence Tab'>
                <MarketEvidenceTab assetData={assetData}></MarketEvidenceTab>
              </TabStripTab>
            )}
          </TabStrip>
        </div>
      </div>
    </>
  )
}

export default AssetInformationPage
