import { IAssetClass } from "../models/asset";

export const transformAssetClass = (assetClass: IAssetClass) : IAssetClass => {
    return {
        ...assetClass,
        assetClassComponents: assetClass.assetClassComponents.map((component) => (
            {
                ...component,
                componentTypes: component.assetClassComponentTypes.map((item) => (item.typeName)),
                selectedComponentTypes: component.assetClassComponentTypes.map((item) => (
                    {
                        label: item.typeName,
                        value: item.typeName
                    }
                )) as any
            }
        ))
    }

}



export const transformSaveAssetClass = (assetClass: IAssetClass) : IAssetClass => {
    return {
        ...assetClass,
        assetClassComponents: assetClass.assetClassComponents.map((component) => (
            {
                ...component,
                id: +component.id < 1 ? 0 : component.id, // lessthan 1 means temporary id by math.random
            }
        ))
    }

}