import {useAccount, useIsAuthenticated, useMsal} from '@azure/msal-react'
import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'

const MsalService = () => {
  const isAuthenticated = useIsAuthenticated()
  const {instance, accounts} = useMsal()
  const account = useAccount(accounts[0] || {})
  const dispatch = useDispatch()

  const setActiveAccount = () => {
    // ensure active account is set
    if (instance.getActiveAccount() == null && instance.getAllAccounts().length > 0) {
      const accounts = instance.getAllAccounts()
      instance.setActiveAccount(accounts[0])
      console.log('MSAL accountInfo:', accounts[0])
    }
  }

  const acquireTokenSilent = useCallback(
    async (scopes) => {
      try {
        if (account) {
          const response = await instance.acquireTokenSilent({
            scopes: scopes,
            account: account,
          })

          if (response) {
            console.log('Got access token:', response)
            return response.accessToken
          } else {
            console.warn('No response from token acquisition.')
          }
        } else {
          console.log('No account...')
        }
      } catch (error: any) {
        console.log('Error getting token: ' + error.message)
        console.error(error)
      }
    },
    [instance, account]
  )

  const logoutAzureAccount = () => {
    instance
      .logoutPopup()
      .then(() => {
        dispatch(auth.actions.logout())
      })
      .catch((e) => {
        console.error(e)
      })
  }

  return {acquireTokenSilent, logoutAzureAccount, setActiveAccount, isAuthenticated, accounts}
}

export default MsalService
