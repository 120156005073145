import {FC} from 'react'
import {UseFormRegister} from 'react-hook-form'
import {IAssetInsuranceValuation} from '../../../../../../models/project-asset/insurance-valuation'

type MainAreaFieldProps = {
  register: UseFormRegister<IAssetInsuranceValuation>
  dataIndex: number
}

const MainAreaField: FC<MainAreaFieldProps> = ({register, dataIndex}) => {
  return (
    <td key={dataIndex}>
      <input
        type='number'
        className='form-control'
        {...register(`componentized.${dataIndex}.mainArea`)}
      />
    </td>
  )
}

export {MainAreaField}
