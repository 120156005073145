/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import InfoCards from './components/InfoCards'
import ProjectListTable from './components/ProjectListTable'
import {ProjectListContextProvider} from './context/ProjectListContext'

const DashboardPage: FC = () => (
  <>
    <InfoCards />
    <div className='mb-7' />
    <ProjectListTable />
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <ProjectListContextProvider>
      <PageTitle breadcrumbs={[]} isShowSync={true}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <DashboardPage />
    </ProjectListContextProvider>
  )
}

export {DashboardWrapper}
