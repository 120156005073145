import {useEffect, useState} from 'react'
import {
  Controller,
  Control,
  UseFormSetValue,
  FieldErrors,
  UseFormRegister,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormWatch,
  useWatch,
} from 'react-hook-form'
import Select from 'react-select'
import TitleHeader from '../../../../../../../../../../_metronic/layout/components/header/TitleHeader'
import {ProjectAssetData} from '../../../../../../../models/project-assets'
import {IOption, IOptionNumber} from '../../../../../../../models/project-model'
import {IRegionIndex} from '../../../../../../../models/region-index'
import {getDistinctObjects} from '../../../../../../../../../shared/service/utils'
import {
  transformDefaultNumericOptions,
  transformDefaultOptions,
} from '../../../../../../../transformers/project-transformer'

/**DUMMY */

const mainAreaOptions = [
  {value: 'Square Meters (sqm)', label: 'Square Meters (sqm)'},
  {value: 'Square Feet', label: 'Square Feet'},
  {value: 'Cubic Meters', label: 'Cubic Meters'},
]

const secondaryAreaOptions = [
  {value: 'Square Meters (sqm)', label: 'Square Meters (sqm)'},
  {value: 'Square Feet', label: 'Square Feet'},
  {value: 'Cubic Meters', label: 'Cubic Meters'},
]

type IBuildingReplacementRateProps = {
  control: Control<ProjectAssetData>
  isValid: boolean
  setValue: UseFormSetValue<ProjectAssetData>
  errors: FieldErrors<ProjectAssetData>
  register: UseFormRegister<ProjectAssetData>
  clearErrors: UseFormClearErrors<ProjectAssetData>
  handleSubmit: UseFormHandleSubmit<ProjectAssetData>
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData[]) => void
  watch: UseFormWatch<ProjectAssetData>
  valuationRegionOptions: IOption[]
  regionIndexes: IRegionIndex[]
}

function BuildingReplacementRate(props: IBuildingReplacementRateProps) {
  const {register, control, valuationRegionOptions, errors, clearErrors, setValue, regionIndexes} =
    props
  const [locationOptions, setLocationOptions] = useState<IOption[]>([])
  const [regionIndexOptions, setRegionIndexOptions] = useState<IOptionNumber[]>([])

  const stateCodeValue = useWatch({
    control,
    name: 'stateCode',
  })

  const locationBaseValue = useWatch({
    control,
    name: 'locationBase',
  })

  useEffect(() => {
    if (stateCodeValue && stateCodeValue !== '') {
      setValue('locationBase', '')
      setValue('locationPremiumAdjustment', 0)
      let locBase = regionIndexes.filter((item) => item.stateCode === stateCodeValue)
      let locBaseOption = transformDefaultOptions(locBase.map((res) => res.localGovernmentArea))
      let distinct: IOption[] = getDistinctObjects(locBaseOption, ['label', 'value'])
      setLocationOptions(distinct)

      setRegionIndexOptions([])
    }
  }, [stateCodeValue])

  useEffect(() => {
    if (locationBaseValue && locationBaseValue !== '') {
      setValue('locationPremiumAdjustment', 0)
      let regionIndex = regionIndexes.filter(
        (item) =>
          item.stateCode === stateCodeValue && item.localGovernmentArea === locationBaseValue
      )
      let regionIndexOption = transformDefaultNumericOptions(
        regionIndex.map((res) => res.regionalIndex)
      )
      let distinct: IOptionNumber[] = getDistinctObjects(
        regionIndexOption.map((regIndex) => ({
          value: +regIndex.value,
          label: +regIndex.label,
        })),
        ['label', 'value']
      )
      setRegionIndexOptions(distinct)
    }
  }, [locationBaseValue])

  /** */
  function formatNumberInput(input: any) {
    let cleanedInput = input.replace(/[^0-9.]/g, '')
    cleanedInput = cleanedInput.replace(/(\..*)\./g, '$1')
    cleanedInput = cleanedInput.substring(0, 10)
    const dotIndex = cleanedInput.indexOf('.')

    if (cleanedInput.length === 10 && cleanedInput.endsWith('.')) {
      cleanedInput = cleanedInput.substring(0, cleanedInput.length - 1)
    }

    if (dotIndex !== -1) {
      const digitsAfterDot = cleanedInput.length - dotIndex - 1
      if (digitsAfterDot > 2) {
        cleanedInput = cleanedInput.substring(0, dotIndex + 3)
      }
    }
    return cleanedInput
  }
  // const handleInputChange = (e: any, field: string) => {
  //   const value = formatNumberInput(e.target.value)
  //   setValue(field, value)
  // }
  return (
    <>
      <TitleHeader title='Building Replacement Rate' />
      <div id='buildingReplacementRate'>
        <div className='row h-auto'>
          <div className='col-4 d-flex flex-column justify-content-between'>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='valuationRegion'>
                Valuation Region
              </label>
              <Controller
                control={control}
                name='valuationRegion'
                render={({field: {onChange, value}}) => (
                  <div>
                    <Select
                      placeholder='Select Valuation Region'
                      id='valuationRegion'
                      isMulti={false}
                      options={valuationRegionOptions}
                      value={{
                        value,
                        label: value,
                      }}
                      onChange={(e) => {
                        onChange(e?.value)
                        clearErrors('valuationRegion')
                      }}
                      className={`controllerSelect${
                        errors.valuationRegion ? ' border-danger' : ''
                      }`}
                    />
                    {errors?.valuationRegion && (
                      <small className='text-danger'>{errors?.valuationRegion.message}</small>
                    )}
                  </div>
                )}
              />
            </div>

            <div className='form-outline'>
              <label className='fw-bolder' htmlFor='locationBase'>
                Location Base
              </label>
              <Controller
                control={control}
                name='locationBase'
                render={({field: {onChange, value}}) => (
                  <div>
                    <Select
                      placeholder='Select Location Base'
                      id='locationBase'
                      isMulti={false}
                      options={locationOptions}
                      isDisabled={locationOptions.length === 0}
                      value={{
                        value,
                        label: value,
                      }}
                      onChange={(e) => {
                        onChange(e?.value)
                        clearErrors('locationBase')
                      }}
                      className={`controllerSelect${errors.locationBase ? ' border-danger' : ''}`}
                    />
                    {errors?.locationBase && (
                      <small className='text-danger'>{errors?.locationBase.message}</small>
                    )}
                  </div>
                )}
              />
              {/* <input
                style={{
                  fontSize: '1rem',
                }}
                type='text'
                id='locationBase'
                className={`form-control${errors.locationBase ? ' border-danger' : ''}`}
                placeholder='Location Base'
                {...register('locationBase', {
                  onChange: (e) => {
                    clearErrors('locationBase')
                  },
                })}
              /> */}
              {errors?.locationBase && (
                <small className='text-danger'>{errors?.locationBase.message}</small>
              )}
            </div>
          </div>
          <div className='col-4  d-flex flex-column justify-content-between'>
            <div className='mb-4'>
              <label className='fw-bolder' htmlFor='mainAreaNum'>
                Main Area
              </label>
              <div className='row'>
                <div className='col-6'>
                  <input
                    style={{
                      fontSize: '1rem',
                    }}
                    maxLength={4}
                    type='text'
                    id='mainAreaNum'
                    className={`form-control${errors.mainAreaNum ? ' border-danger' : ''}`}
                    {...register('mainAreaNum', {
                      onChange: (e) => {
                        const value = formatNumberInput(e.target.value)
                        setValue('mainAreaNum', value)
                        clearErrors('mainAreaNum')
                      },
                    })}
                  />
                  {errors?.mainAreaNum && (
                    <small className='text-danger'>{errors?.mainAreaNum.message}</small>
                  )}
                </div>

                <div className='col-6'>
                  <div className='form-outline'>
                    <Controller
                      control={control}
                      name='mainAreaType'
                      render={({field: {onChange, value}}) => (
                        <div>
                          <Select
                            id='mainAreaType'
                            isMulti={false}
                            options={mainAreaOptions}
                            value={{
                              value,
                              label: value,
                            }}
                            onChange={(e) => {
                              onChange(e?.value)
                              clearErrors('mainAreaType')
                            }}
                            className={`controllerSelect${
                              errors.mainAreaType ? ' border-danger' : ''
                            }`}
                          />
                          {errors.mainAreaType && (
                            <small className='text-danger'>{errors.mainAreaType.message}</small>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <div className='form-outline'>
                  <label className='fw-bolder' htmlFor='locationPremium'>
                    Location Premium
                  </label>
                  <Controller
                    control={control}
                    name='locationPremiumAdjustment'
                    render={({field: {onChange, value}}) => (
                      <div>
                        <Select
                          id='locationPremiumAdjustment'
                          isMulti={false}
                          options={regionIndexOptions}
                          isDisabled={regionIndexOptions.length === 0}
                          value={{
                            value: value,
                            label: value,
                          }}
                          onChange={(e) => {
                            onChange(e?.value)
                            clearErrors('locationPremiumAdjustment')
                          }}
                          className={`controllerSelect${
                            errors.locationPremiumAdjustment ? ' border-danger' : ''
                          }`}
                        />
                        {errors.locationPremiumAdjustment && (
                          <small className='text-danger'>
                            {errors.locationPremiumAdjustment.message}
                          </small>
                        )}
                      </div>
                    )}
                  />
                  {/* <input
                    style={{
                      fontSize: '1rem',
                    }}
                    maxLength={10}
                    type='text'
                    id='locationPremium'
                    className={`form-control${errors.locationPremiumAdjustment ? ' border-danger' : ''}`}
                    {...register('locationPremiumAdjustment', {
                      onChange: (e) => {
                        const value = formatNumberInput(e.target.value)
                        setValue("locationPremiumAdjustment", value)
                        clearErrors('locationPremiumAdjustment')
                      },
                    })}
                  /> */}
                  {errors?.locationPremiumAdjustment && (
                    <small className='text-danger'>
                      {errors?.locationPremiumAdjustment.message}
                    </small>
                  )}
                </div>
              </div>
              <div className='col-6' />
            </div>
          </div>
          <div className='col-4  d-flex flex-column justify-content-between'>
            <div className='mb-4'>
              <label className='fw-bolder' htmlFor='secondaryAreaNum'>
                Secondary Area
              </label>
              <div className='row'>
                <div className='col-6'>
                  <input
                    style={{
                      fontSize: '1rem',
                    }}
                    maxLength={4}
                    type='text'
                    id='secondaryQuantity'
                    className={`form-control${errors.secondaryQuantity ? ' border-danger' : ''}`}
                    {...register('secondaryQuantity', {
                      onChange: (e) => {
                        const value = formatNumberInput(e.target.value)
                        setValue('secondaryQuantity', value)
                        clearErrors('secondaryQuantity')
                      },
                    })}
                  />
                  {errors?.secondaryQuantity && (
                    <small className='text-danger'>{errors?.secondaryQuantity.message}</small>
                  )}
                </div>
                <div className='col-6'>
                  <div className='form-outline'>
                    <Controller
                      control={control}
                      name='secondaryAreaType'
                      render={({field: {onChange, value}}) => (
                        <div>
                          <Select
                            id='secondaryAreaType'
                            isMulti={false}
                            options={secondaryAreaOptions}
                            value={{
                              label: value,
                              value,
                            }}
                            onChange={(e) => {
                              onChange(e?.value)
                              clearErrors('secondaryAreaType')
                            }}
                            className={`controllerSelect${
                              errors.secondaryAreaType ? ' border-danger' : ''
                            }`}
                          />
                          {errors.secondaryAreaType && (
                            <small className='text-danger'>
                              {errors.secondaryAreaType.message}
                            </small>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <div className='form-outline'>
                  <label className='fw-bolder' htmlFor='timeIndex'>
                    Time Index
                  </label>
                  <input
                    style={{
                      fontSize: '1rem',
                    }}
                    maxLength={4}
                    type='text'
                    id='timeIndex'
                    className={`form-control${errors.timeIndex ? ' border-danger' : ''}`}
                    {...register('timeIndex', {
                      onChange: (e) => {
                        const value = formatNumberInput(e.target.value)
                        setValue('timeIndex', value)
                        clearErrors('timeIndex')
                      },
                    })}
                  />
                  {errors?.timeIndex && (
                    <small className='text-danger'>{errors?.timeIndex.message}</small>
                  )}
                </div>
              </div>
              <div className='col-6' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BuildingReplacementRate
