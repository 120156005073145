import React, {useContext, useEffect} from 'react'
import Select from 'react-select'
import {Dialog} from '@progress/kendo-react-dialogs'
import {useForm, Controller} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {componentFormValidationSchema} from '../../validators/component-form'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import './AddComponentModal.css'
import {AssetContext} from '../../context/AssetContext'
import {IAssetClass, IAssetComponent, IAssetComponentData} from '../../models/asset'
import {NumericTextBox} from '@progress/kendo-react-inputs'

type IAddComponentModal = {
  assetData?: IAssetClass
  setAssetData?: (value: IAssetClass) => void
  isEdit?: boolean
  setIsEdit?: (value: boolean) => void
}
function AddComponentModal(props: IAddComponentModal) {
  const {
    componentInitialValue,
    assetClassComponents,
    selectedData,
    setNewComponent,
    setAssetClassComponents,
    componentTypeOptions,
    updateData,
    showToastrNotification,
    isModalVisible,
    setModalVisible,
  } = useContext(AssetContext)

  const {assetData, setAssetData = () => {}, isEdit, setIsEdit = () => {}} = props

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    clearErrors,
  } = useForm({
    defaultValues: isEdit ? componentInitialValue : selectedData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(componentFormValidationSchema),
  })

  const closeModal = () => {
    setModalVisible(false)
  }

  const onSubmit = async (values: IAssetComponentData) => {
    let payload = {...values}
    if (!Object.values(errors).some((error) => error)) {
      try {
        if (componentInitialValue.id === 0) {
          payload = {...payload, id: Math.random()}
          setAssetClassComponents((prev: IAssetComponent[]) => [payload, ...prev])
          assetData &&
            setAssetData({
              ...assetData,
              assetClassComponents: [payload, ...assetData.assetClassComponents],
            })
          showToastrNotification(
            'Asset component successfully added. Your changes will take effect after you click Save Changes.',
            'info'
          )
        } else {
          const updatedComponents = assetClassComponents.map((component: IAssetComponent) =>
            component.id === componentInitialValue.id ? payload : component
          )
          setAssetClassComponents(updatedComponents)

          if (assetData) {
            const updatedAssetComponents = assetData.assetClassComponents.map(
              (component: IAssetComponentData) =>
                component.id === componentInitialValue.id ? payload : component
            )
            setAssetData({...assetData, assetClassComponents: updatedAssetComponents})
          }
          showToastrNotification(
            'Asset component successfully updated. Your changes will take effect after you click Save Changes.',
            'info'
          )
        }
      } catch (error) {}

      updateData()
      setNewComponent(componentInitialValue)
      setIsEdit(false)
      closeModal()
    }
  }

  const handleCancel = () => {
    setIsEdit(false)
    setNewComponent(componentInitialValue)
    closeModal()
  }

  useEffect(() => {
    setNewComponent(componentInitialValue)
  }, [isModalVisible])

  const customStyles = {
    menuPortal: (provided: any) => ({...provided, zIndex: 9999}),
    menu: (provided: any) => ({...provided, zIndex: 9999}),
  }

  return (
    <>
      {isModalVisible && (
        <Dialog
          height={'auto'}
          minWidth={'60%'}
          style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
          title={!props?.isEdit ? 'Add Asset Component' : 'Edit Asset Component'}
          onClose={handleCancel}
        >
          <form onSubmit={handleSubmit(onSubmit)} name='component'>
            <div className='col'>
              <div className='row mb-4'>
                <div className='col-4'>
                  <div className='form-outline'>
                    <label className='fw-bolder' htmlFor='number'>
                      Component Number
                    </label>
                    <input
                      style={{
                        fontSize: '1rem',
                      }}
                      maxLength={10}
                      type='text'
                      id='number'
                      className={`form-control${errors.number ? ' border-danger' : ''}`}
                      placeholder='Component Number'
                      {...register('number')}
                      onKeyPress={(event) => {
                        if (
                          event.charCode === 0 ||
                          /\d/.test(String.fromCharCode(event.charCode))
                        ) {
                          return true
                        } else {
                          event.preventDefault()
                        }
                      }}
                    />
                  </div>
                  {errors?.number && (
                    <small className='text-danger'>{errors?.number.message}</small>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col-6'>
                  <div className='form-outline'>
                    <label className='fw-bolder' htmlFor='name'>
                      Component Name
                    </label>
                    <input
                      minLength={1}
                      maxLength={200}
                      style={{
                        fontSize: '1rem',
                      }}
                      type='text'
                      id='name'
                      className={`form-control${errors.name ? ' border-danger' : ''}`}
                      placeholder='Component Name'
                      {...register('name')}
                    />
                  </div>
                  {errors?.name && <small className='text-danger'>{errors?.name.message}</small>}
                </div>
                <div className='col-6'>
                  <div className='form-outline'>
                    <label className='fw-bolder' htmlFor='componentTypes'>
                      Component Type
                    </label>
                    <Controller
                      control={control}
                      name='componentTypes'
                      render={({field: {onChange, value}}) => (
                        <div>
                          <Select
                            styles={customStyles}
                            isMulti={false}
                            options={componentTypeOptions}
                            value={value?.map((item: string) => {
                              return {
                                label: item,
                                value: item,
                              }
                            })}
                            onChange={(e) => {
                              // onChange(e.map((item: any) => item.value))
                              onChange([e?.value])
                              clearErrors('componentTypes')
                            }}
                            className={`controllerSelect${
                              errors.componentTypes ? ' border-danger' : ''
                            }`}
                          />
                        </div>
                      )}
                    />
                  </div>
                  {errors.componentTypes && (
                    <small className='text-danger'>{errors.componentTypes.message}</small>
                  )}
                </div>
              </div>

              <div className='row mb-4'>
                <div className='col-4'>
                  <div className='form-outline'>
                    <label className='fw-bolder' htmlFor='adjustmentRate'>
                      Additional Valuer Adjustment Rate
                    </label>
                    <Controller
                      control={control}
                      name='additionalValuerAdjustmentRate'
                      render={({field: {onChange, value}}) => (
                        <NumericTextBox
                          style={{
                            fontSize: '1rem',
                          }}
                          className={`form-control${
                            errors.additionalValuerAdjustmentRate ? ' border-danger' : ''
                          }`}
                          defaultValue={value}
                          format='n2'
                          min={1}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                  {errors?.additionalValuerAdjustmentRate && (
                    <small className='text-danger'>
                      {errors?.additionalValuerAdjustmentRate.message}
                    </small>
                  )}
                </div>
                <div className='col-4'>
                  <div className='form-outline'>
                    <label className='fw-bolder' htmlFor='secondaryAdjustmentFactor'>
                      Secondary Adjustment Factor
                    </label>
                    <Controller
                      control={control}
                      name='secondaryAdjustmentFactor'
                      render={({field: {onChange, value}}) => (
                        <NumericTextBox
                          style={{
                            fontSize: '1rem',
                          }}
                          className={`form-control${
                            errors.secondaryAdjustmentFactor ? ' border-danger' : ''
                          }`}
                          defaultValue={value}
                          format='n2'
                          min={1}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                  {errors?.secondaryAdjustmentFactor && (
                    <small className='text-danger'>
                      {errors?.secondaryAdjustmentFactor.message}
                    </small>
                  )}
                </div>
                <div className='col-4'>
                  <div className='form-outline'>
                    <label className='fw-bolder' htmlFor='usefulLife'>
                      Useful Life
                    </label>
                    <Controller
                      control={control}
                      name='usefulLife'
                      render={({field: {onChange, value}}) => (
                        <NumericTextBox
                          style={{
                            fontSize: '1rem',
                          }}
                          className={`form-control${errors.usefulLife ? ' border-danger' : ''}`}
                          defaultValue={value}
                          format='n2'
                          min={1}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                  {errors?.usefulLife && (
                    <small className='text-danger'>{errors?.usefulLife.message}</small>
                  )}
                </div>
              </div>
            </div>
            <div className='d-flex flex-row align-items-center justify-content-end w-100 mt-10'>
              <div className='d-flex flex-row align-items-center'>
                <Button buttonType='primary' text={'Save'} />
                <Button
                  buttonType='secondary'
                  text={'Cancel'}
                  onClick={handleCancel}
                  style={{marginLeft: 10}}
                />
              </div>
            </div>
          </form>
        </Dialog>
      )}
    </>
  )
}

export default AddComponentModal
