import * as yup from 'yup'

export const reportSheetValidationSchema = yup.object().shape({
  sheets: yup.array().of(
    yup.object().shape({
      title: yup.string()
        .required('Sheet title is required')
        .min(1, 'Sheet title must be at least 1 character'),
      selectedDataFields: yup.array().min(1, 'Select at least 1 data field!').required('required'),
      referenceDateType: yup.string(),
      fromDate: yup.date().when('referenceDateType', {
        is: (val: string | undefined) => val && val.trim().length > 0,
        then: yup.date().required('From Date is required'),
      }),
      toDate: yup.date().when('referenceDateType', {
        is: (val: string | undefined) => val && val.trim().length > 0,
        then: yup
          .date()
          .required('To Date is required')
          .min(yup.ref('fromDate'), 'To Date must be greater than From Date'),
      }),
    })
  ),
})
