import {IReport} from '../models/report'

export const REPORT_INITIAL: IReport = {
  id: 0,
  reportType: '',
  coverageMonth: '',
  created: null,
}

export const initialSort: Array<any> = [{field: 'id', dir: 'desc'}]
