import {useEffect, useState} from 'react'
import {TimeoutWarningModal} from './TimeoutWarningModal'
import {addEventListeners, removeEventListeners} from './utils/eventListenerUtil'

const TimeoutLogic = () => {
  const [isWarningModalOpen, setWarningModalOpen] = useState(false)

  useEffect(() => {
    const createTimeout = () => {
      const refreshToken = localStorage.getItem('refresh-token')
      if (refreshToken) {
        return setTimeout(() => {
          setWarningModalOpen(true)
        }, 600000 * 3)
      } else {
        return null
      }
    }

    const listener = () => {
      if (!isWarningModalOpen) {
        clearTimeout(timeout)
        timeout = createTimeout()!
      }
    }

    // Initialization
    let timeout = createTimeout()!
    addEventListeners(listener)

    // Cleanup
    return () => {
      removeEventListeners(listener)
      clearTimeout(timeout)
    }
  }, [isWarningModalOpen])

  return (
    <>
      {isWarningModalOpen && (
        <TimeoutWarningModal
          show={isWarningModalOpen}
          onRequestClose={() => setWarningModalOpen(false)}
        />
      )}
    </>
  )
}

export {TimeoutLogic}
