import React from 'react'
import Select from 'react-select'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
} from 'react-hook-form'
import {IOption, IUserData} from '../../../models/user-model'

interface IProps {
  userData?: IUserData
  register: UseFormRegister<any>
  control: Control<any, any>
  errors: FieldErrors<any>
  userGroupOptions: any
  isDisabled: boolean
  clearErrors: UseFormClearErrors<any>
  officeOptions: IOption[]
}

const UserFormFields: React.FC<IProps> = ({
  userData,
  register,
  errors,
  control,
  userGroupOptions,
  isDisabled,
  clearErrors,
  officeOptions,
}) => {
  return (
    <React.Fragment>
      <div className='col'>
        {userData ? (
          <input
            type='hidden'
            className={`form-control${errors.id ? ' border-danger' : ''}`}
            {...register('id')}
            disabled
          />
        ) : null}
        <div className='row mb-4'>
          <div className='col-6'>
            <div className='form-outline'>
              <label className='fw-bolder' htmlFor='firstName'>
                First Name
              </label>
              <input
                type='text'
                className={`form-control${errors.firstName ? ' border-danger' : ''}`}
                placeholder='Enter First Name'
                {...register('firstName', {
                  onChange: (e) => {
                    clearErrors('firstName')
                  },
                })}
                disabled={isDisabled}
              />
              {errors.firstName && (
                <small className='text-danger'>{errors.firstName.message}</small>
              )}
            </div>
          </div>
          <div className='col-6'>
            <div className='form-outline'>
              <label className='fw-bolder' htmlFor='lastName'>
                Last Name
              </label>
              <input
                type='text'
                className={`form-control${errors.lastName ? ' border-danger' : ''}`}
                placeholder='Enter Last Name'
                {...register('lastName', {
                  onChange: (e) => {
                    clearErrors('lastName')
                  },
                })}
                disabled={isDisabled}
              />
              {errors.lastName && <small className='text-danger'>{errors.lastName.message}</small>}
            </div>
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-8'>
            <div className='form-outline'>
              <label className='fw-bolder' htmlFor='email'>
                Email Address
              </label>
              <input
                type='text'
                className={`form-control${errors.email ? ' border-danger' : ''}`}
                placeholder='Enter Email'
                {...register('email', {
                  onChange: (e) => {
                    clearErrors('email')
                  },
                })}
                disabled={isDisabled}
              />
              {errors.email && <small className='text-danger'>{errors.email.message}</small>}
            </div>
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-8'>
            <div className='form-outline'>
              <label className='fw-bolder' htmlFor='userGroups'>
                User Group
              </label>
              <Controller
                control={control}
                name='selectedUserGroups'
                render={({field: {onChange, value}}) => (
                  <div>
                    <Select
                      isMulti={true}
                      options={userGroupOptions}
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        clearErrors('selectedUserGroups')
                      }}
                      className={`controllerSelect${
                        errors.selectedUserGroups ? ' border-danger' : ''
                      }`}
                    />
                    {errors.selectedUserGroups && (
                      <small className='text-danger'>{errors.selectedUserGroups.message}</small>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-8'>
            <div className='form-outline'>
              <label className='fw-bolder' htmlFor='selectedUserOffices'>
                Offices
              </label>
              <Controller
                control={control}
                name='selectedUserOffices'
                render={({field: {onChange, value}}) => (
                  <div>
                    <Select
                      isMulti={true}
                      options={officeOptions}
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        clearErrors('selectedUserOffices')
                      }}
                      className={`controllerSelect${
                        errors.selectedUserOffices ? ' border-danger' : ''
                      }`}
                    />
                    {errors.selectedUserOffices && (
                      <small className='text-danger'>{errors.selectedUserOffices.message}</small>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        {/* <div className='row mb-4'>
          <div className='col-8'>
            <div className='form-outline'>
              <label className='fw-bolder' htmlFor='userGroups'>
                Office
              </label>
              <Controller
                control={control}
                name='officeId'
                render={({field: {onChange, value}}) => (
                  <div>
                    <Select
                      options={officeOptions}
                      value={officeOptions.find(item => item.value === value) || USER_OFFICE_DEFAULT}
                      onChange={(event) => onChange(+(event?.value || ''))}
                      className={`controllerSelect${
                        errors.officeId ? ' border-danger' : ''
                      }`}
                    />
                    {errors.officeId && (
                      <small className='text-danger'>{errors.officeId.message}</small>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        </div> */}
        <div className='row mb-10'>
          <div className='col-8'>
            <div className='form-outline'>
              <input className='form-check-input' type='checkbox' {...register('isActive')} />{' '}
              <label className='fw-bolder' htmlFor='isActive'>
                Active
              </label>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export {UserFormFields}
