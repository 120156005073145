/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useContext, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {AssetContext, AssetContextProvider} from './context/AssetContext'
import Button from '../../../_metronic/layout/components/buttons/Button'
import AssetClassLookupTable from './components/AssetClassLookupTable/AssetClassLookupTable'
import {useHistory} from 'react-router-dom'
import {AccessControlProvider} from '../../../_metronic/layout/core/AccessControlProvider'
import {PAGE_PERMISSION} from '../roles/models/role-model'

const AssetPage = () => {
  const {setSearchFilter} = useContext(AssetContext)
  const history = useHistory()
  const [searchText, setSearchText] = useState<string>('')

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      setSearchFilter(searchText)
      event.preventDefault()
    }
  }

  return (
    <div
      style={{
        backgroundColor: '#fff',
        height: 'auto',
        padding: 26,
        boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px',
      }}
    >
      <div className='d-flex align-items-center justify-content-between'>
        <div style={{position: 'relative'}}>
          <input
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            onKeyDown={handleKeyPress}
            type='text'
            placeholder='Search Asset Classes'
            style={{
              minWidth: 400,
              minHeight: 35,
              borderRadius: 17.5,
              border: '1px solid #E5E4E2',
              paddingLeft: 30,
              outline: 'none',
              fontStyle: 'italic',
            }}
          />
          <i
            className='bi bi-search'
            style={{position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)'}}
          ></i>
        </div>
        <div className='d-flex align-items-center'>
          <AccessControlProvider allowedPermission={PAGE_PERMISSION.ASSET_CLASSIFICATION_CREATE}>
            <Button
              onClick={() => history.push('/asset-class/new')}
              text={'Add Asset Class'}
              buttonType='primary'
              icon={<i className='bi bi-plus-lg' style={{color: '#fff'}}></i>}
            />
          </AccessControlProvider>
        </div>
      </div>
      <AssetClassLookupTable />
    </div>
  )
}

const AssetWrapper: FC = () => {
  const intl = useIntl()

  return (
    <AssetContextProvider>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ASSETCLASSES'})}</PageTitle>
      <AssetPage />
    </AssetContextProvider>
  )
}

export {AssetWrapper}
