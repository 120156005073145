/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import {LoginPage} from './components/LoginPage'
import {ResetPasswordPage} from './components/ResetPasswordPage'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <Switch>
      <Route path='/auth/login' component={LoginPage} />
      <Route path='/auth/forgot-password' component={ForgotPassword} />
      <Route path='/azure/signin-saml' component={ForgotPassword} />
      <Route path='/auth/reset-password' component={ResetPasswordPage} />

      <Redirect from='/auth' exact={true} to='/auth/login' />
      <Redirect to='/auth/login' />
    </Switch>
  )
}
