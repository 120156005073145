import {NumericTextBox} from '@progress/kendo-react-inputs'
import {FC} from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import {IAssetComponentized} from '../../../../../models/project-asset/componentized'
import {formatNumberWithCommas} from '../../../../../transformers/project-transformer'
import SectionTitleHeader from '../../../../common/SectionTitleHeader'

type IPreviousReplacementCostSectionProps = {
  control: Control<IAssetComponentized>
  isValid: boolean
  watch: UseFormWatch<IAssetComponentized>
  setValue: UseFormSetValue<IAssetComponentized>
  getValues: UseFormGetValues<IAssetComponentized>
  errors: FieldErrors<IAssetComponentized>
  register: UseFormRegister<IAssetComponentized>
  clearErrors: UseFormClearErrors<IAssetComponentized>
  handleSubmit: UseFormHandleSubmit<IAssetComponentized>
  currentIndex: number
}

const PreviousReplacementCostForm: FC<IPreviousReplacementCostSectionProps> = ({
  control,
  errors,
  getValues,
  currentIndex,
}) => {
  return (
    <>
      <div className='d-flex justify-content-between'>
        <SectionTitleHeader title='Previous Replacement Cost' showButtons={false} />
      </div>
      <div id='previousReplacementCostSection'>
        <div id='previousReplacementCostForm'>
          <div className='row h-auto mt-5'>
            <div className='col-3'>
              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='replacementCost'>
                  Replacement Cost
                </label>
                <div>
                  <input
                    style={{
                      fontSize: '1rem',
                      width: '120px',
                    }}
                    readOnly={true}
                    id='replacementCost'
                    className={`form-control${
                      errors.componentized &&
                      errors.componentized[currentIndex]?.secondaryAdjustmentFactorRate
                        ? ' border-danger'
                        : ''
                    }`}
                    placeholder='0.00'
                    value={formatNumberWithCommas(
                      getValues(`componentized.${currentIndex}.replacementCost`)
                    )}
                  />
                </div>
              </div>

              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='registerReplacementCost'>
                  Register Replacement Cost
                </label>
                <div>
                  <Controller
                    control={control}
                    name={`componentized.${currentIndex}.registerReplacementCost`}
                    render={({field: {onChange, value, ref}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${
                          errors.componentized &&
                          errors.componentized[currentIndex]?.registerReplacementCost
                            ? ' border-danger'
                            : ''
                        }`}
                        defaultValue={value}
                        value={value}
                        format='c2'
                        min={0}
                        max={10}
                        step={0.01}
                        ref={ref}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.componentized &&
                    errors.componentized[currentIndex]?.registerReplacementCost && (
                      <div>
                        <small className='text-danger'>
                          {errors.componentized[currentIndex]?.registerReplacementCost?.message}
                        </small>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className='col-3'>
              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='replacementCostVariance'>
                  Replacement Cost Variance
                </label>
                <div>
                  <Controller
                    control={control}
                    name={`componentized.${currentIndex}.replacementCostVariance`}
                    render={({field: {onChange, value, ref}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${
                          errors.componentized &&
                          errors.componentized[currentIndex]?.replacementCostVariance
                            ? ' border-danger'
                            : ''
                        }`}
                        defaultValue={value}
                        value={value}
                        format='n2'
                        min={0}
                        max={10}
                        step={0.01}
                        ref={ref}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.componentized &&
                    errors.componentized[currentIndex]?.replacementCostVariance && (
                      <div>
                        <small className='text-danger'>
                          {errors.componentized[currentIndex]?.replacementCostVariance?.message}
                        </small>
                      </div>
                    )}
                </div>
              </div>

              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='priorRevalReplacementCost'>
                  Prior Reval Replacement Cost
                </label>
                <div>
                  <Controller
                    control={control}
                    name={`componentized.${currentIndex}.priorRevalReplacementCost`}
                    render={({field: {onChange, value}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${
                          errors.componentized &&
                          errors.componentized[currentIndex]?.priorRevalReplacementCost
                            ? ' border-danger'
                            : ''
                        }`}
                        defaultValue={value}
                        value={value}
                        format='n2'
                        min={0}
                        max={10}
                        step={0.01}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.componentized &&
                    errors.componentized[currentIndex]?.priorRevalReplacementCost && (
                      <div>
                        <small className='text-danger'>
                          {errors.componentized[currentIndex]?.priorRevalReplacementCost?.message}
                        </small>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className='col-3'>
              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='varianceOnGfa'>
                  Variance on GFA
                </label>
                <div>
                  <Controller
                    control={control}
                    name={`componentized.${currentIndex}.varianceOnGfa`}
                    render={({field: {onChange, value}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          width: '120px',
                        }}
                        className={`form-control${
                          errors.componentized && errors.componentized[currentIndex]?.varianceOnGfa
                            ? ' border-danger'
                            : ''
                        }`}
                        defaultValue={value}
                        value={value}
                        format='n2'
                        min={0}
                        max={10}
                        step={0.01}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.componentized && errors.componentized[currentIndex]?.varianceOnGfa && (
                    <div>
                      <small className='text-danger'>
                        {errors.componentized[currentIndex]?.varianceOnGfa?.message}
                      </small>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export {PreviousReplacementCostForm}
