import {GridSetup, IOption, IUserData, IUserSearch} from '../models/user-model'

export const USER_FORM_DEFAULT: IUserData = {
  id: '',
  userName: '',
  email: '',
  firstName: '',
  lastName: '',
  isActive: '1',
  isResetPasswordRequired: false,
  userRoles: [],
  userGroups: [],
  userOffices: [],
  status: '',
  userRoleDisplay: '',
  selectedUserGroups: [],
  selectedUserOffices: [],
  officeData: [],
}

export const USER_STATUS: IOption[] = [
  {value: '1', label: 'Active'},
  {value: '0', label: 'Inactive'},
]

export const SEARCH_DEFAULT: IUserSearch = {
  search: '',
  userName: '',
  firstName: '',
  lastName: '',
  status: true,
}

export const Initial_GridSetup: GridSetup = {
  sort: [{field: 'id', dir: 'desc'}],
  skip: 0,
  take: 10,
  filter: {
    logic: 'and',
    filters: [],
  },
}

export const USER_OFFICE_DEFAULT: IOption = {value: '0', label: ''}
