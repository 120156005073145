/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {PAGE_PERMISSION} from '../../../../app/modules/roles/models/role-model'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
        pagePermission={PAGE_PERMISSION.DASHBOARD_VIEW}
      />
      <AsideMenuItem
        to='/projects'
        title={intl.formatMessage({id: 'MENU.PROJECTS'})}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
        pagePermission={PAGE_PERMISSION.PROJECT_VIEW}
      />
      <AsideMenuItem
        to='/reports'
        title={intl.formatMessage({id: 'MENU.REPORTS'})}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
        pagePermission={PAGE_PERMISSION.REPORTS_VIEW}
      />

      <AsideMenuItemWithSub
        to='#'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.ADMINTOOLS'})}
        fontIcon='fa-cog'
        pagePermission={[
          PAGE_PERMISSION.ASSET_CLASSIFICATION_VIEW,
          PAGE_PERMISSION.USER_MANAGEMENT_VIEW,
          PAGE_PERMISSION.CLIENTS_VIEW,
          PAGE_PERMISSION.OFFICE_LOCATIONS_VIEW,
        ]}
      >
        <AsideMenuItem
          to='/asset-classes'
          title={intl.formatMessage({id: 'MENU.ASSETCLASSES'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
          pagePermission={PAGE_PERMISSION.ASSET_CLASSIFICATION_VIEW}
        />
        {/* <Restricted to='user.list'>
          
        </Restricted> */}

        <AsideMenuItem
          to='/user-management'
          title={intl.formatMessage({id: 'MENU.USERMANAGEMENT'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
          pagePermission={PAGE_PERMISSION.USER_MANAGEMENT_VIEW}
        />

        <AsideMenuItem
          to='/clients'
          title={intl.formatMessage({id: 'MENU.CLIENTS'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
          pagePermission={PAGE_PERMISSION.CLIENTS_VIEW}
        />
        {/* <AsideMenuItem
          to='/rates-table'
          title={intl.formatMessage({id: 'MENU.RATESTABLE'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        /> */}

        {/* <AsideMenuItem
          to='/asset-classification'
          title={intl.formatMessage({id: 'MENU.ASSETCLASSIFICATION'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        /> */}

        <AsideMenuItem
          to='/office-locations'
          title={intl.formatMessage({id: 'MENU.OFFICELOCATIONS'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
          pagePermission={PAGE_PERMISSION.OFFICE_LOCATIONS_VIEW}
        />
        <AsideMenuItem
          to='/adjustment-matrix'
          title={intl.formatMessage({id: 'MENU.ADJUSTMENTMATRIX'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
          pagePermission={PAGE_PERMISSION.ADJUSTMENT_MATRIX_VIEW}
        />
      </AsideMenuItemWithSub>
    </>
  )
}
