import React, {useState, useEffect} from 'react'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {GridSetup} from '../../user-management/models/user-model'
import {Initial_GridSetup} from '../../user-management/constant/user-default'
import {IReport} from '../models/report'
import {REPORT_INITIAL} from '../constant/report-constant'
import {getAaagMonthlyReport} from '../api/report-api'

export const ReportContext = React.createContext<any>({})

export const ReportContextProvider: React.FC = ({children}) => {
  /**CLIENTS */
  const [initialValue, setInitialValue] = useState<IReport>(REPORT_INITIAL)
  const [reports, setReports] = useState<any[]>([])
  const [gridSetup, setGridSetup] = useState<GridSetup>(Initial_GridSetup)
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(1)

  const fetchAaagMonthlyReport = async (search: string = '') => {
    var {sort, skip, take} = gridSetup
    setIsLoading(true)

    const response = await getAaagMonthlyReport(skip, take, sort[0], search || '')
    setReports([...response?.data?.items])
    setTotalPages(response?.data?.totalPages)
    setTotalCount(response?.data?.totalCount)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchAaagMonthlyReport()
  }, [gridSetup])

  const handlePageChange = (pageProps: any) => {
    setGridSetup({
      ...gridSetup,
      skip: pageProps.page.skip,
      take: pageProps.page.take,
    })
  }

  useEffect(() => {
    fetchAaagMonthlyReport(searchFilter)
  }, [searchFilter])

  /**
   * TOASTR
   */
  const showToastrNotification = (message: any, type: any) => {
    toastr.options = {
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: false,
    }
    toastr.clear()
    setTimeout(() => {
      if (type === 'success') {
        toastr.success(message)
      } else {
        toastr.error(message)
      }
    }, 300)
  }

  return (
    <ReportContext.Provider
      value={{
        initialValue,
        reports,
        setInitialValue,
        totalCount,
        totalPages,
        handlePageChange,
        setTotalPages,
        setTotalCount,
        showToastrNotification,
        setSearchFilter,
        gridSetup,
        setGridSetup,
        isLoading,
      }}
    >
      {children}
    </ReportContext.Provider>
  )
}
