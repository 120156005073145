import './../../../ui/Project.scss'
import {ProjectData} from '../../../../models/project-assets'
import {AssetValuationReportForm} from '../../../Report/AssetValuationReportForm'
import {IProjectData} from '../../../../models/project-model'

type IReportPageProps = {
  projectData: IProjectData | ProjectData | undefined
}

const ReportPage = (props: IReportPageProps) => {
  const {projectData} = props

  return (
    <div>
      <AssetValuationReportForm projectData={projectData}></AssetValuationReportForm>
    </div>
  )
}

export {ReportPage}
