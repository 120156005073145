import axios from "axios"

export const FULCRUM_SYNC = `${process.env.REACT_APP_API_URL}/FulcrumSync`

export const getCurrentStatus = async () => {
    return await axios.get(`${FULCRUM_SYNC}`)
  }

  export const syncFulcrumApp = async () => {
    return await axios.post(`${FULCRUM_SYNC}/sync`, {
      officeId: 0
    })
  }