import {ISingleLineInsuranceValuation} from '../models/single-line-insurance-valuation'

export const SINGLELINE_INSURANCE_VALUATION_FORM_DEFAULT: ISingleLineInsuranceValuation = {
  id: 0,
  projectAssetId: 0,
  policyPeriod: 0,
  includeDemolition: false,
  demolitionType: '',
  demolitionAllowance: 0,
  insuranceDemolition: 0,
  leadUpPeriod: 0,
  rebuildPeriod: 0,
  insuranceCostEscalation: 0,
  insuranceLimitOfLiability: 0,
  insuranceComments: '',
  selected: false,
}
