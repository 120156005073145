import React, {useContext, useState, useEffect} from 'react'
import toastr from 'toastr'
import {Dialog} from '@progress/kendo-react-dialogs'
import './ProjectInfoModal.css'
import {ProjectContext} from '../../../context/ProjectContext'
import Button from '../../../../../../_metronic/layout/components/buttons/Button'
import {ProjectFormFields} from './ProjectFormFields'
import {CustomAlert, IAlert} from '../../../../../shared/components/CustomAlert'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {projectFormValidationSchema} from '../../../validators/project-form'
import {IProjectData, IProjectUser} from '../../../models/project-model'
import {PROJECT_FORM_DEFAULT} from '../../../constant/project-default'
import {createProject, updateProject} from '../../../api'
import {Loader2} from '../../../../../../_metronic/layout/components/loader/Loader2'
import {usePageData} from '../../../../../../_metronic/layout/core/PageData'
import {hasPermission} from '../../../../../shared/service/utils'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'
import {AccessControlProvider} from '../../../../../../_metronic/layout/core/AccessControlProvider'

function ProjectInfoModal(props: any) {
  const {
    initialValue,
    isModalVisible,
    setModalVisible,
    setSelectedData,
    selectedData,
    projectUserRoleOptions,
    userOptions,
    clientOptions,
    clientOptionFilterChangeHandler,
    isClientOptionLoading,
    fetchData,
  } = useContext(ProjectContext)

  const [formNotification, setformNotification] = useState<IAlert | undefined>()
  const [isDisabled, setDisabled] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const {currentUser} = usePageData()

  const [isReadOnly, setIsReadOnly] = useState(false)

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState,
    reset,
    trigger,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: selectedData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(projectFormValidationSchema) as any,
  })

  useEffect(() => {
    if (selectedData) {
      !selectedData.id ? setDisabled(false) : setDisabled(true)
      reset({
        ...selectedData,
      })
      let hasEditPermission = hasPermission(
        PAGE_PERMISSION.PROJECT_DELETE,
        currentUser.activePages || []
      )

      setIsReadOnly(!hasEditPermission)
    } else {
      reset(PROJECT_FORM_DEFAULT)
      setIsReadOnly(false)
    }

    setformNotification(undefined)
  }, [selectedData, reset])

  /*const openModal = () => {
    setModalVisible(true)
  }*/

  const closeModal = () => {
    setModalVisible(false)
  }

  const checkForDuplicates = (projectUsers: IProjectUser[]) => {
    const seenIds = new Set()

    for (const item of projectUsers) {
      if (seenIds.has(item.userId)) {
        return true
      } else {
        seenIds.add(item.userId)
      }
    }

    return false
  }

  const onSubmit = async (values: IProjectData) => {
    const payload: IProjectData = values
    //validate projectUsers
    let customError = ''
    payload.projectUsers.forEach((item, index) => {
      if (!item.userId) {
        customError = 'User is required for each item'
      } else if (!item.projectUserRole) {
        customError = 'Project User Role is required for each item'
      } else if (checkForDuplicates(payload.projectUsers)) {
        customError = 'Duplicate user not allowed'
      }
    })

    if (customError === '') {
      setIsSaving(true)
      if (payload.id === 0) {
        createProject(payload)
          .then(() => {
            setIsSaving(false)
            closeModal()
            fetchData()
            reset(PROJECT_FORM_DEFAULT)
            setSelectedData(initialValue)

            toastr.success('Project saved successfully.', 'Saving Project')
          })
          .catch((err) => {
            setIsSaving(false)
            reset(PROJECT_FORM_DEFAULT)
            setSelectedData(initialValue)

            if (err.response) {
              toastr.error(err.response.data.message, 'Saving Project')
            } else {
              toastr.error(err.message, 'Saving Project ')
            }
            setformNotification({
              message: err.response.data ? err.response.data.message : 'Error saving',
              header: `Error Saving Project`,
              type: 'danger',
            })
          })
      } else {
        updateProject(payload)
          .then(() => {
            setIsSaving(false)
            closeModal()
            fetchData()
            setSelectedData(initialValue)
            toastr.success('Project update successfully.', 'Saving Project')
          })
          .catch((err) => {
            setIsSaving(false)
            if (err.response) {
              toastr.error(err.response.data.message, 'Saving Project')
            } else {
              toastr.error(err.message, 'Saving Project')
            }
            setformNotification({
              message: err.response.data ? err.response.data.message : 'Error saving',
              header: `Error Edit Project`,
              type: 'danger',
            })
          })
      }
    } else {
      setError(`projectUsers`, {
        type: 'manual',
        message: customError,
      })
    }
  }

  const handleCancel = () => {
    setSelectedData(initialValue)
    closeModal()
    //
    reset(PROJECT_FORM_DEFAULT)
    clearErrors()
  }

  return (
    <div style={{display: 'none'}}>
      {isModalVisible && (
        <Dialog
          height={'auto'}
          minWidth={'70%'}
          style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
          title={selectedData.id === 0 ? 'New Project' : 'Edit Project'}
          onClose={handleCancel}
        >
          <form onSubmit={handleSubmit(onSubmit)} name='user'>
            {formNotification && <CustomAlert {...formNotification} />}
            <ProjectFormFields
              projectData={selectedData}
              register={register}
              control={control}
              getValues={getValues}
              setValue={setValue}
              errors={formState.errors}
              clientOptions={clientOptions}
              userOptions={userOptions}
              projectUserRoleOptions={projectUserRoleOptions}
              isDisabled={isDisabled}
              trigger={trigger}
              clearErrors={clearErrors}
              clientOptionFilterChangeHandler={clientOptionFilterChangeHandler}
              isClientOptionLoading={isClientOptionLoading}
              isReadOnly={isReadOnly}
            ></ProjectFormFields>
            <div className='d-flex flex-row align-items-center justify-content-center w-100 mb-2'>
              <div className='d-flex flex-row align-items-center'>
                {isSaving && <Loader2></Loader2>}
                {selectedData.id === 0 ? (
                  <AccessControlProvider allowedPermission={PAGE_PERMISSION.PROJECT_CREATE}>
                    <Button
                      buttonType='primary'
                      disabled={isSaving}
                      text={isSaving ? 'Please wait...' : 'Create Project'}
                    />
                  </AccessControlProvider>
                ) : (
                  <AccessControlProvider allowedPermission={PAGE_PERMISSION.PROJECT_UPDATE}>
                    <Button
                      buttonType='primary'
                      disabled={isSaving}
                      text={isSaving ? 'Please wait...' : 'Update Project'}
                    />
                  </AccessControlProvider>
                )}
                {/* <Button
                  buttonType='primary'
                  disabled={isSaving}
                  text={
                    isSaving
                      ? 'Please wait...'
                      : selectedData.id === 0
                      ? 'Create Project'
                      : 'Update Project'
                  }
                /> */}
                {/* <Button
                  buttonType='secondary'
                  text={'Cancel'}
                  onClick={handleCancel}
                  style={{marginLeft: 10}}
                /> */}
              </div>
            </div>
          </form>
        </Dialog>
      )}
    </div>
  )
}

export default ProjectInfoModal
