/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_metronic/layout/core'
import NewClientInfo from './NewClientInfo'

const AddNewClientPage: FC = (props: any) => {
  return (
    <div
      style={{
        backgroundColor: '#fff',
        height: 'auto',
        padding: 10,
        boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px',
      }}
    >
      <NewClientInfo />
    </div>
  )
}

const AddNewClientWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ADDNEWCLIENT'})}</PageTitle>
      <AddNewClientPage />
    </>
  )
}

export {AddNewClientWrapper}
