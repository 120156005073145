/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {UserModel} from '../../../app/modules/auth/models/UserModel'
import {GetLoginUser} from '../../../app/shared/service/redux-store'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  currentUser: UserModel
  setCurrentUser: (_userModel: UserModel) => void
  isShowSync: boolean
  setIsShowSync: (_isShowSync: boolean) => void
}

export const Initial_User: UserModel = {
  id: '',
  username: '',
  password: '',
  email: '',
  firstName: '',
  lastName: '',
  userRoleDisplay: '',
  userRoles: [],
  userGroups: [],
  isResetPasswordRequired: false,
  projectCount: 0,
  activePages: [],
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setCurrentUser: (_userModel: UserModel) => {},
  currentUser: Initial_User,
  isShowSync: false,
  setIsShowSync: (_isShowSync: boolean) => {},
})

const PageDataProvider: React.FC = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const [currentUser, setCurrentUser] = useState<UserModel>(GetLoginUser())
  const [isShowSync, setIsShowSync] = useState<boolean>(false)

  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    currentUser,
    setCurrentUser,
    isShowSync,
    setIsShowSync,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
  isShowSync?: boolean
}

const PageTitle: FC<Props> = ({children, description, breadcrumbs, isShowSync}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs, setIsShowSync} = usePageData()
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  useEffect(() => {
    if (isShowSync) {
      setIsShowSync(isShowSync)
    }
    return () => {
      setIsShowSync(false)
    }
  }, [isShowSync])

  return <></>
}

const PageDescription: React.FC = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, PageTitle, PageDataProvider, usePageData}
