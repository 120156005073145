import React from 'react'
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form'
import {IAssetClass} from '../../models/asset'
import {Loader2} from '../../../../../_metronic/layout/components/loader/Loader2'

type IAssetForm = {
  errors: FieldErrors<any>
  register: UseFormRegister<IAssetClass>
  clearErrors: UseFormClearErrors<IAssetClass>
  handleSubmit: UseFormHandleSubmit<IAssetClass>
  isSaving: boolean
}

function AssetForm(props: IAssetForm) {
  const {errors, register, clearErrors, isSaving} = props
  return (
    <div className='row mb-8 align-items-center'>
      {isSaving && <Loader2></Loader2>}
      <div className='col-5'>
        <div className='form-outline'>
          <label className='fw-bolder' htmlFor='assetName'>
            Asset Name
          </label>
          <input
            id='assetName'
            maxLength={99}
            type='text'
            className={`form-control${errors.assetName ? ' border-danger' : ''}`}
            placeholder='Enter Asset Name'
            {...register('name', {
              onChange: (e: any) => {
                clearErrors('name')
              },
            })}
          />
          {errors.name && <small className='text-danger'>{errors.name.message}</small>}
        </div>
      </div>
      <div className='col-1' />
      <div className='col-3'>
        <div className='form-outline'>
          <input
            id='fairValue'
            className='form-check-input'
            type='checkbox'
            {...register('fairValue')}
          />{' '}
          <label className='fw-bolder' htmlFor='fairValue'>
            Fair Value
          </label>
        </div>
      </div>
      <div className='col-3'>
        <div className='form-outline'>
          <input
            id='insuranceValuation'
            className='form-check-input'
            type='checkbox'
            {...register('insuranceValuation')}
          />{' '}
          <label className='fw-bolder' htmlFor='insuranceValuation'>
            Insurance Valuation
          </label>
        </div>
      </div>
    </div>
  )
}

export default AssetForm
