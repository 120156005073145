import {
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormClearErrors,
  UseFormHandleSubmit,
  Control,
  UseFormSetValue,
  UseFormGetValues,
  UseFormWatch,
} from 'react-hook-form'
import Select from 'react-select'
import {DatePicker} from '@progress/kendo-react-dateinputs'
import {NumericTextBox} from '@progress/kendo-react-inputs'
import {ISingleLineValutaion} from '../../../models/single-line-valuation'
import SectionTitleHeader from '../SectionTitleHeader'
import {CurrencyFormatter} from '../../../../../shared/service/utils'
import Button from '../../../../../../_metronic/layout/components/buttons/Button'
import {useContext, useEffect, useState} from 'react'
import {IOption} from '../../../models/project-model'
import {ProjectContext} from '../../../context/ProjectContext'
import {formatNumberWithCommas} from '../../../transformers/project-transformer'

type IUnitRateSectionProps = {
  control: Control<ISingleLineValutaion>
  isValid: boolean
  watch: UseFormWatch<ISingleLineValutaion>
  setValue: UseFormSetValue<ISingleLineValutaion>
  getValues: UseFormGetValues<ISingleLineValutaion>
  errors: FieldErrors<ISingleLineValutaion>
  register: UseFormRegister<ISingleLineValutaion>
  clearErrors: UseFormClearErrors<ISingleLineValutaion>
  handleSubmit: UseFormHandleSubmit<ISingleLineValutaion>
  selectedData: ISingleLineValutaion
  setSelectedData: (value: ISingleLineValutaion) => void
  assetClassComponentOptions: IOption[]
  locationOptions: IOption[]
}

function UnitRateSection(props: IUnitRateSectionProps) {
  const {
    selectedData,
    control,
    watch,
    errors,
    clearErrors,
    setValue,
    getValues,
    assetClassComponentOptions,
    locationOptions,
  } = props
  const [locationIndexRateFormat, setLocationIndexRateFormat] = useState<string>('$0.00')
  const [timeIndexRateFormat, setTimeIndexRateFormat] = useState<string>('$0.00')
  const [constructionDifficultyRateFormat, setConstructionDifficultyRateFormat] =
    useState<string>('$0.00')
  const [additionalValuerFormat, setAdditionalValuerFormat] = useState<string>('$0.00')
  const [additionalCostsFormat, setAdditionalCostsFormat] = useState<string>('$0.00')
  const [secondaryAdjustmentFactorRateFormat, setSecondaryAdjustmentFactorRateFormat] =
    useState<string>('$0.00')
  const [mainBaseRateFormat, setMainBaseRateFormat] = useState<string>('$0.00')
  const [mainBaseRateDisabled, setMainBaseRateDisabled] = useState<boolean>(true)
  const {setSingleLineReplacementCost} = useContext(ProjectContext)

  const customStyles = {
    menuPortal: (provided: any) => ({...provided, zIndex: 9999}),
    menu: (provided: any) => ({...provided, zIndex: 9999}),
  }

  const watchedRcUnitRate = watch('rcUnitRate')
  const watchedRcSecondaryRate = watch('rcSecondaryRate')

  const watchedMainAreaBaseRate = watch('mainAreaBaseRate')
  const watchedLocationIndexRate = watch('locationIndexRate')
  const watchedTimeIndex = watch('timeIndex')
  const watchedLocationPremium = watch('locationPremium')
  const watchedAdditionalValuerAdjustmentFactor = watch('additionalValuerAdjustmentFactor')
  const watchedTimeIndexRate = watch('timeIndexRate')
  const watchedConstructionDifficultyFactor = watch('constructionDifficultyFactor')
  const watchedConstructionDifficultyRate = watch('constructionDifficultyRate')
  const watchedSecondaryAdjustmentFactorRate = watch('secondaryAdjustmentFactorRate')
  const watchedSecondaryAdjustmentFactor = watch('secondaryAdjustmentFactor')
  const watchedAdditionalCostsAdjustmentFactor = watch('additionalCostsAdjustmentFactor')

  const onOverride = (value: string) => {
    setMainBaseRateDisabled(!mainBaseRateDisabled)
  }

  useEffect(() => {
    computeSecondaryAdjustmentFactorRate()
  }, [watchedMainAreaBaseRate, watchedSecondaryAdjustmentFactor])

  useEffect(() => {
    computeRCSecondaryRate()
  }, [watchedSecondaryAdjustmentFactorRate])

  useEffect(() => {
    computeReplacementCost()
  }, [watchedAdditionalCostsAdjustmentFactor, watchedRcUnitRate, watchedRcSecondaryRate])

  useEffect(() => {
    computeAdditionalValuerRate()
  }, [watchedConstructionDifficultyRate, watchedAdditionalValuerAdjustmentFactor])

  useEffect(() => {
    computeConstructionDifficultyRate()
  }, [watchedLocationIndexRate, watchedConstructionDifficultyFactor])

  useEffect(() => {
    computeLocationIndexRate()
  }, [watchedTimeIndexRate, watchedLocationPremium])

  useEffect(() => {
    computeTimeIndexRate()
  }, [watchedTimeIndex, watchedMainAreaBaseRate])

  useEffect(() => {
    const mainBaseRate = getValues('mainAreaBaseRate')
    setMainBaseRateFormat('$ ' + mainBaseRate.toFixed(2))
  }, [watchedMainAreaBaseRate])

  useEffect(() => {
    computeRCUnitRate()
  }, [
    watchedMainAreaBaseRate,
    watchedLocationIndexRate,
    watchedTimeIndex,
    watchedLocationPremium,
    watchedAdditionalValuerAdjustmentFactor,
  ])

  useEffect(() => {
    computeTimeIndexRate()
    computeConstructionDifficultyRate()
    computeAdditionalValuerRate()
    computeSecondaryAdjustmentFactorRate()
  }, [selectedData])

  useEffect(() => {
    computeRCSecondaryRate()
    computeReplacementCost()
  }, [watchedRcUnitRate])

  const computeRCUnitRate = () => {
    let timeIndex = getValues('timeIndexRate')
      ? parseFloat(getValues('timeIndexRate').toFixed(2))
      : 0
    let mainAreaBaseRate = getValues('mainAreaBaseRate')
      ? parseFloat(getValues('mainAreaBaseRate').toFixed(2))
      : 0
    let locationIndexRate = getValues('locationIndexRate')
      ? parseFloat(getValues('locationIndexRate').toFixed(2))
      : 0
    let additionalValuerAdjustmentFactor = getValues('additionalValuerAdjustmentFactor')
      ? parseFloat(getValues('additionalValuerAdjustmentFactor').toFixed(2))
      : 0

    const rcUnitRate =
      mainAreaBaseRate * locationIndexRate * timeIndex * additionalValuerAdjustmentFactor

    setValue('rcUnitRate', rcUnitRate)
  }
  const computeRCSecondaryRate = () => {
    let rcUnitRate = getValues('rcUnitRate') === undefined ? 0 : getValues('rcUnitRate')
    let secondaryAdjustmentFactorRate =
      getValues('secondaryAdjustmentFactorRate') === undefined
        ? 0
        : getValues('secondaryAdjustmentFactorRate')

    const rcSecondaryRate =
      parseFloat(rcUnitRate.toFixed(2)) * parseFloat(secondaryAdjustmentFactorRate.toFixed(2))
    setValue('rcSecondaryRate', rcSecondaryRate)
  }

  const computeReplacementCost = () => {
    let rcUnitRate = getValues('rcUnitRate') === undefined ? 0 : getValues('rcUnitRate')
    let rcSecondaryRate =
      getValues('rcSecondaryRate') === undefined ? 0 : getValues('rcSecondaryRate')

    const a = parseFloat(rcUnitRate.toFixed(2)) * parseFloat(getValues('mainArea').toFixed(2))
    const b =
      parseFloat(rcSecondaryRate.toFixed(2)) * parseFloat(getValues('secondaryArea').toFixed(2))
    const replacementCost = a + b + parseFloat(getValues('additionalCostsRate').toFixed(2))

    setValue('replacementCost', replacementCost)
    setSingleLineReplacementCost(replacementCost)
  }

  const computeSecondaryAdjustmentFactorRate = () => {
    let mainAreaBaseRate = getValues('mainAreaBaseRate')
      ? parseFloat(getValues('mainAreaBaseRate').toFixed(2))
      : 0
    let secondaryAdjustmentFactor = getValues('secondaryAdjustmentFactor')
      ? parseFloat(getValues('secondaryAdjustmentFactor').toFixed(2))
      : 0

    const rate = mainAreaBaseRate * secondaryAdjustmentFactor
    setValue('secondaryAdjustmentFactorRate', rate)
    setSecondaryAdjustmentFactorRateFormat(formatNumberWithCommas(rate))
  }

  const computeTimeIndexRate = () => {
    let mainAreaBaseRate = getValues('mainAreaBaseRate')
      ? parseFloat(getValues('mainAreaBaseRate').toFixed(2))
      : 0
    let timeIndex = getValues('timeIndex') ? parseFloat(getValues('timeIndex').toFixed(2)) : 0
    const rate = mainAreaBaseRate * timeIndex

    setValue('timeIndexRate', rate)
    setTimeIndexRateFormat(formatNumberWithCommas(rate))
  }

  const computeLocationIndexRate = () => {
    let locationPremium = getValues('locationPremium')
      ? parseFloat(getValues('locationPremium').toFixed(2))
      : 0
    let timeIndex = getValues('timeIndexRate')
      ? parseFloat(getValues('timeIndexRate').toFixed(2))
      : 0

    const rate = timeIndex * locationPremium

    setValue('locationIndexRate', rate)
    setLocationIndexRateFormat(formatNumberWithCommas(rate))
  }

  const computeConstructionDifficultyRate = () => {
    let locationIndexRate = getValues('locationIndexRate')
      ? parseFloat(getValues('locationIndexRate').toFixed(2))
      : 0
    let constructionDifficultyFactor = getValues('constructionDifficultyFactor')
      ? parseFloat(getValues('constructionDifficultyFactor').toFixed(2))
      : 0

    const rate = locationIndexRate * constructionDifficultyFactor
    setValue('constructionDifficultyRate', rate)
    setConstructionDifficultyRateFormat(formatNumberWithCommas(rate))
  }

  const computeAdditionalValuerRate = () => {
    let constructionDifficultyFactorRate = getValues('constructionDifficultyRate')
      ? parseFloat(getValues('constructionDifficultyRate').toFixed(2))
      : 0
    let additionalValuerAdjustmentFactor = getValues('additionalValuerAdjustmentFactor')
      ? parseFloat(getValues('additionalValuerAdjustmentFactor').toFixed(2))
      : 0

    const rate = constructionDifficultyFactorRate * additionalValuerAdjustmentFactor
    setValue('additionalValuerRate', rate)

    setAdditionalValuerFormat(formatNumberWithCommas(rate))
  }

  return (
    <>
      <div className='d-flex justify-content-between'>
        <SectionTitleHeader title='Unit Rate' showButtons={false} />
      </div>
      <div id='unitRateSection' style={{display: 'flex', flexWrap: 'wrap', gap: '20px'}}>
        <div className={`card bg-dark`} style={{width: '32%'}}>
          <div className='card-body'>
            <div className={`text-inverse-dark fw-bold fs-5 mb-2`}>RC Unit Rate</div>
            <div
              className={`fw-bolder text-inverse-dark fs-1 text-right`}
              style={{textAlign: 'right'}}
            >
              {CurrencyFormatter(getValues('rcUnitRate'))}
            </div>
          </div>
        </div>
        <div className={`card bg-dark`} style={{width: '32%'}}>
          <div className='card-body'>
            <div className={`text-inverse-dark fw-bold fs-5 mb-2`}>RC Secondary Rate</div>
            <div
              className={`fw-bolder text-inverse-dark fs-1 text-right`}
              style={{textAlign: 'right'}}
            >
              {CurrencyFormatter(getValues('rcSecondaryRate'))}
            </div>
          </div>
        </div>
        <div className={`card bg-dark`} style={{width: '32%'}}>
          <div className='card-body'>
            <div className={`text-inverse-dark fw-bold fs-5 mb-2`}>Replacement Cost</div>
            <div
              className={`fw-bolder text-inverse-dark fs-1 text-right`}
              style={{textAlign: 'right'}}
            >
              {CurrencyFormatter(getValues('replacementCost'))}
            </div>
          </div>
        </div>
      </div>
      <div id='unitRateForm'>
        <div className='row h-auto mt-5'>
          <div className='col-3'>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='assetClass'>
                Adopted Base Rate
              </label>
              <Controller
                control={control}
                name='adoptedBaseRate'
                render={({field: {onChange, value}}) => {
                  return (
                    <div>
                      <Select
                        placeholder='Select Adopted BaseRate'
                        styles={customStyles}
                        id='adoptedBaseRate'
                        isMulti={false}
                        options={assetClassComponentOptions}
                        value={{
                          label: value,
                          value: value,
                        }}
                        onChange={(e) => {
                          onChange(e?.value)
                          clearErrors('adoptedBaseRate')
                        }}
                        className={`controllerSelect${
                          errors.adoptedBaseRate ? ' border-danger' : ''
                        }`}
                      />
                      {errors.adoptedBaseRate && (
                        <small className='text-danger'>{errors.adoptedBaseRate.message}</small>
                      )}
                    </div>
                  )
                }}
              />
            </div>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='mainAreaBaseRate'>
                Main Area Base Rate
              </label>

              <div>
                {mainBaseRateDisabled ? (
                  <input
                    style={{
                      fontSize: '1rem',
                      width: '200px',
                      marginRight: '15px',
                      display: 'inline-flex',
                    }}
                    readOnly={true}
                    id='mainAreaBaseRate'
                    className={`form-control${errors.mainAreaBaseRate ? ' border-danger' : ''}`}
                    placeholder='0.00'
                    value={mainBaseRateFormat}
                  />
                ) : (
                  <Controller
                    control={control}
                    name='mainAreaBaseRate'
                    render={({field: {onChange, value}}) => (
                      <NumericTextBox
                        style={{
                          fontSize: '1rem',
                          width: '200px',
                          marginRight: '15px',
                          height: '40px',
                        }}
                        className={`form-control${errors.mainAreaBaseRate ? ' border-danger' : ''}`}
                        defaultValue={value}
                        value={value}
                        format='c2'
                        min={1}
                        max={10}
                        step={0.01}
                        onChange={onChange}
                      />
                    )}
                  />
                )}
                {errors?.mainAreaBaseRate && (
                  <div>
                    <small className='text-danger'>{errors?.mainAreaBaseRate.message}</small>
                  </div>
                )}
                <Button
                  text={'Override'}
                  isSubmit={false}
                  buttonType='section'
                  onClick={onOverride}
                />
              </div>
            </div>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='secondaryAdjustmentFactor'>
                Secondary Adjustment Factor
              </label>
              <Controller
                control={control}
                name='secondaryAdjustmentFactor'
                render={({field: {onChange, value}}) => (
                  <NumericTextBox
                    style={{
                      fontSize: '1rem',
                      height: '40px',
                      width: '120px',
                    }}
                    className={`form-control${
                      errors.secondaryAdjustmentFactor ? ' border-danger' : ''
                    }`}
                    defaultValue={value}
                    value={value}
                    format='n2'
                    min={1}
                    max={10}
                    step={0.01}
                    onChange={onChange}
                  />
                )}
              />
              {errors?.secondaryAdjustmentFactor && (
                <div>
                  <small className='text-danger'>{errors?.secondaryAdjustmentFactor.message}</small>
                </div>
              )}
            </div>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='secondaryAdjustmentFactorRate'>
                Secondary Adjustment Rate
              </label>
              <input
                style={{
                  fontSize: '1rem',
                }}
                readOnly={true}
                id='secondaryAdjustmentFactorRate'
                className={`form-control${
                  errors.secondaryAdjustmentFactorRate ? ' border-danger' : ''
                }`}
                placeholder='0.00'
                value={secondaryAdjustmentFactorRateFormat}
              />
            </div>
            <div className='form-outline mb-4' style={{height: '62px'}}></div>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='componentBreakdown'>
                Component Breakdown
              </label>
              <div>
                <Controller
                  control={control}
                  name='componentBreakdown'
                  render={({field: {onChange, value}}) => (
                    <NumericTextBox
                      style={{
                        fontSize: '1rem',
                        width: '120px',
                        height: '40px',
                      }}
                      className={`form-control${errors.componentBreakdown ? ' border-danger' : ''}`}
                      defaultValue={value}
                      value={value}
                      format='p'
                      min={1}
                      max={4}
                      step={0.01}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.componentBreakdown && (
                  <div>
                    <small className='text-danger'>{errors?.componentBreakdown.message}</small>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='col-3'>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='locationBase'>
                Location Base
              </label>
              <Controller
                control={control}
                name='locationBase'
                render={({field: {onChange, value}}) => (
                  <div>
                    <Select
                      placeholder='Select Location Base'
                      id='locationBase'
                      isMulti={false}
                      options={locationOptions}
                      value={{
                        label: value,
                        value: value,
                      }}
                      onChange={(e) => {
                        onChange(e?.value)
                        clearErrors('locationBase')
                      }}
                      className={`controllerSelect${errors.locationBase ? ' border-danger' : ''}`}
                    />
                    {errors.locationBase && (
                      <div>
                        <small className='text-danger'>{errors.locationBase.message}</small>
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
            <div className='form-outline'>
              <label className='fw-bolder' htmlFor='timeAdjustToPeriod'>
                Time Adjust to Period
              </label>
              <>
                <Controller
                  control={control}
                  name='timeAdjustToPeriod'
                  render={({field: {onChange, value, onBlur}}) => {
                    return (
                      <DatePicker
                        value={value ? new Date(value) : null}
                        onBlur={onBlur}
                        format={'dd-MMM-yyyy'}
                        formatPlaceholder='formatPattern'
                        className={`controllerSelect${
                          errors.timeAdjustToPeriod ? ' border-danger' : ''
                        }`}
                        onChange={(e) => {
                          onBlur()
                          clearErrors('timeAdjustToPeriod')
                          onChange(e.target.value)
                        }}
                      />
                    )
                  }}
                />
                {errors.timeAdjustToPeriod && (
                  <div>
                    <small className='text-danger'>{errors.timeAdjustToPeriod.message}</small>
                  </div>
                )}
              </>
            </div>
          </div>
          <div className='col-3'>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='locationPremium'>
                Location Premium
              </label>
              <div>
                <Controller
                  control={control}
                  name='locationPremium'
                  render={({field: {onChange, value}}) => (
                    <NumericTextBox
                      style={{
                        fontSize: '1rem',
                        height: '40px',
                        width: '120px',
                      }}
                      className={`form-control${errors.locationPremium ? ' border-danger' : ''}`}
                      defaultValue={value}
                      value={value}
                      format='n2'
                      min={1}
                      max={10}
                      step={0.01}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.locationPremium && (
                  <div>
                    <small className='text-danger'>{errors?.locationPremium.message}</small>
                  </div>
                )}
              </div>
            </div>

            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='timeIndex'>
                Time Index
              </label>
              <div>
                <Controller
                  control={control}
                  name='timeIndex'
                  render={({field: {onChange, value}}) => (
                    <NumericTextBox
                      style={{
                        fontSize: '1rem',
                        height: '40px',
                        width: '120px',
                      }}
                      className={`form-control${errors.timeIndex ? ' border-danger' : ''}`}
                      defaultValue={value}
                      value={value}
                      format='n2'
                      min={1}
                      max={10}
                      step={0.01}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.timeIndex && (
                  <div>
                    <small className='text-danger'>{errors?.timeIndex.message}</small>
                  </div>
                )}
              </div>
            </div>

            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='constructionDifficultyFactor'>
                Construction Difficulty Factor
              </label>
              <div>
                <Controller
                  control={control}
                  name='constructionDifficultyFactor'
                  render={({field: {onChange, value}}) => (
                    <NumericTextBox
                      style={{
                        fontSize: '1rem',
                        height: '40px',
                        width: '120px',
                      }}
                      className={`form-control${
                        errors.constructionDifficultyFactor ? ' border-danger' : ''
                      }`}
                      defaultValue={value}
                      value={value}
                      format='n2'
                      min={1}
                      max={10}
                      step={0.01}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.constructionDifficultyFactor && (
                  <div>
                    <small className='text-danger'>
                      {errors?.constructionDifficultyFactor.message}
                    </small>
                  </div>
                )}
              </div>
            </div>

            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='additionalValuerAdjustmentFactor'>
                Additional Valuer Adjustment Factor
              </label>
              <div>
                <Controller
                  control={control}
                  name='additionalValuerAdjustmentFactor'
                  render={({field: {onChange, value}}) => (
                    <NumericTextBox
                      style={{
                        fontSize: '1rem',
                        height: '40px',
                        width: '120px',
                      }}
                      className={`form-control${
                        errors.additionalValuerAdjustmentFactor ? ' border-danger' : ''
                      }`}
                      defaultValue={value}
                      value={value}
                      format='n2'
                      min={1}
                      max={10}
                      step={0.01}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.additionalValuerAdjustmentFactor && (
                  <div>
                    <small className='text-danger'>
                      {errors?.additionalValuerAdjustmentFactor.message}
                    </small>
                  </div>
                )}
              </div>
            </div>

            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='additionalCostsAdjustmentFactor'>
                Additional Costs Adjustment Factor
              </label>
              <div>
                <Controller
                  control={control}
                  name='additionalCostsAdjustmentFactor'
                  render={({field: {onChange, value}}) => (
                    <NumericTextBox
                      style={{
                        fontSize: '1rem',
                        height: '40px',
                        width: '120px',
                      }}
                      className={`form-control${
                        errors.additionalCostsAdjustmentFactor ? ' border-danger' : ''
                      }`}
                      defaultValue={value}
                      value={value}
                      format='n2'
                      min={1}
                      max={10}
                      step={0.01}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.additionalCostsAdjustmentFactor && (
                  <div>
                    <small className='text-danger'>
                      {errors?.additionalCostsAdjustmentFactor.message}
                    </small>
                  </div>
                )}
              </div>
            </div>

            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='professionalFee'>
                Professional Fee
              </label>
              <div>
                <Controller
                  control={control}
                  name='professionalFee'
                  render={({field: {onChange, value}}) => (
                    <NumericTextBox
                      style={{
                        fontSize: '1rem',
                        height: '40px',
                        width: '120px',
                      }}
                      className={`form-control${errors.professionalFee ? ' border-danger' : ''}`}
                      defaultValue={value}
                      value={value}
                      format='n2'
                      min={1}
                      max={10}
                      step={0.01}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.professionalFee && (
                  <div>
                    <small className='text-danger'>{errors?.professionalFee.message}</small>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='col-3'>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='locationIndexRate'>
                Location Index Rate
              </label>
              <input
                style={{
                  fontSize: '1rem',
                }}
                readOnly={true}
                id='locationIndexRate'
                className={`form-control${errors.locationIndexRate ? ' border-danger' : ''}`}
                placeholder='0.00'
                value={locationIndexRateFormat}
              />
            </div>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='timeIndexRate'>
                Time Index Rate
              </label>
              <input
                style={{
                  fontSize: '1rem',
                }}
                readOnly={true}
                id='timeIndexRate'
                className={`form-control${errors.timeIndexRate ? ' border-danger' : ''}`}
                placeholder='0.00'
                value={timeIndexRateFormat}
              />
            </div>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='constructionDifficultyRate'>
                Construction Difficulty Rate
              </label>
              <input
                style={{
                  fontSize: '1rem',
                }}
                readOnly={true}
                id='constructionDifficultyRate'
                className={`form-control${
                  errors.constructionDifficultyRate ? ' border-danger' : ''
                }`}
                placeholder='0.00'
                value={constructionDifficultyRateFormat}
              />
            </div>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='additionalValuerRate'>
                Additional Valuer Rate
              </label>
              <input
                style={{
                  fontSize: '1rem',
                }}
                readOnly={true}
                id='additionalValuerRate'
                className={`form-control${errors.additionalValuerRate ? ' border-danger' : ''}`}
                placeholder='0.00'
                value={additionalValuerFormat}
              />
            </div>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='additionalCostsRate'>
                Additional Costs Rate
              </label>
              <input
                style={{
                  fontSize: '1rem',
                }}
                readOnly={true}
                id='additionalCostsRate'
                className={`form-control${errors.additionalCostsRate ? ' border-danger' : ''}`}
                placeholder='0.00'
                value={additionalCostsFormat}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default UnitRateSection
