import React from 'react'

function InfoCard(props: any) {
  const {label, content, isLoadingInfoCard} = props
  return (
    <div className='bg-light-danger px-4 py-4 rounded-2 d-flex flex-column' style={{width: '100%'}}>
      <h3 className='fs-6 mb-0 fw-bolder'>{label}</h3>
      {isLoadingInfoCard ? (
        <div className='d-flex alig-items-center justify-content-center'>
          <div className='spinner-border' role='status' style={{color: '#a71304'}}>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        <p className='align-self-end mb-0 fs-1 fw-bold'>{content}</p>
      )}
    </div>
  )
}

export default InfoCard
