/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {UserContextProvider} from './context/UserContext'
import {UserManagementPage} from './components/UsersList/UserManagementPage'
import {UserGroupPage} from './components/UserGroupList/UserGroupPage'
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'
import React from 'react'
const UserManagementWrapper: FC = () => {
  const intl = useIntl()
  const [selected, setSelected] = React.useState(0)
  const handleSelect = (e: any) => {
    setSelected(e.selected)
  }

  return (
    <>
      <UserContextProvider>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.USERMANAGEMENT'})}</PageTitle>
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab title='User List'>
            <UserManagementPage />
          </TabStripTab>
          <TabStripTab title='User Groups'>
            <UserGroupPage />
          </TabStripTab>
        </TabStrip>
      </UserContextProvider>
    </>
  )
}

export {UserManagementWrapper}
