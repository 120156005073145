import * as yup from 'yup'
export const componentFormValidationSchema = yup.object().shape({
  id: yup.string(),
  number: yup
    .string()
      .min(1, 'Component number with a minimum of 1 character is required!')
      .required('Component number with a minimum of 1 character is required!'),
  name: yup.string().required('Component name is required!'),
  additionalValuerAdjustmentRate: yup
    .number()
    .positive('Additional Valuer Adjustment Rate must have value')
    .min(1, 'Additional Valuer Adjustment Rate is required')
    .max(9999.99, 'Additional Valuer Adjustment Rate cannot exceed 9,999.99')
    .required('Additional Valuer Adjustment Rate is required!'),
  secondaryAdjustmentFactor: yup
    .number()
    .positive('Secondary Adjustment Factory must have value')
    .min(1, 'Secondary Adjustment Factory is required')
    .max(9999.99, 'Secondary Adjustment Factory cannot exceed 9,999.99')
    .required('Secondary Adjustment Factory is required!'),
  componentTypes: yup
    .array()
    .min(1, 'Select at least 1 component type!')
    .required('Select at least 1 component type!'),

  usefulLife: yup
    .number()
    .positive('Useful Life must have value')
    .min(1, 'Useful Life is required')
    .max(9999.99, 'Useful Life cannot exceed 9,999.99')
    .required('Useful Life is required!'),
})
