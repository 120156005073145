import { GridSetup } from "../models/project-model"

export const EVALUATION_TYPE = {
    insuranceValuation: 'Insurance Valuation',
    marketApproarch: 'Market Approarch'
}

export const INITIAL_GRIDSETUP_ATTACHMENT: GridSetup = {
    sort: [{field: 'id', dir: 'desc'}],
    skip: 0,
    take: 5,
    search: '',
    filter: {
      logic: 'and',
      filters: [],
    },
  }

export type NameProp =
| 'hotWaterSystem'
| 'hvac'
| 'solar'
| 'waterTank'
| 'tankStand'
| 'septicSystem'
| 'fireServices'
| 'lift'
| 'liftSecondary'
| 'alarmSystem'

export type NamePropType =
| 'hotWaterSystemType'
| 'hvacType'
| 'solarSystemSize'
| 'waterTankTotalSize'
| 'tankStandSize'
| 'septicSysteType'
| 'fireServicesPresent'
| 'liftType'
| 'liftSecondaryType'
| 'alarmSystemSubtype'

