import React, {useContext, useEffect, useRef, useState} from 'react'
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridNoRecords,
  GridPageChangeEvent,
} from '@progress/kendo-react-grid'
import {useFieldArray, useForm} from 'react-hook-form'
import {ProjectAssetData} from '../../../models/project-assets'
import {FileViewer} from '../../../models/file-attachment'
import Button from '../../../../../../_metronic/layout/components/buttons/Button'
import {ActionButton} from '../../../../../../_metronic/layout/components/buttons/ActionButton'
import {gearIcon} from '@progress/kendo-svg-icons'
import {downloadAttachment, updateAssetAttachment} from '../../../api/asset-api'
import {ProjectContext} from '../../../context/ProjectContext'
import SectionTitleHeader from '../SectionTitleHeader'
import {GridSetup} from '../../../models/config-default'
import {INITIAL_GRIDSETUP_ATTACHMENT} from '../../../constant/config-defaut'
import {downloadFileFromBase64} from '../../../../../shared/service/utils'
import AttachmentRemoveModal from './AttachmentRemoveModal'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'

type IAssetAttachmentsProps = {
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData) => void
}

function AssetAttachments(props: IAssetAttachmentsProps) {
  const {selectedData, setSelectedData} = props
  const {showToastrNotification} = useContext(ProjectContext)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [selectedAttachment, setSelectedAttachment] = useState<FileViewer>()
  const [selectedIndex, setSelectedIndex] = useState<number>()
  const [isShowDelete, setIsShowDelete] = useState<boolean>(false)
  const [isShowRename, setIsShowRename] = useState<boolean>(false)

  const fileInputRef = useRef<HTMLInputElement>(null)
  const items = ['Rename File', 'Delete File']

  const {control, reset, handleSubmit} = useForm({
    defaultValues: selectedData,
    reValidateMode: 'onSubmit',
  })

  const {
    fields: attachments,
    insert: insertAtachment,
    remove: removeAttachment,
    update: updateAttachment,
    replace: replaceAttachment,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'attachments', // unique name for your Field Array
    keyName: 'idx',
  })

  const onSubmit = (values: ProjectAssetData) => {
    updateAssetAttachment(attachments, values.id)
      .then((response) => {
        const [result]: any = response

        if (result) {
          setSelectedData({
            ...selectedData,
            attachments: result.data,
          })
          replaceAttachment([...result.data])
          showToastrNotification('Asset Saved succesfully', 'success')
          setIsEdit(false)
        } else {
          showToastrNotification('Error Saving Asset. Please try again.', 'error')
        }
      })
      .catch(() => {
        showToastrNotification('Error Saving Asset. Please try again.', 'error')
      })
  }

  useEffect(() => {
    reset({...selectedData})
    // replaceAttachment([...selectedData.attachments])
  }, [selectedData])

  const onFileUpload = () => {
    if (fileInputRef.current) fileInputRef.current.click()
  }

  const uploadImages = (fileEvent: React.ChangeEvent<HTMLInputElement>) => {
    const files = fileEvent.target.files
    let newImageBlobs: FileViewer[] = []
    if (!files) {
      return
    }
    if (files && files !== null) {
      for (let i = 0; i < files.length; i++) {
        const fileItem = files.item(i)
        if (fileItem) {
          const newImageBlob: FileViewer = {
            idx: '',
            file: fileItem,
            fileName: getFileNameWithoutExtension(fileItem.name),
            imageUrl: '',
            uploadDate: new Date().toDateString(),
          }
          newImageBlobs.push(newImageBlob)
          const reader = new FileReader()
          reader.onload = (event: any) => {
            let index = newImageBlobs.findIndex((f) => f.file === files.item(i))
            if (index > -1) newImageBlobs[index].imageUrl = event.target.result

            insertAtachment(0, newImageBlobs[index])
          }
          reader.readAsDataURL(fileItem)
        }
      }
    }
  }

  const handleOnEdit = () => {
    setIsEdit(true)
  }
  const handleOnCancel = () => {
    setIsEdit(false)
    replaceAttachment([...selectedData.attachments])
  }

  const getFileNameWithoutExtension = (fileName: string): string => {
    return fileName.split('.').slice(0, -1).join('.') || ''
  }

  const handleProceedHandler = () => {
    removeAttachment(selectedIndex)
    setSelectedAttachment(undefined)
    setSelectedIndex(0)
    setIsShowDelete(false)
    setIsShowRename(false)
  }

  // Kendo
  const [gridSetup, setGridSetup] = useState<GridSetup>(INITIAL_GRIDSETUP_ATTACHMENT)
  const handlePageChange = (pageProps: GridPageChangeEvent) => {
    setGridSetup({
      ...gridSetup,
      skip: pageProps.page.skip,
      take: pageProps.page.take,
    })
  }
  const handleDropDownClick = (dataItem: any, action: string, dataIndex: number) => {
    setSelectedAttachment(dataItem)
    setSelectedIndex(dataIndex)

    if (action === 'Rename File') {
      // onClickAsset(dataItem)
      setIsShowRename(true)
    } else if (action === 'Delete File') {
      setIsShowDelete(true)
      setIsShowRename(false)
    } else if (action === 'Download') {
      downloadAttachment(dataItem.id, selectedData.id)
        .then((result) => {
          downloadFileFromBase64(
            result.data,
            `${dataItem.fileName}${dataItem.fileExtension}`,
            dataItem.fileType
          )
        })
        .catch(() => {
          showToastrNotification('Failed to download file. Please try again later.', 'error')
        })
    }
  }

  const customHeaderCell = (props: any) => {
    return (
      <>
        <span onClick={props.onClick} className={'k-cell-inner'}>
          <span className={'k-link'}>
            <span
              className={'k-column-title fw-bolder'}
              style={{
                textAlign: 'center',
                marginBottom: 0,
                whiteSpace: 'normal',
                verticalAlign: 'middle',
                overflow: 'visible',
              }}
            >
              {props.title}
            </span>
            {props.children}
          </span>
        </span>
      </>
    )
  }

  const fileNameCell = (props: GridCellProps) => {
    return (
      <td className='align-middle text-left'>
        {isShowRename && props.dataIndex === selectedIndex ? (
          <input
            className='form-control'
            defaultValue={props.dataItem.fileName}
            onBlur={(event) => {
              updateAttachment(props.dataIndex, {
                ...props.dataItem,
                fileName: event.currentTarget.value,
              })
              setIsShowRename(false)
            }}
          />
        ) : (
          <span>{props.dataItem.fileName}</span>
        )}
      </td>
    )
  }

  return (
    <React.Fragment>
      {isShowDelete && (
        <AttachmentRemoveModal
          fileName={selectedAttachment?.fileName || ''}
          handleProceed={handleProceedHandler}
          handleCancel={() => {
            setSelectedAttachment(undefined)
            setIsShowDelete(false)
            replaceAttachment([...selectedData.attachments])
          }}
        ></AttachmentRemoveModal>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <SectionTitleHeader
          title='Asset Attachments'
          isEdit={isEdit}
          onEdit={handleOnEdit}
          onCancel={handleOnCancel}
          permission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}
        />
        <div id='assetAttachments'>
          {isEdit && (
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <input
                type='file'
                accept='image/*,.pdf,.doc,.docx,.xlsx,.xls,.csv'
                multiple
                onChange={uploadImages}
                ref={fileInputRef}
                hidden
              />

              <Button
                text={'Attach File'}
                buttonType='primary'
                onClick={(e) => {
                  e.preventDefault()
                  onFileUpload()
                }}
              />
            </div>
          )}
          <Grid
            // data={attachments}
            data={attachments.slice(gridSetup.skip, gridSetup.take + gridSetup.skip)}
            pageable={true}
            onPageChange={handlePageChange}
            total={attachments.length}
            {...gridSetup}
          >
            <GridNoRecords>No files available</GridNoRecords>
            <GridColumn title='File Name' headerCell={customHeaderCell} cell={fileNameCell} />
            <GridColumn
              title='File Size'
              headerCell={customHeaderCell}
              cell={(props) => {
                return (
                  <td className='align-middle text-left'>
                    {(props.dataItem.file ? props.dataItem.file.size : props.dataItem.length) /
                      1000}{' '}
                    kB
                  </td>
                )
              }}
            />
            <GridColumn
              title='Upload Date'
              headerCell={customHeaderCell}
              cell={(props) => {
                return (
                  <td className='align-middle text-left'>
                    {/* {props.dataItem.file.lastModifiedDate.toDateString()} */}
                    {props.dataItem.file
                      ? props.dataItem.uploadDate
                      : new Date(props.dataItem.created).toDateString()}
                  </td>
                )
              }}
            />
            {
              <GridColumn
                width={130}
                title=''
                cell={(props) => (
                  <td className='align-middle text-center'>
                    <ActionButton
                      svgIcon={gearIcon}
                      text='Actions'
                      items={isEdit ? items : ['Download']}
                      onItemClick={(event: any) =>
                        handleDropDownClick(props.dataItem, event.item, props.dataIndex)
                      }
                    />
                  </td>
                )}
              />
            }
          </Grid>
        </div>
      </form>
    </React.Fragment>
  )
}

export default AssetAttachments
