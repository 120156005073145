import {
  Control,
  UseFormSetValue,
  FieldErrors,
  UseFormRegister,
  UseFormClearErrors,
  UseFormHandleSubmit,
  Controller,
  UseFormWatch,
} from 'react-hook-form'
import TitleHeader from '../../../../../../../../../../_metronic/layout/components/header/TitleHeader'
import {Checkbox, CheckboxChangeEvent} from '@progress/kendo-react-inputs'
import {CheckBoxProps, ProjectAssetData} from '../../../../../../../models/project-assets'
import Select from 'react-select'
import {ProjectContext} from '../../../../../../../context/ProjectContext'
import {useContext} from 'react'

type IBuildingElementsProps = {
  control: Control<ProjectAssetData>
  isValid: boolean
  setValue: UseFormSetValue<ProjectAssetData>
  errors: FieldErrors<ProjectAssetData>
  register: UseFormRegister<ProjectAssetData>
  clearErrors: UseFormClearErrors<ProjectAssetData>
  handleSubmit: UseFormHandleSubmit<ProjectAssetData>
  selectedData: ProjectAssetData
  watch: UseFormWatch<ProjectAssetData>
  setSelectedData: (value: ProjectAssetData[]) => void
}
const customStyles = {
  menuPortal: (provided: any) => ({...provided, zIndex: 9999}),
  menu: (provided: any) => ({...provided, zIndex: 9999}),
}

function BuildingElements(props: IBuildingElementsProps) {
  const {register, control, clearErrors, watch, setValue} = props

  const {buildingElementOption} = useContext(ProjectContext)

  const CheckBoxElement = (props: CheckBoxProps) => (
    <div
      className='d-flex justify-content-between rounded p-4'
      style={{background: props.background, flex: 'calc(50% - 10px)'}}
    >
      <div>
        <Controller
          control={control}
          name={props.name}
          defaultValue={false}
          render={({field: {onChange, value}}) => {
            return (
              <Checkbox
                value={value}
                {...register(props.name)}
                onChange={(e: CheckboxChangeEvent) => {
                  clearErrors(props.name)
                  onChange(e)
                  if (!e.target.value) {
                    setValue(props.subType, 0)
                  }
                }}
              />
            )
          }}
        />
        <label htmlFor={props.name} className='ms-2'>
          {props.checkBoxName}
        </label>
      </div>
      {watch(props.name) && (
        <div style={{width: '300px'}}>
          <div className='text-end'>
            <label htmlFor={props.subType}>SubType</label>
          </div>

          <div>
            <Controller
              control={control}
              name={props.subType}
              render={({field: {onChange, value}}) => {
                return (
                  <div>
                    <Select
                      placeholder='Select Asset Class'
                      styles={customStyles}
                      id='assetClass'
                      isMulti={false}
                      options={props.options}
                      value={props.options.find((item) => item.value === value)}
                      onChange={(e) => {
                        onChange(e?.value)
                        clearErrors('assetClass')
                      }}
                      // className={`controllerSelect${errors[props.subType] ? ' border-danger' : ''}`}
                      className={`controllerSelect${value === 0 ? ' border-danger' : ''}`}
                    />
                    {/* {errors[props.subType] && (
                      <small className='text-danger'>{errors[props.subType]?.message}</small>
                    )} */}
                    {value === 0 && <small className='text-danger'>{'SubType is required!'}</small>}
                  </div>
                )
              }}
            />
          </div>
        </div>
      )}
    </div>
  )

  return (
    <>
      <TitleHeader title='Building Elements' />
      <div id='buildingElements' style={{display: 'flex', flexWrap: 'wrap', gap: '20px'}}>
        <CheckBoxElement
          name='hotWaterSystem'
          subType='hotWaterSystemType'
          background='#EFF2F5'
          checkBoxName='Hot Water System'
          options={buildingElementOption.hotWaterSystems}
        />
        <CheckBoxElement
          name='septicSystem'
          subType='septicSysteType'
          background='rgb(255, 245, 248)'
          checkBoxName='Septic System'
          options={buildingElementOption.septicSystem}
        />
        <CheckBoxElement
          name='hvac'
          subType='hvacType'
          background='rgb(255, 245, 248)'
          checkBoxName='HVAC'
          options={buildingElementOption.hvaCs}
        />
        <CheckBoxElement
          name='fireServices'
          subType='fireServicesPresent'
          background='#EFF2F5'
          checkBoxName='Fire Services'
          options={buildingElementOption.fireServices}
        />
        <CheckBoxElement
          name='solar'
          subType='solarSystemSize'
          background='#EFF2F5'
          checkBoxName='Solar'
          options={buildingElementOption.solars}
        />
        <CheckBoxElement
          name='lift'
          subType='liftType'
          background='rgb(255, 245, 248)'
          checkBoxName='Lift'
          options={buildingElementOption.lift}
        />
        <CheckBoxElement
          name='waterTank'
          subType='waterTankTotalSize'
          background='rgb(255, 245, 248)'
          checkBoxName='Water Tank'
          options={buildingElementOption.waterTank}
        />
        <CheckBoxElement
          name='liftSecondary'
          subType='liftSecondaryType'
          background='#EFF2F5'
          checkBoxName='Lift Secondary'
          options={buildingElementOption.liftSecondary}
        />
        <CheckBoxElement
          name='tankStand'
          subType='tankStandSize'
          background='#EFF2F5'
          checkBoxName='Tank Stand'
          options={buildingElementOption.tankStand}
        />
        <CheckBoxElement
          name='alarmSystem'
          subType='alarmSystemSubtype'
          background='rgb(255, 245, 248)'
          checkBoxName='Alarm System'
          options={buildingElementOption.alarmSystem}
        />
      </div>
    </>
  )
}
export default BuildingElements
