import {IProjectAssetHistoricalData} from '../models/project-asset-historical-data'

export const HISTORICAL_DATA_DEFAULT: IProjectAssetHistoricalData = {
  id: 0,
  projectAssetId: 0,
  assetNumberFromPriorFV: '',
  priorFieldNotes: '',
  priorClientCopyComments: '',
  priorPrimaryUnitRate: 0,
  priorRawlosRate: 0,
  priorAlternateRate: 0,
  priorLocationPremium: '',
  priorMainRateAdjFactor: 0,
  priorSecondRateAdjFactor: 0,
  priorProfessionalFees: 0,
  priorValuationYear: 0,
  priorReplacementCost: 0,
  priorFairValue: 0,
  equivalentRSPScore: 0,
}
