/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useContext, useState, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'

import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {AssetContext, AssetContextProvider} from '../../context/AssetContext'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import {useHistory} from 'react-router-dom'
import AssetComponent from './AssetComponent'
import AssetForm from './AssetForm'
import AddComponentModal from './AddComponentModal'
import {assetFormValidationSchema} from '../../validators/asset-form'
import {Modal} from '../../../../../_metronic/layout/components/modal/Modal'
import {IAssetClass} from '../../models/asset'
import {createAssetClass} from '../../api/asset-class-api'

const AddAssetPage = () => {
  const {
    setAssets,
    assetClassComponents,
    setAssetClassComponents,
    showToastrNotification,
    isModalVisible,
    setModalVisible,
    selectedData,
  } = useContext(AssetContext)

  const [isEdit, setIsEdit] = useState(false)
  const [isPromptVisible, setIsPromptVisible] = useState(true)
  const history = useHistory()
  const [isSaving, setIsSaving] = useState(false)

  const {
    register,
    handleSubmit,
    formState: {errors},
    clearErrors,
  } = useForm({
    defaultValues: selectedData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(assetFormValidationSchema),
  })

  const handleAddNewAsset = (values: IAssetClass) => {
    setIsSaving(true)
    const payload = {
      id: Math.random(),
      fairValue: values.fairValue,
      insuranceValuation: values.insuranceValuation,
      name: values.name,
      assetClassComponents,
    }
    createAssetClass(payload)
      .then((result) => {
        setAssets((prev: any) => [payload, ...prev])
        setIsSaving(false)
        history.push('/asset-classes')
        showToastrNotification('New asset class successfully added', 'success')
      })
      .catch(() => {
        setIsSaving(false)
        showToastrNotification('Oops we encountered a problem. Please try again!', 'error')
      })
  }

  useEffect(() => {
    if (assetClassComponents.length === 0) {
      setIsPromptVisible(true)
    }
  }, [assetClassComponents])

  return (
    <div
      style={{
        backgroundColor: '#fff',
        height: 'auto',
        padding: 26,
        boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px',
      }}
    >
      <form onSubmit={handleSubmit(handleAddNewAsset)} name='asset'>
        <div className='d-flex flex-row align-items-center justify-content-end w-100'>
          <div className='d-flex flex-row align-items-center'>
            <Button buttonType='primary' text={'Save'} />
            <Button
              buttonType='secondary'
              text={'Cancel'}
              onClick={() => {
                history.push('/asset-classes')
              }}
              style={{marginLeft: 10}}
            />
          </div>
        </div>
        <AssetForm
          errors={errors}
          handleSubmit={handleSubmit}
          clearErrors={clearErrors}
          register={register}
          isSaving={isSaving}
        />
        <AssetComponent
          setModalVisible={setModalVisible}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          assetData={assetClassComponents}
          setAssetData={setAssetClassComponents}
        />
      </form>

      {isModalVisible &&
        createPortal(<AddComponentModal isEdit={isEdit} setIsEdit={setIsEdit} />, document.body)}
    </div>
  )
}

const AddAssetClassWrapper: FC = () => {
  const intl = useIntl()

  return (
    <AssetContextProvider>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ADDASSETCLASS'})}</PageTitle>
      <AddAssetPage />
    </AssetContextProvider>
  )
}

export {AddAssetClassWrapper}
