import {NumericTextBox} from '@progress/kendo-react-inputs'
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import SectionTitleHeader from '../SectionTitleHeader'
import {IMarketApproachValuation} from '../../../models/market-approach-valuation'
import {DatePicker} from '@progress/kendo-react-dateinputs'
import {useEffect} from 'react'

type IMarketApproachFairValueSectionProps = {
  control: Control<IMarketApproachValuation>
  isValid: boolean
  watch: UseFormWatch<IMarketApproachValuation>
  setValue: UseFormSetValue<IMarketApproachValuation>
  getValues: UseFormGetValues<IMarketApproachValuation>
  errors: FieldErrors<IMarketApproachValuation>
  register: UseFormRegister<IMarketApproachValuation>
  clearErrors: UseFormClearErrors<IMarketApproachValuation>
  handleSubmit: UseFormHandleSubmit<IMarketApproachValuation>
  selectedData: IMarketApproachValuation
  setSelectedData: (value: IMarketApproachValuation) => void
}

function MarketEvidenceSection(props: IMarketApproachFairValueSectionProps) {
  const {register, control, errors, clearErrors} = props

  const {fields: marketEvidences, update: updateMarketEvidences} = useFieldArray({
    control,
    name: 'marketEvidences',
    keyName: 'keyIdx',
  })

  useEffect(() => {
    console.log('errors', errors)
  }, [errors])

  return (
    <>
      <div className='d-flex justify-content-between ttile'>
        <SectionTitleHeader title='Market Evidence' showButtons={false} />
      </div>
      <div id='marketEvidenceSection'>
        <div id='marketEvidenceForm'>
          {marketEvidences.map((marketEvidence, index) => (
            <div className='card' key={`marketevidence-sale-${index}`}>
              <div
                className='card-header marketEvidenceSection'
                data-bs-toggle='collapse'
                data-bs-target={`#marketevidence-sale-${index}`}
                aria-expanded='false'
              >
                <h3 className='card-title fw-bolder text-dark'>
                  <i className='fa fa-angle-up '></i>
                  <i className='fa fa-angle-down '></i> SALE {index + 1}
                </h3>
              </div>
              <div id={`marketevidence-sale-${index}`} className='card-body collapse'>
                <div className='row h-auto mt-5'>
                  <div className='col-2'>
                    <div className='form-outline mb-4'>
                      <label className='fw-bolder' htmlFor='salesId'>
                        Sales ID
                      </label>
                      <div>
                        <Controller
                          control={control}
                          name={`marketEvidences.${index}.salesId`}
                          render={({field: {onChange, value}}) => (
                            <NumericTextBox
                              style={{
                                fontSize: '1rem',
                                height: '40px',
                              }}
                              className={`form-control${
                                errors.marketEvidences &&
                                errors.marketEvidences[index] &&
                                errors.marketEvidences[index]?.salesId
                                  ? ' border-danger'
                                  : ''
                              }`}
                              format='#'
                              defaultValue={value}
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                        {errors &&
                          errors.marketEvidences &&
                          errors.marketEvidences[index] &&
                          errors.marketEvidences[index]?.salesId && (
                            <div>
                              <small className='text-danger'>
                                {errors.marketEvidences[index]?.salesId?.message}
                              </small>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='form-outline mb-4'>
                      <label className='fw-bolder' htmlFor='streetAddress'>
                        Address
                      </label>
                      <div>
                        <input
                          style={{
                            fontSize: '1rem',
                          }}
                          type='text'
                          id='australisClassification'
                          className={`form-control${
                            errors.adjustmentFactor ? ' border-danger' : ''
                          }`}
                          placeholder='Australis Classification'
                          {...register(`marketEvidences.${index}.streetAddress`, {
                            onChange: (e: any) => {
                              clearErrors('adjustmentFactor')
                            },
                          })}
                        />
                        {errors &&
                          errors.marketEvidences &&
                          errors.marketEvidences[index] &&
                          errors.marketEvidences[index]?.streetAddress && (
                            <div>
                              <small className='text-danger'>
                                {errors.marketEvidences[index]?.streetAddress?.message}
                              </small>
                            </div>
                          )}
                      </div>
                    </div>

                    <div className='form-outline mb-4'>
                      <div className='row h-auto mt-5'>
                        <div className='col-6'>
                          <div className='form-outline mb-4'>
                            <label className='fw-bolder' htmlFor='saleArea'>
                              Sale Area
                            </label>
                            <div>
                              <Controller
                                control={control}
                                name={`marketEvidences.${index}.saleArea`}
                                render={({field: {onChange, value}}) => (
                                  <NumericTextBox
                                    style={{
                                      fontSize: '1rem',
                                      height: '40px',
                                    }}
                                    className={`form-control${
                                      errors.marketEvidences &&
                                      errors.marketEvidences[index] &&
                                      errors.marketEvidences[index]?.saleArea
                                        ? ' border-danger'
                                        : ''
                                    }`}
                                    defaultValue={value}
                                    value={value}
                                    format='n2'
                                    onChange={onChange}
                                  />
                                )}
                              />
                              {errors &&
                                errors.marketEvidences &&
                                errors.marketEvidences[index] &&
                                errors.marketEvidences[index]?.saleArea && (
                                  <div>
                                    <small className='text-danger'>
                                      {errors.marketEvidences[index]?.saleArea?.message}
                                    </small>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='form-outline mb-4'>
                            <label className='fw-bolder' htmlFor='landSize'>
                              Sale Land Size
                            </label>
                            <div>
                              <Controller
                                control={control}
                                name={`marketEvidences.${index}.landSize`}
                                render={({field: {onChange, value}}) => (
                                  <NumericTextBox
                                    style={{
                                      fontSize: '1rem',
                                      height: '40px',
                                    }}
                                    className={`form-control${
                                      errors.marketEvidences &&
                                      errors.marketEvidences[index] &&
                                      errors.marketEvidences[index]?.landSize
                                        ? ' border-danger'
                                        : ''
                                    }`}
                                    defaultValue={value}
                                    value={value}
                                    format='n2'
                                    onChange={onChange}
                                  />
                                )}
                              />
                              {errors &&
                                errors.marketEvidences &&
                                errors.marketEvidences[index] &&
                                errors.marketEvidences[index]?.landSize && (
                                  <div>
                                    <small className='text-danger'>
                                      {errors.marketEvidences[index]?.landSize?.message}
                                    </small>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='form-outline mb-4'>
                      <label className='fw-bolder' htmlFor='saleDate'>
                        Sale Date
                      </label>
                      <div>
                        <Controller
                          control={control}
                          name={`marketEvidences.${index}.saleDate`}
                          render={({field: {onChange, value, onBlur}}) => {
                            return (
                              <DatePicker
                                value={value ? new Date(value) : null}
                                onBlur={onBlur}
                                format={'dd-MMM-yyyy'}
                                formatPlaceholder='formatPattern'
                                className={`controllerSelect${
                                  errors.adjustmentFactor ? ' border-danger' : ''
                                }`}
                                onChange={(e) => {
                                  onBlur()
                                  clearErrors(`marketEvidences.${index}.saleDate`)
                                  onChange(e.target.value)
                                }}
                              />
                            )
                          }}
                        />
                        {errors &&
                          errors.marketEvidences &&
                          errors.marketEvidences[index] &&
                          errors.marketEvidences[index]?.saleDate && (
                            <div>
                              <small className='text-danger'>
                                {errors.marketEvidences[index]?.saleDate?.message}
                              </small>
                            </div>
                          )}
                      </div>
                    </div>

                    <div className='form-outline mb-4'>
                      <label className='fw-bolder' htmlFor='salePrice'>
                        Sale Price
                      </label>
                      <div>
                        <Controller
                          control={control}
                          name={`marketEvidences.${index}.salePrice`}
                          render={({field: {onChange, value}}) => (
                            <NumericTextBox
                              style={{
                                fontSize: '1rem',
                                height: '40px',
                              }}
                              className={`form-control${
                                errors.marketEvidences &&
                                errors.marketEvidences[index] &&
                                errors.marketEvidences[index]?.salePrice
                                  ? ' border-danger'
                                  : ''
                              }`}
                              defaultValue={value}
                              value={value}
                              format='c2'
                              onChange={onChange}
                            />
                          )}
                        />
                      </div>
                      {errors &&
                        errors.marketEvidences &&
                        errors.marketEvidences[index] &&
                        errors.marketEvidences[index]?.salePrice && (
                          <div>
                            <small className='text-danger'>
                              {errors.marketEvidences[index]?.salePrice?.message}
                            </small>
                          </div>
                        )}
                    </div>

                    <div className='form-outline mb-4'>
                      <label className='fw-bolder' htmlFor='timeAdjustmentLandValue'>
                        Time Adjusted Land Value
                      </label>
                      <div>
                        <Controller
                          control={control}
                          name={`marketEvidences.${index}.timeAdjSalePrice`}
                          render={({field: {onChange, value}}) => (
                            <NumericTextBox
                              style={{
                                fontSize: '1rem',
                                height: '40px',
                              }}
                              className={`form-control${
                                errors.marketEvidences &&
                                errors.marketEvidences[index] &&
                                errors.marketEvidences[index]?.timeAdjSalePrice
                                  ? ' border-danger'
                                  : ''
                              }`}
                              defaultValue={value}
                              value={value}
                              format='c2'
                              onChange={onChange}
                            />
                          )}
                        />
                      </div>
                      {errors &&
                        errors.marketEvidences &&
                        errors.marketEvidences[index] &&
                        errors.marketEvidences[index]?.timeAdjSalePrice && (
                          <div>
                            <small className='text-danger'>
                              {errors.marketEvidences[index]?.timeAdjSalePrice?.message}
                            </small>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='form-outline mb-4'>
                      <label className='fw-bolder' htmlFor='comparison'>
                        Comparison
                      </label>
                      <div>
                        <textarea
                          rows={5}
                          className={`form-control${
                            errors.marketEvidences &&
                            errors.marketEvidences[index] &&
                            errors.marketEvidences[index]?.comment
                              ? ' border-danger'
                              : ''
                          }`}
                          placeholder='Enter Comparison'
                          {...register(`marketEvidences.${index}.comparison`, {
                            onChange: (e) => {
                              clearErrors(`marketEvidences.${index}.comparison`)
                            },
                          })}
                        />
                        {errors &&
                          errors.marketEvidences &&
                          errors.marketEvidences[index] &&
                          errors.marketEvidences[index]?.comparison && (
                            <div>
                              <small className='text-danger'>
                                {errors.marketEvidences[index]?.comparison?.message}
                              </small>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className='form-outline mb-4'>
                      <label className='fw-bolder' htmlFor='comment'>
                        Comments
                      </label>
                      <div>
                        <textarea
                          rows={5}
                          className={`form-control${
                            errors.marketEvidences &&
                            errors.marketEvidences[index] &&
                            errors.marketEvidences[index]?.comment
                              ? ' border-danger'
                              : ''
                          }`}
                          placeholder='Enter Comment'
                          {...register(`marketEvidences.${index}.comment`, {
                            onChange: (e) => {
                              clearErrors(`marketEvidences.${index}.comment`)
                            },
                          })}
                        />
                        {errors &&
                          errors.marketEvidences &&
                          errors.marketEvidences[index] &&
                          errors.marketEvidences[index]?.comment && (
                            <div>
                              <small className='text-danger'>
                                {errors.marketEvidences[index]?.comment?.message}
                              </small>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
export default MarketEvidenceSection
