import axios from 'axios'
import {IClient} from '../models/client'
import {SortDescriptor} from '@progress/kendo-data-query'

export const CLIENT_URL = `${process.env.REACT_APP_API_URL}/client`
export const PROJECTS_URL = `${process.env.REACT_APP_API_URL}/Project`

export const getClientById = async (id: any) => {
  return await axios.get(`${CLIENT_URL}/getclientbyid`, {
    params: {
      id,
    },
  })
}

export const addNewClient = async (client: IClient) => {
  try {
    var data = await axios.post(`${CLIENT_URL}`, client)
    return [data, null]
  } catch (err) {
    return [null, err]
  }
}

export const updateClient = async (client: IClient) => {
  try {
    var data = await axios.put(`${CLIENT_URL}`, client)
    return [data, null]
  } catch (err) {
    return [null, err]
  }
}

export const deleteClient = async (id: number) => {
  return await axios.delete(`${CLIENT_URL}`, {
    params: {
      id,
    },
  })
}

export const getClientProjectsPagination = async (
  clientId: number,
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  search: string
) => {
  return await axios.get(`${PROJECTS_URL}/getclientprojectspagination`, {
    params: {
      //...userSearch,
      clientId,
      filter: search,
      skip,
      take,
      sort: pageSort ? `${pageSort.field} ${pageSort.dir}` : '',
    },
  })
}
