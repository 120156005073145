/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useContext, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import Button from '../../../_metronic/layout/components/buttons/Button'
import {
  OfficeLocationsContext,
  OfficeLocationsContextProvider,
  officeInitialValue,
} from './context/OfficeLocationsContext'
import TitleHeader from '../../../_metronic/layout/components/header/TitleHeader'
import {getAllOffices} from './api'
import OfficeLocationsListTable from './components/OfficeLocationsList/OfficeLocationsListTable'
import NewOfficeInfoModal from './components/AddNewOffice/NewOfficeInfoModal'
import {GridSetup} from '../projects/models/project-model'
import {Initial_GridSetup} from '../projects/constant/project-default'

const OfficeLocationsPage = () => {
  const {setModalVisible, setInitialValue, setSearchFilter} = useContext(OfficeLocationsContext)

  // useEffectOnce(() => {
  //   if (!offices) {
  //     fetchAllOffices()
  //   }
  // })

  /**HACK */
  const [gridSetup, setGridSetup] = useState<GridSetup>(Initial_GridSetup)
  const [allOffices, setAllOffices] = useState<any>([])

  const [searchTerm, setSearchTerm] = useState('')
  const [isEdit, setIsEdit] = useState(false)

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      setSearchFilter(searchTerm)
      event.preventDefault()
    }
  }

  return (
    <div
      style={{
        backgroundColor: '#fff',
        height: 'auto',
        padding: 26,
        boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px',
      }}
    >
      <TitleHeader title='Office List' />
      {/*  */}
      <div className='d-flex align-items-center justify-content-between'>
        <div style={{position: 'relative'}}>
          <input
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value)
            }}
            onKeyDown={handleKeyPress}
            type='text'
            placeholder='Search Offices'
            style={{
              minWidth: 400,
              minHeight: 35,
              borderRadius: 17.5,
              border: '1px solid #E5E4E2',
              paddingLeft: 30,
              outline: 'none',
              fontStyle: 'italic',
            }}
          />
          <i
            className='bi bi-search'
            style={{position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)'}}
          ></i>
        </div>
        <div className='d-flex align-items-center'>
          <Button
            text={'New Office'}
            buttonType='primary'
            onClick={() => {
              setIsEdit(false)
              setModalVisible(true)
              setInitialValue({...officeInitialValue})
            }}
            icon={<i className='bi bi-plus-lg' style={{color: '#fff'}}></i>}
          />
        </div>
      </div>
      <OfficeLocationsListTable
        showOfficeModal={setModalVisible}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        allOffices={allOffices}
      />
      <NewOfficeInfoModal isEdit={isEdit} setIsEdit={setIsEdit} />
    </div>
  )
}

const OfficeLocationsWrapper: FC = () => {
  const intl = useIntl()

  return (
    <OfficeLocationsContextProvider>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.OFFICES'})}</PageTitle>
      <OfficeLocationsPage />
    </OfficeLocationsContextProvider>
  )
}

export {OfficeLocationsWrapper}
