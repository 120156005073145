import {InteractionType} from '@azure/msal-browser'
import {useMsal} from '@azure/msal-react'
import authConfig from './authConfig'
import * as auth from '../redux/AuthRedux'
import {useDispatch} from 'react-redux'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {loginEntraUser} from '../redux/AuthCRUD'

export const SignInButton = () => {
  const {instance} = useMsal()
  const dispatch = useDispatch()

  function handleLogin() {
    if (authConfig.loginMode === InteractionType.Redirect) {
      instance.loginRedirect({scopes: []}).catch((e: any) => {
        console.error('MSAL login error:', e)
      })
    }
    if (authConfig.loginMode === InteractionType.Popup) {
      loginPopUp()
    }
  }

  // ReCaptcha execute
  const {executeRecaptcha} = useGoogleReCaptcha()

  const loginPopUp = async () => {
    try {
      const loginResponse = await instance.loginPopup({scopes: []})
      instance.setActiveAccount(loginResponse.account)
      /*const tokenResponse: any = await instance.acquireTokenPopup({
        scopes: authConfig.api.scopes,
        account: loginResponse.account,
      })*/
      logInAccount(loginResponse.account.username ?? '')
      //dispatch(auth.actions.login(tokenResponse.accessToken, tokenResponse.refreshToken, true))
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const logInAccount = async (email: string) => {
    const tokenRecaptcha = await executeRecaptcha('login')
    loginEntraUser(email, tokenRecaptcha).then(
      ({data: {token, twoFactorEnabled, name, longTermToken, refreshToken}}) => {
        localStorage.setItem('refresh-token', refreshToken)
        dispatch(auth.actions.login(token, refreshToken, true))
      }
    )
  }

  return (
    <button
      className='btn btn-sm btn-block fs-6'
      style={{
        backgroundColor: '#fff',
        width: '75%',
        position: 'relative',
      }}
      type='button'
      onClick={handleLogin}
    >
      <img
        src={toAbsoluteUrl('/media/icons/Microsoft.svg')}
        alt='Microsoft Logo'
        style={{position: 'absolute', left: 12}}
      />
      <p
        style={{
          fontWeight: 'bold',
          color: '#000',
          marginBottom: 0,
          textAlign: 'center',
        }}
      >
        Sign in with Microsoft
      </p>
    </button>
  )
}
