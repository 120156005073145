import * as yup from 'yup'

export const buildingReplacementRateSchema = yup.object().shape({
  valuationRegion: yup.string().required('Valuation region is required!').nullable(),
  locationBase: yup.string().required('Location base is required').nullable(),
  mainAreaType: yup.string().required('Main area type is required').nullable(),
  secondaryAreaType: yup.string().required('Secondary area type is required').nullable(),
  mainAreaNum: yup
    .number()
    .typeError('Main area must be a number')
    .min(1, 'Must be at least 1')
    .max(9999, 'Main area must not exceed to 4 numbers')
    .required('Main area is required!'),
  secondaryQuantity: yup
    .number()
    .typeError('Secondary quantity must be a number')
    .min(1, 'Must be at least 1')
    .max(9999, 'Secondary Quantity must not exceed to 4 numbers')
    .required('Secondary Quantity is required!'),
  locationPremiumAdjustment: yup
    .number()
    .typeError('Location premium must be a number')
    .min(1, 'Must be at least 1')
    .max(9999, 'Location premium must not exceed to 4 numbers'),
  timeIndex: yup
    .number()
    .typeError('Time index must be a number')
    .min(1, 'Must be at least 1')
    .max(9999, 'Location premium must not exceed to 4 numbers'),
})

export const assetInformationValidationSchema = yup.object().shape({
  // General Details
  id: yup.string(),
  //Asset Information
  asbestosStatus: yup.string().required('Asbestos status is required!').nullable(),
  assetClass: yup.string().required('Asset class is required!').nullable(),
  assetType: yup.string().required('Asset type is required!').nullable(),
  australisClassification: yup.string().max(99, 'Character limit exceeded!').nullable(),
  valuationDate: yup.date().required('Valuation date is required!').nullable(),
  valuationType: yup.string().required('Valuation type is required!').nullable(),
})
