import {Controller, useForm} from 'react-hook-form'
import {Checkbox, CheckboxChangeEvent} from '@progress/kendo-react-inputs'
import Select from 'react-select'
import {useContext, useEffect, useState} from 'react'
import {BuildingElement, CheckBoxProps, ProjectAssetData} from '../../../models/project-assets'
import {ProjectContext} from '../../../context/ProjectContext'
import {yupResolver} from '@hookform/resolvers/yup'
import {updateBuildingElement} from '../../../api/asset-api'
import {buildingElementFormValidationSchema} from '../../../validators/buildingelement-form'
import SectionTitleHeader from '../SectionTitleHeader'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'

type IBuildingElementsProps = {
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData) => void
  onHandleFormChange: (isDirty: boolean) => void
}

const customStyles = {
  menuPortal: (provided: any) => ({...provided, zIndex: 9999}),
  menu: (provided: any) => ({...provided, zIndex: 9999}),
}

function BuildingElements(props: IBuildingElementsProps) {
  const {buildingElementOption, showToastrNotification} = useContext(ProjectContext)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const {selectedData, setSelectedData} = props

  const {
    register,
    control,
    handleSubmit,
    formState: {errors, isValid, isDirty},
    clearErrors,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    defaultValues: selectedData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(buildingElementFormValidationSchema) as any,
  })

  useEffect(() => {
    props.onHandleFormChange(isDirty)
  }, [isDirty])

  useEffect(() => {
    reset({...selectedData})
    props.onHandleFormChange(false)
  }, [selectedData, reset])

  const ViewCheckBoxElement = (viewProps: CheckBoxProps) => (
    <div
      className='d-flex flex-row rounded p-4'
      style={{background: viewProps.background, flex: 'calc(50% - 10px)'}}
    >
      <div>
        <Checkbox checked={getValues(viewProps?.name)} disabled />
      </div>
      <div>
        <div className='fw-bold ms-2'>{viewProps?.checkBoxName}</div>
        {/* {console.log(watch(viewProps.subType))}
          {console.log(props.selectedData[viewProps.subType])} */}
        {getValues(viewProps?.subType) > 0 && (
          <div className='ms-2'>
            <span className='fw-bold'>Subtype:</span>
            <span className='ms-5'>
              {viewProps.options && viewProps.options.length > 0
                ? viewProps.options.find((item) => item.value === +getValues(viewProps?.subType))
                    ?.label
                : ''}
            </span>
          </div>
        )}
      </div>
    </div>
  )

  const CheckBoxElement = (checkProps: CheckBoxProps) => (
    <div
      className='d-flex justify-content-between rounded p-4'
      style={{background: checkProps.background, flex: 'calc(50% - 10px)'}}
    >
      <div>
        <Controller
          control={control}
          name={checkProps.name}
          defaultValue={false}
          render={({field: {onChange, value}}) => {
            return (
              <Checkbox
                value={value}
                {...register(checkProps.name)}
                onChange={(e: CheckboxChangeEvent) => {
                  clearErrors(checkProps.name)
                  onChange(e)
                  if (!e.target.value) {
                    setValue(checkProps.subType, 0)
                  }
                }}
              />
            )
          }}
        />
        <label htmlFor={checkProps.name} className='ms-2'>
          {checkProps.checkBoxName}
        </label>
      </div>
      {watch(checkProps.name) && (
        <div style={{width: '300px'}}>
          <div className='text-end'>
            <label htmlFor={checkProps.subType}>SubType</label>
          </div>

          <div>
            <Controller
              control={control}
              name={checkProps.subType}
              render={({field: {onChange, value}}) => {
                return (
                  <div>
                    <Select
                      placeholder='Select Asset Class'
                      styles={customStyles}
                      id='assetClass'
                      isMulti={false}
                      options={checkProps.options}
                      value={checkProps.options.find((item) => item.value === +value)}
                      onChange={(e) => {
                        onChange(e?.value)
                        clearErrors('assetClass')
                      }}
                      className={`controllerSelect${value === 0 ? ' border-danger' : ''}`}
                    />
                    {value === 0 && <small className='text-danger'>{'SubType is required!'}</small>}
                    {errors[checkProps.subType] ? (
                      <small className='text-danger'>{'SubType is required!'}</small>
                    ) : null}
                  </div>
                )
              }}
            />
          </div>
        </div>
      )}
    </div>
  )

  const renderFormComponent = (additionalProps: CheckBoxProps) => {
    return isEdit ? (
      <CheckBoxElement {...additionalProps} />
    ) : (
      <ViewCheckBoxElement {...additionalProps} />
    )
  }
  const onSubmit = (values: BuildingElement) => {
    if (!isEdit || !isValid) return

    updateBuildingElement(selectedData.id, values).then((response) => {
      if (response) {
        setIsEdit(!isEdit)
        showToastrNotification('Building Element Saved succesfully', 'success')
        setSelectedData({
          ...selectedData,
          ...values,
        })
      } else {
        setIsEdit(!isEdit)
        showToastrNotification('Error Saving Building Element. Please try again.', 'error')
      }
    })
    props.onHandleFormChange(false)
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='d-flex justify-content-between'>
          <SectionTitleHeader
            title='Building Elements'
            isEdit={isEdit}
            onEdit={() => setIsEdit(true)}
            onCancel={() => {
              setIsEdit(false)
              reset({...selectedData})
              props.onHandleFormChange(false)
            }}
            permission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}
          />
        </div>
        <div id='buildingElements' style={{display: 'flex', flexWrap: 'wrap', gap: '20px'}}>
          {renderFormComponent({
            name: 'hotWaterSystem',
            subType: 'hotWaterSystemType',
            background: '#EFF2F5',
            checkBoxName: 'Hot Water System',
            options: buildingElementOption?.hotWaterSystems,
          })}
          {renderFormComponent({
            name: 'septicSystem',
            subType: 'septicSysteType',
            background: 'rgb(255, 245, 248)',
            checkBoxName: 'Septic System',
            options: buildingElementOption?.septicSystem,
          })}
          {renderFormComponent({
            name: 'hvac',
            subType: 'hvacType',
            background: 'rgb(255, 245, 248)',
            checkBoxName: 'HVAC',
            options: buildingElementOption?.hvaCs,
          })}
          {renderFormComponent({
            name: 'fireServices',
            subType: 'fireServicesPresent',
            background: '#EFF2F5',
            checkBoxName: 'Fire Services',
            options: buildingElementOption?.fireServices,
          })}
          {renderFormComponent({
            name: 'solar',
            subType: 'solarSystemSize',
            background: '#EFF2F5',
            checkBoxName: 'Solar',
            options: buildingElementOption?.solars,
          })}
          {renderFormComponent({
            name: 'lift',
            subType: 'liftType',
            background: 'rgb(255, 245, 248)',
            checkBoxName: 'Lift',
            options: buildingElementOption?.lift,
          })}
          {renderFormComponent({
            name: 'waterTank',
            subType: 'waterTankTotalSize',
            background: 'rgb(255, 245, 248)',
            checkBoxName: 'Water Tank',
            options: buildingElementOption?.waterTank,
          })}
          {renderFormComponent({
            name: 'liftSecondary',
            subType: 'liftSecondaryType',
            background: '#EFF2F5',
            checkBoxName: 'Lift Secondary',
            options: buildingElementOption?.liftSecondary,
          })}
          {renderFormComponent({
            name: 'tankStand',
            subType: 'tankStandSize',
            background: '#EFF2F5',
            checkBoxName: 'Tank Stand',
            options: buildingElementOption?.tankStand,
          })}
          {renderFormComponent({
            name: 'alarmSystem',
            subType: 'alarmSystemSubtype',
            background: 'rgb(255, 245, 248)',
            checkBoxName: 'Alarm System',
            options: buildingElementOption?.alarmSystem,
          })}
        </div>
      </form>
    </>
  )
}
export default BuildingElements
