/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ProjectContextProvider} from './context/ProjectContext'
import {ProjectManagementPage} from './components/ProjectsList/ProjectManagementPage'
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom'
import Button from '../../../_metronic/layout/components/buttons/Button'
import {ProjectInformationPage} from './components/information/ProjectInformationPage'
import AssetInformationPage from './components/assetinformation/AssetInformationPage'

const ProjectManagementWrapper: FC = () => {
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()
  const [showProjectInfo, setShowProjectInfo] = useState(false)

  useEffect(() => {
    const path = location.pathname
    if (path.startsWith('/projects/') && path !== '/projects/list') {
      setShowProjectInfo(true)
    } else {
      setShowProjectInfo(false)
    }
  }, [location.pathname])

  return (
    <>
      <Switch>
        <Redirect exact from='/projects' to='/projects/list' />
        <ProjectContextProvider>
          <Route exact path='/projects/list'>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PROJECTS'})}</PageTitle>
            <ProjectManagementPage />
          </Route>
          <Route exact path='/projects/:projectId/asset/:assetId'>
            <PageTitle />
            <div style={{position: 'absolute', zIndex: 99, right: 30, top: 'calc(71.8875px)'}}>
              <Button
                buttonType='primary'
                text={'Return to Project Page'}
                onClick={() => history.goBack()}
              />
            </div>
            <AssetInformationPage />
          </Route>
          {showProjectInfo && (
            <Route exact path='/projects/:projectId'>
              <PageTitle>Project Information</PageTitle>
              <ProjectInformationPage />
            </Route>
          )}
        </ProjectContextProvider>
      </Switch>
    </>
  )
}

export default ProjectManagementWrapper
