import {IOffice} from '../../office-list/models/office'
import {IOfficeData, IOption, IRole} from '../models/user-model'

export const transformSaveAdminRoles = (option: IOption[]) => {
  return (option || []).map((add) => add.value)
}

export const transformDataAdminRoles = (roles: string[]) => {
  return (roles || []).map((role) => ({
    value: role,
    label: role,
  }))
}

export const transformDataStatus = (status: string) => {
  return status === 'Active' ? '1' : '0'
}

export const transformDataRoles = (roles: IRole[]) => {
  return (roles || []).map((role) => ({
    value: role.name,
    label: role.name,
  }))
}

export const transformSaveUserGroups = (option: IOption[]) => {
  return (option || []).map((add) => add.value)
}

export const transformSaveUserOffices = (option: IOption[]) => {
  return (option || []).map((add) => +add.value)
}

export const transformDataUserGroups = (userGroups: IOption[]) => {
  return (userGroups || []).map((userGroup) => ({
    value: userGroup.label,
    label: userGroup.label,
  }))
}

export const transformDataUserGroupNames = (userGroups: string[]) => {
  return (userGroups || []).map((userGroup) => ({
    value: userGroup,
    label: userGroup,
  }))
}

export const transformDataUserOffices = (userGroups: IOfficeData[]) => {
  return (userGroups || []).map((userGroup) => ({
    value: userGroup?.id,
    label: userGroup.name,
  }))
}

export const transformDataOffices = (users: IOffice[]) => {
  return (users || []).map((user: any) => ({
    value: user.id,
    label: user.name,
  }))
}
