import {useDispatch} from 'react-redux'
import {useEffectOnce} from 'react-use'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'

export function Signout() {
  const dispatch = useDispatch()
  useEffectOnce(() => {
    dispatch(auth.actions.logout())
  })
  return <>Sign-in Out</>
}
