import React from 'react'
import TitleHeaderBorder from './TitleHeaderBorder'

function TitleHeader(props: any) {
  const {title} = props
  return (
    <>
      <div className='d-flex justify-content-between align-items-center '>
        <div style={{width: '100%'}}>
          <h3
            style={{
              color: '#000',
              marginBottom: 0,
              paddingLeft: 10,
              paddingTop: 5,
              paddingBottom: 5,
              fontWeight: 600,
            }}
          >
            {title}
          </h3>
        </div>
      </div>
      <TitleHeaderBorder />
    </>
  )
}

export default TitleHeader
