/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {Redirect, Route, Switch, useHistory} from 'react-router'
import {PageTitle} from '../../../_metronic/layout/core'
import {ReportsPage} from './components/ReportsPage'
import {ReportContextProvider} from './context/ReportContext'

const ReportsWrapper: FC = () => {
  const intl = useIntl()
  /*
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.REPORTS'})}</PageTitle>
      <ReportsPage />
    </>
  )*/
  const history = useHistory()
  return (
    <Switch>
      <Redirect exact from='/reports' to='/reports/list' />
      <ReportContextProvider>
        <Route exact path='/reports/list'>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.REPORTS'})}</PageTitle>
          <ReportsPage />
        </Route>
      </ReportContextProvider>
    </Switch>
  )
}

export {ReportsWrapper}
