/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import {useDispatch} from 'react-redux'
import * as auth from '../redux/AuthRedux'
import { Loader1 } from '../../../../_metronic/layout/components/loader/Loader1'

const RememberMe: React.FC = () => {
  //export function Login2Fa() {
  const [loading, setLoading] = useState(false)
  const rememberMeStorage = JSON.parse(localStorage.getItem('remember-me') as any)
  const dispatch = useDispatch()

  const onClickHandler = (isYes: boolean) => {
    setLoading(true)  
    if (isYes) {
      dispatch(auth.actions.login(rememberMeStorage.longTermToken, rememberMeStorage.refreshToken, true))
    } else {
      dispatch(auth.actions.login(rememberMeStorage.token, rememberMeStorage.refreshToken, false))
    }
  }

  return (
    <div className='form w-100' id='kt_login_signin_form'>
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <div className='text-gray-100 fw-bold mb-3 fs-1'>Welcome, {rememberMeStorage?.name}</div>
        <div className='text-gray-100 fw-bold mb-3 fs-3'>Stay Signed In?</div>
        <div className='text-gray-400 fw-bold'>
          Stay signed in so you do not have to sign in again next time.
        </div>
      </div>
      {
        loading ? (
          <Loader1></Loader1>
        ) : (
        <div className='text-center'>
            <div className='d-flex justify-content-around'>
              <button
                type='button'
                className='btn btn-primary w-100 fw-bolder'
                onClick={() => onClickHandler(true)}
                style={{fontSize: '1.15rem'}}
              >
                Yes
              </button>
              <div className='mx-4' />
              <button
                type='button'
                className='btn custom-btn w-100 fw-bolder'
                onClick={() => onClickHandler(false)}
                style={{fontSize: '1.15rem'}}
              >
                No
              </button>
            </div>
          </div>
        )
      }
      
      {/* end::Action */}
    </div>
  )
}

export {RememberMe}
