import { IOffice } from "../models/office";


export const OFFICE_DEFAULT: IOffice =  {
    id: 0,
    name: '',
    code: '',
    address: '',
    suburb: '',
    state: '',
    postCode: '',
    status: '',
  }