import React from 'react'
import {ProjectAssetData} from '../../../models/project-assets'
import ComponentizedForm from './component-valuation/ComponentizedForm/ComponentizedForm'

type IAssetInformationTabProps = {
  assetData: ProjectAssetData
  onHandleFormChange: (isDirty: boolean) => void
}

function ComponentValuationTab(props: IAssetInformationTabProps) {
  return (
    <React.Fragment>
      <ComponentizedForm
        assetData={props.assetData}
        onHandleFormChange={props.onHandleFormChange}
      ></ComponentizedForm>
    </React.Fragment>
  )
}

export default ComponentValuationTab
