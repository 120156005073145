import {useInternationalization} from '@progress/kendo-react-intl'
import moment from 'moment'
import {PAGE_PERMISSION} from '../../modules/roles/models/role-model'

const SQL_DATE_FORMAT = 'yyyyMMdd hh:mm:ss'
const mappings: any = {
  eq: "{0} = '{1}'",
  neq: "{0} != '{1}'",
  isnull: '{0} IS NULL',
  isnotnull: '{0} IS NOT NULL',
  lt: "{0} < '{1}'",
  lte: "{0} <= '{1}'",
  gt: "{0} > '{1}'",
  gte: "{0} >= '{1}'",
  startswith: "{0} LIKE '{1}%'",
  doesnotstartwith: "{0} NOT LIKE '{1}%'",
  contains: "{0} LIKE '%{1}%'",
  doesnotcontain: "{0} NOT LIKE '%{1}%'",
  isempty: "{0} = ''",
  isnotempty: "{0} != ''",
}

export const LS_DEFAULTSELECTEDOFFICE = 'ls_defaultselectedoffice'

export const toSQLExpression = (filter: any) => {
  if (!filter) return
  var {filters} = filter
  var result = ''
  for (let i = 0; i < filters.length; i++) {
    if (i !== 0) {
      result += ` ${filter.logic} `
    }

    var {operator, field, value} = filters[i]
    var mapping = mappings[operator]
    let type: typeof value

    if (type === 'date') {
      value = value.toString(value, SQL_DATE_FORMAT)
    }
    var query = mapping.replace('{0}', field).replace('{1}', value)
    result += query
  }

  return result
}

export const toObjectExpression = (filter: any) => {
  var data: any = {}
  ;((filter !== null && filter.filters) || []).forEach((filter: any) => {
    var {field, value} = filter
    data[field] = value
  })
  return data
}

export const toLinQExpression = (filter: any) => {
  let data: any = ''
  ;((filter !== null && filter.filters) || []).forEach((filter: any, index: number) => {
    if (index > 0) data += ' and '
    var {field, value, type} = filter

    if (typeof 0 === type) {
      data += `${field} = (${value})`
    } else if (typeof true === type) {
      if (field === 'Inactive') {
        data += `${field}.equals(${value !== 'Active'} || ${value === 'Delivered'})`
      } else data += `${field}.equals(${value === 'Active'} || ${value === 'Delivered'})`
    } else if (typeof new Date() === type) {
      if (value) {
        const newValue = dateFormat(value)
        data += `${field} = DateTime.ParseExact("${newValue}", "dd/MM/yyyy", null)`
      }
    } else data += `${field}.contains("${value}")`
  })
  return data
}

// mm/dd/yyyy date args
export const dateFormat = (date: any) => {
  if (!date) return null
  date = new Date(date)
  return moment(date).format('DD/MM/YYYY')
}

// dd/mm/yyyy to dates
export const dateFormatDDMMYYY = (dateString: any) => {
  if (!dateString) return null
  var dateParts = dateString.split('/')

  return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]).setSeconds(1)
}

export const validateDate = (date: Date) => {
  if (!moment(date).isValid()) return false

  if (date.getFullYear() < 1900) return false

  return true
}

export const parseDdMmYyyyStringToDate = (str: string): Date | null => {
  const parsed = moment(str, 'DD/MM/YYYY', true)
  return parsed.isValid() ? parsed.toDate() : null
}

export const CurrencyFormatter = (value: number) => {
  const intl = useInternationalization()
  if (!value) return intl.formatNumber(0, 'c')

  return intl.formatNumber(value, 'c')
}

export const userCanEdit = (roles?: string[]) => {
  const data = (roles || ['']).find((role: string) => role === 'Administrator')
  return data !== undefined
}

export const isNumeric = (value: string) => {
  return /^-?\d+$/.test(value)
}

export const unique = (value: any, index: any, self: any) => {
  return self.indexOf(self.find((t: any) => t.clientId === value.clientId)) === index
}
export const filterToObject = (filter: any) => {
  var data = {}
  ;((filter !== null && filter.filters) || []).forEach((filter: any) => {
    var {field, value} = filter
    if (value)
      data = {
        ...data,
        [field]: value,
      }
  })
  return data
}

export const capitalizeFirstLetter = (str: string): string => {
  if (str !== null && str.length > 0) return str.charAt(0).toUpperCase() + str.slice(1)
  else return str
}

export const userInitial = (firstName: string, lastName: string) => {
  return (
    firstName.charAt(0).toUpperCase() +
    (lastName !== '' ? lastName.charAt(0).toUpperCase() : firstName.charAt(1).toUpperCase())
  )
}

export const formatPhoneNumber = (input: any) => {
  if (!input) {
    return ''
  }
  // Remove all non-digit characters
  const cleanedInput = input.replace(/\D/g, '')

  // Format the number as 'xx 1234 5678'
  let formattedNumber = ''
  if (cleanedInput.length > 0) {
    formattedNumber += cleanedInput.slice(0, 2)
  }
  if (cleanedInput.length >= 3) {
    formattedNumber += ' ' + cleanedInput.slice(2, 6)
  }
  if (cleanedInput.length >= 7) {
    formattedNumber += ' ' + cleanedInput.slice(6, 10)
  }

  return formattedNumber
}

export const formatDate = (dateString: Date) => {
  const date = new Date(dateString)
  let day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date)
  let month = new Intl.DateTimeFormat('en', {month: 'long'}).format(date)
  let year = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date)
  return `${day} ${month} ${year}`
}

export const getRandomNumber = (min: number, max: number): number => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const getDistinctObjects = <T>(arr: T[], props: string[]): T[] => {
  const keyFn = (obj: T) => props.map((prop) => (obj as any)[prop]).join('|')
  const distinctKeys = new Set(arr.map(keyFn))
  return Array.from(distinctKeys).map((key) => {
    const obj: T = {} as T
    props.forEach((prop, index) => {
      ;(obj as any)[prop] = key.split('|')[index]
    })
    return obj
  })
}

// export const getBase64 = () => {
//     const link = 'C:/C9_Project/BES/src/client/public/images/andrew_sig.png'
//     let canvas = document.createElement('canvas');
//     let context = canvas.getContext('2d');
//     let img = document.querySelector('img');
//     context.drawImage(img, 0, 0);

//     let reader = new Blob(link)
// }

export function formatLongDate(dateString: any) {
  const parts = dateString.split('/')
  const date = new Date(parts[2], parts[0] - 1, parts[1])
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const monthName = monthNames[date.getMonth()]
  const formattedDate = `${date.getDate()}-${monthName}-${date.getFullYear()}`
  return formattedDate
}

export const dateFormatddMMMyyyy = (dateString: any) => {
  const date = new Date(dateString)
  const day = String(date.getDate()).padStart(2, '0')
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const month = monthNames[date.getMonth()]
  const year = date.getFullYear()
  return `${day}-${month}-${year}`
}

export const downloadFileFromBase64 = (base64PDFData: any, fileName: string, mimeType: string) => {
  var binaryData = atob(base64PDFData)
  var blob = new Blob([new Uint8Array(Array.from(binaryData).map((char) => char.charCodeAt(0)))], {
    type: mimeType,
  })

  // Create a URL for the Blob
  var url = window.URL.createObjectURL(blob)
  // Create a link element for download
  var a = document.createElement('a')
  a.href = url

  // Set the download attribute with the desired filename
  a.download = fileName
  // Programmatically click the link to trigger the download
  a.click()
  // Clean up by revoking the Blob URL
  window.URL.revokeObjectURL(url)
}

export const getUTCDate = (startDate: Date) => {
  const currentDate = new Date()
  // if selected picker equals to current date time then convert to utc date
  if (
    currentDate.getDate() === startDate.getDate() &&
    currentDate.getMonth() === startDate.getMonth() &&
    currentDate.getFullYear() === startDate.getFullYear()
  ) {
    const utcDate = new Date(startDate.toISOString())
    const result = new Date(
      utcDate.getFullYear(),
      utcDate.getMonth(),
      utcDate.getDate(),
      currentDate.getUTCHours(),
      currentDate.getUTCMinutes()
    )
    return result
  } else {
    // month/day/year from startDate and time(hour, mins) from currentDateTimeFromJavascript
    return new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes()
    )
  }
}

export const hasPermission = (permission: PAGE_PERMISSION, activePages: string[]) => {
  return activePages.some((page) => page === permission)
}
