import * as yup from 'yup'
export const userFormValidationSchema = yup.object().shape({
  id: yup.string(),
  email: yup.string().email('Invalid email format').required('A valid email address is required!'),
  firstName: yup.string().required('First name is required!'),
  lastName: yup.string().required('Last name is required!'),
  isActive: yup.bool().required('Required.'),
  selectedUserGroups: yup.array().min(1, 'Select at least 1 user group!').required('required'),
  officeId: yup.number().moreThan(0, 'Office is required.'),
})
