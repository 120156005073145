import {dataProvider} from '../../../../setup/data/data-provider'
import {getUTCDate} from '../../../shared/service/utils'
import {CustomFields} from '../components/information/tabs/settings/CustomFields'
import {IBuildingElementSection} from '../models/building-element'
import {IEvaluationRegion} from '../models/evaluation-region'
import {IProjectAssetCustomField} from '../models/project-asset-custom-field'
import {ProjectAssetData} from '../models/project-assets'
import {IOption, IOptionNumberValue, IRole, IUserGroupOption} from '../models/project-model'
import {IAssetValuationReportSheets} from '../models/report'

export const transformSaveAdminRoles = (option: IOption[]) => {
  return (option || []).map((add) => add.value)
}

export const transformSaveProjectAssetData = (projectAsset: ProjectAssetData) => {
  return {
    ...projectAsset,

    inspectionDate: getUTCDate(projectAsset.inspectionDate || new Date()),
    valuationDate: getUTCDate(projectAsset.valuationDate || new Date()),
    projectAssetCustomFields: transformSaveProjectAssetCustomFields(
      projectAsset.projectAssetCustomFields
    ),
  }
}

export const transformSaveProjectAssetCustomFields = (
  projectAssetCustomFields?: IProjectAssetCustomField[]
) => {
  return (projectAssetCustomFields || []).map((custField) => ({
    ...custField,
    value: custField.value === null ? '' : custField.value.toString(),
  }))
}

export const transformDataAdminRoles = (roles: string[]) => {
  return (roles || []).map((role) => ({
    value: role,
    label: role,
  }))
}

export const transformDataStatus = (status: string) => {
  return status === 'Active' ? '1' : '0'
}

export const transformDataRoles = (roles: IRole[]) => {
  return (roles || []).map((role) => ({
    value: role.name,
    label: role.name,
  }))
}

export const transformSaveUserGroups = (option: IOption[]) => {
  return (option || []).map((add) => add.value)
}

export const transformDataUserGroups = (userGroups: IUserGroupOption[]) => {
  return userGroups
}

export const transformDataClientOptions = (clients: IOption[]) => {
  return clients
}

export const transformDataUserOptions = (clients: IOption[]) => {
  return clients
}

export const transformDataOptions = (clients: IOption[]) => {
  return clients
}

export const transformDataEvaluationRegionOptions = (regions: IEvaluationRegion[]): IOption[] => {
  return regions.map((region) => ({
    value: region.code,
    label: region.code,
  }))
}

export const transformDefaultOptions = (data: string[]) => {
  return data.map((result) => ({
    value: result,
    label: result,
  }))
}

export const transformStateOptions = (data: string[]) => {
  return data.map((result) => ({
    value: result,
    label:
      result +
        ' - ' +
        dataProvider.states.find(
          (code) => code.code.toLowerCase().trim() === result.toLowerCase().trim()
        )?.value ?? 'Unknown',
  }))
}

export const transformDefaultNumericOptions = (data: number[]) => {
  return data.map((result) => ({
    value: result,
    label: result,
  }))
}

export const transformBuildingElementOptions = (
  data: IBuildingElementSection[]
): IOptionNumberValue[] => {
  return data.map((result) => ({
    value: result.id,
    label: result.name,
  }))
}

export const formatNumberWithCommas = (value: number) => {
  return '$ ' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const transformUpdateAssetValuationReportSheetForm = (data: IAssetValuationReportSheets) => {
  data.sheets.forEach((sheet) => {
    sheet.dataFields = sheet.selectedDataFields.map((option) => option.value)
    if (sheet.fromDate !== undefined) sheet.fromDateString = transformLocalDate(sheet.fromDate)
    if (sheet.toDate !== undefined) sheet.toDateString = transformLocalDate(sheet.toDate)
  })

  return data
}

export const transformLocalDate = (dateValue?: Date) => {
  const localDay = dateValue?.toLocaleDateString(undefined, {day: 'numeric'})
  const localMonth = dateValue?.toLocaleDateString(undefined, {month: 'numeric'})
  const localYear = dateValue?.toLocaleDateString(undefined, {year: 'numeric'})

  return `${localYear}-${localMonth}-${localDay}`
}
