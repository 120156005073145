import axios from 'axios'

export const PROJECTS = `${process.env.REACT_APP_API_URL}/`

export const getAllProjects: any = async (pageNumber?: any, pageSize?: any) => {
  return await axios.get(
    `${PROJECTS}Project/Pagination?PageNumber=${pageNumber}&PageSize=${pageSize}`
  )
}

export const getProjectsList: any = async (pageNumber?: any, pageSize?: any, sort?: any) => {
  return await axios.get(`${PROJECTS}Project/list`, {
    params: {PageNumber: pageNumber, PageSize: pageSize, Sort: sort},
  })
}

export const getInfoCards: any = async (officeId: number) => {
  return await axios.get(`${PROJECTS}Project/getinfocard`, {
    params: {
      officeId,
    },
  })
}

export const getRecentProjects: any = async (officeId: number) => {
  return await axios.get(`${PROJECTS}Project/getrecentprojects`, {
    params: {
      officeId,
    },
  })
}
