import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import {MsalProvider} from '@azure/msal-react'
import {PublicClientApplication} from '@azure/msal-browser'
import authConfig from './modules/auth/azure/authConfig'
import {TimeoutLogic} from './shared/components/TimeoutLogic'
type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  const msalInstance = new PublicClientApplication(authConfig.msal)

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <TimeoutLogic />
      <BrowserRouter basename={basename}>
        <MsalProvider instance={msalInstance}>
          {/* <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            audience={audience}
            scope={scope}
          > */}
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <Routes />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
          {/* </Auth0Provider> */}
        </MsalProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
