import {
  Control,
  UseFormSetValue,
  FieldErrors,
  UseFormRegister,
  UseFormClearErrors,
  UseFormHandleSubmit,
  useFieldArray,
  useForm,
} from 'react-hook-form'

import {useContext, useEffect, useState} from 'react'
import {IProjectAssetNote, ProjectAssetData, ProjectData} from '../../../models/project-assets'
import {IProjectData} from '../../../models/project-model'
import {ProjectContext} from '../../../context/ProjectContext'
import SectionTitleAddHeader from '../SectionTitleAddHeader'
import {AssetNoteCustomFields} from './AssetNoteCustomFields'
import {PROJECT_ASSET_NOTE} from '../../../constant/project-asset-default'
import {UserModel} from '../../../../auth/models/UserModel'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../../setup'
import {saveProjectAssetNote} from '../../../api/asset-api'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'

type IClientSpecificsProps = {
  control: Control<ProjectAssetData>
  isValid: boolean
  setValue: UseFormSetValue<any>
  errors: FieldErrors<any>
  register: UseFormRegister<any>
  clearErrors: UseFormClearErrors<any>
  handleSubmit: UseFormHandleSubmit<any>
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData[]) => void
  projectId: string
  projectData: IProjectData | ProjectData
  onHandleFormChange: (isDirty: boolean) => void
}

function AssetNotes(props: IClientSpecificsProps) {
  const {selectedData, onHandleFormChange} = props
  const [isAdd, setIsAdd] = useState<boolean>(false)
  const {showToastrNotification} = useContext(ProjectContext)
  const [editedIndex, setEditedIndex] = useState<number | null>(null)
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [noteText, setNoteText] = useState<string>('')

  const handleOnAdd = () => {
    setEditedIndex(-1)
  }

  useEffect(() => {
    setIsAdd(editedIndex !== null)
  }, [editedIndex])

  const handleOnCancel = () => {
    setEditedIndex(null)
    setNoteText('')
    onHandleFormChange(false)
  }

  const {
    control,
    formState: {isValid, isDirty},
    reset,
  } = useForm({
    defaultValues: selectedData,
    reValidateMode: 'onSubmit',
  })

  useEffect(() => {
    onHandleFormChange(isDirty)
  }, [isDirty])

  const onSubmit = async (event: any) => {
    event.preventDefault()
    if (editedIndex === null) return

    const note: IProjectAssetNote =
      editedIndex !== null && editedIndex > -1
        ? projectAssetNotes[editedIndex]
        : {...PROJECT_ASSET_NOTE}
    note.projectAssetId = selectedData.id
    note.note = noteText
    note.userId = user.id

    await saveProjectAssetNote(note).then((response) => {
      if (response) {
        //new note add
        if (note.id === 0) {
          note.id = parseInt(response.data.id)
          note.userName = response.data.userName
          note.lastModified = response.data.lastModified

          var newdata = [
            ...projectAssetNotes,
            {
              ...note,
            },
          ]
          replaceProjectAssetNotes(newdata)
        } else {
          projectAssetNotes[editedIndex].id = parseInt(response.data.id)
          projectAssetNotes[editedIndex].userName = response.data.userName
          projectAssetNotes[editedIndex].lastModified = response.data.lastModified
          replaceProjectAssetNotes([...projectAssetNotes])
        }
        setEditedIndex(null)
        showToastrNotification('Asset Note Saved succesfully', 'success')
      } else {
        showToastrNotification('Error Saving Asset Note. Please try again.', 'error')
      }
    })

    onHandleFormChange(false)
    setNoteText('')
    setEditedIndex(null)
  }

  const {fields: projectAssetNotes, replace: replaceProjectAssetNotes} = useFieldArray({
    control,
    name: 'projectAssetNotes',
    keyName: 'keyIdx',
  })

  useEffect(() => {
    reset({...selectedData})
    onHandleFormChange(false)
  }, [selectedData])

  return (
    <>
      <form onSubmit={onSubmit}>
        <SectionTitleAddHeader
          title='Asset Notes'
          isAdd={isAdd}
          onAdd={handleOnAdd}
          addButtonText={'Add Note'}
          onCancel={handleOnCancel}
          permission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}
        />
        <div id='assetNotes'>
          <div className='row mt-5'>
            <div className='col'>
              <AssetNoteCustomFields
                projectAssetId={selectedData.id}
                projectAssetNotes={projectAssetNotes}
                replaceProjectAssetNotes={replaceProjectAssetNotes}
                editedIndex={editedIndex}
                setEditedIndex={setEditedIndex}
                currentUserId={user.id}
                setNoteText={setNoteText}
                noteText={noteText}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default AssetNotes
