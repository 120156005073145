import {GridCellProps} from '@progress/kendo-react-grid'

export const fullNameInitial = (fullName: string) => {
  var name = fullName.split(' ')

  return (
    name[0].charAt(0).toUpperCase() +
    (name.length > 1 && name[1] !== ''
      ? name[1].charAt(0).toUpperCase()
      : name[0].charAt(1).toUpperCase())
  )
}

export const FullNameFormatCell = (props: GridCellProps) => {
  const {dataItem} = props
  const field = props.field || ''
  const dataValue = dataItem ? (dataItem[field] ? dataItem[field] : '') : ''
  const groups = dataValue.split(',')

  return (
    <td>
      {groups.length > 2 ? (
        <div className='symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2'>
          {groups?.map((item: any, index: any) =>
            item.trim() !== '' ? (
              <div key={index} data-toggle='tooltip' title={item.trim()}>
                <div className='symbol symbol-circle symbol-25px'>
                  <div>
                    <p className='circle-inner'>{fullNameInitial(item.trim())}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div key={index}></div>
            )
          )}
        </div>
      ) : (
        <div>
          {groups.length <= 2 &&
            groups?.map((item: any, index: any) =>
              item.trim() !== '' ? (
                <div key={index} data-toggle='tooltip' title={item.trim()}>
                  <div>
                    <p>
                      <i className='fa fa-user'></i> {item.trim()}
                    </p>
                  </div>
                </div>
              ) : (
                <div key={index}></div>
              )
            )}
        </div>
      )}
    </td>
  )
}
