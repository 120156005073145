import React from 'react'

function TitleHeaderBorder() {
  return (
    <div
      className='mb-4'
      style={{
        borderBottom: '1px solid gray',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
      }}
    />
  )
}

export default TitleHeaderBorder
