import {IAssetValuationReportSheets} from '../models/report'

export const REPORT_SHEETS_DEFAULT: IAssetValuationReportSheets = {
  sheets: [
    {
      title: 'Sheet1',
      assetStatus: 'completed',
      referenceDateType: '',
      selectedDataFields: [
        {value: 'assetId', label: 'Asset ID'},
        {value: 'assetName', label: 'Asset Name'},
      ],
      dataFields: [],
    },
  ],
}
