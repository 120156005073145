import React, {useState, useEffect, useCallback} from 'react'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {GridPageChangeEvent, GridSortChangeEvent} from '@progress/kendo-react-grid'
import {GridSetup} from '../../projects/models/config-default'
import {Initial_GridSetup} from '../../projects/constant/project-default'
import {IAdjustmentMatrix} from '../models/adjustmentmatrix'
import {ADJUSTMENT_MATRIX_FORM_DEFAULT} from '../constant/adjustment-matrix-default'
import {getAdjustmentMatrixPagination} from '../api/adjustment-matrix-api'

export const AdjustmentMatrixContext = React.createContext<any>({})

export const AdjustmentMatrixContextProvider: React.FC = ({children}) => {
  const [selectedData, setSelectedData] = useState<IAdjustmentMatrix>(
    ADJUSTMENT_MATRIX_FORM_DEFAULT
  )
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [totalRows, setTotalRows] = useState<number>(0)
  const [adjustmentMatrix, setAdjustmentMatrix] = useState<IAdjustmentMatrix[]>([])
  const [gridSetup, setGridSetup] = useState<GridSetup>(Initial_GridSetup)
  const [isModalVisible, setModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handlePageChange = (pageProps: GridPageChangeEvent) => {
    setGridSetup({
      ...gridSetup,
      skip: pageProps.page.skip,
      take: pageProps.page.take,
    })
  }

  const handleSortChange = (pageProps: GridSortChangeEvent) => {
    setGridSetup({...gridSetup, sort: pageProps.sort})
  }

  const fetchData = useCallback(
    (filter?: string) => {
      setIsLoading(true)

      var {sort, skip, take} = gridSetup

      getAdjustmentMatrixPagination(skip, take, sort[0], filter)
        .then((response: any) => {
          setAdjustmentMatrix(response.data.items)
          setTotalRows(response.data.totalCount)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    },
    [gridSetup]
  )

  useEffect(() => {
    fetchData(searchFilter)
  }, [gridSetup, fetchData, searchFilter])

  const showToastrNotification = (message: any, type: any) => {
    toastr.options = {
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: false,
    }
    toastr.clear()
    setTimeout(() => {
      if (type === 'success') {
        toastr.success(message)
      } else {
        toastr.error(message)
      }
    }, 300)
  }

  return (
    <AdjustmentMatrixContext.Provider
      value={{
        initialValue: ADJUSTMENT_MATRIX_FORM_DEFAULT,
        selectedData,
        setSelectedData,
        isModalVisible,
        setModalVisible,
        setSearchFilter,
        adjustmentMatrix,
        setAdjustmentMatrix,
        totalRows,
        handlePageChange,
        handleSortChange,
        gridSetup,
        fetchData,
        showToastrNotification,
      }}
    >
      {children}
    </AdjustmentMatrixContext.Provider>
  )
}
